import { useInstanceStore } from '@/store';
import type {
  BaseInstance,
  IBaseInstanceConfig,
} from '@pigello/pigello-matrix';
import { isEqual } from 'lodash';
import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { DefaultValues } from 'react-hook-form';
import { useForm as useHookForm } from 'react-hook-form';
import type { DynamicField, OrderedFields } from './types';

type UseFormProps<T extends BaseInstance, I> = {
  isUpdate?: boolean;
  disabled?: boolean;
  config: IBaseInstanceConfig<T>;
  preMutatedValues?: Partial<T>;
  defaultValues: DefaultValues<T>;
  values?: Partial<T>;
  persistedInstanceId?: string;
  orderedFields?: Array<{
    key: I;
    fields: Array<keyof T>;
  }>;
};

export function useForm<T extends BaseInstance, I extends string = string>({
  isUpdate = false,
  disabled = false,
  config,
  preMutatedValues,
  defaultValues,
  values,
  orderedFields,
  persistedInstanceId,
}: UseFormProps<T, I>) {
  const pathname = usePathname();

  const [hasRegisteredDefaults, setHasRegisteredDefaults] =
    useState<boolean>(false);

  const dirtyFieldsRef = useRef<Partial<T>>({});

  const {
    addInstance,
    updateInstance,
    hasInstance,
    getInstance,
    removeInstance,
  } = useInstanceStore();

  const zIdentifier = useMemo(() => {
    let id = persistedInstanceId ?? `${pathname}-${config.modelName}`;

    if (!isUpdate || persistedInstanceId) return id;

    if (values && values.id != null && values.id.length > 0) {
      id += `-${values.id}`;
    } else if (!hasRegisteredDefaults) {
      return null;
    }
    return id;
  }, [
    pathname,
    config.modelName,
    values,
    isUpdate,
    hasRegisteredDefaults,
    persistedInstanceId,
  ]);

  const zIdentifierRef = useRef(zIdentifier);

  useEffect(() => {
    zIdentifierRef.current = zIdentifier;
  }, [zIdentifier]);

  const hasStoredInstance = useMemo(() => {
    if (!zIdentifier) return false;
    return hasInstance(zIdentifier);
  }, [zIdentifier, hasInstance]);

  const form = useHookForm<T | Partial<T>>({
    defaultValues,
    shouldFocusError: true,
    values,
    disabled,
  });

  useEffect(() => {
    if (!hasStoredInstance || !zIdentifier) return;

    if (isUpdate && !hasRegisteredDefaults) return;

    const savedValues = getInstance(zIdentifier);

    if (!savedValues) {
      console.error(
        'The instance should exists in zustand. But was unable to fetch it.',
        zIdentifier
      );

      return;
    }

    for (const [key, value] of Object.entries(savedValues)) {
      form.setValue(key as keyof typeof values, value as never, {
        shouldDirty: true,
      });
    }
  }, [
    hasStoredInstance,
    getInstance,
    zIdentifier,
    hasRegisteredDefaults,
    isUpdate,
    form,
  ]);

  useEffect(() => {
    if (hasRegisteredDefaults || !isUpdate || defaultValues?.id === '') return;

    setHasRegisteredDefaults(true);
    form.reset({
      ...defaultValues,
    });
  }, [isUpdate, defaultValues, hasRegisteredDefaults, form]);

  useEffect(() => {
    if (preMutatedValues) {
      for (const [key, value] of Object.entries(preMutatedValues)) {
        form.setValue(key as never, value, {
          shouldDirty: true,
        });
      }
    }
  }, []);

  const getDirtyData = useCallback(
    (dirtyFields: Array<keyof T>) => {
      const currentValues = form.getValues();
      return dirtyFields.reduce(
        (acc, field) => {
          acc[field] = currentValues[field];
          return acc;
        },
        {} as T | Partial<T>
      );
    },
    [form]
  );

  const checkStore = useCallback(
    (dirtyInstance: Partial<T>) => {
      if (!zIdentifierRef.current) {
        return false;
      }
      if (!hasInstance(zIdentifierRef.current)) {
        addInstance(zIdentifierRef.current, config.modelName, dirtyInstance);
        return true;
      }
      return false;
    },
    [addInstance, hasInstance, config.modelName]
  );

  const handleDirtyFields = useCallback(
    (currentDirty: Partial<T>) => {
      dirtyFieldsRef.current = currentDirty;

      if (!zIdentifierRef.current) return;

      if (Object.keys(currentDirty).length === 0) {
        removeInstance(zIdentifierRef.current);
        return;
      }

      if (!checkStore(currentDirty)) {
        updateInstance(zIdentifierRef.current, currentDirty);
      }
    },
    [checkStore, removeInstance, updateInstance]
  );

  const handleFormUpdate = useCallback(() => {
    const dirtyData = getDirtyData(
      Object.keys(form.formState.dirtyFields) as Array<keyof T>
    );

    if (isEqual(dirtyFieldsRef.current, dirtyData)) return;

    handleDirtyFields(dirtyData);
  }, [form.formState.dirtyFields, getDirtyData, handleDirtyFields]);

  useEffect(() => {
    const { unsubscribe } = form.watch(() => {
      handleFormUpdate();
    });

    return () => unsubscribe();
  }, [form, handleFormUpdate]);

  const fields = useMemo(
    () =>
      (Object.keys(defaultValues) as Array<keyof T>)
        .map((field) => ({
          [field]: {
            name: field,
            required: config.fields[field].required,
            label: config.fields[field].verboseName,
            description: config.fields[field]?.description,
            control: form.control,
            type: config.fields[field].type,
            readOnly: config.fields[field].readOnly,
            config,
          },
        }))
        .reduce((acc, field) => ({ ...acc, ...field }), {}),
    [config, defaultValues, form.control]
  );

  const chapterFields = useMemo(
    () =>
      orderedFields
        ?.map((chapter) => ({
          [chapter.key]: chapter.fields.map((field) => ({
            name: field,
            required: config.fields[field].required,
            label: config.fields[field].verboseName,
            description: config.fields[field]?.description,
            control: form.control,
            type: config.fields[field].type,
            readOnly: config.fields[field].readOnly,
            config,
          })),
        }))
        .reduce((acc, chapter) => ({ ...acc, ...chapter }), {} as const) ?? {},
    [config, form.control, orderedFields]
  ) as OrderedFields<I, T>;

  return {
    ...form,
    fields: fields as DynamicField<T>,
    orderedFields: chapterFields,
    getDirtyData: () =>
      getDirtyData(
        Object.keys(form.formState.dirtyFields) as Array<keyof typeof values>
      ),
  };
}
