import { CategoriesField } from '@/components/form/dynamic-field/components/categories-field';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import type { useForm } from '@/hooks/useForm';
import type { DynamicField } from '@/hooks/useForm/types';
import {
  InformationCircleIcon,
  MinusIcon,
  PlusIcon,
} from '@heroicons/react/16/solid';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { useEffect, useRef, useState } from 'react';

export function AdvancedCategories<Instance extends BaseInstance>({
  form,
  fields,
  advancedSettingsTitle,
}: {
  form: ReturnType<typeof useForm<Instance>>;
  fields: DynamicField<Instance>[keyof Instance][];
  advancedSettingsTitle?: string;
}) {
  const advancedRef = useRef<HTMLDivElement>(null);
  const [showAdvanced, setShowAdvanced] = useState(false);
  useEffect(() => {
    if (showAdvanced) {
      advancedRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [showAdvanced]);

  return (
    <>
      <div>
        <Button
          onClick={() => setShowAdvanced((prev) => !prev)}
          type='button'
          variant={'link'}
          className='h-auto p-0'
        >
          {showAdvanced
            ? `Dölj ${advancedSettingsTitle ? advancedSettingsTitle : 'avancerade inställningar'} `
            : `Visa ${advancedSettingsTitle ? advancedSettingsTitle : 'avancerade inställningar'} `}
          {showAdvanced ? (
            <MinusIcon className='ml-1 size-4' />
          ) : (
            <PlusIcon className='ml-1 size-4' />
          )}
        </Button>
      </div>
      {showAdvanced && (
        <div className='grid gap-4' ref={advancedRef}>
          <Alert>
            <InformationCircleIcon className='size-4' />
            <AlertTitle>Avancerade inställningar</AlertTitle>
            <AlertDescription className='text-sm'>
              De avancerade inställningarna tillåter att styrning av
              inställningen görs mot specifika objektskategorier. Om inget val
              görs kommer inställningen vara tillgänglig och användas för alla
              kategorier som inte har någon specifik styrd inställning.
            </AlertDescription>
          </Alert>
          <div className='grid grid-cols-1 gap-4'>
            <CategoriesField form={form} formFields={fields} />
          </div>
        </div>
      )}
    </>
  );
}
