import {
  defaultBooleanFilter,
  defaultRelationFilter,
  defaultSelectFilter,
  defaultStringFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import { ArticleCategoryConfig, ArticleConfig } from '@pigello/pigello-matrix';

const articleRentFilters: IFilter[] = [
  {
    title: ArticleConfig.fields.name.verboseName,
    externalFieldName: ArticleConfig.fields.name.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: ArticleConfig.fields.category.verboseName,
    externalFieldName: ArticleConfig.fields.category.externalFieldName,
    relationModelName: ArticleCategoryConfig.modelName,
    ...defaultRelationFilter,
  },
  {
    title: ArticleConfig.fields.revenueLossAccount.verboseName,
    externalFieldName:
      ArticleConfig.fields.revenueLossAccount.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: ArticleConfig.fields.vat.verboseName,
    externalFieldName: ArticleConfig.fields.vat.externalFieldName,
    choices: ArticleConfig.fields.vat.choices,
    ...defaultSelectFilter,
  },
  {
    title: ArticleConfig.fields.ignoreDynamiclyCalculatedVat.verboseName,
    externalFieldName:
      ArticleConfig.fields.ignoreDynamiclyCalculatedVat.externalFieldName,
    ...defaultBooleanFilter,
  },
  {
    title: ArticleConfig.fields.allowSpaceBoundRevenueRows.verboseName,
    externalFieldName:
      ArticleConfig.fields.allowSpaceBoundRevenueRows.externalFieldName,
    ...defaultBooleanFilter,
  },
  {
    title: ArticleConfig.fields.archived.verboseName,
    externalFieldName: ArticleConfig.fields.archived.externalFieldName,
    ...defaultBooleanFilter,
  },
];

export { articleRentFilters };
