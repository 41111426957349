'use client';

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { UsersIcon } from '@heroicons/react/16/solid';
import PreviewRecipient from './preview-recipient';
import type { IInvoiceRecipient } from './types';

export default function PreviewRecipients({
  recipients,
}: {
  recipients: IInvoiceRecipient[];
}) {
  const getIsPrimary = (recipient: IInvoiceRecipient) => {
    return recipients.reduce((acc, cur) => {
      if (recipient.priority > cur.priority) return false;

      return acc;
    }, true);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className='flex items-center'>
          <UsersIcon className='mr-2 size-4' />
          Mottagare
        </CardTitle>
      </CardHeader>
      <CardContent className='grid grid-cols-2 items-start gap-4'>
        {recipients?.map((recipient) => (
          <PreviewRecipient
            primary={getIsPrimary(recipient)}
            recipient={recipient}
            key={recipient.id}
          />
        ))}
      </CardContent>
    </Card>
  );
}
