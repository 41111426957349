import { DynamicField } from '@/components/form/dynamic-field';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { useCreateCustomerColumnValue } from '@/config/annotating/customercolumnvalue/create';
import { useDeleteCustomerColumnValue } from '@/config/annotating/customercolumnvalue/delete';
import { useMutateCustomerColumnValue } from '@/config/annotating/customercolumnvalue/mutate';
import { useForm } from '@/hooks/useForm';
import { handleFormErrors } from '@/hooks/useForm/utils';
import type { ErrorResponse } from '@/requests/types';
import type { BaseInstance } from '@pigello/pigello-matrix';
import {
  CustomerColumnValueConfig,
  getCleanCustomerColumnValue,
} from '@pigello/pigello-matrix';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import type { CustomerColumnsModalContentData } from './customer-columns-modal';

export function UpdateCustomerColumnsForm<Instance extends BaseInstance>(
  props: CustomerColumnsModalContentData<Instance> & { closeModal: () => void }
) {
  const queryClient = useQueryClient();
  const form = useForm({
    config: CustomerColumnValueConfig,
    defaultValues: getCleanCustomerColumnValue(),
    values: props.customerColumn,
    isUpdate: !!props.customerColumn,
    preMutatedValues: {
      columnType: {
        id:
          props.customerColumn?.columnType?.id ??
          props.customerColumnType?.id ??
          '',
      },
      objectId: props.customerColumn?.objectId ?? props.objectId ?? '',
      contentType: {
        id:
          props.customerColumn?.contentType?.id ??
          props.config?.contentType ??
          '',
      },
    },
  });

  const { mutateAsync } = useMutateCustomerColumnValue({});
  const { mutateAsync: create } = useCreateCustomerColumnValue({});
  const { mutateAsync: deleteCustomerValue } = useDeleteCustomerColumnValue({});

  const onSubmit = () => {
    const formValues = form.getDirtyData();
    if (!props.customerColumn?.value) {
      toast.promise(
        create({
          body: formValues,
        }),
        {
          loading: 'Sparar värde...',
          success: () => {
            queryClient.invalidateQueries({
              queryKey: [props.config?.modelName],
            });
            form.reset();
            props.closeModal();
            return 'Värde uppdaterat';
          },
          error: (error: ErrorResponse) => {
            const field = handleFormErrors(error, form);
            return field;
          },
        }
      );
      return;
    }
    if (formValues.value?.trim() === '') {
      toast.promise(
        deleteCustomerValue({
          id: props.customerColumn.id,
        }),
        {
          loading: 'Uppdaterar värde...',
          success: () => {
            queryClient.invalidateQueries({
              queryKey: [props.config?.modelName],
            });
            form.reset();
            props.closeModal();
            return 'Värde uppdaterat';
          },
          error: (error: ErrorResponse) => {
            const field = handleFormErrors(error, form);
            return field;
          },
        }
      );
      return;
    }
    toast.promise(
      mutateAsync({
        body: formValues,
        id: props.customerColumn.id,
      }),
      {
        loading: 'Uppdaterar värde...',
        success: () => {
          queryClient.invalidateQueries({
            queryKey: [props.config?.modelName],
          });
          form.reset();
          props.closeModal();
          return 'Värde uppdaterat';
        },
        error: (error: ErrorResponse) => {
          const field = handleFormErrors(error, form);
          return field;
        },
      }
    );
  };

  return (
    <Form {...form}>
      <form className='grid gap-4' onSubmit={form.handleSubmit(onSubmit)}>
        <DynamicField formField={{ ...form.fields.value, required: false }} />
        <div className='flex items-center gap-2 justify-self-end'>
          <Button
            onClick={() => {
              form.reset();
              props.closeModal();
            }}
            variant={'outline'}
            type='button'
          >
            Avbryt
          </Button>
          <Button disabled={!form.formState.isDirty} type='submit'>
            Spara
          </Button>
        </div>
      </form>
    </Form>
  );
}
