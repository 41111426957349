import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useToast } from '@/hooks/useToast';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import { useMutateGenericInstance } from '@/requests/hooks';
import { mutateGenericInstance } from '@/requests/mutating';
import type { ErrorResponse } from '@/requests/types';
import { XMarkIcon } from '@heroicons/react/16/solid';
import {
  type BaseContract,
  type BaseInstance,
  type IBaseInstanceConfig,
  type ModelName,
} from '@pigello/pigello-matrix';
import { useEffect, useState } from 'react';

export type CancelCancelledContractModalContentData<
  Instance extends BaseInstance,
> = {
  modelName: ModelName;
  id: string;
  config: IBaseInstanceConfig<Instance>;
};

interface Props<Instance extends BaseInstance = BaseInstance>
  extends GetModalContentProps {
  closeModal: () => void;
  contentData: CancelCancelledContractModalContentData<Instance>;
}

function CancelCancelledContractSignContractModal<
  Instance extends BaseInstance = BaseInstance,
>({ closeModal, contentData: { modelName, id, config } }: Props<Instance>) {
  const { addToast } = useToast();
  const [randomNumbers, setRandomNumbers] = useState(0);
  const [randomSolve, setRandomSolve] = useState('');

  useEffect(() => {
    const random = Math.floor(1000 + Math.random() * 9000);

    setRandomNumbers(random);
  }, []);
  const mutation = useMutateGenericInstance<Instance>(
    async (options) =>
      mutateGenericInstance<Instance>({ ...options, config: config }),
    {},
    modelName
  );
  const onSubmit = () => {
    addToast({
      type: 'promise',
      promise: mutation.mutateAsync({
        id,
        body: {
          cancellationTime: null,
          cancellationSignedTime: null,
          cancelledByCounterpart: null,
          cancellationReason: null,
      } as Partial<Instance> & BaseContract,
      }),
      loading: 'Uppdaterar avtalet...',
      success: () => {
        closeModal();
        return 'Avtalet har uppdaterats';
      },
      error: (error: ErrorResponse) => {
        const field = handleFormErrors(error);
        return field;
      },
    });
  };

  return (
    <AlertDialog defaultOpen>
      <AlertDialogContent className='gap-0 p-0'>
        <AlertDialogHeader className='flex flex-row items-center justify-between  border-b p-4'>
          <AlertDialogTitle>Ångra uppsägning utav avtal</AlertDialogTitle>
          <Button variant='outline' size={'icon-sm'} onClick={closeModal}>
            <XMarkIcon className='size-4' />
          </Button>
        </AlertDialogHeader>
        <div className='p-4'>
          <div className='rounded border bg-muted p-4'>
            <div className='mb-4 flex items-center'>
              <span className='p text-muted-foreground'>
                Fyll i dessa siffror för att bekräfta
              </span>

              <div className='ml-2 rounded-sm bg-foreground p-1 text-background'>
                <code>{randomNumbers}</code>
              </div>
            </div>
            <Input
              className='bg-background'
              placeholder='Fyll i siffrorna för att bekräfta'
              value={randomSolve}
              onChange={(e) => setRandomSolve(e.target.value)}
            />
          </div>
        </div>
        <AlertDialogFooter className='border-t p-4'>
          <Button type='button' variant={'outline'} onClick={closeModal}>
            Avbryt
          </Button>
          <Button
            disabled={String(randomSolve) !== String(randomNumbers)}
            onClick={onSubmit}
          >
            Bekräfta
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export const getCancelCancelledContractSignContractModalContent = (
  props: Props
): ModalContent => {
  return {
    body: <CancelCancelledContractSignContractModal {...props} />,
    isCloseOnEscape: true,
    closeButton: true,
    externalModalHandling: true,
  };
};
