import { raise } from '@/lib/utils';
import type {
  BaseInstance,
  IBaseInstanceConfig,
  ModelName,
} from '@pigello/pigello-matrix';
import { getConfig } from '@pigello/pigello-matrix';
import { destroy } from './api/destroy';
import { toExternalFieldNames, toInternalFieldNames } from './instanceMapper';
import type { GenericMutateOptions } from './types';

export const deleteGenericInstance = async <Instance extends BaseInstance>({
  modelName,
  id,
  body,
  options,
  config,
}: {
  modelName?: ModelName;
  id: string;
  body?: Partial<Instance>;
  options?: GenericMutateOptions;
  config?: IBaseInstanceConfig<Instance>;
}) => {
  if (!config && !modelName) raise('No config or modelname');

  const actualConfig = config
    ? config
    : await getConfig<Instance>(modelName ?? 'unset');

  const url =
    id === ''
      ? `${actualConfig.instanceUrl}/`
      : `${actualConfig.instanceUrl}/${id}/`;

  const bodyToSend = body
    ? await toExternalFieldNames(actualConfig, body, options?.convertRelations)
    : {};

  const res = await destroy<Instance>({
    url: url,
    body: bodyToSend,
  });

  return await toInternalFieldNames<Instance>(actualConfig, res.data);
};
