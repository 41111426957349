import { cn } from '@/lib/utils';
import {
  ArrowsPointingOutIcon,
  ArrowTrendingUpIcon,
  BellSlashIcon,
  BuildingOffice2Icon,
  BuildingOfficeIcon,
  CalculatorIcon,
  ChartBarSquareIcon,
  ComputerDesktopIcon,
  DocumentCurrencyEuroIcon,
  DocumentTextIcon,
  HomeIcon,
  InboxIcon,
  RectangleGroupIcon,
  RectangleStackIcon,
  Square2StackIcon,
  Square3Stack3DIcon,
  TruckIcon,
  UserIcon,
} from '@heroicons/react/16/solid';
import type { ModelName } from '@pigello/pigello-matrix';

export const getTabIcon = (
  modelName: ModelName,
  className?: string
): JSX.Element | null => {
  const iconClass = cn('size-4 min-h-[16px] min-w-[16px]', className);

  const icons: Partial<Record<ModelName, JSX.Element>> = {
    tenant: <UserIcon className={iconClass} />,
    building: <BuildingOfficeIcon className={iconClass} />,
    realestate: <BuildingOffice2Icon className={iconClass} />,
    apartment: <HomeIcon className={iconClass} />,
    parkingspot: <TruckIcon className={iconClass} />,
    parkinglot: <TruckIcon className={iconClass} />,
    outdoorsection: <Square2StackIcon className={iconClass} />,
    outdoorarea: <Square3Stack3DIcon className={iconClass} />,
    company: <RectangleStackIcon className={iconClass} />,
    region: <ArrowsPointingOutIcon className={iconClass} />,
    commonarea: <RectangleGroupIcon className={iconClass} />,
    industrialpremises: <BuildingOfficeIcon className={iconClass} />,
    apartmentcontract: <DocumentTextIcon className={iconClass} />,
    industrialpremisescontract: <DocumentTextIcon className={iconClass} />,
    outdoorsectioncontract: <DocumentTextIcon className={iconClass} />,
    parkingspotcontract: <DocumentTextIcon className={iconClass} />,
    othercontract: <DocumentTextIcon className={iconClass} />,
    blockcontract: <DocumentTextIcon className={iconClass} />,
    organizationuser: <UserIcon className={iconClass} />,
    signabledocument: <DocumentTextIcon className={iconClass} />,
    invoice: <DocumentTextIcon className={iconClass} />,
    invoicepayment: <DocumentCurrencyEuroIcon className={iconClass} />,
    monitoringconfiguration: <InboxIcon className={iconClass} />,
    sensor: <ArrowTrendingUpIcon className={iconClass} />,
    brfapartment: <HomeIcon className={iconClass} />,
    automaticdistributionquota: <CalculatorIcon className={iconClass} />,
    notification: <BellSlashIcon className={iconClass} />,
    measurementseries: <ChartBarSquareIcon className={iconClass} />,
    verificationgroup: <ComputerDesktopIcon className={iconClass} />,
    verification: <ComputerDesktopIcon className={iconClass} />,
  };

  return icons[modelName] ?? null;
};
