'use client';
import { DefaultUpdateInstanceForm } from '@/components/form/default-edit-form/detail-edit-form';
import type { Chapter } from '@/components/form/types';
import { useGetGenerics } from '@/hooks/useGetGenerics';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import type {
  BaseInstance,
  IBaseInstanceConfig,
} from '@pigello/pigello-matrix';
export type UpdateSingleInstanceModalResponse = Promise<unknown>;

export type UpdateSingleInstanceModalContentData<
  Instance extends BaseInstance = BaseInstance,
> = {
  title: string;
  description?: string;
  chapters?: Chapter<Instance>;
  config: IBaseInstanceConfig<Instance>;
  id: string;
  onSuccess?: (data: Instance) => void;
};

export interface UpdateSingleInstanceModalProps extends GetModalContentProps {
  contentData: UpdateSingleInstanceModalContentData;
}

function UpdateSingleInstanceModal({
  closeModal,
  contentData: { config, title, chapters, description, id, onSuccess },
}: UpdateSingleInstanceModalProps) {
  const { cleanInstance } = useGetGenerics(config.modelName);
  if (!cleanInstance) return <></>;
  return (
    <DefaultUpdateInstanceForm
      id={id}
      title={title}
      description={description}
      chapters={chapters}
      cleanInstance={() => ({ ...cleanInstance })}
      config={config}
      closeModal={closeModal}
      onSuccess={onSuccess}
    />
  );
}

export const getUpdateSingleInstanceModalContent = ({
  ...props
}: UpdateSingleInstanceModalProps): ModalContent => {
  return {
    body: <UpdateSingleInstanceModal {...props} />,
    externalModalHandling: true,
    isCloseOnEscape: false,
  };
};
