import { useModal } from './useModal';

type FormDirtyOptions = {
  isDirty: boolean;
  resetFunction?: () => void;
  closeModal: () => void;
};

export function useIsFormDirty({
  isDirty,
  resetFunction,
  closeModal,
}: FormDirtyOptions) {
  const { openIsFormDirtyModal } = useModal();
  const confirmClose = () => {
    if (isDirty) {
      return openIsFormDirtyModal({
        onConfirm() {
          resetFunction?.();
          closeModal();
        },
      });
    }
    resetFunction?.();
    closeModal();
  };
  return { confirmClose };
}
