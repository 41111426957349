'use client';

import { useGetTotalInvoice } from '@/components/detail/invoice/hooks/use-get-invoice';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { DialogFooter } from '@/components/ui/detail-dialog';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import OverlayLoader from '@/components/ui/overlay-loader';
import { useToast } from '@/hooks/useToast';
import { raise } from '@/lib/utils';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import { postApi } from '@/requests/api';
import { BASE_BACKEND_URL } from '@/requests/constants';
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/16/solid';
import type { InvoiceRecipient } from '@pigello/pigello-matrix';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import RecipientCard from './recipient-card';
import SelectRecipientPopover from './select-recipient';

export type SendInvoiceResponse = Promise<boolean>;

export type SendInvoiceContentData = {
  invoiceId?: string;
};

interface Props extends GetModalContentProps {
  closeModal: () => void;
  contentData: SendInvoiceContentData;
}

function SendInvoice({ closeModal, contentData }: Props) {
  const [selectedRecipients, setSelectedRecipients] = useState<
    InvoiceRecipient[]
  >([]);
  const [
    selectedDistributionInstructions,
    setSelectedDistributionInstructions,
  ] = useState<Record<string, number[][]>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addToast } = useToast();
  const { invoiceId } = contentData;
  const { invoiceRecipients, isLoading } = useGetTotalInvoice({
    id: invoiceId ?? raise('No invoice id'),
  });

  const recipients = invoiceRecipients?.list ?? [];

  useEffect(() => {
    if (invoiceRecipients?.list) {
      setSelectedRecipients(invoiceRecipients.list);

      const initialInstructions: Record<string, number[][]> = {};

      invoiceRecipients.list.forEach((recipient: InvoiceRecipient) => {
        initialInstructions[recipient.id] =
          recipient.invoiceDistributionInstruction;
      });

      setSelectedDistributionInstructions(initialInstructions);
    }
  }, [invoiceRecipients]);

  const disabled =
    !isEmpty(selectedDistributionInstructions) &&
    selectedRecipients.some((recipient) =>
      isEmpty(selectedDistributionInstructions[recipient.id]?.at(0))
    );

  const selectableRecipients = recipients
    .filter((r) => !selectedRecipients.find((sr) => sr.id === r.id))
    .map((r) => r.id);

  return (
    <Dialog open onOpenChange={() => closeModal()}>
      <DialogContent size='md' className='p-0 '>
        <DialogHeader className='flex w-full flex-row items-center justify-between border-b px-4 py-2'>
          <DialogTitle>Skicka avi</DialogTitle>
          <Button variant='outline' size={'icon-sm'} onClick={closeModal}>
            <XMarkIcon className='size-4' />
          </Button>
        </DialogHeader>

        {!isLoading && recipients.length === 0 && (
          <div className='flex items-center justify-center p-12 text-sm'>
            Det saknas mottagare att skicka till
          </div>
        )}

        {recipients.length > 0 && (
          <div className='grid max-h-[80vh] grid-cols-2 gap-4 overflow-y-auto p-4'>
            {(isSubmitting || isLoading) && <OverlayLoader />}
            {selectedRecipients?.map((recipient) => (
              <RecipientCard
                key={recipient.id}
                recipient={recipient}
                onClick={() => {
                  setSelectedRecipients(
                    selectedRecipients.filter(
                      (selectedRecipient) =>
                        selectedRecipient.id !== recipient.id
                    )
                  );

                  setSelectedDistributionInstructions((prev) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { [recipient.id]: removedValue, ...rest } = prev;
                    return rest;
                  });
                }}
                onSelected={(instructions) =>
                  setSelectedDistributionInstructions({
                    ...selectedDistributionInstructions,
                    [recipient.id]: [
                      instructions.map((instruction) => Number(instruction.v)),
                    ],
                  })
                }
                currentValues={
                  selectedDistributionInstructions[recipient.id]?.at(0) ?? []
                }
              />
            ))}
            {selectedRecipients.length !== recipients.length && (
              <SelectRecipientPopover
                ids={selectableRecipients}
                onSelected={(selectedId) => {
                  const newSelectedRecipient = invoiceRecipients?.list?.find(
                    (recipient) => recipient.id === selectedId
                  );

                  if (!newSelectedRecipient) return;

                  setSelectedRecipients(
                    [...selectedRecipients, newSelectedRecipient].filter(
                      (recipient) => recipient !== undefined
                    ) || []
                  );
                  setSelectedDistributionInstructions({
                    ...selectedDistributionInstructions,
                    [selectedId]:
                      newSelectedRecipient.invoiceDistributionInstruction,
                  });
                }}
              >
                <Card className='col-span-1 flex h-full min-h-40 items-center justify-center'>
                  <Button
                    className='transition-opacity duration-300 hover:bg-transparent hover:opacity-80'
                    variant='ghost'
                  >
                    <PlusCircleIcon className='size-10 text-primary' />
                  </Button>
                </Card>
              </SelectRecipientPopover>
            )}
          </div>
        )}

        <DialogFooter className='border-t px-4 py-2'>
          <Button
            disabled={disabled}
            onClick={async () => {
              try {
                setIsSubmitting(true);
                await postApi<Response>({
                  url: `${BASE_BACKEND_URL}/accounting/pdf/force_file_creation/`,
                  body: JSON.stringify({
                    ids: [{ id: invoiceId }],
                    manual_distribution_instruction:
                      selectedDistributionInstructions,
                  }),
                });

                addToast({
                  type: 'success',
                  title: 'Avi skickad',
                });

                setIsSubmitting(false);
                closeModal();
              } catch (error) {
                addToast({
                  type: 'error',
                  title: `Utskick misslyckades: ${error}`,
                });

                setIsSubmitting(false);
              }
            }}
          >
            Skicka
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export const getSendInvoiceContent = (props: Props): ModalContent => {
  return {
    title: 'Skicka avi',
    body: <SendInvoice {...props} />,
    isCloseOnEscape: true,
    closeButton: true,
    externalModalHandling: true,
  };
};
