 import type { InvoicePayment } from '@pigello/pigello-matrix';

import { InvoicePaymentConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetInvoicePayment = (
  options: useGetGenericInstanceProps<InvoicePayment>
) => {
  return useGetInstance<InvoicePayment>({
    ...options,
    config: InvoicePaymentConfig,
    modelName: 'invoicepayment'
  });
};

export const useGetInvoicePaymentList = (
  options: useGetGenericListProps<InvoicePayment>
) => {
  return useGetList<InvoicePayment>({
    ...options,
    config: InvoicePaymentConfig,
    modelName: 'invoicepayment'
  });
};
