/* eslint-disable @next/next/no-img-element */
import { Icons } from '@/components/icons';
import { cn } from '@/lib/utils';
import {
  FolderIcon,
  PhotoIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/16/solid';
import type { Row } from '../types';

const getSize = {
  small: 'size-6',
  medium: 'size-9',
  large: 'size-16',
} as const;

export function GetIcon({
  item,
  size,
  name,
}: {
  item?: Row;
  name?: string;
  size: 'small' | 'medium' | 'large';
}) {
  if (item?.type === 'folder') {
    return item?.private ? (
      <Icons.privateFolder
        style={item?.color ? { color: item.color } : undefined}
        className={cn('shrink-0', getSize[size])}
      />
    ) : (
      <FolderIcon
        style={item?.color ? { color: item.color } : undefined}
        className={cn('shrink-0', getSize[size])}
      />
    );
  }
  const extension =
    item?.content?.name.split('.').pop() ?? name?.split('.').pop();

  switch (extension) {
    case 'jpg':
      return (
        <PhotoIcon className={cn('shrink-0 text-primary', getSize[size])} />
      );
    case 'png':
      return (
        <PhotoIcon className={cn('shrink-0 text-primary', getSize[size])} />
      );
    case 'jpeg':
      return (
        <PhotoIcon className={cn('shrink-0 text-primary', getSize[size])} />
      );
    case 'pdf':
      return (
        <Icons.pdf className={cn('shrink-0 text-destructive', getSize[size])} />
      );
    case 'xlsx':
      return (
        <Icons.excel className={cn('shrink-0 text-green-600', getSize[size])} />
      );
    case 'zip':
      return (
        <Icons.zip className={cn('shrink-0 text-yellow-600', getSize[size])} />
      );
    case 'doc':
      return (
        <Icons.doc className={cn('shrink-0 text-blue-600', getSize[size])} />
      );
    case 'txt':
      return (
        <Icons.txt className={cn('shrink-0 text-gray-600', getSize[size])} />
      );
    case 'stl':
      return (
        <Icons.stl className={cn('shrink-0 text-orange-600', getSize[size])} />
      );
    default:
      return (
        <QuestionMarkCircleIcon className={cn('shrink-0', getSize[size])} />
      );
  }
}
