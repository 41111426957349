 import type { Invoice } from '@pigello/pigello-matrix';

import { InvoiceConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetInvoice = (
  options: useGetGenericInstanceProps<Invoice>
) => {
  return useGetInstance<Invoice>({
    ...options,
    config: InvoiceConfig,
    modelName: 'invoice'
  });
};

export const useGetInvoiceList = (
  options: useGetGenericListProps<Invoice>
) => {
  return useGetList<Invoice>({
    ...options,
    config: InvoiceConfig,
    modelName: 'invoice'
  });
};
