import { SimpleTable } from '@/components/data-table/custom-tables/simple-table';
import { AnnulledModal } from '@/components/detail/invoice/components/annulled-modal';
import { DateTimePicker } from '@/components/form/dynamic-field/components/date-field/date-time-picker';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { DetailModalLink } from '@/components/ui/detailModalLink';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Label, labelVariants } from '@/components/ui/label';
import OverlayLoader from '@/components/ui/overlay-loader';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useGetDebtInvoice } from '@/config/accounting/debt/debtinvoice/client';
import { useGetInvoicePayment } from '@/config/accounting/payments/invoicepayment/client';
import { useMutateInvoicePayment } from '@/config/accounting/payments/invoicepayment/mutate';
import { toReadableDate } from '@/lib/dates';
import { Urls } from '@/lib/urls';
import { cn, ObjectEntries, toMoneyString, typeGuard } from '@/lib/utils';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import type { ErrorResponse } from '@/requests/types';
import { BanknotesIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { parseDate } from '@internationalized/date';
import {
  InvoiceConfig,
  InvoicePaymentConfig,
  PaymentMethodConfig,
  type InvoicePayment,
} from '@pigello/pigello-matrix';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { InvoiceColumns } from './invoice-columns';
import { PaymentStatusBadge } from './payment-status-badge';

export type MatchPaymentResponse = Promise<boolean>;

type InvoicePaymentRecipient =
  | InvoicePayment['tenant']
  | InvoicePayment['subTenant']
  | InvoicePayment['organizationUser'];

export type MatchPaymentContentData = {
  paymentId: string;
};

interface Props extends GetModalContentProps {
  closeModal: () => void;
  contentData: MatchPaymentContentData;
}

function MatchPayment({ closeModal, contentData }: Props) {
  const { mutateAsync } = useMutateInvoicePayment({});
  const [movePayment, setMovePayment] = useState<boolean>(false);
  const [removePayment, setRemovePayment] = useState<string>();
  const [date, setDate] = useState<string>();
  const [selectedInvoice, setSelectedInvoice] = useState<string>();
  const { data, isPending } = useGetInvoicePayment({
    id: contentData.paymentId,
    nested: getRelationFieldNames(InvoicePaymentConfig),
  });

  const { data: debtInvoice } = useGetDebtInvoice({
    id: data?.debtInvoice?.id,
    nested: ['invoice'],
    enabled: !!data?.debtInvoice?.id,
  });

  useEffect(
    function setPaymentDate() {
      if (data?.paymentDate) {
        setDate(data.paymentDate);
      }
    },
    [data?.paymentDate]
  );
  const matchPayment = () => {
    if (!selectedInvoice) {
      toast.error('Vänligen välj en avi att matcha mot');
      return;
    }
    toast.promise(
      mutateAsync({
        id: contentData.paymentId,
        body: {
          paymentDate: date,
          registrationDate: date,
          originalInvoice: { id: selectedInvoice },
        },
      }),
      {
        loading: 'Matchar betalning...',
        success: () => {
          setSelectedInvoice(undefined);
          closeModal();
          return 'Betalningen har matchats';
        },
        error: (error: ErrorResponse) => {
          const err = ObjectEntries(error.errorData).map(([key, value]) => {
            return `${key}: ${value}`;
          });
          return `Kunde inte matcha betalningen ${' - ' + err}`;
        },
      }
    );
  };

  const recipient = data?.tenant ?? data?.subTenant ?? data?.organizationUser;
  const recipientName = typeGuard<NonNullable<InvoicePaymentRecipient>>(
    recipient,
    'communicationName'
  )
    ? recipient?.communicationName
    : '-';
  const isNotMatched =
    !data?.invoice?.id &&
    !data?.debtInvoice?.id &&
    ((!!recipient?.id && !data?.originalInvoice?.id) ||
      (!recipient?.id && !!data?.originalInvoice?.id));

  const invoice =
    (data?.debtInvoice && debtInvoice?.invoice) ??
    data?.originalInvoice ??
    data?.invoice;

  return (
    <>
      {removePayment && (
        <AnnulledModal
          invoicePaymentId={removePayment}
          closeFunction={() => {
            setRemovePayment(undefined);
          }}
        />
      )}
      <Dialog open onOpenChange={() => closeModal()}>
        <DialogContent size='3xl' className='relative p-0'>
          {isPending && <OverlayLoader message='Hämtar betalning...' />}
          <DialogHeader className='flex flex-col'>
            <div className='flex w-full flex-row items-center justify-between'>
              <DialogTitle>
                {isPending
                  ? 'Laddar...'
                  : data?.annulled
                    ? 'Annullerad betalning'
                    : data?.originalInvoice?.id ||
                        data?.invoice?.id ||
                        data?.debtInvoice?.id
                      ? 'Matchad betalning'
                      : 'Ej matchad betalning'}
              </DialogTitle>
              <Button variant='outline' size={'icon-sm'} onClick={closeModal}>
                <XMarkIcon className='size-4' />
              </Button>
            </div>
          </DialogHeader>
          <div className='max-h-[calc(var(--window-keeper-height)-175px)] min-h-[min(0px,calc(var(--window-keeper-height)-175px))] overflow-hidden overflow-y-auto p-4'>
            <Card>
              <CardHeader className='flex flex-row items-center gap-2'>
                <CardTitle>Inbetalningsinformation</CardTitle>
                {data ? (
                  <PaymentStatusBadge payment={data} />
                ) : (
                  <Skeleton className='h-4 w-24' />
                )}
                {data?.externalTransactionId ? (
                  <Badge variant={'green'}>Inläst via fil</Badge>
                ) : (
                  <Badge variant={'yellow'}>Manuellt skapad</Badge>
                )}
              </CardHeader>
              <CardContent
                className={cn(
                  'grid grid-cols-6 gap-2',
                  isNotMatched && 'grid-cols-4'
                )}
              >
                <div className='grid gap-2'>
                  <div className={cn(labelVariants())}>Mottagare</div>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className='truncate text-sm'>{recipientName}</div>
                    </TooltipTrigger>
                    <TooltipContent className='text-sm'>
                      {recipientName}
                    </TooltipContent>
                  </Tooltip>
                </div>
                <div className='grid gap-2'>
                  <div className={cn(labelVariants())}>Inbetalningsdatum</div>
                  <div className='text-sm'>
                    {toReadableDate(data?.paymentDate)}
                  </div>
                </div>
                <div className='grid gap-2'>
                  <div className={cn(labelVariants())}>Inbetalt belopp</div>
                  <div className='text-sm'>
                    {toMoneyString(
                      (data?.value ?? 0) + (data?.splitFromPayment?.value ?? 0)
                    )}
                  </div>
                </div>
                {!isNotMatched && (
                  <div className='grid gap-2'>
                    <div className={cn(labelVariants())}>Nyttjat belopp</div>
                    <div className='text-sm'>
                      {toMoneyString(data?.value ?? 0)}
                    </div>
                  </div>
                )}
                <div className='grid gap-2'>
                  <div className={cn(labelVariants())}>OCR, betalning</div>
                  <div className='text-sm'>{data?.ocrNumber ?? '-'}</div>
                </div>
                {isNotMatched && <hr className='col-span-full' />}
                <div className='grid gap-2'>
                  <div className={cn(labelVariants())}>Betalningsreferens</div>
                  <div className='text-sm'>{data?.referenceText ?? '-'}</div>
                </div>
                {!isNotMatched && <hr className='col-span-full' />}
                {!isNotMatched && (
                  <div className='grid gap-2'>
                    <div className={cn(labelVariants())}>
                      {invoice?.rentalInvoice ? 'Avi' : 'Faktura'}
                    </div>
                    <DetailModalLink
                      className='justify-start py-0 text-sm'
                      pathname={Urls.detail.invoice.overview(invoice?.id)}
                    >
                      {invoice?.rentalInvoice ? 'Avi' : 'Faktura'} #
                      {invoice?.invoiceNumber ?? '-'}
                    </DetailModalLink>
                  </div>
                )}
                {!isNotMatched && (
                  <div className='grid gap-2'>
                    <div className={cn(labelVariants())}>OCR, matchad</div>
                    <div className='text-sm'>
                      {data?.invoice?.ocrNumber ??
                        data?.originalInvoice?.ocrNumber ??
                        '-'}
                    </div>
                  </div>
                )}
                {!isNotMatched && (
                  <div className='grid gap-2'>
                    <div className={cn(labelVariants())}>Betalningsmetod</div>
                    <div className='text-sm'>
                      {PaymentMethodConfig.fields.method.choices?.find(
                        (choice) => choice.v === data?.paymentMethod?.method
                      )?.d ?? '-'}
                    </div>
                  </div>
                )}
                <div className='grid gap-2'>
                  <div className={cn(labelVariants())}>Avsändare</div>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className='truncate text-sm'>
                        {data?.company?.name ?? '-'}
                      </div>
                    </TooltipTrigger>
                    <TooltipContent className='text-sm'>
                      {data?.company?.name ?? '-'}
                    </TooltipContent>
                  </Tooltip>
                </div>
                <div className='grid gap-2'>
                  <div className={cn(labelVariants())}>Avsändare, konto</div>
                  <div className='text-sm'>
                    {data?.fromAccountNumber ?? '-'}
                  </div>
                </div>
                <div className='grid gap-2'>
                  <div className={cn(labelVariants())}>Registreringsdatum</div>
                  <div className='text-sm'>
                    {toReadableDate(data?.registrationDate)}
                  </div>
                </div>
              </CardContent>
            </Card>
            {(isNotMatched || movePayment) && !isPending && (
              <>
                <div className='flex w-full items-center justify-between py-2'>
                  <div className='grid gap-2'>
                    <Label htmlFor='paymentDate'>Betalningsdatum</Label>
                    <p>Välj vilket datum betalningen ska bokföras på.</p>
                  </div>
                  <div className='w-1/3'>
                    <DateTimePicker
                      label='Betalningsdatum'
                      value={date ? parseDate(date) : null}
                      onChange={(date) => {
                        setDate(date?.toString());
                      }}
                      hideResetButton={true}
                    />
                  </div>
                </div>
                <Card>
                  <CardContent>
                    <SimpleTable
                      title='Möjliga avier att matcha mot'
                      config={InvoiceConfig}
                      columns={InvoiceColumns}
                      queryParams={{
                        filters: {
                          attested_date: { '__isnull!': true },
                          ...(data?.company?.id && {
                            company: { __in: data?.company?.id },
                          }),
                          ...(data?.originalInvoice && {
                            original_invoice: {
                              '__in!': data?.originalInvoice?.id,
                            },
                          }),
                        },
                      }}
                      onRowSelection={(row) => {
                        setSelectedInvoice(row);
                      }}
                      nested={['company']}
                      icon={<BanknotesIcon className='mr-2 size-4' />}
                    />
                  </CardContent>
                </Card>
              </>
            )}
          </div>
          <DialogFooter>
            <Button type='button' variant={'outline'} onClick={closeModal}>
              Avbryt
            </Button>
            {movePayment ? (
              <Button disabled={!selectedInvoice} onClick={matchPayment}>
                Bekräfta
              </Button>
            ) : data?.originalInvoice || data?.invoice ? (
              !data.annulled && (
                <Button onClick={() => setMovePayment(true)} type='button'>
                  Flytta betalning
                </Button>
              )
            ) : (
              <Button
                onClick={matchPayment}
                disabled={!selectedInvoice}
                type='button'
              >
                Lägg till manuell matchning
              </Button>
            )}
            {!isPending && !data?.annulled && !movePayment && (
              <Button
                variant='destructive'
                onClick={() => setRemovePayment(contentData.paymentId)}
                type='button'
              >
                Annullera betalning
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}

export const getMatchPaymentContent = (props: Props): ModalContent => {
  return {
    title: 'Matcha betalning mot avi',
    body: <MatchPayment {...props} />,
    isCloseOnEscape: true,
    closeButton: true,
    externalModalHandling: true,
  };
};
