 import type { Invoice } from '@pigello/pigello-matrix';

import { InvoiceConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useDeleteGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, deleteGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { deleteGenericInstance } from '@/requests/deleting';

export const deleteInvoice = async (
  options: deleteGenericInstanceProps<Invoice>
) => {
  return deleteGenericInstance<Invoice>({
    ...options,
    config: InvoiceConfig,
  });
};

export const useDeleteInvoice = (
  options: UseMutationOptions<Invoice, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useDeleteGenericInstance<Invoice>(deleteInvoice, options, 'invoice');