'use client';

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Switch } from '@/components/ui/switch';
import { toMoneyString } from '@/lib/utils';
import { ExclamationTriangleIcon } from '@heroicons/react/16/solid';
import { PaymentTermsSettingConfig } from '@pigello/pigello-matrix';
import { CardSection } from '../../components/cardSection';
import type { IInvoiceCreateResult } from './types';

export default function PreviewDebtSettings({
  invoiceData,
}: {
  invoiceData: IInvoiceCreateResult;
}) {
  const debtIsActive =
    invoiceData.debt_reminder_amount != null
      ? invoiceData.debt_reminder_amount > 0
      : false;
  return (
    <Card>
      <CardHeader>
        <CardTitle className='flex items-center'>
          <ExclamationTriangleIcon className='mr-2 size-4' />
          Inställningar för kravhantering
        </CardTitle>
      </CardHeader>
      <CardContent>
        <CardSection
          className='py-1'
          items={[
            {
              title: 'Skicka till kravhantering',
              content: <Switch disabled checked={debtIsActive} />,
            },
            {
              title: 'Krav börjar med',
              content:
                PaymentTermsSettingConfig.fields.debtReminderStageStart.choices?.find(
                  (c) => c.v == invoiceData.debt_reminder_stage_start
                )?.d,
              hidden: !debtIsActive,
            },
            {
              title: 'Krav avslutas med',
              content:
                PaymentTermsSettingConfig.fields.debtReminderStageEnd.choices?.find(
                  (c) => c.v == invoiceData.debt_reminder_stage_end
                )?.d,
              hidden: !debtIsActive,
            },
            {
              title: 'Antal kravfakturor',
              content: invoiceData.debt_reminder_amount,
              hidden: !debtIsActive,
            },
            {
              title: 'Krav skickas efter',
              content: `${invoiceData.debt_reminder_days_after_due_date ?? '-'} dagar`,
              hidden: !debtIsActive,
            },
            {
              title: 'Betalningsvillkor för krav',
              content: `${invoiceData.debt_reminder_expires_in_days ?? '-'} dagar`,
              hidden: !debtIsActive,
            },
            {
              title: 'Avgift för krav',
              content: toMoneyString(invoiceData.debt_reminder_fee),
              hidden: !debtIsActive,
            },
            {
              title:
                PaymentTermsSettingConfig.fields.informSocialCommitteeOnBailiff
                  .verboseName,
              content: (
                <Switch
                  disabled
                  checked={invoiceData.inform_social_committee_on_bailiff}
                />
              ),
              hidden: !debtIsActive,
            },
            {
              title:
                PaymentTermsSettingConfig.fields.evictionOnBailiff.verboseName,
              content: (
                <Switch disabled checked={invoiceData.eviction_on_bailiff} />
              ),
              hidden: !debtIsActive,
            },
            {
              title: 'Nådedagar',
              content: `${invoiceData.grace_days ?? '-'} dagar`,
              hidden: !debtIsActive,
            },
            {
              title: 'Nådevärde',
              content: toMoneyString(invoiceData.grace_value),
              hidden: !debtIsActive,
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}
