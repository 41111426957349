'use client';

import { convertStructureOperationTypes } from '@/components/data-table/constants';
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { TableActions } from '@/components/data-table/table-actions';
import { TableTags } from '@/components/data-table/table-tags';
import { Checkbox } from '@/components/ui/checkbox';
import { Urls } from '@/lib/urls';
import { toReadableAddress, toSqm } from '@/lib/utils';
import {
  OutdoorAreaConfig,
  RealEstateConfig,
  type OutdoorArea,
} from '@pigello/pigello-matrix';
import type { ColumnDef } from '@tanstack/react-table';
import { DropdownList } from '../../components/dropdown-list';

export const columns: ColumnDef<OutdoorArea>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'name',
    meta: {
      columnVisibilityName: OutdoorAreaConfig.fields.name.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.name}</div>
    ),
  },
  {
    accessorKey: 'customId',
    meta: {
      columnVisibilityName: OutdoorAreaConfig.fields.customId.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.customId ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'structureType',
    meta: {
      columnVisibilityName: OutdoorAreaConfig.fields.structureType.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span>
        {OutdoorAreaConfig.fields.structureType.choices?.find(
          (choice) => choice.v === row.original.structureType
        )?.d ?? '-'}
      </span>
    ),
  },
  {
    accessorKey: 'structureOperationType',
    meta: {
      columnVisibilityName:
        OutdoorAreaConfig.fields.structureOperationType.verboseName,

      type: 'status',
      operations: convertStructureOperationTypes(
        OutdoorAreaConfig.fields.structureOperationType
      ),
    },
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Användningsområde' />
    ),
    cell: ({ row }) => (
      <span>
        {OutdoorAreaConfig.fields.structureOperationType.choices?.find(
          (choice) => choice.v === row.original.structureOperationType
        )?.d ?? '-'}
      </span>
    ),
  },
  {
    accessorKey: 'registeredUsableArea',
    meta: {
      columnVisibilityName:
        OutdoorAreaConfig.fields.registeredUsableArea.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='text-end'>{toSqm(row.original.registeredUsableArea)}</div>
    ),
  },
  {
    accessorKey: 'registeredBiArea',
    meta: {
      columnVisibilityName:
        OutdoorAreaConfig.fields.registeredBiArea.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='text-end'>{toSqm(row.original.registeredBiArea)}</div>
    ),
  },
  {
    accessorKey: 'realestate',
    enableSorting: false,
    meta: {
      columnVisibilityName: OutdoorAreaConfig.fields.realestate.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        config={RealEstateConfig}
        detailModalLinkUrl={(id) => Urls.detail.realestate.overview(id)}
        fields={['name']}
        ids={[row.original.realestate?.id]}
        initialData={row.original.realestate}
      />
    ),
  },
  {
    accessorKey: 'address',
    enableSorting: false,
    meta: {
      columnVisibilityName: OutdoorAreaConfig.fields.address.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='whitespace-nowrap'>
        {toReadableAddress(row.original.address)}
      </span>
    ),
  },
  {
    accessorKey: 'tags',
    meta: {
      columnVisibilityName: 'Taggar',
    },
    header: 'Taggar',
    cell: ({ row }) => (
      <span className='flex gap-2'>
        <TableTags
          tags={row.original.tags}
          modelName={OutdoorAreaConfig.modelName}
        />
      </span>
    ),
  },
  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <TableActions
        instance={row.original}
        config={OutdoorAreaConfig}
        title={row.original.name}
      />
    ),
  },
];
