'use client';

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Switch } from '@/components/ui/switch';
import { useGetInstance } from '@/requests/hooks';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/16/solid';
import type {
  ApartmentContract,
  IndustrialPremisesContract,
  OutdoorSectionContract,
  ParkingSpotContract,
} from '@pigello/pigello-matrix';
import { type ModelName } from '@pigello/pigello-matrix';
import { CardSection } from '../../components/cardSection';
import type { IInvoiceCreateResult } from './types';

export default function PreviewInvoiceSettings({
  invoiceData,
}: {
  invoiceData: IInvoiceCreateResult;
}) {
  const hasSource = !!invoiceData.originates_from_object_id;
  const { data: sourceObjectData, isLoading: sourceObjectLoading } =
    useGetInstance<
      | ApartmentContract
      | IndustrialPremisesContract
      | OutdoorSectionContract
      | ParkingSpotContract
    >({
      modelName: invoiceData.originates_from_content_type?.id?.split(
        '.'
      )[1] as ModelName,
      id: invoiceData.originates_from_object_id as string,
      enabled: hasSource,
    });

  return (
    <Card>
      <CardHeader>
        <CardTitle className='flex items-center'>
          <AdjustmentsHorizontalIcon className='mr-2 size-4' />
          Avi
        </CardTitle>
      </CardHeader>
      <CardContent>
        <CardSection
          className='py-1'
          items={[
            {
              title: 'Aviserat avtal',
              content: sourceObjectLoading
                ? 'Laddar...'
                : sourceObjectData?.idNumber,
              hidden: !hasSource,
            },
            {
              title: 'Period',
              content: `${invoiceData.main_period_start} - ${invoiceData.main_period_end}`,
            },
            {
              title: 'Avidatum',
              content: invoiceData.invoice_date,
            },
            {
              title: 'Förfallodatum',
              content: invoiceData.due_date,
            },
            {
              title: 'Märkning',
              content: invoiceData.marking,
            },
            {
              title: 'Kundens referens',
              content: invoiceData.your_reference,
            },
            {
              title: 'Använd öresavrundning',
              content: <Switch disabled checked={invoiceData.use_rounding} />,
            },
            {
              title: 'Nyttja kundsaldo',
              content: (
                <Switch
                  disabled
                  checked={invoiceData.utilize_customer_balance}
                />
              ),
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}
