import organizationUserFilters from '@/app/settings/organization/users/filters';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { cn } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  OrganizationUserConfig,
  type OrganizationUser,
} from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './relation-item-map';

export const OrganizationUserItem: RelationFieldProps<OrganizationUser> = {
  columns: [],
  nested: getRelationFieldNames(OrganizationUserConfig),
  filters: organizationUserFilters,
  renderItem: (item, isRelationField) => ({
    title: item?.communicationName,
    avatar: (
      <Avatar className='size-8'>
        <AvatarImage
          className='object-cover'
          src={item?.profilePicture?.get}
          alt={item?.communicationName}
        />
        <AvatarFallback className='text-xs'>
          {item?.firstName?.[0]}
          {item?.lastName?.[0]}
        </AvatarFallback>
      </Avatar>
    ),
    badge: (
      <span className='text-xs'>
        {item.username ? `@${item.username}` : '-'}
      </span>
    ),
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <span className='font-medium'>{item.email ?? '-'}</span>
        {!isRelationField && <Dot />}
        <span>{item.phoneNumber ?? '-'}</span>
      </div>
    ),
  }),
};
