import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { cn } from '@/lib/utils';
import type { BaseInstance } from '@pigello/pigello-matrix';
import type { Path } from 'react-hook-form';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { colorPickerColors } from '@/lib/colors';
import { Circle } from '@uiw/react-color/esm';
import type { FieldProps } from '../types';
import { DescriptionTooltip } from './description-tooltip';

export function ColorField<Instance extends BaseInstance = BaseInstance>({
  formField,
  name,
  label,
  description,
  className,
  disabled,
  canHandleField,
}: FieldProps<Instance>) {
  return (
    <FormField
      control={formField.control}
      rules={{
        required: formField.required && 'Detta fält är obligatoriskt',
      }}
      name={name as Path<Instance>}
      render={({ field }) => {
        const color = field.value
          ? field.value?.startsWith('#')
            ? field.value
            : `#${field.value}`
          : '#000000';
        console.log('color', color);
        return (
          <FormItem className={cn('grid gap-2', className)}>
            <div className='flex items-center'>
              <FormLabel>
                {label ?? formField.label}{' '}
                {formField.required && <span>*</span>}
              </FormLabel>
              {(description || formField.description) && (
                <DescriptionTooltip
                  description={description ?? formField.description}
                />
              )}
            </div>
            <FormControl>
              <Popover>
                <PopoverTrigger
                  disabled={disabled ?? field.disabled ?? !canHandleField}
                  className='inline-flex cursor-pointer items-center gap-2 justify-self-start'
                >
                  <div className='rounded border px-2 py-1'>
                    <div
                      style={{
                        backgroundColor: color,
                      }}
                      className='h-4 w-9 rounded'
                    />
                  </div>
                  <FormLabel>Välj en färg</FormLabel>
                </PopoverTrigger>
                <PopoverContent>
                  <Circle
                    color={color}
                    className='[&>*]:!m-1'
                    colors={colorPickerColors}
                    onChange={(e) => field.onChange(e.hex)}
                  />
                </PopoverContent>
              </Popover>
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}
