 import type { ArticleAccountOverride } from '@pigello/pigello-matrix';

import { ArticleAccountOverrideConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetArticleAccountOverride = (
  options: useGetGenericInstanceProps<ArticleAccountOverride>
) => {
  return useGetInstance<ArticleAccountOverride>({
    ...options,
    config: ArticleAccountOverrideConfig,
    modelName: 'articleaccountoverride'
  });
};

export const useGetArticleAccountOverrideList = (
  options: useGetGenericListProps<ArticleAccountOverride>
) => {
  return useGetList<ArticleAccountOverride>({
    ...options,
    config: ArticleAccountOverrideConfig,
    modelName: 'articleaccountoverride'
  });
};
