import { DynamicField as DynamicFieldComp } from '@/components/form/dynamic-field';
import { Label } from '@/components/ui/label';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';
import type { BaseInstance } from '@pigello/pigello-matrix';

import type { DynamicField } from '@/hooks/useForm/types';
import { useInView } from 'framer-motion';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useRef } from 'react';
import type { Chapter } from './types';

interface IChapter<Instance extends BaseInstance> {
  chapter: Chapter<Instance>[number];
  containerRef?: React.RefObject<HTMLDivElement>;
  isFirst: boolean;
  isLast?: boolean;
  horizontal?: boolean;
  hasDescription: boolean;
  isLoadingData: boolean;
  offset?: {
    max: number;
    min: number;
  };
  orderedFields: Record<string, DynamicField<Instance>[keyof Instance][]>;

  activeTab: string[];
  setActiveTab: Dispatch<SetStateAction<string[]>>;
}

export function ChapterComp<Instance extends BaseInstance = BaseInstance>({
  chapter,
  isFirst,
  isLast,
  horizontal,
  isLoadingData,
  orderedFields,
  setActiveTab,
  containerRef,
  hasDescription,
}: IChapter<Instance>) {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { amount: 0.25, root: containerRef });

  useEffect(() => {
    if (isInView) {
      setActiveTab((prev) => [...prev, chapter.title]);
    } else {
      setActiveTab((prev) => prev.filter((tab) => tab !== chapter.title));
    }
  }, [chapter.title, isInView, setActiveTab]);

  return (
    <div
      ref={ref}
      className={cn(
        'grid gap-3 border-b py-14 first:pt-8',
        hasDescription && isFirst && 'pt-8 first:pt-0',
        isLast && 'border-none'
      )}
      id={chapter.title}
    >
      <div className={'flex flex-col gap-3 border-b pb-2 text-left'}>
        <h4>{chapter.title}</h4>
        <p>{chapter.description}</p>
      </div>
      <div
        className={cn(
          'grid grid-cols-1 gap-8 lg:grid-cols-2',
          horizontal && 'grid-cols-1'
        )}
      >
        {isLoadingData &&
          orderedFields?.[chapter.title].map((field) => (
            <div className='flex flex-col gap-2' key={field.name.toString()}>
              <Label>{field.label}</Label>
              <Skeleton className='h-9 rounded-md border' />
            </div>
          ))}
        {!isLoadingData &&
          orderedFields?.[chapter.title].map((field) => (
            <DynamicFieldComp
              key={field.name.toString()}
              formField={field}
              horizontal={horizontal}
              filters={chapter?.filter?.[field.name]}
            />
          ))}
      </div>
    </div>
  );
}
