 import type { OrganizationUserFileAccessShare } from '@pigello/pigello-matrix';

import { OrganizationUserFileAccessShareConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetOrganizationUserFileAccessShare = (
  options: useGetGenericInstanceProps<OrganizationUserFileAccessShare>
) => {
  return useGetInstance<OrganizationUserFileAccessShare>({
    ...options,
    config: OrganizationUserFileAccessShareConfig,
    modelName: 'organizationuserfileaccessshare'
  });
};

export const useGetOrganizationUserFileAccessShareList = (
  options: useGetGenericListProps<OrganizationUserFileAccessShare>
) => {
  return useGetList<OrganizationUserFileAccessShare>({
    ...options,
    config: OrganizationUserFileAccessShareConfig,
    modelName: 'organizationuserfileaccessshare'
  });
};
