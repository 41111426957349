import { articleRentFilters } from '@/app/settings/modules/invoicing/articles/rent/filters';

import { columns } from '@/app/settings/modules/invoicing/articles/invoice/columns';
import { Badge } from '@/components/ui/badge';
import { ArticleConfig, type Article } from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './relation-item-map';

export const ArticleItem: RelationFieldProps<Article> = {
  columns: columns,
  nested: ['category'],
  filters: articleRentFilters,
  renderItem: (item) => ({
    title: item?.name,
    badge: (
      <Badge variant={item?.considerBaseRent ? 'green' : 'gray'}>
        {item?.considerBaseRent ? 'Bashyra' : 'Ej bashyra'}
      </Badge>
    ),
    descriptionItems: (
      <div className='flex w-full items-center'>
        <span className='font-medium'>
          {item?.ignoreDynamiclyCalculatedVat
            ? `${item?.vat}% moms`
            : 'Dynamisk moms'}
        </span>
        <Dot />
        <span>
          {
            ArticleConfig?.fields.invoiceTypes.choices?.find(
              (opt) => opt.v === item?.invoiceTypes
            )?.d
          }
        </span>
        {item.unit && (
          <>
            <Dot />
            <span>{item.unit}</span>
          </>
        )}
      </div>
    ),
  }),
};
