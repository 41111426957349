import { DateTimePicker } from '@/components/form/dynamic-field/components/date-field/date-time-picker';
import { Button, buttonVariants } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import OverlayLoader from '@/components/ui/overlay-loader';
import {
  RadioGroup,
  RadioGroupCard,
  RadioGroupCardDescription,
  RadioGroupCardHeader,
  RadioGroupCardTitle,
  RadioGroupItem,
} from '@/components/ui/radio-group';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select';
import { useGetContractCancellationReasonList } from '@/config/contracts/cancellations/contractcancellationreason/client';
import { useGetRealEstate } from '@/config/objects/structural/realestate/client';
import { useConfig } from '@/hooks/useConfig';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useToast } from '@/hooks/useToast';
import { cn, toWeekInterval } from '@/lib/utils';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import { useGetInstance, useMutateGenericInstance } from '@/requests/hooks';
import { mutateGenericInstance } from '@/requests/mutating';
import { Cog6ToothIcon, PencilIcon } from '@heroicons/react/16/solid';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { parseDateTime } from '@internationalized/date';
import type {
  Apartment,
  BlockContract,
  ContractCancellationReason,
  IndustrialPremises,
  OutdoorSection,
  ParkingSpot,
  RealEstate,
  RelationalFieldValue,
} from '@pigello/pigello-matrix';
import {
  ApartmentConfig,
  ApartmentContractConfig,
  BlockContractConfig,
  IndustrialPremisesConfig,
  IndustrialPremisesContractConfig,
  OutdoorSectionConfig,
  OutdoorSectionContractConfig,
  ParkingSpotConfig,
  ParkingSpotContractConfig,
  type ApartmentContract,
  type IndustrialPremisesContract,
  type ModelName,
  type OutdoorSectionContract,
  type ParkingSpotContract,
} from '@pigello/pigello-matrix';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import Link from 'next/link';
import { useEffect, useState } from 'react';

export type CancelContractModalContentData = {
  instanceId: string;
  latestCancellationTime?: DateTime;

  modelName: ModelName;
  contract?:
    | ApartmentContract
    | IndustrialPremisesContract
    | OutdoorSectionContract
    | ParkingSpotContract
    | BlockContract;
};

interface Props extends GetModalContentProps {
  closeModal: () => void;
  contentData: CancelContractModalContentData;
}

function ContractCancelModal({ closeModal, contentData }: Props) {
  const { instanceId, modelName, contract, latestCancellationTime } =
    contentData;
  const { config } = useConfig<
    | ApartmentContract
    | IndustrialPremisesContract
    | OutdoorSectionContract
    | ParkingSpotContract
    | BlockContract
  >(modelName);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [canceledBy, setCanceledBy] = useState<'counterpart' | 'owner'>();
  const [kind, setKind] = useState<'moveout' | 'renegotiations'>();
  const [cancellationReason, setCancellationReason] = useState<string | null>(
    null
  );
  const [freeTextReason, setFreeTextReason] = useState(false);
  const [canceledDate, setCanceledDate] = useState<string | null>(
    DateTime.now().minus({ day: 1 }).toISO({ includeOffset: false })
  );
  const [lifeTimeEndDate, setLifeTimeEndDate] = useState<string | null>(null);

  const { data: space, isLoading: spaceLoading } = useGetInstance<
    Apartment | IndustrialPremises | OutdoorSection | ParkingSpot
  >({
    id: (contract && 'space' in contract ? contract?.space?.id : '') as string,
    modelName:
      modelName === ApartmentContractConfig.modelName
        ? ApartmentConfig.modelName
        : modelName === IndustrialPremisesContractConfig.modelName
          ? IndustrialPremisesConfig.modelName
          : modelName === OutdoorSectionContractConfig.modelName
            ? OutdoorSectionConfig.modelName
            : ParkingSpotConfig.modelName,

    // @ts-expect-error ts confused
    nested:
      modelName === ApartmentContractConfig.modelName ||
      modelName === IndustrialPremisesContractConfig.modelName
        ? ['building']
        : modelName === OutdoorSectionContractConfig.modelName
          ? ['outdoorArea']
          : modelName === ParkingSpotContractConfig.modelName
            ? ['parkingLot', 'building']
            : undefined,
    enabled: !!contract && 'space' in contract,
  });

  const realEstateId =
    (space && 'building' in space ? space.building?.realestate?.id : null) ??
    (space && 'outdoorArea' in space
      ? space.outdoorArea?.realestate?.id
      : null) ??
    (space && 'parkingLot' in space
      ? space.parkingLot?.realestate?.id
      : null) ??
    (contract && 'mainRealestate' in contract
      ? (contract.mainRealestate as RelationalFieldValue<RealEstate>)?.id
      : null);

  const { data: realEstate, isLoading: realEstateLoading } = useGetRealEstate({
    id: realEstateId as string,
    enabled: !!realEstateId,
    nested: [
      'apartmentContractCancellationConfiguration',
      'industrialPremisesContractCancellationConfiguration',
      'outdoorSectionContractCancellationConfiguration',
      'parkingSpotContractCancellationConfiguration',
    ],
  });

  const usedConfig =
    modelName === ApartmentContractConfig.modelName
      ? realEstate?.apartmentContractCancellationConfiguration
      : modelName === IndustrialPremisesContractConfig.modelName
        ? realEstate?.industrialPremisesContractCancellationConfiguration
        : modelName === OutdoorSectionContractConfig.modelName
          ? realEstate?.outdoorSectionContractCancellationConfiguration
          : modelName === ParkingSpotContractConfig.modelName
            ? realEstate?.parkingSpotContractCancellationConfiguration
            : modelName === BlockContractConfig.modelName
              ? realEstate?.blockContractCancellationConfiguration
              : null;

  useEffect(() => {
    if (!usedConfig) return;

    if (usedConfig.allowRegularTermination && !kind) {
      setKind('moveout');
    } else if (!kind) {
      setKind('renegotiations');
    }
  }, [usedConfig, kind]);

  const canSubmit = () => {
    return (
      !!canceledBy &&
      !!canceledDate &&
      !isEmpty(usedConfig) &&
      !(
        usedConfig?.requirePredeterminedCancellationReason &&
        !cancellationReason
      )
    );
  };

  const { data: cancellationReasons, isLoading: cancellationReasonsLoading } =
    useGetContractCancellationReasonList({
      queryParams: {
        page: 1,
        pageSize: 500,
        filters: {
          id: {
            __in: usedConfig?.allowedCancellationReasons
              ?.map((r) => r.id)
              ?.join(','),
          },
        },
      },
      enabled:
        !!usedConfig &&
        (usedConfig.allowedCancellationReasons || []).length > 0,
    });

  useEffect(() => {
    if (contract?.cancelledByCounterpart) {
      setCanceledBy('counterpart');
    } else if (contract?.cancelledByCounterpart != null) {
      setCanceledBy('owner');
    }
    if (contract?.pendingReNegotiations) {
      setKind('renegotiations');
    }
    if (contract?.cancellationTime) {
      setCanceledDate(
        DateTime.fromISO(contract?.cancellationTime).toISO({
          includeOffset: false,
        })
      );
    }
    if (contract?.lifetimeEndDate) {
      setLifeTimeEndDate(
        DateTime.fromISO(contract?.lifetimeEndDate).toISO({
          includeOffset: false,
        })
      );
    }
    if (contract?.cancellationReason) {
      setCancellationReason(contract.cancellationReason);
    }
  }, [contract]);

  const mutation = useMutateGenericInstance<
    | ApartmentContract
    | IndustrialPremisesContract
    | OutdoorSectionContract
    | ParkingSpotContract
    | BlockContract
  >(
    async (options) =>
      mutateGenericInstance<
        | ApartmentContract
        | IndustrialPremisesContract
        | OutdoorSectionContract
        | ParkingSpotContract
        | BlockContract
      >({ ...options, config: config! }),
    {
      onMutate: () => setLoading(true),
      onSettled: () => setLoading(false),
      onSuccess: () => {
        addToast({
          title: 'Avtalet uppdaterades',
          type: 'success',
        });
        closeModal();
      },
      onError: (error) => {
        const field = handleFormErrors(error);
        addToast({
          title: 'Något gick fel',
          message: `Error: ${field}`,
          type: 'error',
        });
      },
    },
    modelName
  );

  const onSubmit = () => {
    const body = {
      cancelledByCounterpart: canceledBy === 'counterpart',
      cancellationTime: canceledDate?.toLocaleString(),
      cancellationReason: cancellationReason ?? undefined,
      lifeTimeEndDate: lifeTimeEndDate ?? undefined,
      pendingReNegotiations: !usedConfig?.allowPendingReNegotations
        ? false
        : !usedConfig.allowRegularTermination
          ? true
          : kind === 'renegotiations',
    };

    mutation.mutate({
      id: instanceId,
      body,
    });
  };

  const filteredCancellationReasons: ContractCancellationReason[] | undefined =
    cancellationReasons?.list.filter((r) => {
      if (kind === 'moveout') return r.allowedForRegularTermination;
      if (kind === 'renegotiations') return r.allowedForPendingReNegotation;
    });

  return (
    <Dialog open onOpenChange={closeModal}>
      <DialogContent size='md' className={'p-0'}>
        {(spaceLoading ||
          realEstateLoading ||
          cancellationReasonsLoading ||
          loading) && <OverlayLoader />}
        <DialogHeader>
          <DialogTitle>Säg upp avtal</DialogTitle>
          <Button variant={'secondary'} size={'icon-sm'} onClick={closeModal}>
            <XMarkIcon className='size-4' />
          </Button>
        </DialogHeader>
        {!isEmpty(usedConfig) ? (
          <div className='grid gap-4 p-4'>
            <div>
              <h4 className='mb-2'>Part som sade upp avtal</h4>

              <RadioGroup
                value={canceledBy}
                onValueChange={(e) => setCanceledBy(e as typeof canceledBy)}
                className='grid grid-cols-2 items-start gap-4'
              >
                <RadioGroupCard htmlFor='counterpart'>
                  <RadioGroupCardHeader>
                    <RadioGroupCardTitle>
                      Uppsagt av hyresgäst
                    </RadioGroupCardTitle>
                    <RadioGroupCardDescription>
                      Markera avtalet som uppsagt av hyresgäst. Detta innebär
                      att det är hyresgästens uppsägningstid som kommer att
                      gälla, dvs{' '}
                      {toWeekInterval(
                        contract?.counterpartNotificationIntervalWeeks
                      )}
                      .
                    </RadioGroupCardDescription>
                  </RadioGroupCardHeader>
                  <RadioGroupItem value='counterpart' id='counterpart' />
                </RadioGroupCard>
                <RadioGroupCard htmlFor='owner'>
                  <RadioGroupCardHeader>
                    <RadioGroupCardTitle>
                      Uppsagt av hyresvärd
                    </RadioGroupCardTitle>
                    <RadioGroupCardDescription>
                      Markera avtalet som uppsagt av hyresvärd. Detta innebär
                      att det är hyresvärdens uppsägningstid som kommer att
                      gälla, dvs{' '}
                      {toWeekInterval(contract?.ownerNotificationIntervalWeeks)}
                      .
                    </RadioGroupCardDescription>
                  </RadioGroupCardHeader>
                  <RadioGroupItem value='owner' id='owner' />
                </RadioGroupCard>
              </RadioGroup>
            </div>

            {canceledBy != null && (
              <div>
                <div className='mb-2 flex items-center justify-between'>
                  <h4>Anledning till uppsägning</h4>{' '}
                  {!usedConfig?.requirePredeterminedCancellationReason && (
                    <Button
                      onClick={() => setFreeTextReason(true)}
                      type='button'
                      variant='link'
                      className='text-xs'
                    >
                      Ange som fritext <PencilIcon className='ml-1 size-3.5' />
                    </Button>
                  )}
                </div>

                {filteredCancellationReasons &&
                  filteredCancellationReasons.length > 0 &&
                  !freeTextReason && (
                    <Select
                      onValueChange={(val) => setCancellationReason(val)}
                      value={cancellationReason ?? undefined}
                    >
                      <SelectTrigger>
                        {cancellationReason ?? 'Välj anledning till uppsägning'}
                      </SelectTrigger>
                      <SelectContent>
                        {filteredCancellationReasons.map((reason) => (
                          <SelectItem value={reason.title} key={reason.id}>
                            {reason.title}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}

                {(filteredCancellationReasons?.length === 0 ||
                  freeTextReason) &&
                  !usedConfig?.requirePredeterminedCancellationReason && (
                    <Input
                      placeholder='Ange anledning till uppsägning'
                      value={cancellationReason ?? ''}
                      onChange={({ target: { value } }) =>
                        setCancellationReason(value)
                      }
                    />
                  )}
              </div>
            )}

            <div>
              <h4 className='mb-2'>Datum för uppsägning</h4>
              <div className='grid grid-cols-2'>
                <div className='col-span-full w-full'>
                  <DateTimePicker
                    label='Datum för uppsägning'
                    hideResetButton={true}
                    granularity='minute'
                    value={
                      canceledDate !== null ? parseDateTime(canceledDate) : null
                    }
                    onChange={(date) => {
                      if (!date) return;
                      setCanceledDate(date.toString());
                    }}
                    {...(latestCancellationTime?.toISO() !== null &&
                      latestCancellationTime?.toISO() !== undefined && {
                        minValue: parseDateTime(
                          // @ts-expect-error - Luxon types are not correct
                          latestCancellationTime
                            .minus({ year: 1000 })
                            .toISO({ includeOffset: false })
                        ),
                        maxValue: parseDateTime(
                          // @ts-expect-error - Luxon types are not correct
                          latestCancellationTime.toISO({ includeOffset: false })
                        ),
                      })}
                  />
                </div>
              </div>
            </div>

            {usedConfig?.allowPendingReNegotations &&
              usedConfig.allowRegularTermination && (
                <div>
                  <h4 className='mb-2'>Typ av uppsägning</h4>
                  <RadioGroup
                    value={kind}
                    onValueChange={(e) => setKind(e as typeof kind)}
                    className='grid grid-cols-2 items-start gap-4'
                  >
                    <RadioGroupCard htmlFor='renegotiations'>
                      <RadioGroupCardHeader>
                        <RadioGroupCardTitle>
                          Uppsagt för omförhandling
                        </RadioGroupCardTitle>
                        <RadioGroupCardDescription>
                          Markera avtalet som uppsagt för omförhandling.
                        </RadioGroupCardDescription>
                      </RadioGroupCardHeader>
                      <RadioGroupItem
                        value='renegotiations'
                        id='renegotiations'
                      />
                    </RadioGroupCard>
                    <RadioGroupCard htmlFor='moveout'>
                      <RadioGroupCardHeader>
                        <RadioGroupCardTitle>
                          Uppsagt för avflytt
                        </RadioGroupCardTitle>
                        <RadioGroupCardDescription>
                          Markera avtalet som uppsagt för avflytt.
                        </RadioGroupCardDescription>
                      </RadioGroupCardHeader>
                      <RadioGroupItem value='moveout' id='moveout' />
                    </RadioGroupCard>
                  </RadioGroup>
                </div>
              )}

            <div>
              <h4>
                Datum för{' '}
                {kind === 'renegotiations' ? 'avtalsändring' : 'utflytt'}{' '}
              </h4>
              <div className='mb-2 text-xs'>
                Detta datum sätts automatiskt enligt avtalet om det lämnas
                blankt
              </div>
              <div className='grid grid-cols-2'>
                <div className='col-span-full w-full'>
                  <DateTimePicker
                    label='Datum för Utflytt/avtalsändring'
                    hideResetButton={false}
                    granularity='day'
                    value={
                      lifeTimeEndDate !== null
                        ? parseDateTime(lifeTimeEndDate)
                        : null
                    }
                    onChange={(date) => {
                      setLifeTimeEndDate(date ? date.toString() : null);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='p-4'>
            <h4 className='mb-2'>Inställningar saknas</h4>
            <Card>
              <CardContent className='flex flex-col items-center justify-center p-4'>
                <p>
                  Fastigheten som detta avtal är knutet till saknar
                  uppsägningsinställningar. Klicka på länken nedan om du vill
                  konfigurera Avtalsuppsägning i kontrollcentret.
                </p>
                <Link
                  prefetch={false}
                  href='/settings/modules/base/contractcancellations/settings'
                  className={cn(
                    'mt-3 flex items-center gap-1',
                    buttonVariants({ variant: 'secondary' })
                  )}
                >
                  <Cog6ToothIcon className='size-4' />
                  Avtalsuppsägning
                </Link>
              </CardContent>
            </Card>
          </div>
        )}
        <DialogFooter>
          <Button variant='outline' onClick={closeModal}>
            Avbryt
          </Button>
          <Button disabled={!canSubmit()} onClick={onSubmit}>
            Spara uppsägning
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export const getCancelContractModalContent = ({
  closeModal,
  contentData,
}: Props): ModalContent => {
  return {
    externalModalHandling: true,
    body: (
      <ContractCancelModal closeModal={closeModal} contentData={contentData} />
    ),
  };
};
