import baseFilters from '@/app/dashboard/filters';
import type { IFilter } from '@/components/table-filters/constants';
import {
  defaultBooleanFilter,
  defaultStringFilter,
} from '@/components/table-filters/constants';
import { IndexationTableConfig } from '@pigello/pigello-matrix';

const indexationTableFilters: IFilter[] = [
  {
    title: IndexationTableConfig.fields.name.verboseName,
    externalFieldName: IndexationTableConfig.fields.name.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: IndexationTableConfig.fields.customId.verboseName,
    externalFieldName: IndexationTableConfig.fields.customId.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: IndexationTableConfig.fields.useLastKnownValueAsFallback.verboseName,
    externalFieldName:
      IndexationTableConfig.fields.useLastKnownValueAsFallback
        .externalFieldName,
    ...defaultBooleanFilter,
  },

  ...baseFilters,
];

export default indexationTableFilters;
