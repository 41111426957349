'use client';

import { columns } from '@/app/dashboard/contracts/apartment/columns';
import apartmentContractFilters from '@/app/dashboard/contracts/apartment/filters';
import { StatusBadge } from '@/components/ui/statusBadge';
import { cn } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  ApartmentContractConfig,
  type ApartmentContract,
} from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './relation-item-map';

export const ApartmentContractItem: RelationFieldProps<ApartmentContract> = {
  nested: getRelationFieldNames(ApartmentContractConfig),
  filters: apartmentContractFilters,
  columns: columns,
  renderItem: (item, isRelationField) => ({
    title: item?.idNumber,
    badge:
      item.status != null ? (
        <StatusBadge
          className='font-medium'
          status={item.status}
          type='contract'
        />
      ) : undefined,
    descriptionItems: (
      <div
        className={cn(
          isRelationField ? 'grid w-full' : 'flex flex-row items-center'
        )}
      >
        <div className='font-medium'>
          {item.tenant?.communicationName ?? 'Ingen hyresgäst kopplad'}
        </div>
        {!isRelationField && <Dot />}
        <span> {item.customId ?? 'Inget objekts-id angivet'}</span>
      </div>
    ),
  }),
};
