'use client';

import { DynamicField } from '@/components/form/dynamic-field';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Fieldset } from '@/components/ui/fieldset';
import { Form } from '@/components/ui/form';
import { Legend } from '@/components/ui/legend';
import OverlayLoader from '@/components/ui/overlay-loader';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useGetArticle } from '@/config/accounting/articles/article/client';
import { useCreateArticle } from '@/config/accounting/articles/article/create';
import { useMutateArticle } from '@/config/accounting/articles/article/mutate';
import { useIsFormDirty } from '@/hooks/use-is-form-dirty';
import { useForm } from '@/hooks/useForm';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useToast } from '@/hooks/useToast';
import type { ErrorResponse } from '@/requests/types';
import { PlusIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { ArticleConfig, getCleanArticle } from '@pigello/pigello-matrix';
import { useMemo, useState } from 'react';
import { AdvancedCategories } from '../../components/advanced-categories';
import { ArticleCategoryForm } from '../categories/category-create-form';
import { revenueAccount } from '../utils';

export function ArticleConsumableForm({
  closeFunction,
  articleId,
}: {
  closeFunction: VoidFunction;
  articleId?: string;
}) {
  const { addToast } = useToast();
  const [openCategory, setOpenCategory] = useState(false);
  const { data, isLoading } = useGetArticle({
    id: articleId,
    enabled: !!articleId,
  });

  const form = useForm({
    config: ArticleConfig,
    isUpdate: !!articleId,
    values: data,
    disabled: isLoading,
    defaultValues: getCleanArticle(),
    orderedFields: [
      {
        key: 'general',
        fields: ['name', 'invoiceDisplayName'],
      },
      {
        key: 'settings',
        fields: [
          'includeSpaceCategoryInDisplayName',
          'allowSpaceBoundRevenueRows',
        ],
      },
      {
        key: 'vatSettings',
        fields: ['vat'],
      },
      {
        key: 'accounts',
        fields: [
          'revenueVat0Account',
          'revenueVat6Account',
          'revenueVat12Account',
          'revenueVat25Account',
        ],
      },
      {
        key: 'spaceCategories',
        fields: [
          'apartmentCategories',
          'industrialPremisesCategories',
          'parkingSpotCategories',
          'outdoorSectionCategories',
          'brfApartmentCategories',
        ],
      },
    ],
    ...(!articleId && {
      preMutatedValues: {
        allowSpaceBoundRevenueRows: true,
      },
    }),
  });
  const formValues = useMemo(() => form.getValues(), [form]);
  const { isDirty } = form.formState;

  const mutation = useCreateArticle({});

  const closeAndClear = () => {
    closeFunction();
    form.reset();
  };

  const mutationPatch = useMutateArticle({});

  const handleSubmit = () => {
    const data = form.getDirtyData();

    if (articleId) {
      return addToast({
        type: 'promise',
        promise: mutationPatch.mutateAsync({
          id: articleId,
          body: {
            ...data,
            ...(formValues.vat != null && {
              revenueVat0Account: formValues[revenueAccount[formValues.vat]],
              revenueVat6Account: formValues[revenueAccount[formValues.vat]],
              revenueVat12Account: formValues[revenueAccount[formValues.vat]],
              revenueVat25Account: formValues[revenueAccount[formValues.vat]],
            }),
          },
        }),
        loading: 'Uppdaterar artikel...',
        success: () => {
          closeAndClear();
          return 'Artikeln uppdaterades';
        },
        error: (error: ErrorResponse) => {
          const field = handleFormErrors(error, form);
          return field;
        },
      });
    }
    addToast({
      type: 'promise',
      promise: mutation.mutateAsync({
        body: {
          ...data,
          invoiceTypes: 2,
          ...(formValues.vat != null && {
            revenueVat0Account: formValues[revenueAccount[formValues.vat]],
            revenueVat6Account: formValues[revenueAccount[formValues.vat]],
            revenueVat12Account: formValues[revenueAccount[formValues.vat]],
            revenueVat25Account: formValues[revenueAccount[formValues.vat]],
          }),
        },
      }),
      loading: 'Skapar artikel...',
      success: () => {
        closeAndClear();
        return 'Artikeln skapades';
      },
      error: (error: ErrorResponse) => {
        const field = handleFormErrors(error, form);
        return field;
      },
    });
  };

  const { confirmClose } = useIsFormDirty({
    isDirty,
    closeModal: closeAndClear,
  });

  return (
    <>
      {openCategory && (
        <ArticleCategoryForm closeFunction={() => setOpenCategory(false)} />
      )}
      <Dialog defaultOpen onOpenChange={confirmClose}>
        <DialogContent
          onInteractOutside={(e) => {
            e.preventDefault();
            if (openCategory) return;
            confirmClose();
          }}
          size='lg'
          className='p-0'
        >
          <DialogHeader>
            <DialogTitle>
              {articleId
                ? 'Redigera förbrukningsartikel'
                : 'Skapa förbrukningsartikel'}
            </DialogTitle>
            <Button
              onClick={confirmClose}
              variant='secondary'
              size='icon-sm'
              type='button'
            >
              <XMarkIcon className='size-4' />
            </Button>
          </DialogHeader>
          <Form {...form}>
            <form
              onSubmit={(e) => {
                form.clearErrors();
                form.handleSubmit(handleSubmit)(e);
              }}
            >
              <div className='relative grid h-[60vh] gap-6 overflow-auto p-4'>
                {(mutation.isPending || isLoading) && <OverlayLoader />}
                <Fieldset className='grid-cols-2'>
                  <Legend>Generellt</Legend>
                  {form.orderedFields.general.map((field) => (
                    <DynamicField key={field.name} formField={field} />
                  ))}
                  {form.orderedFields.settings.map((field) => (
                    <DynamicField
                      className='col-span-full'
                      horizontal
                      key={field.name}
                      formField={field}
                    />
                  ))}
                  <div className='relative col-span-full grid grid-cols-2 gap-6'>
                    <DynamicField
                      className='w-full'
                      formField={form.fields.unit}
                    />
                    <DynamicField
                      className='w-full'
                      formField={form.fields.category}
                    />
                    {!formValues.category && (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            size={'icon'}
                            onClick={() => setOpenCategory(true)}
                            type='button'
                            variant={'secondary'}
                            className='absolute -right-3 top-3 z-10 rounded-full'
                          >
                            <PlusIcon className='size-4' />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          Skapa en ny artikelkategori
                        </TooltipContent>
                      </Tooltip>
                    )}
                  </div>
                </Fieldset>
                <Fieldset className='grid-cols-2'>
                  <Legend>Moms</Legend>

                  <DynamicField
                    className='col-span-full'
                    horizontal={true}
                    formField={form.fields.vat}
                  />
                  {formValues.vat != null && (
                    <DynamicField
                      horizontal
                      className='col-span-full'
                      formField={form.fields[revenueAccount[formValues.vat]]}
                    />
                  )}
                </Fieldset>

                <AdvancedCategories
                  form={form}
                  fields={form.orderedFields.spaceCategories}
                />
              </div>

              <DialogFooter>
                <Button
                  variant={'outline'}
                  onClick={confirmClose}
                  type='button'
                >
                  Avbryt
                </Button>
                <Button type='submit'>Spara</Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
}
