import type { ModelName, TagUsage } from '@pigello/pigello-matrix';
import TagDropdown from '../detail/components/tags/tag-dropdown';
import { Tag } from '../ui/tag';

interface Props {
  tags: TagUsage[];
  modelName: ModelName;
}

export function TableTags({ tags, modelName }: Props) {
  if (tags?.length === 0) return <span>-</span>;
  if (tags?.length === 1) {
    return <Tag tag={tags[0]} modelName={modelName} />;
  }
  return <TagDropdown tags={tags} modelName={modelName} />;
}
