export const revenueAccount: Record<
  number,
  | 'revenueVat0Account'
  | 'revenueVat6Account'
  | 'revenueVat12Account'
  | 'revenueVat25Account'
> = {
  0: 'revenueVat0Account',
  6: 'revenueVat6Account',
  12: 'revenueVat12Account',
  25: 'revenueVat25Account',
} as const;
