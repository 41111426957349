import { columns } from '@/app/dashboard/structural/buildings/columns';
import buildingFilters from '@/app/dashboard/structural/buildings/filters';
import { cn, toReadableAddress } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { BuildingConfig, type Building } from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './relation-item-map';

export const BuildingItem: RelationFieldProps<Building> = {
  nested: getRelationFieldNames(BuildingConfig),
  columns: columns,
  filters: buildingFilters,
  renderItem: (item, isRelationField) => ({
    title: item?.name,
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <span className='font-medium'>
          {item.address
            ? toReadableAddress(item.address, true, false)
            : 'Ingen adress angiven'}
        </span>
        {!isRelationField && <Dot />}
        <span>{item?.customId ?? 'Inget objekts-id angivet'}</span>
      </div>
    ),
  }),
};
