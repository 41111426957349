 import type { ArticleCategory } from '@pigello/pigello-matrix';

import { ArticleCategoryConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetArticleCategory = (
  options: useGetGenericInstanceProps<ArticleCategory>
) => {
  return useGetInstance<ArticleCategory>({
    ...options,
    config: ArticleCategoryConfig,
    modelName: 'articlecategory'
  });
};

export const useGetArticleCategoryList = (
  options: useGetGenericListProps<ArticleCategory>
) => {
  return useGetList<ArticleCategory>({
    ...options,
    config: ArticleCategoryConfig,
    modelName: 'articlecategory'
  });
};
