import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import { ExclamationTriangleIcon } from '@heroicons/react/16/solid';
import React from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from './alert-dialog';

export type IsFormDirtyResponse = Promise<boolean>;

export type IsFormDirtyContentData = {
  title?: string;
  message?: React.ReactNode;
  body?: React.ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

interface Props extends GetModalContentProps {
  closeModal: (response: Awaited<IsFormDirtyResponse>) => void;
  contentData: IsFormDirtyContentData;
}

function IsFormDirtyModal({ closeModal, contentData }: Props) {
  return (
    <AlertDialog defaultOpen>
      <AlertDialogContent className='gap-2'>
        <AlertDialogHeader className='flex-col items-start'>
          <ExclamationTriangleIcon className='size-8 text-orange-500' />
          <AlertDialogTitle>
            {contentData.title ?? 'Du har osparade ändringar!'}
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>
          {contentData.message ??
            'Du har osparade ändringar som kommer att gå förlorade. Är du säker på att du vill fortsätta?'}
        </AlertDialogDescription>

        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={() => {
              contentData?.onCancel?.();
              closeModal(false);
            }}
          >
            {contentData?.cancelButtonText ?? 'Avbryt'}
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={() => {
              contentData?.onConfirm?.();
              closeModal(true);
            }}
          >
            {contentData?.confirmButtonText ?? 'Bekräfta'}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export const getIsFormDirtyContent = ({
  closeModal,
  contentData,
}: Props): ModalContent => {
  return {
    body: (
      <IsFormDirtyModal closeModal={closeModal} contentData={contentData} />
    ),
    isCloseOnEscape: false,
    externalModalHandling: true,
  };
};
