 import type { InvoicePayment } from '@pigello/pigello-matrix';

import { InvoicePaymentConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, mutateGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { mutateGenericInstance } from '@/requests/mutating';

export const mutateInvoicePayment = async (
  options: mutateGenericInstanceProps<InvoicePayment>
) => {
  return mutateGenericInstance<InvoicePayment>({
    ...options,
    config: InvoicePaymentConfig,
  });
};

export const useMutateInvoicePayment = (
  options: UseMutationOptions<InvoicePayment, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useMutateGenericInstance<InvoicePayment>(mutateInvoicePayment, options, 'invoicepayment');