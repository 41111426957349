import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { MultiSelectInput } from '@/components/ui/multi-select-input';
import { cn } from '@/lib/utils';
import { PlusIcon, XMarkIcon } from '@heroicons/react/16/solid';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { useState } from 'react';
import type { Path } from 'react-hook-form';
import type { FieldProps } from '../types';
import { DescriptionTooltip } from './description-tooltip';

export function Array<Instance extends BaseInstance = BaseInstance>({
  formField,
  name,
  label,
  placeholder,
  description,
  horizontal,
  className,
  disabled,
  canHandleField,
}: FieldProps<Instance>) {
  const [renderKey, setRenderKey] = useState(window.crypto.randomUUID());
  const choiceField = formField.config?.fields[formField.name];

  const defaultValues =
    'choices' in choiceField
      ? choiceField?.choices?.map(({ v, d }) => ({
          v,
          d,
        })) ?? []
      : [];

  if (horizontal) {
    return (
      <FormField
        key={renderKey}
        control={formField.control}
        name={name as Path<Instance>}
        rules={{
          required: formField.required && 'Detta fält är obligatoriskt',
        }}
        render={({ field }) => (
          <FormItem
            className={cn('flex items-center justify-between', className)}
          >
            <div className='w-1/2 shrink-0'>
              <FormLabel>
                {label ?? formField.label}{' '}
                {formField.required && <span>*</span>}
              </FormLabel>
              <FormDescription>
                {description ?? formField?.description}
              </FormDescription>
              <FormMessage />
            </div>
            <FormControl>
              <div className='w-full'>
                <MultiSelectInput
                  placeholder={`Välj ${placeholder ?? formField.label}`}
                  onSelected={(items) => {
                    field.onChange(items.map((i) => i.v));
                  }}
                  values={
                    field.value?.map((val: string) => ({
                      v: val,
                      d: defaultValues?.find((d: { v: string }) => d.v === val)
                        ?.d,
                    })) ?? []
                  }
                  defaultValues={defaultValues}
                  disabled={disabled ?? field.disabled ?? !canHandleField}
                />
                {'choices' in choiceField &&
                  choiceField.choices &&
                  choiceField?.choices?.length > 0 && (
                    <div className='mt-0 flex justify-end'>
                      <button
                        type='button'
                        disabled={disabled ?? field.disabled}
                        onClick={() => {
                          field.onChange(defaultValues.map((v) => v.v));
                          setRenderKey(window.crypto.randomUUID());
                        }}
                        className='flex items-center text-end text-xs leading-none disabled:opacity-50'
                      >
                        <PlusIcon className='mr-1 size-3' />
                        Välj alla
                      </button>
                    </div>
                  )}
              </div>
            </FormControl>
          </FormItem>
        )}
      />
    );
  }
  return (
    <FormField
      key={renderKey}
      control={formField.control}
      rules={{
        required: formField.required && 'Detta fält är obligatoriskt',
      }}
      name={name as Path<Instance>}
      render={({ field }) => (
        <FormItem className={className}>
          <div className='flex items-center'>
            <FormLabel>
              {label ?? formField.label} {formField.required && <span>*</span>}
            </FormLabel>
            {(description || formField.description) && (
              <DescriptionTooltip
                description={description ?? formField.description}
              />
            )}
          </div>
          <FormControl>
            <div className='w-full'>
              <MultiSelectInput
                placeholder={`Välj ${placeholder ?? formField.label}`}
                onSelected={(items) => {
                  field.onChange(items.map((i) => i.v));
                }}
                values={
                  field?.value?.map((val: string) => ({
                    v: val,
                    d: defaultValues?.find((d: { v: string }) => d.v === val)
                      ?.d,
                  })) ?? []
                }
                defaultValues={defaultValues}
                disabled={disabled ?? field.disabled ?? !canHandleField}
              />
              <div className='mt-0 flex justify-end'>
                {field.value?.length > 0 && (
                  <button
                    disabled={disabled ?? field.disabled ?? !canHandleField}
                    type='button'
                    onClick={() => {
                      field.onChange(null);
                      setRenderKey(window.crypto.randomUUID());
                    }}
                    className='mr-2 flex items-center text-end text-xs leading-none'
                  >
                    <XMarkIcon className='mr-1 size-3' />
                    Rensa fält
                  </button>
                )}
                {'choices' in choiceField &&
                  choiceField.choices &&
                  choiceField?.choices?.length > 0 && (
                    <button
                      disabled={disabled ?? field.disabled ?? !canHandleField}
                      type='button'
                      onClick={() => {
                        field.onChange(defaultValues.map((v) => v.v));
                        setRenderKey(window.crypto.randomUUID());
                      }}
                      className='flex items-center text-end text-xs leading-none'
                    >
                      <PlusIcon className='mr-1 size-3' />
                      Välj alla
                    </button>
                  )}
              </div>
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
