'use client';

import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import OverlayLoader from '@/components/ui/overlay-loader';
import { useGetSubTenant } from '@/config/accounts/users/customer/subtenant/client';
import { useGetTenant } from '@/config/accounts/users/customer/tenant/client';
import { useGetOrganizationUser } from '@/config/accounts/users/organization/organizationuser/client';
import { useGetOrganizationUserContactPerson } from '@/config/accounts/users/organization/organizationusercontactperson/client';
import {
  cn,
  toReadableAddress,
  toReadableDistributionMethod,
} from '@/lib/utils';
import {
  EyeIcon,
  PaperAirplaneIcon,
  PlusIcon,
} from '@heroicons/react/16/solid';
import type {
  Address,
  BaseInstance,
  OrganizationUser,
  OrganizationUserContactPerson,
  SubTenant,
  Tenant,
} from '@pigello/pigello-matrix';
import { useMemo, useState } from 'react';
import { CardSection } from '../../components/cardSection';
import type { IInvoiceRecipient } from './types';

type IActualRecipient = Tenant &
  OrganizationUser &
  OrganizationUserContactPerson &
  SubTenant;

export default function PreviewRecipient({
  recipient,
  primary,
}: {
  recipient: IInvoiceRecipient;
  primary?: boolean;
}) {
  const [showMoreOpen, setShowMoreOpen] = useState(false);
  const { data: tenantData, isLoading: tenantDataLoading } = useGetTenant({
    id: recipient.tenant?.id,
    enabled: !!recipient.tenant?.id,
  });
  const { data: organizationUserData, isLoading: organizationUserDataLoading } =
    useGetOrganizationUser({
      id: recipient.organization_user?.id,
      enabled: !!recipient.organization_user?.id,
    });
  const { data: subTenantData, isLoading: subTenantDataLoading } =
    useGetSubTenant({
      id: recipient.sub_tenant?.id,
      enabled: !!recipient.sub_tenant?.id,
    });
  const {
    data: organizationUserContactData,
    isLoading: organizationUserContactDataLoading,
  } = useGetOrganizationUserContactPerson({
    id: recipient.organization_user_contact_person?.id,
    enabled: !!recipient.organization_user_contact_person?.id,
  });

  const isLoading =
    tenantDataLoading ||
    organizationUserDataLoading ||
    subTenantDataLoading ||
    organizationUserContactDataLoading;

  const actualRecipient = useMemo(() => {
    return (tenantData ??
      organizationUserData ??
      subTenantData ??
      organizationUserContactData) as IActualRecipient;
  }, [
    tenantData,
    organizationUserData,
    subTenantData,
    organizationUserContactData,
  ]);

  const getValue = <T extends BaseInstance = BaseInstance>(
    attrList: (keyof T)[],
    obj: T
  ) => {
    if (!obj) return '';

    for (let i = 0; i < attrList.length; i++) {
      const curAttr = attrList[i];
      if (curAttr in obj && !!obj[curAttr]) {
        return obj[curAttr as keyof T] as string;
      }
    }
    return '-';
  };

  return (
    <Card
      key={recipient.id}
      className={cn('relative', {
        'pt-8': primary || recipient.view_only,
      })}
    >
      {isLoading && <OverlayLoader />}
      {primary && (
        <Badge
          variant='green'
          className='absolute left-0 top-0 w-full rounded-b-none text-xs '
        >
          <PaperAirplaneIcon className='mr-1 size-4' /> Primär mottagare
        </Badge>
      )}
      {recipient.view_only && (
        <Badge
          variant='blue'
          className='absolute left-0 top-0 w-full rounded-b-none text-xs '
        >
          <EyeIcon className='mr-1 size-4' /> Granskare
        </Badge>
      )}
      <CardContent>
        <CardSection
          className='py-1'
          isLoading={isLoading}
          items={[
            {
              title: 'Namn',
              content: actualRecipient
                ? (recipient.name ??
                  getValue<IActualRecipient>(
                    ['communicationName', 'name'],
                    actualRecipient
                  ))
                : '',
            },
            {
              title: 'Leveransmetod',
              content: toReadableDistributionMethod(
                recipient.invoice_distribution_instruction
              ),
            },
            {
              title: 'Personnummer/Orgnr',
              content: actualRecipient
                ? (recipient.org_no ??
                  recipient.ssn ??
                  getValue<IActualRecipient>(['orgNo', 'ssn'], actualRecipient))
                : '',
              hidden: !showMoreOpen,
            },
            {
              title: 'E-post',
              content: actualRecipient
                ? (recipient.email ??
                  getValue<IActualRecipient>(
                    ['invoiceEmail', 'email'],
                    actualRecipient
                  ))
                : '',
              hidden: !showMoreOpen,
            },
            {
              title: 'Telefon',
              content: actualRecipient
                ? (recipient.phone_number ??
                  getValue<IActualRecipient>(['phoneNumber'], actualRecipient))
                : '',
              hidden: !showMoreOpen,
            },
            {
              title: 'Leveransadress',
              content: actualRecipient
                ? (recipient.postal_address ??
                  toReadableAddress(
                    getValue<IActualRecipient>(
                      ['invoiceAddress'],
                      actualRecipient
                    ) as Partial<Address> | null,
                    false
                  ))
                : '',
              hidden: !showMoreOpen,
            },
          ]}
        />

        {!showMoreOpen && (
          <Button
            onClick={() => setShowMoreOpen(true)}
            variant='link'
            className='mt-2 p-0'
          >
            Visa mer <PlusIcon className='ml-2 size-4' />
          </Button>
        )}
      </CardContent>
    </Card>
  );
}
