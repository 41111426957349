'use client';

import type { ColumnDef } from '@tanstack/react-table';

import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { Checkbox } from '@/components/ui/checkbox';

import { TableActions } from '@/components/data-table/table-actions';
import { TableTags } from '@/components/data-table/table-tags';
import { toReadableDate } from '@/lib/dates';
import { Urls } from '@/lib/urls';
import { toReadableAddress } from '@/lib/utils';
import type { BrfCompany } from '@pigello/pigello-matrix';
import {
  BrfCompanyConfig,
  CompanyConfig,
  ExternalClientConfig,
} from '@pigello/pigello-matrix';
import Image from 'next/image';
import { DropdownList } from '../components/dropdown-list';

export const columns: ColumnDef<BrfCompany>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'company.name',
    meta: {
      columnVisibilityName: CompanyConfig.fields.name.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {row.original.company?.name ?? '-'}
      </div>
    ),
  },
  {
    accessorKey: 'logo',
    meta: {
      columnVisibilityName: CompanyConfig.fields.logo.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      if (!row.original.company?.logo?.get) return '-';
      return (
        <Image
          height={48}
          width={64}
          className='h-12 w-16 rounded-sm border object-contain object-center'
          alt='Logo'
          src={row.original.company?.logo.get}
        />
      );
    },
  },
  {
    accessorKey: 'orgNo',
    meta: {
      columnVisibilityName: CompanyConfig.fields.orgNo.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {row.original.company?.orgNo ?? '-'}
      </div>
    ),
  },
  {
    accessorKey: 'managedBy',
    meta: {
      columnVisibilityName: CompanyConfig.fields.managedBy.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        ids={[row.original.company?.managedBy?.id]}
        initialData={row.original.company?.managedBy}
        detailModalLinkUrl={(id) => Urls.detail.externalClient.overview(id)}
        config={ExternalClientConfig}
        fields={['name']}
      />
    ),
  },

  {
    accessorKey: 'postalAddress',
    meta: {
      columnVisibilityName: CompanyConfig.fields.postalAddress.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    enableSorting: false,
    cell: ({ row }) => (
      <span className='whitespace-nowrap'>
        {toReadableAddress(row.original.company?.postalAddress)}
      </span>
    ),
  },
  {
    accessorKey: 'customId',
    meta: {
      columnVisibilityName: BrfCompanyConfig.fields.customId.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.customId ?? '-'}</div>
    ),
  },

  {
    accessorKey: 'sniNumber',
    meta: {
      columnVisibilityName: CompanyConfig.fields.sniNumber.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => <span>{row.original.company?.sniNumber}</span>,
  },
  {
    accessorKey: 'registeredAddress',
    meta: {
      columnVisibilityName: CompanyConfig.fields.registeredAddress.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    enableSorting: false,
    cell: ({ row }) => (
      <span className='whitespace-nowrap'>
        {toReadableAddress(row.original.company?.registeredAddress)}
      </span>
    ),
  },

  {
    accessorKey: 'visitingAddress',
    meta: {
      columnVisibilityName: CompanyConfig.fields.visitingAddress.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    enableSorting: false,
    cell: ({ row }) => (
      <span className='whitespace-nowrap'>
        {toReadableAddress(row.original.company?.visitingAddress)}
      </span>
    ),
  },

  {
    accessorKey: 'createdAt',
    meta: {
      columnVisibilityName: BrfCompanyConfig.fields.createdAt.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.createdAt)}
      </div>
    ),
  },
  {
    accessorKey: 'tags',
    enableSorting: false,
    meta: {
      columnVisibilityName: BrfCompanyConfig.fields.tags.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='flex gap-2'>
        <TableTags
          tags={row.original.tags}
          modelName={BrfCompanyConfig.modelName}
        />
      </span>
    ),
  },
  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <TableActions
        instance={row.original}
        config={BrfCompanyConfig}
        title={row.original.company?.name}
      />
    ),
  },
];
