import { Chapters } from '@/app/detail/[type]/[id]/[subpage]/chapter-data';
import type { Chapter } from '@/components/form/types';
import { Icons } from '@/components/icons';
import { getGenericDisplayValue } from '@/components/monitoring/components/displayGenericValue';
import { Button } from '@/components/ui/button';
import { useOpenDetailModal } from '@/components/ui/detailModalLink';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useModal } from '@/hooks/useModal';
import { usePerms } from '@/hooks/usePerms';
import { useToast } from '@/hooks/useToast';
import { cn, raise } from '@/lib/utils';
import type { QueryParams } from '@/requests/api/types';
import { useGetList } from '@/requests/hooks';
import type { ListResponse } from '@/requests/types';
import {
  ArrowTopRightOnSquareIcon,
  ArrowUpRightIcon,
  ChevronDownIcon,
  ClipboardDocumentIcon,
  PencilIcon,
} from '@heroicons/react/16/solid';
import type {
  BaseInstance,
  FieldValue,
  IBaseInstanceConfig,
  ModelName,
  RelationalFieldValue,
  Tenant,
} from '@pigello/pigello-matrix';
import { useState } from 'react';

export function DropdownList<Instance extends BaseInstance>({
  ids,
  title,
  prefix,
  config,
  initialData,
  detailModalLinkUrl,
  fields,
  emptyTitle,
  overrideParams,
  disableEdit,
  className,
  detailPageModelName,
}: {
  ids: FieldValue<string>[];
  title?: string;
  prefix?: string;
  config: IBaseInstanceConfig<Instance>;
  initialData?: RelationalFieldValue<Instance>;
  detailModalLinkUrl: (id: FieldValue<string>) => string;
  fields: Array<keyof IBaseInstanceConfig<Instance>['fields']>;
  emptyTitle?: string;
  overrideParams?: QueryParams<Instance>;
  disableEdit?: boolean;
  className?: string;
  detailPageModelName?: ModelName;
}) {
  const { canView, canUpdate } = usePerms(config.modelName);
  const { canView: canViewDetailPage } = usePerms(
    detailPageModelName ?? config.modelName
  );
  const [isOpen, setIsOpen] = useState(false);
  const { data, isLoading: isLoading } = useGetList<Instance>({
    config,
    modelName: config.modelName,
    queryParams: overrideParams
      ? { ...overrideParams }
      : {
          filters: {
            id: { __in: ids?.join(',') },
          },
        },
    enabled: isOpen && ids.length > 0 && canView,
    ...(ids.length === 1 && {
      initialData: {
        list: [initialData],
      } as ListResponse<Instance>,
    }),
  });
  const field = fields.find((f) => data?.list.find((d) => d?.[f] != null));
  const initialTitle =
    ids.length === 1
      ? initialData?.[field ?? fields[0]]
      : `${initialData?.[field ?? fields[0]]} + ${ids.length - 1} till`;

  const displayName = title
    ? title
    : prefix !== undefined
      ? `${prefix} ${initialTitle?.toString()}`
      : initialTitle?.toString() ?? '-';

  if (!canView)
    return <span className='pointer-events-none whitespace-nowrap'>-</span>;
  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild disabled={ids.filter(Boolean).length === 0}>
        {ids.filter(Boolean).length > 0 ? (
          <Button
            className={cn('gap-2 whitespace-nowrap', className)}
            variant={'ghost'}
            size={'icon-sm'}
          >
            <span>{displayName}</span>
            <ChevronDownIcon
              className={cn(
                'size-4 transition-transform duration-75',
                isOpen && 'rotate-180 '
              )}
            />
          </Button>
        ) : (
          <span className='pointer-events-none whitespace-nowrap'>
            {emptyTitle ?? '-'}
          </span>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align='center'
        className='max-h-[400px] overflow-auto'
      >
        {isLoading &&
          ids.map((id) => (
            <DropdownMenuItem key={id}>
              <Icons.loader className='mr-2 size-4 animate-spin' /> Hämtar
              objekt...
            </DropdownMenuItem>
          ))}
        {data?.list.length === 1 && !isLoading && (
          <DropdownMenuItems
            url={detailModalLinkUrl(data?.list?.[0]?.id)}
            config={config}
            item={data.list[0]}
            field={field ?? fields[0]}
            disableEdit={disableEdit}
            canUpdate={canUpdate}
            canViewDetailPage={canViewDetailPage}
          />
        )}
        {data?.list &&
          data.list.length > 1 &&
          !isLoading &&
          data?.list.map((item) => (
            <DropdownMenuSub key={item.id}>
              <DropdownMenuSubTrigger>
                {title ??
                  getGenericDisplayValue({
                    fieldConfig: config.fields[field],
                    value: item?.[field ?? fields[0]],
                  }) ??
                  '-'}
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  <DropdownMenuItems
                    url={detailModalLinkUrl(item.id)}
                    config={config}
                    item={item}
                    field={field ?? fields[0]}
                    disableEdit={disableEdit}
                    canUpdate={canUpdate}
                    canViewDetailPage={canViewDetailPage}
                  />
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

type DropdownMenuItemsProps<Instance extends BaseInstance> = {
  url: string;
  config: IBaseInstanceConfig<Instance>;
  item: Instance;
  field: keyof Instance;
  disableEdit?: boolean;
  canUpdate?: boolean;
  canViewDetailPage?: boolean;
};

function DropdownMenuItems<Instance extends BaseInstance>({
  url,
  config,
  item,
  field,
  disableEdit,
  canUpdate,
  canViewDetailPage = true,
}: DropdownMenuItemsProps<Instance>) {
  const navigate = useOpenDetailModal(url);
  const { addToast } = useToast();
  const { openUpdateSingleInstanceModal } = useModal();
  const instanceName = getGenericDisplayValue({
    fieldConfig: config.fields[field],
    value: item?.[field],
  });
  const copyIdToClipboard = () => {
    const origin = window.origin;
    try {
      navigator.clipboard.writeText(origin + url);
      addToast({
        type: 'success',
        title: `Url har kopierats till urklipp`,
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: `Url kunde inte kopieras till urklipp`,
      });
    }
  };
  const tenantChapter =
    item &&
    config.modelName === 'tenant' &&
    'isCompany' in item &&
    item.isCompany
      ? Chapters['tenant_company']
      : Chapters['tenant_private'];

  const chapterTenant = {
    title: `Redigera ${instanceName ?? item.customId ?? 'objekt'}`,
    config: config as IBaseInstanceConfig<Tenant>,
    id: item.id ?? raise('No id provided in actions'),
    chapters: tenantChapter,
  };

  return (
    <>
      {canViewDetailPage && (
        <>
          <DropdownMenuItem onSelect={() => navigate()}>
            <ArrowUpRightIcon className='mr-2 size-4' />
            Gå till översikt
          </DropdownMenuItem>
          <DropdownMenuItem>
            <a
              className='flex cursor-default items-center'
              href={url}
              target='_blank'
            >
              <ArrowTopRightOnSquareIcon className='mr-2 size-4' />
              Öppna i ny flik
            </a>
          </DropdownMenuItem>
        </>
      )}
      <DropdownMenuItem onSelect={() => copyIdToClipboard()}>
        <ClipboardDocumentIcon className='mr-2 size-4' />
        Kopiera url till urklipp
      </DropdownMenuItem>
      {canUpdate && (
        <DropdownMenuItem
          disabled={disableEdit}
          className={disableEdit ? 'pointer-events-none opacity-50' : ''}
          onSelect={() =>
            config.modelName === 'tenant'
              ? openUpdateSingleInstanceModal<Tenant>(chapterTenant)
              : openUpdateSingleInstanceModal({
                  title: `Redigera ${instanceName}`,
                  config: config,
                  id: item.id ?? raise('No id provided in actions'),
                  chapters: Chapters[
                    config.modelName as keyof typeof Chapters
                  ] as Chapter<Instance>,
                })
          }
        >
          <PencilIcon className='mr-2 size-4' />
          Redigera
        </DropdownMenuItem>
      )}
    </>
  );
}
