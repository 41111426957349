'use client';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import type {
  BaseInstance,
  CustomerColumnType,
  CustomerColumnValue,
  IBaseInstanceConfig,
} from '@pigello/pigello-matrix';
import { UpdateCustomerColumnsForm } from './update-customer-columns-form';

export type CustomerColumnsModalResponse = Promise<boolean>;

export type CustomerColumnsModalContentData<Instance extends BaseInstance> = {
  config?: IBaseInstanceConfig<Instance>;
  customerColumn?: CustomerColumnValue;
  customerColumnType?: CustomerColumnType;
  objectId?: string;
};

export interface CustomerColumnsModalProps<Instance extends BaseInstance>
  extends GetModalContentProps {
  contentData: CustomerColumnsModalContentData<Instance>;
}

function CustomerColumnsModal<Instance extends BaseInstance>({
  closeModal,
  contentData,
}: CustomerColumnsModalProps<Instance>) {
  return <UpdateCustomerColumnsForm {...contentData} closeModal={closeModal} />;
}

export function getCustomerColumnsModalContent<
  Instance extends BaseInstance = BaseInstance,
>({ ...props }: CustomerColumnsModalProps<Instance>): ModalContent {
  const title =
    props.contentData.customerColumn?.columnType?.name ??
    props.contentData.customerColumnType?.name;
  return {
    title: props.contentData.customerColumn?.value
      ? `Redigera värde för ${title}`
      : `Lägg till ett värde för ${title}`,
    body: <CustomerColumnsModal {...props} />,
  };
}
