import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/16/solid';
import { createCalendar } from '@internationalized/date';
import { useRef, useState } from 'react';
import { useLocale, useRangeCalendar } from 'react-aria';
import { useRangeCalendarState } from 'react-stately';
import {
  CalendarButton,
  RangeCalendarGrid,
  RangeMonthDropdown,
  RangeYearDropdown,
} from '../date-field/calendar';

export function RangeCalendar({ ...props }) {
  const { locale } = useLocale();
  const state = useRangeCalendarState({
    ...props,
    locale,
    createCalendar,
  });
  const [key, setKey] = useState(crypto.randomUUID()); // [1
  const ref = useRef<HTMLDivElement | null>(null);
  const { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(
    props,
    state,
    ref
  );

  return (
    <div {...calendarProps} ref={ref} className='inline-block'>
      <div className='flex items-center gap-2 pb-4'>
        <RangeMonthDropdown state={state} />
        <RangeYearDropdown state={state} />
        <CalendarButton {...prevButtonProps}>
          <ChevronLeftIcon className='size-4' />
        </CalendarButton>
        <CalendarButton {...nextButtonProps}>
          <ChevronRightIcon className='size-4' />
        </CalendarButton>
      </div>
      <RangeCalendarGrid key={key} state={state} setKey={setKey} />
    </div>
  );
}
