import { columns } from '@/app/dashboard/brf-associations/columns';
import brfCompanyFilters from '@/app/dashboard/brf-associations/filters';
import { cn } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { BrfCompanyConfig, type BrfCompany } from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './relation-item-map';
export const BrfCompanyItem: RelationFieldProps<BrfCompany> = {
  columns: columns,
  nested: getRelationFieldNames(BrfCompanyConfig),
  filters: brfCompanyFilters,
  renderItem: (item, isRelationField) => {
    return {
      title: item.company?.name ?? '',
      descriptionItems: (
        <div
          className={cn(
            'flex w-full items-center',
            isRelationField && 'justify-between'
          )}
        >
          <span className='font-medium'>
            Org.no {item.company?.orgNo ?? 'Inget organisationsnummer angivet'}
          </span>
        </div>
      ),
    };
  },
};
