'use client';

import { getRelationFieldNames } from '@/requests/instanceMapper';
import { RoleConfig, type Role } from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './relation-item-map';

export const RoleItem: RelationFieldProps<Role> = {
  filters: [],
  columns: null,
  nested: getRelationFieldNames(RoleConfig),
  renderItem: (item) => ({
    title: item?.name,
    descriptionItems: (
      <span className='font-medium'>
        {item.customId ?? 'Inget objekts-id angivet'}
      </span>
    ),
  }),
};
