'use client';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import {
  RadioGroup,
  RadioGroupCard,
  RadioGroupCardDescription,
  RadioGroupCardHeader,
  RadioGroupCardTitle,
  RadioGroupItem,
} from '@/components/ui/radio-group';
import { useMutateTagUsage } from '@/config/annotating/tagusage/mutate';
import { useToast } from '@/hooks/useToast';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { parseDate } from '@internationalized/date';
import { type ModelName, type TagUsage } from '@pigello/pigello-matrix';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import type { DateRange } from 'react-aria';
import { toast } from 'sonner';
import { DateTimePicker } from '../dynamic-field/components/date-field/date-time-picker';
import { DateRangePicker } from '../dynamic-field/components/range-calendar/date-range-picker';

export default function EditTag({
  tag,
  modelName,
  closeFunction,
}: {
  tag: TagUsage;
  modelName?: ModelName;
  closeFunction: VoidFunction;
}) {
  const [value, setValue] = useState<DateRange>();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const mutation = useMutateTagUsage({});
  const [kind, setKind] = useState<string>(
    tag.startDate && tag.endDate
      ? 'range'
      : tag.startDate && !tag.endDate
        ? 'start-date'
        : 'forever'
  );

  const clearAndClose = () => {
    setValue(undefined);
    closeFunction();
  };

  const onSave = () => {
    if (kind === 'start-date' && !value?.start) {
      return addToast({
        type: 'error',
        title: 'Startdatum saknas',
        message: 'Välj ett startdatum',
      });
    }
    if (kind === 'range' && !value?.start && !value?.end) {
      return addToast({
        type: 'error',
        title: 'Tidsintervall saknas',
        message: 'Välj ett start- och slutdatum',
      });
    }
    const startDate =
      kind === 'start-date'
        ? value?.start?.toString()
        : kind === 'range'
          ? value?.start?.toString()
          : null;
    const endDate = kind === 'range' ? value?.end?.toString() : null;
    toast.promise(
      mutation.mutateAsync({
        id: tag.id,
        body: {
          startDate,
          endDate,
        },
      }),
      {
        loading: 'Sparar...',
        success: () => {
          queryClient.invalidateQueries({ queryKey: [modelName] });
          clearAndClose();
          return 'Intervallet för taggen uppdaterades';
        },
        error: 'Kunde inte uppdatera intervallet för taggen',
      }
    );
  };

  return (
    <Dialog defaultOpen onOpenChange={closeFunction}>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        size='md'
        className='p-0'
      >
        <DialogHeader>
          <DialogTitle>
            Redigera tidsintervallet för taggen {tag.tag?.name}
          </DialogTitle>
          <Button
            variant={'secondary'}
            size={'icon-sm'}
            onClick={() => clearAndClose()}
          >
            <XMarkIcon className='size-4' />
          </Button>
        </DialogHeader>
        <div className='grid gap-4 p-4'>
          <RadioGroup
            value={kind}
            onValueChange={(e) => setKind(e)}
            className='flex gap-2'
          >
            <RadioGroupCard
              htmlFor='start-date'
              className='h-full'
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <RadioGroupCardHeader>
                <RadioGroupCardTitle>Tillsvidare</RadioGroupCardTitle>
                <RadioGroupCardDescription>
                  Taggen kommer att vara aktiv från start och tillsvidare
                </RadioGroupCardDescription>
              </RadioGroupCardHeader>
              <RadioGroupItem value='start-date' id='start-date' />
            </RadioGroupCard>
            <RadioGroupCard
              htmlFor='range'
              className='h-full'
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <RadioGroupCardHeader>
                <RadioGroupCardTitle>Tidsintervall</RadioGroupCardTitle>
                <RadioGroupCardDescription>
                  Taggen kommer att vara aktiv under ett visst tidsintervall
                </RadioGroupCardDescription>
              </RadioGroupCardHeader>
              <RadioGroupItem value='range' id='range' />
            </RadioGroupCard>
            <RadioGroupCard
              htmlFor='forever'
              className='h-full'
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <RadioGroupCardHeader>
                <RadioGroupCardTitle>Inget tidsintervall</RadioGroupCardTitle>
                <RadioGroupCardDescription>
                  Taggen kommer att vara aktiv tillsvidare
                </RadioGroupCardDescription>
              </RadioGroupCardHeader>
              <RadioGroupItem value='forever' id='forever' />
            </RadioGroupCard>
          </RadioGroup>
          {kind === 'range' && (
            <DateRangePicker
              label='Välj tidsintervall'
              defaultValue={
                tag.startDate && tag.endDate
                  ? {
                      start: value?.start ?? parseDate(tag.startDate),
                      end: value?.end ?? parseDate(tag.endDate),
                    }
                  : null
              }
              onChange={setValue}
            />
          )}
          {kind === 'start-date' && (
            <div className='grid gap-2'>
              <Label>Startdatum</Label>
              <DateTimePicker
                label='Välj startdatum'
                {...(tag.startDate && {
                  defaultValue: value?.start ?? parseDate(tag.startDate),
                })}
                onChange={(e) =>
                  setValue({
                    start: e,
                    end: value?.end ?? e,
                  })
                }
              />
            </div>
          )}
        </div>
        <DialogFooter>
          <Button onClick={() => clearAndClose()} variant='outline'>
            Avbryt
          </Button>
          <Button onClick={onSave}>Spara</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
