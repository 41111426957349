import { InvoiceTableColumnHeader } from '@/components/data-table/custom-tables/invoice-row-table/invoice-table-column-header';
import { Checkbox } from '@/components/ui/checkbox';
import { toReadableDate } from '@/lib/dates';
import { toMoneyString } from '@/lib/utils';
import { InvoiceConfig, type Invoice } from '@pigello/pigello-matrix';
import type { ColumnDef } from '@tanstack/react-table';
import { InvoiceStatus } from '../../invoices/components/invoice-status';

export const InvoiceColumns: ColumnDef<Invoice>[] = [
  {
    id: 'select',
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'status',
    enableSorting: false,
    meta: {
      columnVisibilityName: 'Status',
      isFilterable: false,
    },
    header: ({ column }) => <InvoiceTableColumnHeader column={column} />,
    cell: ({ row }) => <InvoiceStatus invoice={row.original} />,
  },
  {
    accessorKey: 'value',
    enableSorting: false,
    meta: {
      columnVisibilityName: 'Belopp netto',
      isFilterable: false,
    },
    header: ({ column }) => <InvoiceTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div>{toMoneyString(row.original.calculatedRowData?.rows_sum_net)}</div>
    ),
  },

  {
    accessorKey: 'dueDate',
    enableSorting: true,
    meta: {
      columnVisibilityName: InvoiceConfig.fields.dueDate.verboseName,
      isFilterable: false,
    },
    header: ({ column }) => <InvoiceTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.dueDate)}
      </div>
    ),
  },
  {
    accessorKey: 'ourReference',
    enableSorting: true,
    meta: {
      columnVisibilityName: InvoiceConfig.fields.ourReference.verboseName,
      isFilterable: false,
    },
    header: ({ column }) => <InvoiceTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {row.original.ourReference ?? '-'}
      </div>
    ),
  },
  {
    accessorKey: 'ocrNumber',
    enableSorting: true,
    meta: {
      columnVisibilityName: InvoiceConfig.fields.ocrNumber.verboseName,
      isFilterable: false,
    },
    header: ({ column }) => <InvoiceTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.ocrNumber ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'sender',
    enableSorting: false,
    meta: {
      columnVisibilityName: 'Avsändare',
      isFilterable: false,
    },
    header: ({ column }) => <InvoiceTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.company?.name}</div>
    ),
  },
];
