import type { InvoiceRecipient } from '@pigello/pigello-matrix';
import { getActualRecipientFields } from '../../utils';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import {
  AtSymbolIcon,
  MapPinIcon,
  PaperAirplaneIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import { toReadableAddress } from '@/lib/utils';
import { distributionDefaultValues } from '@/components/form/dynamic-field/components/distribution-instruction-field';
import {
  MultiSelectInput,
  type TData,
} from '@/components/ui/multi-select-input';
import { isEmpty } from 'lodash';

const RecipientCard = ({
  recipient,
  onClick,
  currentValues,
  onSelected,
}: {
  recipient: InvoiceRecipient;
  onClick: VoidFunction;
  currentValues: number[];
  onSelected: (values: TData[]) => void;
}) => {
  const actualRecipient = getActualRecipientFields(recipient);

  const values = currentValues?.map((instruction) => ({
    d: distributionDefaultValues.find((d) => d.v === instruction)?.d ?? '',
    v: instruction,
  }));

  return (
    <Card className='group'>
      <div className='flex items-center justify-between gap-2'>
        <h3 className='truncate'>{actualRecipient.name ?? '-'}</h3>
        <Button
          className='opacity-0 transition-opacity duration-300 group-hover:opacity-100'
          variant='outline'
          size='icon-sm'
          onClick={onClick}
        >
          <XMarkIcon className='size-4' />
        </Button>
      </div>
      <div className='flex items-center gap-2'>
        <MapPinIcon className='size-4' />
        <p className='max-w-[370px] truncate text-sm'>
          {toReadableAddress(actualRecipient.address) ??
            'Ingen address angiven'}
        </p>
      </div>
      <div className='flex items-center gap-2'>
        <AtSymbolIcon className='size-4' />
        <p className='max-w-[370px] truncate text-sm'>
          {actualRecipient.email ?? 'Ingen e-post angiven'}
        </p>
      </div>
      <div className='flex items-center gap-2'>
        <PaperAirplaneIcon className='size-4' />
        <MultiSelectInput
          placeholder={
            isEmpty(currentValues) ? 'Välj leveransmetoder' : undefined
          }
          onSelected={onSelected}
          defaultValues={distributionDefaultValues}
          values={values}
        />
      </div>
    </Card>
  );
};

export default RecipientCard;
