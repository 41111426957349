import {
  defaultStringFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import { BrfCompanyConfig } from '@pigello/pigello-matrix';
import baseFilters, { baseTagFilter } from '../filters';

const brfCompanyFilters: IFilter[] = [
  {
    title: BrfCompanyConfig.fields.customId.verboseName,
    externalFieldName: BrfCompanyConfig.fields.customId.externalFieldName,
    ...defaultStringFilter,
  },

  ...baseTagFilter,
  ...baseFilters,
];

export default brfCompanyFilters;
