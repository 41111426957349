import {
  defaultBooleanFilter,
  defaultStringFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import { ArticleCategoryConfig } from '@pigello/pigello-matrix';

const articleCategoryFilters: IFilter[] = [
  {
    title: ArticleCategoryConfig.fields.name.verboseName,
    externalFieldName: ArticleCategoryConfig.fields.name.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: ArticleCategoryConfig.fields.archived.verboseName,
    externalFieldName: ArticleCategoryConfig.fields.archived.externalFieldName,
    ...defaultBooleanFilter,
  },
  {
    title: ArticleCategoryConfig.fields.considerBaseRent.verboseName,
    externalFieldName:
      ArticleCategoryConfig.fields.considerBaseRent.externalFieldName,
    ...defaultBooleanFilter,
  },
];

export { articleCategoryFilters };
