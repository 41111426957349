 import type { IndexationSettingUsage } from '@pigello/pigello-matrix';

import { IndexationSettingUsageConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useCreateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, createGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { createGenericInstance } from '@/requests/creating';

export const createIndexationSettingUsage = async (options: createGenericInstanceProps<IndexationSettingUsage>) => {
  return createGenericInstance<IndexationSettingUsage>({
    ...options,
    config: IndexationSettingUsageConfig,
  });
};

export const useCreateIndexationSettingUsage = (
  options: UseMutationOptions<IndexationSettingUsage, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useCreateGenericInstance<IndexationSettingUsage>(createIndexationSettingUsage, options, 'indexationsettingusage');
