 import type { CustomerColumnValue } from '@pigello/pigello-matrix';

import { CustomerColumnValueConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, mutateGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { mutateGenericInstance } from '@/requests/mutating';

export const mutateCustomerColumnValue = async (
  options: mutateGenericInstanceProps<CustomerColumnValue>
) => {
  return mutateGenericInstance<CustomerColumnValue>({
    ...options,
    config: CustomerColumnValueConfig,
  });
};

export const useMutateCustomerColumnValue = (
  options: UseMutationOptions<CustomerColumnValue, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useMutateGenericInstance<CustomerColumnValue>(mutateCustomerColumnValue, options, 'customercolumnvalue');