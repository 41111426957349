import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { cn } from '@/lib/utils';
import { parseDate, parseDateTime } from '@internationalized/date';
import type { BaseInstance } from '@pigello/pigello-matrix';
import type { DateValue } from 'react-aria';
import type { Path } from 'react-hook-form';
import type { DatePickerStateOptions } from 'react-stately';
import type { FieldProps } from '../../types';
import { DescriptionTooltip } from '../description-tooltip';
import { DateTimePicker } from './date-time-picker';

type DateFieldProps<Instance extends BaseInstance = BaseInstance> =
  FieldProps<Instance> & DatePickerStateOptions<DateValue>;

export function DateField<Instance extends BaseInstance = BaseInstance>({
  formField,
  name,
  label,
  description,
  horizontal,
  className,
  disabled,
  canHandleField = true,
  ...props
}: DateFieldProps<Instance>) {
  if (horizontal) {
    return (
      <FormField
        rules={{
          required: formField.required && 'Detta fält är obligatoriskt',
        }}
        control={formField.control}
        name={name as Path<Instance>}
        render={({ field }) => {
          return (
            <FormItem
              className={cn('flex items-center justify-between', className)}
            >
              <div className='w-1/2 shrink-0'>
                <FormLabel>
                  {label ?? formField.label}{' '}
                  {formField.required && <span>*</span>}
                </FormLabel>
                <FormDescription>
                  {description ?? formField?.description}
                </FormDescription>
                <FormMessage />
              </div>
              <FormControl>
                <DateTimePicker
                  {...field}
                  {...props}
                  isDisabled={disabled ?? field.disabled ?? !canHandleField}
                  label={label ?? formField.label}
                  defaultValue={
                    field.value
                      ? props.granularity === 'minute'
                        ? parseDateTime(field.value.replaceAll('Z', ''))
                        : parseDate(field.value)
                      : null
                  }
                  value={
                    field.value
                      ? props.granularity === 'minute'
                        ? parseDateTime(field.value.replaceAll('Z', ''))
                        : parseDate(field.value)
                      : null
                  }
                  onChange={(e) => {
                    field.onChange(e?.toString() ?? null);
                  }}
                />
              </FormControl>
            </FormItem>
          );
        }}
      />
    );
  }
  return (
    <FormField
      rules={{
        required: formField.required && 'Detta fält är obligatoriskt',
      }}
      control={formField.control}
      name={name as Path<Instance>}
      render={({ field }) => {
        return (
          <FormItem className={className}>
            <div className='flex items-center'>
              <FormLabel>
                {label ?? formField.label}{' '}
                {formField.required && <span>*</span>}
              </FormLabel>
              {(description || formField.description) && (
                <DescriptionTooltip
                  description={description ?? formField.description}
                />
              )}
            </div>
            <FormControl>
              <DateTimePicker
                {...field}
                {...props}
                isDisabled={disabled ?? field.disabled ?? !canHandleField}
                label={label ?? formField.label}
                defaultValue={
                  field.value
                    ? props.granularity === 'minute'
                      ? parseDateTime(field.value.replaceAll('Z', ''))
                      : parseDate(field.value)
                    : null
                }
                value={
                  field.value
                    ? props.granularity === 'minute'
                      ? parseDateTime(field.value.replaceAll('Z', ''))
                      : parseDate(field.value)
                    : null
                }
                onChange={(e) => field.onChange(e?.toString() ?? null)}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}
