 import type { ArticleCategory } from '@pigello/pigello-matrix';

import { ArticleCategoryConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useCreateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, createGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { createGenericInstance } from '@/requests/creating';

export const createArticleCategory = async (options: createGenericInstanceProps<ArticleCategory>) => {
  return createGenericInstance<ArticleCategory>({
    ...options,
    config: ArticleCategoryConfig,
  });
};

export const useCreateArticleCategory = (
  options: UseMutationOptions<ArticleCategory, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useCreateGenericInstance<ArticleCategory>(createArticleCategory, options, 'articlecategory');
