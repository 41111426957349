'use client';

import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { TableActions } from '@/components/data-table/table-actions';
import { TableTags } from '@/components/data-table/table-tags';
import { Checkbox } from '@/components/ui/checkbox';
import { Urls } from '@/lib/urls';
import { toReadableAddress, toSqm } from '@/lib/utils';
import {
  ParkingLotConfig,
  RealEstateConfig,
  type ParkingLot,
} from '@pigello/pigello-matrix';
import type { ColumnDef } from '@tanstack/react-table';
import { DropdownList } from '../../components/dropdown-list';

export const columns: ColumnDef<ParkingLot>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'customId',
    meta: {
      columnVisibilityName: ParkingLotConfig.fields.customId.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.customId ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'name',
    meta: {
      columnVisibilityName: ParkingLotConfig.fields.name.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.name}</div>
    ),
  },
  {
    accessorKey: 'structureType',
    meta: {
      columnVisibilityName: ParkingLotConfig.fields.structureType.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span>
        {ParkingLotConfig.fields.structureType.choices?.find(
          (choice) => choice.v === row.original.structureType
        )?.d ?? '-'}
      </span>
    ),
  },
  {
    accessorKey: 'structureOperationType',
    meta: {
      columnVisibilityName:
        ParkingLotConfig.fields.structureOperationType.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span>
        {ParkingLotConfig.fields.structureOperationType.choices?.find(
          (choice) => choice.v === row.original.structureOperationType
        )?.d ?? '-'}
      </span>
    ),
  },
  {
    accessorKey: 'registeredGrossArea',
    meta: {
      columnVisibilityName:
        ParkingLotConfig.fields.registeredGrossArea.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap text-end'>
        {toSqm(row.original.registeredGrossArea)}
      </div>
    ),
  },
  {
    accessorKey: 'registeredUsableArea',
    meta: {
      columnVisibilityName:
        ParkingLotConfig.fields.registeredUsableArea.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap text-end'>
        {toSqm(row.original.registeredUsableArea)}
      </div>
    ),
  },
  {
    accessorKey: 'registeredBuildingArea',
    meta: {
      columnVisibilityName:
        ParkingLotConfig.fields.registeredBuildingArea.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='whitespace-nowrap text-end'>
        {toSqm(row.original.registeredBuildingArea)}
      </span>
    ),
  },
  {
    accessorKey: 'registeredBiArea',
    meta: {
      columnVisibilityName:
        ParkingLotConfig.fields.registeredBiArea.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='whitespace-nowrap text-end'>
        {toSqm(row.original.registeredBiArea)}
      </span>
    ),
  },

  {
    accessorKey: 'isGarage',
    meta: {
      columnVisibilityName: ParkingLotConfig.fields.isGarage.verboseName,
    },
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Garage' />
    ),
    cell: ({ row }) => <span>{row.original.isGarage ? 'Ja' : 'Nej'}</span>,
  },

  {
    accessorKey: 'realestate',
    enableSorting: false,
    meta: {
      columnVisibilityName: ParkingLotConfig.fields.realestate.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        ids={[row.original.realestate?.id]}
        initialData={row.original.realestate}
        detailModalLinkUrl={(id) => Urls.detail.realestate.overview(id)}
        config={RealEstateConfig}
        fields={['name']}
      />
    ),
  },
  {
    accessorKey: 'address',
    enableSorting: false,
    meta: {
      columnVisibilityName: ParkingLotConfig.fields.address.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='whitespace-nowrap'>
        {toReadableAddress(row.original.address)}
      </span>
    ),
  },
  {
    accessorKey: 'tags',
    meta: {
      columnVisibilityName: 'Taggar',
    },
    header: 'Taggar',
    cell: ({ row }) => (
      <span className='flex gap-2'>
        <TableTags
          tags={row.original.tags}
          modelName={ParkingLotConfig.modelName}
        />
      </span>
    ),
  },
  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <TableActions
        instance={row.original}
        config={ParkingLotConfig}
        title={row.original.name}
      />
    ),
  },
];
