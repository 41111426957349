 import type { OrganizationUserContactPerson } from '@pigello/pigello-matrix';

import { OrganizationUserContactPersonConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetOrganizationUserContactPerson = (
  options: useGetGenericInstanceProps<OrganizationUserContactPerson>
) => {
  return useGetInstance<OrganizationUserContactPerson>({
    ...options,
    config: OrganizationUserContactPersonConfig,
    modelName: 'organizationusercontactperson'
  });
};

export const useGetOrganizationUserContactPersonList = (
  options: useGetGenericListProps<OrganizationUserContactPerson>
) => {
  return useGetList<OrganizationUserContactPerson>({
    ...options,
    config: OrganizationUserContactPersonConfig,
    modelName: 'organizationusercontactperson'
  });
};
