 import type { ApartmentContract } from '@pigello/pigello-matrix';

import { ApartmentContractConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, mutateGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { mutateGenericInstance } from '@/requests/mutating';

export const mutateApartmentContract = async (
  options: mutateGenericInstanceProps<ApartmentContract>
) => {
  return mutateGenericInstance<ApartmentContract>({
    ...options,
    config: ApartmentContractConfig,
  });
};

export const useMutateApartmentContract = (
  options: UseMutationOptions<ApartmentContract, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useMutateGenericInstance<ApartmentContract>(mutateApartmentContract, options, 'apartmentcontract');