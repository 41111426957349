'use client';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import type {
  BaseInstance,
  IBaseInstanceConfig,
} from '@pigello/pigello-matrix';
import type { DefaultValues } from 'react-hook-form';
import type { Chapter } from '../types';
import { DefaultCreateFormDashboard } from './dashboard-form';
export type CreateSingleInstanceModalResponse = Promise<unknown>;

export type CreateSingleInstanceModalContentData<
  Instance extends BaseInstance = BaseInstance,
> = {
  title: string;
  description?: string;
  chapters?: Chapter<Instance>;
  cleanInstance: () => DefaultValues<Instance>;
  config: IBaseInstanceConfig<Instance>;
  preMutatedValues?: Partial<Instance>;
  horizontal?: boolean;
  onSuccess?: (data: Instance) => void;
  persistedIdentifier?: string;
};

export interface CreateSingleInstanceModalProps extends GetModalContentProps {
  contentData: CreateSingleInstanceModalContentData;
}

function CreateSingleInstanceModal({
  closeModal,
  contentData: {
    cleanInstance,
    config,
    title,
    chapters,
    description,
    preMutatedValues,
    horizontal,
    onSuccess,
    persistedIdentifier,
  },
}: CreateSingleInstanceModalProps) {
  return (
    <DefaultCreateFormDashboard
      title={title}
      description={description}
      chapters={chapters}
      cleanInstance={cleanInstance}
      config={config}
      closeModal={closeModal}
      preMutatedValues={preMutatedValues}
      horizontal={horizontal}
      onSuccess={onSuccess}
      persistedIdentifier={persistedIdentifier}
    />
  );
}

export const getCreateSingleInstanceModalContent = ({
  ...props
}: CreateSingleInstanceModalProps): ModalContent => {
  return {
    body: <CreateSingleInstanceModal {...props} />,
    externalModalHandling: true,
    isCloseOnEscape: false,
  };
};
