 import type { TagUsage } from '@pigello/pigello-matrix';

import { TagUsageConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useDeleteGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, deleteGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { deleteGenericInstance } from '@/requests/deleting';

export const deleteTagUsage = async (
  options: deleteGenericInstanceProps<TagUsage>
) => {
  return deleteGenericInstance<TagUsage>({
    ...options,
    config: TagUsageConfig,
  });
};

export const useDeleteTagUsage = (
  options: UseMutationOptions<TagUsage, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useDeleteGenericInstance<TagUsage>(deleteTagUsage, options, 'tagusage');