'use client';

import type { ColumnDef } from '@tanstack/react-table';

import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { Checkbox } from '@/components/ui/checkbox';
import { StatusBadge } from '@/components/ui/statusBadge';

import { TableActions } from '@/components/data-table/table-actions';
import { TableTags } from '@/components/data-table/table-tags';
import { toReadableDate } from '@/lib/dates';
import { Urls } from '@/lib/urls';
import { toReadableAddress, toSqm } from '@/lib/utils';
import type { Tenant } from '@pigello/pigello-matrix';
import {
  ApartmentConfig,
  BuildingConfig,
  TenantConfig,
  type Apartment,
} from '@pigello/pigello-matrix';
import { DropdownList } from '../components/dropdown-list';

export const columns: ColumnDef<Apartment>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'status',
    meta: {
      columnVisibilityName: ApartmentConfig.fields.status.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => <StatusBadge status={row.original.status} />,
  },
  {
    accessorKey: 'customId',
    meta: {
      columnVisibilityName: ApartmentConfig.fields.customId.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.customId ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'apartmentId',
    meta: {
      columnVisibilityName: ApartmentConfig.fields.apartmentId.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.apartmentId}</div>
    ),
  },
  {
    accessorKey: 'popularName',
    meta: {
      columnVisibilityName: ApartmentConfig.fields.popularName.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.popularName}</div>
    ),
  },

  {
    accessorKey: 'category',
    meta: {
      columnVisibilityName: ApartmentConfig.fields.category.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {ApartmentConfig.fields.category?.choices?.find(
          (c) => (c.v as string) === row.original.category
        )?.d ?? '-'}
      </div>
    ),
  },
  {
    accessorKey: 'address',
    meta: {
      columnVisibilityName: ApartmentConfig.fields.address.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return (
        <div className='whitespace-nowrap'>
          {toReadableAddress(row.original.address)}
        </div>
      );
    },
  },
  {
    accessorKey: 'building',
    enableSorting: false,
    meta: {
      columnVisibilityName: ApartmentConfig.fields.building.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        ids={[row.original.building?.id]}
        initialData={row.original.building}
        detailModalLinkUrl={(id) => Urls.detail.building.overview(id)}
        config={BuildingConfig}
        fields={['name']}
      />
    ),
  },
  {
    accessorKey: 'floors',
    meta: {
      columnVisibilityName: ApartmentConfig.fields.floors.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return (
        <div className='whitespace-nowrap text-end'>
          {row.original.floors?.join(', ') ?? '-'}
        </div>
      );
    },
  },
  {
    accessorKey: 'usableArea',
    meta: {
      columnVisibilityName: ApartmentConfig.fields.usableArea.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='text-end'>{toSqm(row.original.usableArea)}</div>
    ),
  },
  {
    accessorKey: 'biArea',
    meta: {
      columnVisibilityName: ApartmentConfig.fields.biArea.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='text-end'>{toSqm(row.original.biArea)}</div>
    ),
  },
  {
    accessorKey: 'tenant',
    enableSorting: false,
    meta: {
      columnVisibilityName: ApartmentConfig.fields.tenant.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        ids={[row.original.tenant?.id]}
        initialData={row.original.tenant}
        detailModalLinkUrl={(id) => Urls.detail.tenant.overview(id)}
        config={TenantConfig}
        fields={['communicationName']}
      />
    ),
  },
  {
    accessorKey: 'biTenants',
    enableSorting: false,
    meta: {
      columnVisibilityName: ApartmentConfig.fields.biTenants.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList<Tenant>
        ids={row.original.biTenants?.map((t) => t.id) ?? []}
        initialData={row.original.biTenants?.[0]}
        detailModalLinkUrl={(id) => Urls.detail.tenant.overview(id)}
        config={TenantConfig}
        fields={['communicationName']}
      />
    ),
  },

  {
    accessorKey: 'createdAt',
    meta: {
      columnVisibilityName: ApartmentConfig.fields.createdAt.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.createdAt)}
      </div>
    ),
  },
  {
    accessorKey: 'tags',
    enableSorting: false,
    meta: {
      columnVisibilityName: ApartmentConfig.fields.tags.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='flex gap-2'>
        <TableTags
          tags={row.original.tags}
          modelName={ApartmentConfig.modelName}
        />
      </span>
    ),
  },
  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <TableActions
        instance={row.original}
        config={ApartmentConfig}
        title={row.original.apartmentId}
      />
    ),
  },
];
