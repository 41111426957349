import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { TableActions } from '@/components/data-table/table-actions';
import { toPercentageString } from '@/lib/utils';
import { ArticleConfig, type Article } from '@pigello/pigello-matrix';
import type { ColumnDef } from '@tanstack/react-table';
import { CategoryEditDropdown } from '../components/category-edit-dropdown';
import { revenueAccount } from '../utils';

export const columns: ColumnDef<Article>[] = [
  {
    accessorKey: 'customId',
    meta: {
      columnVisibilityName: ArticleConfig.fields.customId.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.customId ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'name',
    meta: {
      columnVisibilityName: ArticleConfig.fields.name.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.name ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'category',
    enableSorting: false,
    meta: {
      columnVisibilityName: ArticleConfig.fields.category.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <CategoryEditDropdown category={row.original.category} />
    ),
  },
  {
    accessorKey: 'unit',
    meta: {
      columnVisibilityName: ArticleConfig.fields.unit.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.unit ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'vat',
    meta: {
      columnVisibilityName: ArticleConfig.fields.vat.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toPercentageString(row.original.vat)}
      </div>
    ),
  },
  {
    accessorKey: 'revenueAccount',
    enableSorting: false,
    meta: {
      columnVisibilityName: 'Konto',
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) =>
      row.original.vat != null ? (
        <div className='whitespace-nowrap'>
          {row.original[revenueAccount[row.original.vat]]}
        </div>
      ) : (
        '-'
      ),
  },
  {
    accessorKey: 'allowSpaceBoundRevenueRows',
    meta: {
      columnVisibilityName:
        ArticleConfig.fields.allowSpaceBoundRevenueRows.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {row.original.allowSpaceBoundRevenueRows
          ? 'Tillåts på avtal & objekt'
          : 'Tillåts endast på avtal'}
      </div>
    ),
  },
  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <TableActions
        instance={row.original}
        config={ArticleConfig}
        title={row.original.name}
        disableEdit={true}
      />
    ),
  },
];
