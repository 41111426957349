import { ObjectEntries } from '@/lib/utils';
import { getApi } from '@/requests/api';
import { BASE_BACKEND_URL } from '@/requests/constants';
import { fetchGenericList } from '@/requests/fetching';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import type {
  BaseInstance,
  IBaseInstanceConfig,
  ModelName,
} from '@pigello/pigello-matrix';
import { getConfig } from '@pigello/pigello-matrix';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useManyConfigs } from '../useConfig';

export type BlockedBy = { [key in ModelName]?: string[] };

type Replacement = {
  content_type: string;
  field_name: string;
  id: string;
};

interface Props<Instance extends BaseInstance> {
  config?: IBaseInstanceConfig<Instance>;
  instance?: Instance;
  blockedBy?: BlockedBy;
  formValues?: Partial<BaseInstance> | null;
}

export function useArchive<
  Instance extends BaseInstance = BaseInstance,
  Replacements extends BaseInstance = BaseInstance,
>({ config, instance, blockedBy }: Props<Instance>) {
  const { configs } = useManyConfigs(
    config?.replacableRelationsOnArchive?.map(
      (relation) => relation
    ) as ModelName[]
  );
  const [blockedByInstancesList, setBlockedByInstancesList] =
    useState<
      { config: IBaseInstanceConfig<BaseInstance>; count: number | undefined }[]
    >();
  const replacements = useQuery({
    queryKey: ['replacements', config?.modelName, instance?.id],
    staleTime: 0,
    queryFn: async () => {
      return await getApi<{ replacements: Replacement[] }>({
        url: `${BASE_BACKEND_URL}/archiving/replace/${config?.contentType}/${instance?.id}/`,
        errorMessage: 'Unable to fetch replacements',
      });
    },
  });

  const groupByCategory = Object.groupBy(
    replacements.data?.replacements ?? [],
    (replacement) => replacement.content_type.split('.')[1] as ModelName
  );

  const replacementslist = useQueries({
    combine: (results) => {
      return {
        data: results.map((result) => result.data).filter(Boolean),
        pending: results.some((result) => result.isPending),
      };
    },
    queries: configs?.map((config) => {
      const ids = groupByCategory?.[config.modelName]?.map((item) => item.id);
      return {
        queryKey: ['replacements', config.modelName, ids],
        staleTime: 0,
        enabled: !!ids?.length,
        queryFn: async () => {
          const res = await fetchGenericList<Replacements>({
            modelName: config.modelName,
            queryParams: {
              page: 1,
              pageSize: 100,
              filters: {
                id: {
                  __in: ids?.join(','),
                },
              },
            },
            nested: getRelationFieldNames(config),
          });
          return {
            config,
            instances: res.list,
          };
        },
      };
    }),
  });
  useEffect(() => {
    const getBlockedByInstancesList = async () => {
      const blockedByPromises = ObjectEntries(blockedBy ?? {}).map(
        async ([modelName, ids]) => {
          const config = await getConfig(modelName);

          return {
            config,
            count: ids?.length,
          };
        }
      );
      const blockedByInstancesList = await Promise.all(blockedByPromises);
      setBlockedByInstancesList(blockedByInstancesList);
    };
    getBlockedByInstancesList();
  }, [blockedBy]);
  return {
    blockedByCount: blockedByInstancesList,
    replacementslist,
  };
}
