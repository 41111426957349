'use client';

import InvoiceRowTable from '@/components/data-table/custom-tables/invoice-row-table';
import { Pagination } from '@/components/pagination';
import { buttonVariants } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { useGetInvoiceRowList } from '@/config/accounting/invoices/invoicerow/client';
import { cn } from '@/lib/utils';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
import type { Invoice } from '@pigello/pigello-matrix';
import { useState } from 'react';
import { invoiceRowDebitColumns } from '../debit-columns';

export function DebitRows({ invoice }: { invoice: Invoice }) {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { data, isLoading } = useGetInvoiceRowList({
    queryParams: {
      page,
      pageSize: 10,
      filters: {
        invoice: { noop: invoice.id },
      },
    },
    nested: ['article'],
    enabled: !!invoice.id && open,
  });
  return (
    <div className='whitespace-nowrap'>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger
          className={cn(
            buttonVariants({ variant: 'ghost' }),
            '[&[data-state=open]>svg]:rotate-180'
          )}
        >
          Debiteringsrader{' '}
          <ChevronDownIcon
            className={'ml-2 size-4 transition-transform duration-75'}
          />
        </PopoverTrigger>
        <PopoverContent align='end' className='grid w-[600px] gap-4'>
          <div className='flex items-center justify-between'>
            <h2>Debiteringsrader</h2>
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder='Sök'
              className='max-w-xs'
            />
          </div>
          <div className='max-h-96 overflow-auto'>
            <InvoiceRowTable
              searchValue={search}
              isLoading={isLoading}
              data={data?.list}
              columns={invoiceRowDebitColumns}
            />
          </div>
          <Pagination page={page} setPage={setPage} meta={data?.meta} />
        </PopoverContent>
      </Popover>
    </div>
  );
}
