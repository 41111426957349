import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import type {
  ApprovingParty,
  IBaseInstanceConfig,
} from '@pigello/pigello-matrix';

export type ApprovingPartyMonitoringConfiguration =
  | {
      config: IBaseInstanceConfig<ApprovingParty>;
      instances: ApprovingParty[];
    }[]
  | undefined;

export function ApprovingPartyReplacements({
  data,
  isPending,
}: {
  data?: ApprovingPartyMonitoringConfiguration;
  isPending: boolean;
}) {
  if (isPending) {
    return null;
  }
  return (
    data &&
    data.length > 0 && (
      <>
        {data.map((data) => {
          return (
            <Card key={data?.config.contentType}>
              <CardHeader>
                <CardTitle>{data?.config.verboseNamePlural}</CardTitle>
              </CardHeader>
              <CardContent className='grid grid-cols-2 gap-4'>
                {data?.instances
                  .sort(
                    (a, b) => (a.approvalGroup ?? 0) - (b.approvalGroup ?? 0)
                  )
                  .map((instance) => {
                    return (
                      <Card key={instance.id}>
                        <CardHeader>
                          <CardTitle>Grupp {instance.approvalGroup}</CardTitle>
                        </CardHeader>
                        <CardContent>
                          <p>
                            {instance.workEngagement
                              ? instance.workEngagement.name
                              : instance.user?.communicationName}
                          </p>
                        </CardContent>
                      </Card>
                    );
                  })}
              </CardContent>
            </Card>
          );
        })}
      </>
    )
  );
}
