'use client';

import store from 'store';

import { Chapters } from '@/app/detail/[type]/[id]/[subpage]/chapter-data';
import {
  CONTRACT_MODAL_SOURCE_KEY,
  DETAIL_MODAL_SOURCE_KEY,
} from '@/components/ui/detailModalLink';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import WindowKeeperItem from '@/components/window-keeper/window-keeper-items';
import { useModal } from '@/hooks/useModal';
import { useWindowKeeper } from '@/hooks/useWindowKeeper';
import { cn } from '@/lib/utils';
import { useBulkTabStore } from '@/store';
import type { TrackedBulk } from '@/store/types';
import {
  getConfig,
  type BaseInstance,
  type IBaseInstanceConfig,
  type ModelName,
} from '@pigello/pigello-matrix';
import { usePathname, useRouter } from 'next/navigation';
import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useResizeObserver from 'use-resize-observer';

declare global {
  interface Window {
    _minimize_bulk: (trackedBulk: TrackedBulk) => void;
  }
}

const BULK_MODE_REQUIREMENTS: Record<
  TrackedBulk['mode'],
  {
    instances: boolean;
  }
> = {
  create: {
    instances: false,
  },
  copy: {
    instances: true,
  },
  edit: {
    instances: true,
  },
  download: {
    instances: false,
  },
};

export type Preview = {
  type: 'instance' | 'bulks' | 'nonsavedinstance';
  display: string;
  chapterSuffix?: string;
  identifier: string;
} & (
  | {
      type: 'instance';
      instanceId: string;
    }
  | {
      type: 'nonsavedinstance';
      zIdentifier: string;
    }
  | {
      type: 'bulks';
    }
);

type Stored = {
  type: Preview['type'];
  chapterSuffix?: string;
  identifier: string;
} & (
  | {
      type: 'instance';
      detailPath: string;
    }
  | {
      type: 'nonsavedinstance';
      formPath?: string;
    }
  | {
      type: 'bulks';
      bulkId: string;
      modelName: ModelName;
      mode: TrackedBulk['mode'];
    }
);

interface State {
  addInstance: (
    instance: BaseInstance,
    config: IBaseInstanceConfig<BaseInstance>,
    detailPath: string
  ) => string;
  addNonSavedInstance: (
    confg: IBaseInstanceConfig<BaseInstance>,
    zIdentifier: string,
    display: string,
    options?: {
      chapterSuffix?: string;
    },
    formPath?: string
  ) => string | undefined;
  removePreview: (identifier: string) => void;
  getStored: (identifier: string) => Stored | null;
  previews: Array<Preview>;
  resetPreviews: () => void;
  openBulk: (
    modelName: ModelName,
    type: TrackedBulk['mode'],
    instances?: string[]
  ) => void;
}

const initialState: State = {
  addInstance: () => 'undefined',
  addNonSavedInstance: () => 'undefined',
  getStored: () => ({
    identifier: '',
    type: 'instance',
    queryParams: {},
    detailPath: '',
  }),
  removePreview: () => undefined,
  previews: [],
  resetPreviews: () => undefined,
  openBulk: () => {},
};

export const WindowKeeperContext = createContext(initialState);

const LOCAL_STORAGE_PREVIEWS_KEY = '__pigello__wk__previews';
const LOCAL_STORAGE_STORAGE_KEY = '__pigello__wk__storage';

export default function Provider({ children }: { children: ReactNode }) {
  const [previews, setPreviews] = useState<Array<Preview>>([]);

  const storePreview = (preview: Preview, toStore: Stored) => {
    const stringifed = JSON.stringify(toStore);

    store.set(`${LOCAL_STORAGE_STORAGE_KEY}-${preview.identifier}`, stringifed);

    store.set(
      `${LOCAL_STORAGE_PREVIEWS_KEY}-${preview.identifier}`,
      JSON.stringify(preview)
    );
  };

  const addNonSavedInstance = useCallback(
    (
      config: IBaseInstanceConfig<BaseInstance>,
      zIdentifier: string,
      display: string,
      options?: {
        chapterSuffix?: string;
      },
      formPath?: string
    ) => {
      const found = previews.find((preview) => {
        if (preview.type !== 'nonsavedinstance') return false;
        return preview.identifier === zIdentifier;
      });

      if (found) return;

      const newPreview: Preview = {
        type: 'nonsavedinstance',
        zIdentifier: zIdentifier,
        identifier: zIdentifier,
        chapterSuffix: options?.chapterSuffix,
        display: `${config.modelName}: ${display}`,
      };
      const toStore: Stored = {
        type: 'nonsavedinstance',
        identifier: zIdentifier,
        chapterSuffix: options?.chapterSuffix,
        formPath,
      };

      storePreview(newPreview, toStore);
      setPreviews((prev) => [...prev, newPreview]);

      return zIdentifier;
    },
    [previews]
  );

  const addInstance = useCallback(
    (
      instance: BaseInstance,
      config: IBaseInstanceConfig<BaseInstance>,
      detailPath: string
    ) => {
      const found = previews.find((preview) => {
        if (preview.type !== 'instance') return false;
        return preview.instanceId === instance.id;
      });

      if (found) {
        const stored = getStored(found.identifier);
        if (stored?.type === 'instance' && stored.detailPath !== detailPath) {
          storePreview(found, {
            ...stored,
            detailPath,
          });
        }
        return found.identifier;
      }

      const newPreview: Preview = {
        type: 'instance',
        identifier: `wk_${crypto.randomUUID()}`,
        display: `${config.modelName}: ${config.getDisplayValue(instance)}`,
        instanceId: instance.id as string,
      };

      const toStore: Stored = {
        type: 'instance',
        identifier: newPreview.identifier,
        detailPath,
      };

      storePreview(newPreview, toStore);

      setPreviews((prev) => [...prev, newPreview]);

      return newPreview.identifier;
    },
    [previews]
  );

  const getStored = (identifier: string): Stored | null => {
    const stringifed = store.get(`${LOCAL_STORAGE_STORAGE_KEY}-${identifier}`);

    if (!stringifed) return null;

    try {
      const stored = JSON.parse(stringifed);

      return stored as Stored;
    } catch (err) {
      return null;
    }
  };

  const addTrackedBulk = useCallback((trackedBulk: TrackedBulk) => {
    const newPreview: Preview = {
      type: 'bulks',
      identifier: trackedBulk.id,
      display: `${trackedBulk.modelName}:${trackedBulk.displayName}`,
    };

    const toStore: Stored = {
      type: 'bulks',
      identifier: newPreview.identifier,
      //saving the same id twice (with identifier) so identifiers aren't connected to bulk window id
      bulkId: trackedBulk.id,
      modelName: trackedBulk.modelName,
      mode: trackedBulk.mode,
    };

    storePreview(newPreview, toStore);

    setPreviews((prev) => [...prev, newPreview]);
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: should only run once. if addTrackedBulk hasn't been initialzed we have bigger problems
  useEffect(() => {
    window._minimize_bulk = addTrackedBulk;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removePreview = (identifier: string) => {
    store.remove(`${LOCAL_STORAGE_PREVIEWS_KEY}-${identifier}`);
    store.remove(`${LOCAL_STORAGE_STORAGE_KEY}-${identifier}`);
    setPreviews((prev) => {
      return prev.filter((preview) => preview.identifier !== identifier);
    });
  };

  const resetPreviews = () => {
    store.each((_, key) => {
      if (
        key.includes(LOCAL_STORAGE_PREVIEWS_KEY) ||
        key.includes(LOCAL_STORAGE_STORAGE_KEY)
      ) {
        try {
          store.remove(key);
        } catch (err) {
          console.error(err);
        }
      }
    });
    setPreviews([]);
  };

  useEffect(() => {
    const initialPreviews: Preview[] = [];
    store.each((val, key) => {
      if (key.includes(LOCAL_STORAGE_PREVIEWS_KEY)) {
        try {
          const preview = JSON.parse(val);
          if (preview.type === 'nonsavedinstance') {
            store.remove(key);
            return;
          }

          initialPreviews.push(preview);
        } catch (err) {
          //TODO: maybe delete?
        }
      }
    });
    setPreviews(initialPreviews.filter((p) => p.type !== 'nonsavedinstance'));
  }, []);

  const router = useRouter();

  const openBulk = useCallback(
    async (
      modelName: ModelName,
      type: TrackedBulk['mode'],
      instances?: string[]
    ) => {
      if (type === 'download')
        throw new Error(
          'Mode: download, in un-supported by window keeper provider'
        );

      if (!(type in BULK_MODE_REQUIREMENTS))
        throw new Error(`${type} isn't a bulk mode`);

      const config = await getConfig(modelName);

      const requireInstances = BULK_MODE_REQUIREMENTS[type].instances;

      if (!requireInstances) {
        useBulkTabStore.getState().openNewBulk({
          config,
          //don't know if this is good. it will work. but looks weird.
          mode: type as 'create',
        });
        return;
      }

      if (!instances) {
        throw new Error(
          `Missing instances. Opening: ${modelName} with mode: ${type}`
        );
      }

      useBulkTabStore.getState().openNewBulk({
        config,
        mode: type,
        instances,
      });
    },
    [router]
  );

  const contextValue = useMemo(() => {
    return {
      addInstance,
      addNonSavedInstance,
      removePreview,
      getStored,
      previews,
      resetPreviews,
      openBulk,
    };
  }, [addInstance, addNonSavedInstance, openBulk, previews]);

  return (
    <WindowKeeperContext.Provider value={contextValue}>
      {children}
    </WindowKeeperContext.Provider>
  );
}

const NotVisibleInPathnames = ['/detail', '/login', '/bulk', '/contracts'];

export const WindowKeeperTabs = ({ isDetail }: { isDetail?: boolean }) => {
  const [itemWidth, setItemWidth] = useState(200);
  const [displayOnDetailPage, setDisplayOnDetailPage] = useState(false);
  const pathname = usePathname();
  const { openCreateSingleInstanceModal } = useModal();

  const isVisible = useMemo(() => {
    return !NotVisibleInPathnames.some((start) => pathname.startsWith(start));
  }, [pathname]);

  const { previews, getStored, removePreview } = useWindowKeeper();

  const calculateItemWidth = useCallback(() => {
    const totalWidth = window.innerWidth;
    if (!totalWidth) return 0;
    const itemsCount = previews.length;

    const widthPerItem = (totalWidth - (itemsCount * 8 + 16)) / itemsCount;

    if (widthPerItem > 200) {
      setItemWidth(200);
    } else {
      setItemWidth(widthPerItem);
    }
  }, [previews.length]);

  const { ref: horizontalRef } = useResizeObserver<HTMLDivElement>({
    onResize: calculateItemWidth,
  });

  const router = useRouter();

  useEffect(() => {
    if (previews.length === 0) {
      document.documentElement.style.setProperty(
        '--window-keeper-height',
        '100vh'
      );
    } else {
      document.documentElement.style.setProperty(
        '--window-keeper-height',
        'calc(100vh - 50px)'
      );
    }
  }, [previews.length]);

  const onPreviewClick = async (
    preview: Preview,
    config?: IBaseInstanceConfig<BaseInstance>
  ) => {
    const stored = getStored(preview.identifier);

    if (!stored) return;

    if (stored.type === 'nonsavedinstance') {
      if (stored.formPath) {
        store.set(CONTRACT_MODAL_SOURCE_KEY, pathname);
        router.push(stored.formPath);
      } else if (config) {
        openCreateSingleInstanceModal<any>({
          config,
          chapters:
            Chapters[
              `${config.modelName}${
                stored.chapterSuffix ? stored.chapterSuffix : ''
              }` as keyof typeof Chapters
            ],
          persistedIdentifier: stored.identifier,
          cleanInstance: () => ({}),
          title: 'Skapa',
        });
      }
      return;
    }

    if (!pathname.includes('/detail')) {
      store.set(DETAIL_MODAL_SOURCE_KEY, pathname);
    }

    if (stored.type === 'instance') {
      router.push(stored.detailPath);
    } else if (stored.type === 'bulks') {
      //open bulk tab

      //we cannot minimize/reopen a "mode: download" tab.
      if (stored.mode === 'download') return;

      const config = await getConfig(stored.modelName);

      useBulkTabStore.getState().openNewBulk({
        config,
        //doesn't matter, we're opening existing bulk
        instances: [],
        mode: stored.mode,
        winId: stored.bulkId,
      });

      removePreview(stored.identifier);
    }
  };

  useEffect(() => {
    calculateItemWidth();
  }, [previews, isVisible, calculateItemWidth]);

  if (
    (!isDetail && (!isVisible || previews.length === 0)) ||
    (isDetail && previews.length === 0)
  )
    return null;

  return (
    <>
      {isDetail && !displayOnDetailPage && (
        <div
          className='absolute inset-x-0 bottom-0 z-[999] h-[50px] bg-transparent'
          onMouseEnter={() => setDisplayOnDetailPage(true)}
        ></div>
      )}
      <div
        ref={horizontalRef}
        onMouseLeave={() => setDisplayOnDetailPage(false)}
        className={cn(
          'flex h-[50px] w-screen items-center gap-2 border-t border-border bg-muted px-4',
          {
            'absolute bottom-0  z-[1000] transition-all': isDetail,
            'translate-y-[100%]': isDetail && !displayOnDetailPage,
          }
        )}
      >
        {previews.map((preview) => {
          return (
            <Tooltip key={preview.identifier}>
              <TooltipTrigger>
                <WindowKeeperItem
                  itemWidth={itemWidth}
                  onPreviewClick={onPreviewClick}
                  preview={preview}
                  removePreview={removePreview}
                />
              </TooltipTrigger>
              <TooltipContent>{preview.display.split(':')[1]}</TooltipContent>
            </Tooltip>
          );
        })}
      </div>
    </>
  );
};
