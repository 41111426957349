import DeleteModal from '@/components/delete-modal/delete-modal';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useMutateArticleCategory } from '@/config/accounting/articles/articlecategory/mutate';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useToast } from '@/hooks/useToast';
import type { ErrorResponse } from '@/requests/types';
import {
  ClipboardDocumentIcon,
  EllipsisHorizontalIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/16/solid';
import {
  ArticleCategoryConfig,
  type ArticleCategory,
} from '@pigello/pigello-matrix';
import { useState } from 'react';
import { ArticleCategoryForm } from '../categories/category-create-form';

export function ArticleCategoryDropdown({
  articleCategory,
}: {
  articleCategory: ArticleCategory;
}) {
  const [deleteId, setDeleteId] = useState<string>();
  const [editId, setEditId] = useState<string>();
  const { addToast } = useToast();
  const { mutateAsync } = useMutateArticleCategory({});
  const copyIdToClipboard = (id: string) => {
    addToast({
      type: 'promise',
      promise: navigator.clipboard.writeText(id),
      loading: 'Kopierar id till urklipp...',
      success: () => 'Id har kopierats till urklipp',
      error: () => 'Kunde inte kopiera id till urklipp',
    });
  };
  const handleArchive = (id: string) => {
    addToast({
      type: 'promise',
      promise: mutateAsync({
        id: id,
        body: { archived: true },
      }),
      loading: 'Arkiverar artikel...',
      success: () => {
        setDeleteId(undefined);
        return 'Artikeln har arkiverats';
      },
      error: (error: ErrorResponse) => {
        const field = handleFormErrors(error);
        if (field) {
          return field;
        }
        return 'Kunde inte arkivera artikeln';
      },
    });
  };
  return (
    <>
      {deleteId && (
        <DeleteModal
          id={deleteId}
          modelName={ArticleCategoryConfig.modelName}
          setOpen={() => setDeleteId(undefined)}
          onError={(error) => {
            if (
              'archived' in error.errorData &&
              typeof error.errorData.archived === 'string'
            ) {
              addToast(
                {
                  type: 'warning',
                  title: 'Raderingen misslyckades',
                  message: error.errorData.archived,
                },
                {
                  duration: 10000,
                  action: {
                    label: 'Arkivera',
                    onClick: () => handleArchive(deleteId),
                  },
                }
              );
            }
          }}
        />
      )}
      {editId && (
        <ArticleCategoryForm
          categoryId={editId}
          closeFunction={() => {
            setEditId(undefined);
          }}
        />
      )}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant={'ghost'} size={'icon-sm'}>
            <EllipsisHorizontalIcon className='size-4' />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onSelect={() => setEditId(articleCategory?.id)}>
            <PencilIcon className='mr-2 size-4' /> Redigera
          </DropdownMenuItem>
          {articleCategory?.id && (
            <DropdownMenuItem
              onSelect={() => copyIdToClipboard(articleCategory.id)}
            >
              <ClipboardDocumentIcon className='mr-2 size-4' /> Kopiera id
            </DropdownMenuItem>
          )}
          <DropdownMenuSeparator />
          <DropdownMenuItem onSelect={() => setDeleteId(articleCategory?.id)}>
            <TrashIcon className='mr-2 size-4' /> Radera
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
