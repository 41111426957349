'use client';

import type { ColumnDef } from '@tanstack/react-table';

import { Checkbox } from '@/components/ui/checkbox';

import { Chapters } from '@/app/detail/[type]/[id]/[subpage]/chapter-data';
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { TableActions } from '@/components/data-table/table-actions';
import { TableTags } from '@/components/data-table/table-tags';
import { useModal } from '@/hooks/useModal';
import { Urls } from '@/lib/urls';
import {
  handleAnonymizationValue,
  raise,
  toReadableAddress,
} from '@/lib/utils';
import { BriefcaseIcon, UserIcon } from '@heroicons/react/24/solid';
import {
  RealEstateConfig,
  TenantConfig,
  type Tenant,
} from '@pigello/pigello-matrix';
import { DropdownList } from '../components/dropdown-list';
import { TenantBadge } from './components/tenant-badge';

export const columns: ColumnDef<Tenant>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'status',
    meta: {
      columnVisibilityName: TenantConfig.fields.status.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => <TenantBadge tenant={row.original} />,
  },
  {
    accessorKey: 'customId',
    meta: {
      columnVisibilityName: TenantConfig.fields.customId.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.customId ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'corporateName',
    enableSorting: true,
    meta: {
      columnVisibilityName: 'Typ',
    },
    header: ({ column }) => (
      <DataTableColumnHeader title='Typ' column={column} />
    ),
    cell: ({ row }) => (
      <div className='flex items-center whitespace-nowrap'>
        {row.original.corporateName ? (
          <>
            <BriefcaseIcon className='mr-1 size-4' />
            Bolag
          </>
        ) : (
          <>
            <UserIcon className='mr-1 size-4' />
            Privat{' '}
          </>
        )}
      </div>
    ),
  },
  {
    accessorKey: 'communicationName',
    enableSorting: false,
    meta: {
      columnVisibilityName: TenantConfig.fields.communicationName.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      const isCompany = !!row.original.corporateName;

      if (isCompany) {
        return (
          <div className='whitespace-nowrap'>{row.original.corporateName}</div>
        );
      }
      return (
        <div className='whitespace-nowrap'>
          {handleAnonymizationValue({
            value: row.original.communicationName,
            anonymizationName: row.original.anonymizationName,
            anonymized: row.original.anonymized,
          })}
        </div>
      );
    },
  },
  {
    accessorKey: 'orgNo',
    meta: {
      columnVisibilityName: TenantConfig.fields.orgNo.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {handleAnonymizationValue({
          value: row.original.orgNo,
          anonymized: row.original.anonymized,
        })}
      </div>
    ),
  },
  {
    accessorKey: 'ssn',
    meta: {
      columnVisibilityName: TenantConfig.fields.ssn.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {handleAnonymizationValue({
          value: row.original.ssn,
          anonymized: row.original.anonymized,
        })}
      </div>
    ),
  },
  {
    accessorKey: 'birthDate',
    meta: {
      columnVisibilityName: TenantConfig.fields.birthDate.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {handleAnonymizationValue({
          value: row.original.birthDate,
          anonymized: row.original.anonymized,
        })}
      </div>
    ),
  },
  {
    accessorKey: 'fmi',
    meta: {
      columnVisibilityName: TenantConfig.fields.fmi.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {handleAnonymizationValue({
          value: row.original.fmi,
          anonymized: row.original.anonymized,
        })}
      </div>
    ),
  },
  {
    accessorKey: 'email',
    meta: {
      columnVisibilityName: TenantConfig.fields.email.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {handleAnonymizationValue({
          value: row.original.email,
          anonymized: row.original.anonymized,
        }) ?? '-'}
      </div>
    ),
  },
  {
    accessorKey: 'phoneNumber',
    meta: {
      columnVisibilityName: TenantConfig.fields.phoneNumber.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {handleAnonymizationValue({
          value: row.original.phoneNumber,
          anonymized: row.original.anonymized,
        }) ?? '-'}
      </div>
    ),
  },
  {
    accessorKey: 'username',
    enableSorting: false,
    meta: {
      columnVisibilityName: TenantConfig.fields.username.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {handleAnonymizationValue({
          value: row.original.username,
          anonymized: row.original.anonymized,
        }) ?? '-'}
      </div>
    ),
  },
  {
    accessorKey: 'realestates',
    enableSorting: false,
    meta: {
      columnVisibilityName: TenantConfig.fields.realestates.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        ids={row.original.realestates?.map((re) => re.id)}
        initialData={row.original.realestates[0]}
        detailModalLinkUrl={(id) => Urls.detail.realestate.overview(id)}
        config={RealEstateConfig}
        fields={['name']}
      />
    ),
  },
  {
    accessorKey: 'currentRegisteredAddress',
    meta: {
      columnVisibilityName:
        TenantConfig.fields.currentRegisteredAddress.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return (
        <div className='whitespace-nowrap'>
          {toReadableAddress(row.original.currentRegisteredAddress)}
        </div>
      );
    },
  },
  {
    accessorKey: 'invoiceAddress',
    meta: {
      columnVisibilityName: TenantConfig.fields.invoiceAddress.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return (
        <div className='whitespace-nowrap'>
          {toReadableAddress(row.original.invoiceAddress)}
        </div>
      );
    },
  },
  {
    accessorKey: 'futureAddress',
    meta: {
      columnVisibilityName: TenantConfig.fields.futureAddress.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return (
        <div className='whitespace-nowrap'>
          {toReadableAddress(row.original.futureAddress)}
        </div>
      );
    },
  },
  {
    accessorKey: 'previousAddress',
    meta: {
      columnVisibilityName: TenantConfig.fields.previousAddress.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return (
        <div className='whitespace-nowrap'>
          {toReadableAddress(row.original.previousAddress)}
        </div>
      );
    },
  },
  {
    accessorKey: 'tags',
    meta: {
      columnVisibilityName: 'Taggar',
    },
    header: 'Taggar',
    cell: ({ row }) => (
      <span className='flex gap-2'>
        <TableTags
          tags={row.original.tags}
          modelName={TenantConfig.modelName}
        />
      </span>
    ),
  },
  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => <TenantTableActions tenant={row.original} />,
  },
];

function TenantTableActions({ tenant }: { tenant: Tenant }) {
  const { openUpdateSingleInstanceModal } = useModal();
  return (
    <TableActions
      instance={tenant}
      config={TenantConfig}
      overrideEdit={() => {
        openUpdateSingleInstanceModal({
          title: `Redigera ${tenant.communicationName}`,
          config: TenantConfig,
          id: tenant.id ?? raise('No id provided in actions'),
          chapters: tenant.isCompany
            ? Chapters['tenant_company']
            : Chapters['tenant_private'],
        });
      }}
      title={tenant.communicationName}
    />
  );
}
