import { cn } from '@/lib/utils';
import { XMarkIcon } from '@heroicons/react/16/solid';
import type { Dispatch, SetStateAction } from 'react';
import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../ui/alert-dialog';
import { useOnCloseModal } from './hooks/use-on-click';
import { useOnEscape } from './hooks/use-on-escape';

type CommonProps = {
  body?: React.ReactNode;
  closeButton?: boolean;
  isCloseOnEscape?: boolean;
  externalModalHandling?: boolean;
  contentClassName?: string;
  headerClassName?: string;
};

type ConditionalProps =
  | {
      description?: never;
      title?: never;
      actions?: never;
    }
  | {
      description?: React.ReactNode;
      title?: string;
      actions?: React.ReactNode;
    };

export type ModalProps = CommonProps & ConditionalProps;

const Modal: React.FC<
  ModalProps & {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    onClose: () => void;
  }
> = ({
  description,
  body,
  actions,
  isOpen,
  title,
  isCloseOnEscape = true,
  onClose,
  setIsOpen,
  externalModalHandling,
  closeButton = false,
  contentClassName,
  headerClassName,
}) => {
  const closeModalRef = useRef<HTMLDivElement>(null);
  useOnEscape({ isActive: isCloseOnEscape, isOpen, callback: onClose });
  useOnCloseModal({ isOpen, isCloseOnEscape, closeModalRef, onClose });

  if (externalModalHandling && body) {
    return <>{body}</>;
  }

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogContent className={contentClassName}>
        <AlertDialogHeader className={headerClassName}>
          {title && (
            <AlertDialogTitle
              className={cn(closeButton && 'flex items-center justify-between')}
            >
              {title}
              {closeButton && (
                <AlertDialogCancel>
                  <XMarkIcon className='size-4' />
                </AlertDialogCancel>
              )}
            </AlertDialogTitle>
          )}
          {description && (
            <AlertDialogDescription>{description}</AlertDialogDescription>
          )}
        </AlertDialogHeader>
        {body && <div className='h-auto'>{body}</div>}
        {actions && <AlertDialogFooter>{actions}</AlertDialogFooter>}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default Modal;
