'use client';

import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { TableActions } from '@/components/data-table/table-actions';
import { TableTags } from '@/components/data-table/table-tags';
import { Checkbox } from '@/components/ui/checkbox';
import { StatusBadge } from '@/components/ui/statusBadge';
import { toReadableDate, toReadableDateTime } from '@/lib/dates';
import { Urls } from '@/lib/urls';
import { toWeekInterval } from '@/lib/utils';
import type { OtherContract } from '@pigello/pigello-matrix';
import {
  ApartmentContractConfig,
  IndustrialPremisesContractConfig,
  OtherContractConfig,
  OutdoorSectionContractConfig,
  ParkingSpotContractConfig,
  SubTenantConfig,
  TenantConfig,
} from '@pigello/pigello-matrix';
import type { ColumnDef } from '@tanstack/react-table';
import { DropdownList } from '../../components/dropdown-list';

export const columns: ColumnDef<OtherContract>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'status',
    meta: {
      columnVisibilityName: OtherContractConfig.fields.status.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return (
        <StatusBadge type='contract' status={row.original.status as number} />
      );
    },
  },

  {
    accessorKey: 'customId',
    meta: {
      columnVisibilityName: OtherContractConfig.fields.customId.verboseName,

      isStaticFilter: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.customId ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'idNumber',
    meta: {
      columnVisibilityName: OtherContractConfig.fields.idNumber.verboseName,

      isStaticFilter: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.idNumber}</div>
    ),
  },
  {
    accessorKey: 'mainContract',
    enableSorting: false,
    meta: {
      columnVisibilityName: 'Huvudavtal',
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      if (row.original.mainApartmentContract) {
        return (
          <DropdownList
            ids={[row.original.mainApartmentContract?.id]}
            initialData={row.original.mainApartmentContract}
            detailModalLinkUrl={(id) =>
              Urls.detail.contract.overview(
                ApartmentContractConfig.modelName,
                id
              )
            }
            config={ApartmentContractConfig}
            fields={['idNumber']}
          />
        );
      }
      if (row.original.mainOutdoorsectionContract) {
        return (
          <DropdownList
            ids={[row.original.mainOutdoorsectionContract?.id]}
            initialData={row.original.mainOutdoorsectionContract}
            detailModalLinkUrl={(id) =>
              Urls.detail.contract.overview(
                OutdoorSectionContractConfig.modelName,
                id
              )
            }
            config={OutdoorSectionContractConfig}
            fields={['idNumber']}
          />
        );
      }
      if (row.original.mainIndustrialPremisesContract) {
        return (
          <DropdownList
            ids={[row.original.mainIndustrialPremisesContract?.id]}
            initialData={row.original.mainIndustrialPremisesContract}
            detailModalLinkUrl={(id) =>
              Urls.detail.contract.overview(
                IndustrialPremisesContractConfig.modelName,
                id
              )
            }
            config={IndustrialPremisesContractConfig}
            fields={['idNumber']}
          />
        );
      }
      return (
        <DropdownList
          ids={[row.original.mainParkingspotContract?.id]}
          initialData={row.original.mainParkingspotContract}
          detailModalLinkUrl={(id) =>
            Urls.detail.contract.overview(
              ParkingSpotContractConfig.modelName,
              id
            )
          }
          config={ParkingSpotContractConfig}
          fields={['idNumber']}
        />
      );
    },
  },
  {
    accessorKey: 'counterpart',
    enableSorting: false,
    meta: {
      columnVisibilityName: 'Motpart',
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      if (row.original.subTenantCounterpart) {
        return (
          <DropdownList
            ids={[row.original.subTenantCounterpart?.id]}
            initialData={row.original.subTenantCounterpart}
            detailModalLinkUrl={(id) => Urls.detail.tenant.overview(id)}
            config={SubTenantConfig}
            fields={['communicationName']}
          />
        );
      }
      return (
        <DropdownList
          ids={[row.original.tenantCounterpart?.id]}
          initialData={row.original.tenantCounterpart}
          detailModalLinkUrl={(id) => Urls.detail.tenant.overview(id)}
          config={TenantConfig}
          fields={['communicationName']}
        />
      );
    },
  },
  {
    accessorKey: 'startDate',
    meta: {
      columnVisibilityName:
        OutdoorSectionContractConfig.fields.startDate.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      if (!row.original.startDate) return <span>-</span>;
      return (
        <span className='whitespace-nowrap'>
          {toReadableDate(row.original.startDate)}
        </span>
      );
    },
  },
  {
    accessorKey: 'initialEndDate',
    meta: {
      columnVisibilityName:
        OutdoorSectionContractConfig.fields.initialEndDate.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='whitespace-nowrap'>
        {toReadableDate(row.original.initialEndDate)}
      </span>
    ),
  },
  {
    accessorKey: 'cancellationTime',
    meta: {
      columnVisibilityName:
        OutdoorSectionContractConfig.fields.cancellationTime.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      if (!row.original.cancellationTime) return <span>-</span>;
      return (
        <span className='whitespace-nowrap'>
          {toReadableDateTime(row.original.cancellationTime)}
        </span>
      );
    },
  },
  {
    accessorKey: 'lifetimeEndDate',
    meta: {
      columnVisibilityName:
        OutdoorSectionContractConfig.fields.lifetimeEndDate.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      if (!row.original.lifetimeEndDate) return <span>-</span>;
      return (
        <span className='whitespace-nowrap'>
          {toReadableDate(row.original.lifetimeEndDate)}
        </span>
      );
    },
  },
  {
    accessorKey: 'signedTime',
    meta: {
      columnVisibilityName:
        OutdoorSectionContractConfig.fields.signedTime.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      if (!row.original.signedTime) return <span>-</span>;
      return (
        <span className='whitespace-nowrap'>
          {toReadableDateTime(row.original.signedTime)}
        </span>
      );
    },
  },
  {
    accessorKey: 'cancellationSignedTime',
    meta: {
      columnVisibilityName:
        OutdoorSectionContractConfig.fields.cancellationSignedTime.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      if (!row.original.cancellationSignedTime) return <span>-</span>;
      return (
        <span className='whitespace-nowrap'>
          {toReadableDateTime(row.original.cancellationSignedTime)}
        </span>
      );
    },
  },
  {
    accessorKey: 'counterpartNotificationIntervalWeeks',
    meta: {
      columnVisibilityName:
        OutdoorSectionContractConfig.fields.counterpartNotificationIntervalWeeks
          .verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span>
        {toWeekInterval(row.original.counterpartNotificationIntervalWeeks)}
      </span>
    ),
  },
  {
    accessorKey: 'ownerNotificationIntervalWeeks',
    meta: {
      columnVisibilityName:
        OutdoorSectionContractConfig.fields.ownerNotificationIntervalWeeks
          .verboseName,
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={
          OutdoorSectionContractConfig.fields.ownerNotificationIntervalWeeks
            .verboseName
        }
      />
    ),
    cell: ({ row }) => (
      <span>{toWeekInterval(row.original.ownerNotificationIntervalWeeks)}</span>
    ),
  },
  {
    accessorKey: 'renewedTo',
    meta: {
      columnVisibilityName:
        OutdoorSectionContractConfig.fields.renewedTo.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => <span>{toReadableDate(row.original.renewedTo)}</span>,
  },
  {
    accessorKey: 'renewIntervalWeeks',
    meta: {
      columnVisibilityName:
        OutdoorSectionContractConfig.fields.renewIntervalWeeks.verboseName,
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={
          OutdoorSectionContractConfig.fields.renewIntervalWeeks.verboseName
        }
      />
    ),
    cell: ({ row }) => (
      <span>{toWeekInterval(row.original.renewIntervalWeeks)}</span>
    ),
  },
  {
    accessorKey: 'renewIndefinetely',
    meta: {
      columnVisibilityName:
        OutdoorSectionContractConfig.fields.renewIndefinetely.verboseName,
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={
          OutdoorSectionContractConfig.fields.renewIndefinetely.verboseName
        }
      />
    ),
    cell: ({ row }) => (
      <span>{row.original.renewIndefinetely ? 'Ja' : 'Nej'}</span>
    ),
  },
  {
    accessorKey: 'counterpartWarningIntervalWeeks',
    meta: {
      columnVisibilityName:
        OutdoorSectionContractConfig.fields.counterpartWarningIntervalWeeks
          .verboseName,
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={
          OutdoorSectionContractConfig.fields.counterpartWarningIntervalWeeks
            .verboseName
        }
      />
    ),
    cell: ({ row }) => (
      <span>
        {toWeekInterval(row.original.counterpartWarningIntervalWeeks)}
      </span>
    ),
  },
  {
    accessorKey: 'ownerWarningIntervalWeeks',
    meta: {
      columnVisibilityName:
        OutdoorSectionContractConfig.fields.ownerWarningIntervalWeeks
          .verboseName,
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={
          OutdoorSectionContractConfig.fields.ownerWarningIntervalWeeks
            .verboseName
        }
      />
    ),
    cell: ({ row }) => (
      <span>{toWeekInterval(row.original.ownerWarningIntervalWeeks)}</span>
    ),
  },

  {
    accessorKey: 'counterpartWarningDate',
    meta: {
      columnVisibilityName:
        OutdoorSectionContractConfig.fields.counterpartWarningDate.verboseName,
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={
          OutdoorSectionContractConfig.fields.counterpartWarningDate.verboseName
        }
      />
    ),
    cell: ({ row }) => (
      <span>{toReadableDate(row.original.counterpartWarningDate)}</span>
    ),
  },
  {
    accessorKey: 'ownerWarningDate',
    meta: {
      columnVisibilityName:
        OutdoorSectionContractConfig.fields.ownerWarningDate.verboseName,
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={OutdoorSectionContractConfig.fields.ownerWarningDate.verboseName}
      />
    ),
    cell: ({ row }) => (
      <span>{toReadableDate(row.original.ownerWarningDate)}</span>
    ),
  },
  {
    accessorKey: 'tags',
    enableSorting: false,
    meta: {
      columnVisibilityName:
        OutdoorSectionContractConfig.fields.tags.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='flex gap-2'>
        <TableTags
          tags={row.original.tags}
          modelName={OutdoorSectionContractConfig.modelName}
        />
      </span>
    ),
  },
  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <TableActions
        instance={row.original}
        config={OtherContractConfig}
        title={row.original.idNumber}
      />
    ),
  },
];
