import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';
import type { FieldType } from '@pigello/pigello-matrix';

type Props = {
  horizontal?: boolean;
  className?: string;
};

function TextLoader({ horizontal, className }: Props) {
  if (horizontal) {
    return (
      <div
        className={cn('my-0.5 flex items-center justify-between', className)}
      >
        <Skeleton className='h-4 w-40' />
        <Skeleton className='h-8 w-1/2' />
      </div>
    );
  }
  return (
    <div className={cn('my-0.5 flex flex-col gap-2', className)}>
      <Skeleton className='h-4 w-40' />
      <Skeleton className='h-8 w-full' />
    </div>
  );
}

function BooleanLoader({ horizontal, className }: Props) {
  if (horizontal) {
    return (
      <div className={cn('flex items-center justify-between', className)}>
        <Skeleton className='h-4 w-40' />
        <Skeleton className='h-6 w-12 rounded-full' />
      </div>
    );
  }
  return (
    <div className={cn('flex flex-col gap-2', className)}>
      <Skeleton className='h-4 w-40' />
      <Skeleton className='h-6 w-12 rounded-full' />
    </div>
  );
}

export function DynamicFieldLoader({
  type,
  horizontal,
  className,
}: {
  type: FieldType;
  horizontal?: boolean;
  className?: string;
}) {
  switch (type) {
    case 'text':
      return <TextLoader {...{ className, horizontal }} />;
    case 'uniquetext':
      return <TextLoader {...{ className, horizontal }} />;
    case 'choice':
      return <TextLoader {...{ className, horizontal }} />;
    case 'number':
      return <TextLoader {...{ className, horizontal }} />;
    case 'relation':
      return <TextLoader {...{ className, horizontal }} />;
    case 'contentType':
      return <TextLoader {...{ className, horizontal }} />;
    case 'array':
      return <TextLoader {...{ className, horizontal }} />;
    case 'manyrelation':
      return <TextLoader {...{ className, horizontal }} />;
    case 'boolean':
      return <BooleanLoader {...{ className, horizontal }} />;
  }
  return <>Oskänd typ: {type}</>;
}
