import { DynamicField } from '@/components/form/dynamic-field';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { useCreateFile } from '@/config/documents/filehandling/file/create';
import { useForm } from '@/hooks/useForm';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useToast } from '@/hooks/useToast';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import type { ErrorResponse } from '@/requests/types';
import { FileConfig, getCleanFile } from '@pigello/pigello-matrix';

export type CreateFileModalResponse = Promise<boolean>;

export type CreateFileModalContentData = {
  title?: string;
};

interface Props extends GetModalContentProps {
  closeModal: (response: Awaited<CreateFileModalResponse>) => void;
  contentData: CreateFileModalContentData;
}

function CreateFileModal({ closeModal }: GetModalContentProps) {
  const form = useForm({
    config: FileConfig,
    defaultValues: getCleanFile(),
  });
  const { mutateAsync } = useCreateFile({});
  const { addToast } = useToast();
  const onSubmit = () => {
    const data = form.getDirtyData();

    addToast({
      type: 'promise',
      promise: mutateAsync({ body: data }),
      loading: 'Laddar upp fil...',
      success: () => {
        closeModal(true);
        form.reset();
        return 'Filen har laddats upp';
      },
      error: (error: ErrorResponse) => {
        const field = handleFormErrors(error, form);
        if (field) {
          return field;
        }
        return 'Något gick fel';
      },
    });
  };
  return (
    <Form {...form}>
      <form
        className='grid gap-4'
        onSubmit={(e) => {
          form.clearErrors();
          form.handleSubmit(onSubmit)(e);
        }}
      >
        <DynamicField formField={form.fields.content} />
        <DynamicField formField={form.fields.originalName} />
        <DynamicField formField={form.fields.folder} />
        <div className='flex justify-end'>
          <Button type='submit'>Spara</Button>
        </div>
      </form>
    </Form>
  );
}

export const getCreateFileModalContent = (props: Props): ModalContent => {
  return {
    title: 'Skapa mapp',
    description: 'Välj ett namn för din nya mapp',
    body: <CreateFileModal {...props} />,
    closeButton: true,
  };
};
