 import type { InvoiceRecipient } from '@pigello/pigello-matrix';

import { InvoiceRecipientConfig } from "@pigello/pigello-matrix"



import { fetchGenericInstance, fetchGenericList } from '@/requests/fetching';
import type { getGenericInstanceProps, getGenericListProps } from '@/requests/types';

export const getInvoiceRecipient = async (
  options: getGenericInstanceProps<InvoiceRecipient>
) => {
  return fetchGenericInstance<InvoiceRecipient>({
    ...options,
    config: InvoiceRecipientConfig,
  });
};

export const getInvoiceRecipientList = async (
  options: getGenericListProps<InvoiceRecipient>
) => {
  return fetchGenericList<InvoiceRecipient>({
    ...options,
    config: InvoiceRecipientConfig,
  });
};
