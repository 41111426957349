 import type { Article } from '@pigello/pigello-matrix';

import { ArticleConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, mutateGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { mutateGenericInstance } from '@/requests/mutating';

export const mutateArticle = async (
  options: mutateGenericInstanceProps<Article>
) => {
  return mutateGenericInstance<Article>({
    ...options,
    config: ArticleConfig,
  });
};

export const useMutateArticle = (
  options: UseMutationOptions<Article, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useMutateGenericInstance<Article>(mutateArticle, options, 'article');