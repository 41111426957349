'use client';

import { columns } from '@/app/dashboard/invoicing/invoices/columns';
import { InvoiceStatus } from '@/app/dashboard/invoicing/invoices/components/invoice-status';
import invoiceFilters from '@/app/dashboard/invoicing/invoices/filters';
import { toReadableDateTime } from '@/lib/dates';
import { cn, toMoneyString } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { InvoiceConfig, type Invoice } from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './relation-item-map';

export const InvoiceItem: RelationFieldProps<Invoice> = {
  filters: invoiceFilters,
  columns: columns,
  nested: getRelationFieldNames(InvoiceConfig),
  renderItem: (item, isRelationField) => ({
    title: `${InvoiceConfig.verboseName} nr. ${item.invoiceNumber}`,
    badge: <InvoiceStatus invoice={item} />,
    descriptionItems: (
      <div className='grid w-full'>
        <div
          className={cn(
            'flex w-full items-center',
            isRelationField && 'justify-between'
          )}
        >
          <div className='flex w-full items-center font-medium'>
            <span>
              {item.attestedDate
                ? `Attesterad ${item.attestedDate}`
                : 'Ej attesterad'}
            </span>
            {!isRelationField && <Dot />}
            Skapad {toReadableDateTime(item.createdAt)}
          </div>
        </div>
        <div
          className={cn(
            'flex w-full items-center',
            isRelationField && 'justify-between'
          )}
        >
          <span> Fakturadatum {item.invoiceDate}</span>
          {!isRelationField && <Dot />}
          <span> Förfallodatum {item.dueDate}</span>
        </div>
        <div
          className={cn(
            'flex w-full items-center',
            isRelationField && 'justify-between'
          )}
        >
          <span>
            Originalbelopp{' '}
            {toMoneyString(item.calculatedRowData?.rows_sum_net ?? 0)}
          </span>
        </div>
      </div>
    ),
  }),
};
