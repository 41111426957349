import { Button } from '@/components/ui/button';
import { useMutateTenant } from '@/config/accounts/users/customer/tenant/mutate';
import { useToast } from '@/hooks/useToast';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import type { ErrorResponse } from '@/requests/types';
import { XMarkIcon } from '@heroicons/react/16/solid';

export type DeanonymizeModalContentData = {
  id: string;
  anonymizedName?: string;
};

interface Props extends GetModalContentProps {
  closeModal: (response: Awaited<Promise<boolean>>) => void;
  contentData: DeanonymizeModalContentData;
}

function DeanonymizeTenantContent({ closeModal, contentData }: Props) {
  const { addToast } = useToast();
  const { mutate } = useMutateTenant({
    invalidateQueries: ['eventlog'],
    onSuccess: () => {
      addToast({
        title: 'Hyresgäst avanonymiserad',
        type: 'success',
      });
      closeModal(true);
    },
    onError: (error: ErrorResponse) => {
      addToast({
        title: 'Något gick fel',
        message: `Error: ${error}`,
        type: 'error',
      });
    },
  });
  return (
    <>
      <Button
        className='absolute right-4 top-4'
        variant={'outline'}
        size={'icon'}
        onClick={() => closeModal(false)}
      >
        <XMarkIcon className='size-4' />
      </Button>
      <div className='flex justify-end'>
        <Button
          onClick={() =>
            mutate({
              id: contentData.id,
              body: {
                anonymized: false,
              },
            })
          }
        >
          Bekräfta
        </Button>
      </div>
    </>
  );
}

export const getDeanonymizeModalContent = ({
  closeModal,
  contentData,
}: Props): ModalContent => {
  return {
    title: 'Bekräfta avanonymisering',
    description: `Bekräfta att du vill avanonymisera ${contentData.anonymizedName || 'hyresgästen'}.`,
    body: (
      <DeanonymizeTenantContent
        closeModal={closeModal}
        contentData={contentData}
      />
    ),
  };
};
