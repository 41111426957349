'use client';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useGetInstance } from '@/requests/hooks';
import type {
  ApartmentContract,
  IndustrialPremisesContract,
  OutdoorSectionContract,
  ParkingSpotContract,
} from '@pigello/pigello-matrix';
import {
  CoInvoiceNotationConfig,
  type ModelName,
} from '@pigello/pigello-matrix';
import { CardSection } from '../../components/cardSection';
import type { ICoinvoiceResult } from './types';

const readableContractType: Partial<Record<ModelName, string>> = {
  apartmentcontract: 'Lägenhetsavtal',
  industrialpremisescontract: 'Lokalavtal',
  outdoorsectioncontract: 'Utomhussektionsavtal',
  parkingspotcontract: 'Parkeringsavtal',
};

export default function PreviewCoInvoice({
  coInvoice,
}: {
  coInvoice: ICoinvoiceResult;
}) {
  const modelName = coInvoice.originates_from_content_type?.id?.split(
    '.'
  )[1] as ModelName;

  const { data: instance } = useGetInstance<
    | ApartmentContract
    | IndustrialPremisesContract
    | OutdoorSectionContract
    | ParkingSpotContract
  >({
    modelName,
    id: coInvoice.originates_from_object_id,
    enabled: !!modelName,
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <div className='text-sm'>Samaviserat avtal</div>
          <div>
            {readableContractType[modelName]} {instance?.idNumber}
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <CardSection
          items={[
            {
              title: 'Ihopslagning lyckades',
              content: (
                <Badge variant={coInvoice.failed ? 'destructive' : 'green'}>
                  {coInvoice.failed ? 'Nej' : 'Ja'}
                </Badge>
              ),
            },
            {
              title: 'Typ',
              content:
                CoInvoiceNotationConfig.fields.kind.choices?.find(
                  (c) => c.v == coInvoice.kind
                )?.d ?? 'Okänd',
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}
