import { Button } from '@/components/ui/button';
import { DialogFooter } from '@/components/ui/detail-dialog';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { raise } from '@/lib/utils';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { useGetTotalInvoice } from '../hooks/use-get-invoice';
import { useForm } from '@/hooks/useForm';
import {
  DebtInvoiceConfig,
  getCleanDebtInvoice,
  type ChoiceNonNullFieldValue,
} from '@pigello/pigello-matrix';
import { Fieldset } from '@/components/ui/fieldset';
import { Legend } from '@/components/ui/legend';
import { DynamicField } from '@/components/form/dynamic-field';
import { Form } from '@/components/ui/form';
import { useCreateDebtInvoice } from '@/config/accounting/debt/debtinvoice/create';
import { toast } from 'sonner';
import { handleFormErrors } from '@/hooks/useForm/utils';
import OverlayLoader from '@/components/ui/overlay-loader';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useState } from 'react';

export type SendDebtInvoiceContentData = {
  invoiceId: string | undefined;
  kind: ChoiceNonNullFieldValue<number>;
};

interface Props extends GetModalContentProps {
  closeModal: () => void;
  contentData: SendDebtInvoiceContentData;
}

function SendDebtInvoice({ closeModal, contentData }: Props) {
  const { invoiceId, kind } = contentData;
  const [toggleValue, setToggleValue] = useState(kind?.toString());
  const isReminder = toggleValue === '0';
  const { invoice, isLoading } = useGetTotalInvoice({
    id: invoiceId ?? raise('No invoice id'),
  });
  const form = useForm({
    config: DebtInvoiceConfig,
    defaultValues: getCleanDebtInvoice(),
    isUpdate: false,
    disabled: isLoading,
    values: {
      debtDate: invoice?.dueDate,
    },
    orderedFields: [
      {
        key: 'general',
        fields: isReminder
          ? ['fee', 'dueDate']
          : [
              'interestRate',
              'debtDate',
              'fee',
              'dueDate',
              'debtStage',
              'evictionOnBailiff',
              'informSocialCommitteeOnBailiff',
            ],
      },
    ],
  });

  const { mutateAsync, isPending } = useCreateDebtInvoice({});

  const handleSubmit = () => {
    if (!invoiceId) {
      return;
    }
    const values = form.getValues();

    toast.promise(
      mutateAsync({
        body: {
          ...values,
          interestRateStartDate: values.debtDate,
          kind: Number(toggleValue ?? 0),
          invoice: {
            id: invoiceId,
          },
        },
      }),
      {
        loading: 'Sparar...',
        success: () => {
          form.reset();
          closeModal();
          return isReminder ? 'Påminnelse skickad' : 'Krav skickat';
        },
        error: (err) => {
          const field = handleFormErrors(err, form);
          return `Utskick misslyckades: ${field}`;
        },
      }
    );
  };

  return (
    <Dialog open onOpenChange={closeModal}>
      <DialogContent className='max-w-xl p-0 sm:min-w-[576px]'>
        {(isPending || isLoading) && <OverlayLoader />}
        <Form {...form}>
          <form
            onSubmit={(e) => {
              form.clearErrors();
              form.handleSubmit(handleSubmit)(e);
            }}
          >
            <DialogHeader className='flex w-full flex-row items-center justify-between border-b px-4 py-2'>
              <DialogTitle>
                Skicka {isReminder ? 'påminnelse' : 'krav'}
              </DialogTitle>
              <Button variant='outline' size={'icon-sm'} onClick={closeModal}>
                <XMarkIcon className='size-4' />
              </Button>
            </DialogHeader>
            <div className='min-h-[443px] overflow-y-auto p-4'>
              <Tabs
                value={toggleValue}
                onValueChange={(value: string) => {
                  form.reset();
                  setToggleValue(value);
                }}
                className='mb-4'
              >
                <TabsList>
                  <TabsTrigger value='0'>Påminnelse</TabsTrigger>
                  <TabsTrigger value='1'>Krav</TabsTrigger>
                </TabsList>
              </Tabs>
              <Fieldset>
                <Legend>Inställningar</Legend>
                <div className='grid grid-cols-2 gap-4'>
                  {form.orderedFields.general.map((field) => {
                    if (field.name === 'dueDate') {
                      return (
                        <DynamicField
                          key={field.name}
                          formField={{
                            ...field,
                            label: `Förfallodatum ${isReminder ? 'påminnelse' : 'krav'}`,
                          }}
                        />
                      );
                    }
                    if (
                      field.name === 'evictionOnBailiff' ||
                      field.name === 'informSocialCommitteeOnBailiff'
                    ) {
                      return (
                        <DynamicField
                          key={field.name}
                          formField={field}
                          className='col-span-2 whitespace-nowrap'
                          horizontal
                        />
                      );
                    }
                    return <DynamicField key={field.name} formField={field} />;
                  })}
                </div>
              </Fieldset>
            </div>
            <DialogFooter className='border-t px-4 py-2'>
              <Button type='submit'>Skicka</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export const getSendDebtInvoiceContent = (props: Props): ModalContent => {
  const isReminder = props.contentData.kind === 0;

  return {
    title: isReminder ? 'Skicka påminnelse' : 'Skicka krav',
    body: <SendDebtInvoice {...props} />,
    isCloseOnEscape: true,
    closeButton: true,
    externalModalHandling: true,
  };
};
