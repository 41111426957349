import { patch } from '@/requests/api/patch';
import { getCookies } from '@/requests/cookies';
import { toExternalFieldNames } from '@/requests/instanceMapper';
import type { ErrorResponse } from '@/requests/types';
import type {
  BaseInstance,
  IBaseInstanceConfig,
} from '@pigello/pigello-matrix';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { handleFormErrors } from '../useForm/utils';
import { useToast } from '../useToast';
import { deleteBaseFields } from './utils';

export function useArchiveAndReplace<
  Instance extends BaseInstance = BaseInstance,
>({
  config,
  instance,
  formValues,
  closeModal,
  onSuccessfulArchive,
}: {
  config: IBaseInstanceConfig<Instance>;
  instance?: Instance;
  formValues?: Partial<BaseInstance> | null;
  closeModal?: VoidFunction;
  onSuccessfulArchive?: (data: Instance) => void;
}) {
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  return useMutation<unknown, ErrorResponse>({
    mutationFn: async () => {
      if (!config || !instance) return;
      const { cookies } = await getCookies();
      const bodyToSend = await toExternalFieldNames(
        config,
        {
          ...instance,
          ...formValues,
        },
        true
      );
      const cleanedInstance = deleteBaseFields<Instance>(bodyToSend);
      const cleanedInstanceTobeArchived = deleteBaseFields(formValues);
      const replaceResponse = await patch({
        url: `archiving/replace/${config?.contentType}/${instance?.id}/`,
        body: {
          ...cleanedInstanceTobeArchived,
          ...cleanedInstance,
          ...{
            organization: {
              id: cookies?.organization_id,
            },
          },
        },
      });

      return replaceResponse;
    },
    onError: (err, variables) => {
      const field = handleFormErrors(err);
      addToast({
        title: 'Error',
        message: `Error: ${field}`,
        type: 'error',
      });

      return variables;
    },
    onSuccess: (data) => {
      config.replacableRelationsOnArchive?.forEach((relation) => {
        queryClient.invalidateQueries({
          queryKey: [relation, { modelName: relation, id: instance?.id }],
        });
        queryClient.invalidateQueries({
          queryKey: [
            relation,
            'list',
            {
              modelName: relation,
            },
          ],
        });
      });
      queryClient.invalidateQueries({
        queryKey: [
          config?.modelName,
          { modelName: config?.modelName, id: instance?.id },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          config?.modelName,
          'list',
          {
            modelName: config?.modelName,
          },
        ],
      });
      addToast({
        title: 'Objektet har arkiverats',
        type: 'info',
      });
      onSuccessfulArchive?.(data as Instance);
      closeModal?.();
    },
  });
}
