'use client';

import { useMutateApartmentContract } from '@/config/contracts/rentablecontracts/apartmentcontract/mutate';
import { useConfig } from '@/hooks/useConfig';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useModal } from '@/hooks/useModal';
import { useToast } from '@/hooks/useToast';
import { Urls } from '@/lib/urls';
import { useMutateGenericInstance } from '@/requests/hooks';
import { mutateGenericInstance } from '@/requests/mutating';
import type {
  ApartmentContract,
  BaseSingleSpaceRentableContract,
  BlockContract,
  IBaseInstanceConfig,
  IndustrialPremisesContract,
  ModelName,
  OutdoorSectionContract,
  ParkingSpotContract,
} from '@pigello/pigello-matrix';
import { DateTime } from 'luxon';

const getContractLink = (modelName: ModelName, id: string) => {
  switch (modelName) {
    case 'apartment':
      return Urls.contracts.create.settings(id, modelName);
    case 'industrialpremises':
      return Urls.contracts.create.settings(id, modelName);
    case 'outdoorsection':
      return Urls.contracts.create.settings(id, modelName);
    case 'parkingspot':
      return Urls.contracts.create.settings(id, modelName);
    default:
      return false;
  }
};

const activeStatuses = [0, 1, 2, 7, 8, 9];

const contractModelNames: ModelName[] = [
  'apartmentcontract',
  'industrialpremisescontract',
  'outdoorsectioncontract',
  'parkingspotcontract',
  'othercontract',
  'blockcontract',
];

export default function useDetailModalContractHelpers<
  Instance extends BaseSingleSpaceRentableContract,
>(modelName: ModelName, id: string, instance?: Instance | undefined) {
  const { addToast } = useToast();
  const { openCancelContractModal } = useModal();
  const createContractLink = getContractLink(modelName, id);

  const { config } = useConfig(modelName) as unknown as {
    loading: boolean;
    config: IBaseInstanceConfig<
      | ApartmentContract
      | IndustrialPremisesContract
      | OutdoorSectionContract
      | ParkingSpotContract
      | BlockContract
    >;
  };

  const isContract = contractModelNames.includes(modelName);

  const isDraft = instance?.isDraft;
  const isSigned = !!instance?.signedTime;
  const isCancelled = !!instance?.cancellationTime;

  const mainContract = (
    !instance
      ? false
      : 'mainApartmentContract' in instance && instance.mainApartmentContract
        ? instance.mainApartmentContract
        : 'mainIndustrialPremisesContract' in instance &&
          instance.mainIndustrialPremisesContract
          ? instance.mainIndustrialPremisesContract
          : 'mainOutdoorsectionContract' in instance &&
            instance.mainOutdoorsectionContract
            ? instance.mainOutdoorsectionContract
            : 'mainParkingspotContract' in instance &&
              instance.mainParkingspotContract
              ? instance.mainParkingspotContract
              : false
  ) as Instance | false;

  const startDateTime = instance?.startDate
    ? DateTime.fromFormat(instance?.startDate, 'yyyy-MM-dd')
    : undefined;
  const latestSignDate = startDateTime
    ? startDateTime > DateTime.now()
      ? DateTime.now()
      : startDateTime
    : undefined;

  const isCancelSigned = !!instance?.cancellationSignedTime;
  useMutateApartmentContract;
  const canCancel =
    instance?.status != null && activeStatuses.includes(instance?.status);
  const latestCancellationTime = DateTime.now();
  const earliestCancellationSignedDateTime = instance?.cancellationTime
    ? DateTime.fromISO(instance?.cancellationTime)
    : undefined;

  const mutation = useMutateGenericInstance<
    | ApartmentContract
    | IndustrialPremisesContract
    | OutdoorSectionContract
    | ParkingSpotContract
    | BlockContract
  >(
    async (options) =>
      mutateGenericInstance<
        | ApartmentContract
        | IndustrialPremisesContract
        | OutdoorSectionContract
        | ParkingSpotContract
        | BlockContract
      >({ ...options, config: config }),
    {
      onSuccess: () => {
        addToast({
          title: 'Avtalet uppdaterades',
          type: 'success',
        });
      },
      onError: (error) => {
        const field = handleFormErrors(error);
        addToast({
          title: 'Något gick fel',
          message: `Error: ${field}`,
          type: 'error',
        });
      },
    },
    modelName
  );

  const markAsNonDraft = () => {
    mutation.mutate({
      id,
      body: {
        isDraft: false,
      },
    });
  };

  const markAsManualSigned = (signedTime: string) => {
    mutation.mutate({
      id,
      body: {
        signedTime,
      },
    });
  };
  const markAsManualSignedCancellation = (signedTime: string) => {
    mutation.mutate({
      id,
      body: {
        cancellationSignedTime: signedTime,
      },
    });
  };

  const revertPendingCancellation = () => {
    mutation.mutate({
      id,
      body: {
        cancellationTime: null,
        cancelledByCounterpart: null,
        cancellationReason: null,
        pendingReNegotiations: false,
      },
    });
  };

  return {
    createContractLink,
    isContract,
    isDraft,
    isSigned,
    isCancelled,
    isCancelSigned,
    markAsNonDraft,
    markAsManualSigned,
    markAsManualSignedCancellation,
    revertPendingCancellation,
    earliestCancellationSignedDateTime,
    openCancelContractModal,
    latestCancellationTime,
    latestSignDate,
    canCancel,
    mainContract,
    mutation,
  };
}
