import { Pagination } from '@/components/pagination';
import { Input } from '@/components/ui/input';
import { Loader } from '@/components/ui/loader';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { cn } from '@/lib/utils';
import type { QueryParams } from '@/requests/api/types';
import { useGetList } from '@/requests/hooks';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import type {
  BaseInstance,
  IBaseInstanceConfig,
} from '@pigello/pigello-matrix';
import { keepPreviousData } from '@tanstack/react-query';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  type ColumnDef,
  type ColumnFilter,
  type ColumnOrderState,
  type RowSelectionState,
  type SortingState,
  type VisibilityState,
} from '@tanstack/react-table';
import { useMemo, useState, type ReactNode } from 'react';
import { useDebounceValue } from 'usehooks-ts';

type SimpleTableProps<TData extends BaseInstance, TValue> = {
  title: string;
  config?: IBaseInstanceConfig<TData>;
  columns: ColumnDef<TData, TValue>[];
  subtitle?: string;
  icon?: ReactNode;
  queryParams?: QueryParams;
  onRowSelection?: (id?: string) => void;
  manualData?: TData[];
  nested?: Array<keyof TData>;
};

export function SimpleTable<TData extends BaseInstance, TValue>({
  config,
  title,
  columns,
  subtitle,
  icon,
  queryParams,
  onRowSelection,
  manualData,
  nested,
}: SimpleTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [search, setSearch] = useDebounceValue('', 300);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(1);
  const defaultPageSize = 25;

  const paginationMemo = useMemo(
    () => ({
      pageIndex: page ?? 0,
      pageSize: pageSize ?? defaultPageSize ?? 25,
    }),
    [page, pageSize]
  );
  const { data, isFetching, isLoading, isError } = useGetList<TData>({
    placeholderData: keepPreviousData,
    modelName: config?.modelName,
    queryParams: {
      ...queryParams,
      page: paginationMemo.pageIndex,
      pageSize: paginationMemo.pageSize,
      search,
      order: sorting.map((sort) => (sort.desc ? `-${sort.id}` : sort.id)) ?? [],
    },
    nested,
    enabled: manualData === undefined && !!config,
  });

  const table = useReactTable<TData>({
    data: manualData ?? data?.list ?? [],
    columns: columns,
    state: {
      sorting,
      columnOrder,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination: paginationMemo,
    },
    manualPagination: true,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    manualSorting: true,
    enableSorting: true,
    enableMultiSort: false,
    enableSortingRemoval: true,
    enableMultiRemove: false,
    manualFiltering: true,
    sortDescFirst: false,
    pageCount: data?.meta?.page_amount ?? -1,
    onPaginationChange: (paginationState) => {
      if (typeof paginationState !== 'function') {
        setPageSize(paginationState.pageSize);
        setPage(paginationState.pageIndex);
      }
    },
    onSortingChange: (sortingState) => setSorting(sortingState),
    onColumnOrderChange: setColumnOrder,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => row.id,
  });

  return (
    <>
      <div className='mb-4 flex items-start justify-between'>
        <div>
          <h4 className='flex items-center'>
            {icon && icon}
            {title}
          </h4>

          {subtitle && <div className='mt-1 pl-6 text-xs'>{subtitle}</div>}
        </div>
        <div className='flex max-w-[300px] flex-1 items-center space-x-0 overflow-hidden rounded-full border bg-muted pl-2'>
          <MagnifyingGlassIcon className='size-6' />
          <Input
            className='flex-1 border-none bg-muted focus:outline-none focus-visible:ring-0'
            placeholder='Sök bland rader'
            onChange={({ target: { value } }) => setSearch(value)}
          />
        </div>
      </div>

      <div className='relative max-h-72 w-full min-w-full overflow-auto rounded-md border'>
        {isFetching && !isLoading && (
          <div className='absolute inset-0 z-10 flex items-center justify-center bg-background/80'>
            <Loader />
          </div>
        )}
        <Table>
          <TableHeader className={cn('sticky top-0 shadow-sm')}>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead
                    className='h-auto bg-background px-2'
                    key={header.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading &&
              Array(5)
                .fill(0)
                .map((_, i) => (
                  <TableRow key={i} className='hover:bg-transparent'>
                    {Array(columns.length)
                      .fill(0)
                      .map(() => (
                        <TableCell
                          key={crypto.randomUUID()}
                          className={cn('pl-1 transition-all')}
                        >
                          <Skeleton className='h-5 w-full min-w-[20px] bg-foreground/20' />
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
            {table.getRowModel().rows?.length > 0 ? (
              !isLoading &&
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  onClick={() => {
                    if (row.getIsSelected()) {
                      row.toggleSelected(false);
                      onRowSelection?.();
                    } else {
                      row.toggleSelected(true);
                      onRowSelection?.(row.id);
                    }
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      className={cn('h-auto px-2 py-1 transition-all')}
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <tr>
                <td className='h-20' />
              </tr>
            )}
            {data?.list?.length === 0 && !isFetching && (
              <tr className='absolute inset-0 my-4 flex flex-col items-center justify-center font-medium'>
                <td className='text-center text-muted-foreground'>
                  {search
                    ? 'Hittade inga rader som matchar din sökning'
                    : 'Det finns inga rader att visa'}
                </td>
              </tr>
            )}
            {isError && (
              <tr className='absolute inset-0 my-4 flex flex-col items-center justify-center font-medium'>
                <td className='text-center text-destructive'>
                  Något gick fel, kunde inte hämta rader
                </td>
              </tr>
            )}
          </TableBody>
          <TableFooter>
            {table.getFooterGroups().map((footerGroup) => (
              <TableRow className='border-b-0' key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <TableHead
                    className='h-auto bg-background px-2'
                    key={header.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableFooter>
        </Table>
      </div>
      <div className='p-2'>
        <Pagination page={page} setPage={setPage} meta={data?.meta} />
      </div>
    </>
  );
}
