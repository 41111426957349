import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { MultiSelectInput } from '@/components/ui/multi-select-input';
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/16/solid';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { useEffect, useState } from 'react';
import type { Path } from 'react-hook-form';
import type { FieldProps } from '../types';
import { DescriptionTooltip } from './description-tooltip';
// SMS = 0
// EMAIL = 1
// E_INBOX = 2
// POSTAL = 3
// E_BANK = 4
// E_INVOICE = 5

const reminderAndDebtDoNotInclude = [4, 5];

export const distributionDefaultValues = [
  {
    v: 0,
    d: 'SMS',
  },
  {
    v: 1,
    d: 'E-post',
  },
  {
    v: 2,
    d: 'Digital brevlåda',
  },
  {
    v: 3,
    d: 'Post',
  },
  {
    v: 4,
    d: 'E-faktura (bank)',
  },
  {
    v: 5,
    d: 'E-faktura (PEPPOL)',
  },
];

export function DistributionInstruction<
  Instance extends BaseInstance = BaseInstance,
>({
  formField,
  name,
  placeholder,
  label,
  description,
  className,
  disabled,
  canHandleField,
}: FieldProps<Instance>) {
  const arr = formField.control._formValues[formField.name as string];
  const [selected, setSelected] = useState<number[][]>();
  useEffect(() => {
    const values = arr?.length === 0 || typeof arr === 'undefined' ? [[]] : arr;

    setSelected([...(values ?? [])]);
  }, [arr]);
  return (
    <Card className='col-span-full'>
      <span className='text-sm'>{label ?? formField.label}</span>
      <FormField
        control={formField.control}
        rules={{
          required: formField.required && 'Detta fält är obligatoriskt',
        }}
        name={name as Path<Instance>}
        render={({ field }) => {
          const flattenedSelected = (field.value?.flat() as number[]) ?? [];
          const defaultValues = distributionDefaultValues.filter((d) => {
            const currentlySelected = flattenedSelected.includes(d.v);
            if (
              field.name.includes('debt') ||
              field.name.includes('reminder')
            ) {
              return (
                !currentlySelected && !reminderAndDebtDoNotInclude.includes(d.v)
              );
            }
            return !currentlySelected;
          });

          return (
            <>
              <FormItem className={className}>
                {selected?.map((num, i) => (
                  <div
                    className='grid gap-2'
                    key={`${String(formField.name)}-${num.join('-')}-${i})}`}
                  >
                    <div className='flex items-center'>
                      <FormLabel>
                        {i === 0
                          ? 'Försök först med'
                          : 'Om alla ovanstående misslyckas, försök istället med'}{' '}
                        {formField.required && <span>*</span>}
                      </FormLabel>
                      {(description || formField.description) && (
                        <DescriptionTooltip
                          description={description ?? formField.description}
                        />
                      )}
                    </div>
                    <FormControl>
                      <div className='relative'>
                        <MultiSelectInput
                          placeholder={placeholder ?? `Välj metod`}
                          onSelected={(items) => {
                            const values = items.map((i) => i.v) as number[];
                            const newValues = [...(selected ?? [])];
                            newValues[i] = values;
                            if (newValues.flat().length === 0) {
                              field.onChange(undefined);
                            } else {
                              field.onChange(newValues);
                            }
                          }}
                          values={
                            num?.map((n) => ({
                              v: n,
                              d:
                                distributionDefaultValues.find((d) => d.v === n)
                                  ?.d ?? '',
                            })) ?? []
                          }
                          defaultValues={defaultValues}
                          disabled={
                            disabled ?? field.disabled ?? !canHandleField
                          }
                        />
                        {i !== 0 && (
                          <Button
                            type='button'
                            className='absolute -right-2 -top-2 rounded-full'
                            disabled={
                              disabled ?? field.disabled ?? !canHandleField
                            }
                            onClick={() => {
                              const newValues = selected.toSpliced(i, 1);
                              if (newValues.flat().length === 0) {
                                field.onChange(undefined);
                              } else {
                                field.onChange(newValues);
                              }
                              setSelected(newValues);
                            }}
                            variant={'secondary'}
                            size={'icon'}
                          >
                            <XMarkIcon className='size-4' />
                          </Button>
                        )}
                      </div>
                    </FormControl>
                  </div>
                ))}
                <FormMessage />
              </FormItem>
              <div>
                <Button
                  disabled={
                    field.disabled ||
                    selected?.length === distributionDefaultValues.length ||
                    ((field.name.includes('debt') ||
                      field.name.includes('reminder')) &&
                      selected?.length ===
                        distributionDefaultValues.slice(2).length) ||
                    disabled ||
                    !canHandleField
                  }
                  onClick={() => field.onChange([...(selected ?? []), []])}
                  variant={'outline'}
                  type='button'
                >
                  <PlusCircleIcon className='mr-2 size-4' /> Lägg till ett
                  försök
                </Button>
              </div>
            </>
          );
        }}
      />
    </Card>
  );
}
