 import type { TagUsage } from '@pigello/pigello-matrix';

import { TagUsageConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, mutateGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { mutateGenericInstance } from '@/requests/mutating';

export const mutateTagUsage = async (
  options: mutateGenericInstanceProps<TagUsage>
) => {
  return mutateGenericInstance<TagUsage>({
    ...options,
    config: TagUsageConfig,
  });
};

export const useMutateTagUsage = (
  options: UseMutationOptions<TagUsage, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useMutateGenericInstance<TagUsage>(mutateTagUsage, options, 'tagusage');