'use client';

import { useConfig } from '@/hooks/useConfig';
import { useModal } from '@/hooks/useModal';
import type {
  BaseContractRevenueRow,
  BaseSpaceRevenueRow,
  IApartmentContractRevenueRowConfig,
  IApartmentRevenueRowConfig,
  IBrfApartmentRevenueRowConfig,
  IIndustrialPremisesContractRevenueRowConfig,
  IIndustrialPremisesRevenueRowConfig,
  IOutdoorSectionContractRevenueRowConfig,
  IOutdoorSectionRevenueRowConfig,
  IParkingSpotContractRevenueRowConfig,
  IParkingSpotRevenueRowConfig,
  ModelName,
} from '@pigello/pigello-matrix';

const revenueRowModelNames: ModelName[] = [
  'apartmentrevenuerow',
  'apartmentcontractrevenuerow',
  'industrialpremisesrevenuerow',
  'industrialpremisescontractrevenuerow',
  'outdoorsectionrevenuerow',
  'outdoorsectioncontractrevenuerow',
  'parkingspotrevenuerow',
  'parkingspotcontractrevenuerow',
  'brfapartmentrevenuerow',
];

const indexable: ModelName[] = [
  'apartmentcontractrevenuerow',

  'industrialpremisescontractrevenuerow',

  'outdoorsectioncontractrevenuerow',

  'parkingspotcontractrevenuerow',
];

export default function useRevenueRowHelpers<
  Instance extends BaseContractRevenueRow | BaseSpaceRevenueRow,
>(modelName: ModelName, id: string, instance?: Instance | undefined) {
  const { openIndexationUsageModal } = useModal();

  const { config } = useConfig(modelName) as unknown as {
    loading: boolean;
    config:
      | IApartmentRevenueRowConfig
      | IApartmentContractRevenueRowConfig
      | IIndustrialPremisesRevenueRowConfig
      | IIndustrialPremisesContractRevenueRowConfig
      | IParkingSpotRevenueRowConfig
      | IParkingSpotContractRevenueRowConfig
      | IOutdoorSectionRevenueRowConfig
      | IOutdoorSectionContractRevenueRowConfig
      | IBrfApartmentRevenueRowConfig;
  };

  const isRevenueRow = revenueRowModelNames.includes(modelName);
  const isIndexRow =
    instance && 'indexationUsage' in instance && !!instance.indexationUsage;
  const hasIndexUsage =
    instance &&
    'appliedIndexationUsage' in instance &&
    !!instance.appliedIndexationUsage;
  const indexUsageId =
    instance && 'appliedIndexationUsage' in instance
      ? instance.appliedIndexationUsage?.id
      : null;
  const isDistributionRow =
    instance &&
    'automaticDistributionQuota' in instance &&
    !!instance.automaticDistributionQuota;
  const isRetroactivityRow =
    instance &&
    'fromRetroactiveProposal' in instance &&
    !!instance.fromRetroactiveProposal;
  const isManualRow = !isIndexRow && !isRetroactivityRow && !isDistributionRow;
  const isIndexable =
    isManualRow && indexable.includes(modelName) && !hasIndexUsage;

  const canEdit = isManualRow;

  return {
    isRevenueRow,
    canEdit,
    hasIndexUsage,
    indexUsageId,
    isIndexable,
    isRetroactivityRow,
    config,
    openIndexationUsageModal,
  };
}
