import type { BadgeVariant } from '@/components/ui/badge';
import type { BaseRevenueRow } from '@pigello/pigello-matrix';
import { DateTime } from 'luxon';

export type DisplayModes = 'past' | 'present' | 'future';

export const revenueRowStatuses: Record<string, number> = {
  ACTIVE: 0,
  PAUSED: 1,
  PAST: 2,
  FUTURE: 3,
};

export const revenueRowStatusLabels: Record<number, string> = {
  0: 'Aktiv',
  1: 'Pausad',
  2: 'Tidigare',
  3: 'Kommande',
};

export const revenueRowStatusColors: Record<number, BadgeVariant['variant']> = {
  0: 'green',
  1: 'orange',
  2: 'gray',
  3: 'default',
};

export function calculateRevenueRowStatus(row: BaseRevenueRow) {
  const baseDate = DateTime.now().startOf('month');

  if (
    row.startDate &&
    DateTime.fromISO(row.startDate) > baseDate.endOf('month')
  )
    return revenueRowStatuses.FUTURE;
  if (row.endDate && DateTime.fromISO(row.endDate) < baseDate.startOf('month'))
    return revenueRowStatuses.PAST;

  if (row.excemptFromMonths?.includes(baseDate.month))
    return revenueRowStatuses.PAUSED;

  return revenueRowStatuses.ACTIVE;
}

export function calculateRevenueRowsForMonth({
  instances,
  selectedBaseMonth,
  displayMode,
}: {
  instances: BaseRevenueRow[];
  selectedBaseMonth: DateTime;
  displayMode: DisplayModes;
}) {
  return instances
    .filter((row) => {
      const startsAfterBaseDate =
        row.startDate &&
        DateTime.fromISO(row.startDate) > selectedBaseMonth.endOf('month');
      const endsBeforeBaseDate =
        row.endDate &&
        DateTime.fromISO(row.endDate) < selectedBaseMonth.startOf('month');
      const isActiveNow =
        (!row.startDate && !row.endDate) ||
        (!startsAfterBaseDate && !endsBeforeBaseDate);

      // remove past rows if not show past
      if (displayMode !== 'past' && endsBeforeBaseDate) {
        return false;
      }

      // remove future rows if not show future
      if (displayMode !== 'future' && startsAfterBaseDate) {
        return false;
      }

      if (displayMode !== 'present' && isActiveNow) {
        return false;
      }

      return true;
    })
    .map((row) => {
      const status = calculateRevenueRowStatus(row);

      return {
        ...row,
        status,
        statusLabel: revenueRowStatusLabels[status],
      };
    });
}
