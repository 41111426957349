 import type { InvoicePayment } from '@pigello/pigello-matrix';

import { InvoicePaymentConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useCreateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, createGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { createGenericInstance } from '@/requests/creating';

export const createInvoicePayment = async (options: createGenericInstanceProps<InvoicePayment>) => {
  return createGenericInstance<InvoicePayment>({
    ...options,
    config: InvoicePaymentConfig,
  });
};

export const useCreateInvoicePayment = (
  options: UseMutationOptions<InvoicePayment, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useCreateGenericInstance<InvoicePayment>(createInvoicePayment, options, 'invoicepayment');
