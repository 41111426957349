import { DynamicField } from '@/components/form/dynamic-field';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { useCreateNote } from '@/config/annotating/note/create';
import { useMutateNote } from '@/config/annotating/note/mutate';
import { useConfig } from '@/hooks/useConfig';
import { useForm } from '@/hooks/useForm';
import { useToast } from '@/hooks/useToast';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import type { ModelName, Note } from '@pigello/pigello-matrix';
import { NoteConfig, getCleanNote } from '@pigello/pigello-matrix';
import React from 'react';

export type NoteModalResponse = Promise<boolean>;

export type NoteModalContentData = {
  instanceId: string;
  modelName: ModelName;
  note?: Note;
};

interface Props extends GetModalContentProps {
  closeModal: (response: Awaited<NoteModalResponse>) => void;
  contentData: NoteModalContentData;
}

function NoteForm({ closeModal, contentData }: Props) {
  const { addToast } = useToast();
  const { config } = useConfig(contentData.modelName);
  const [open, setOpen] = React.useState(false);
  const form = useForm<Note>({
    config: NoteConfig,
    defaultValues: getCleanNote(),
    isUpdate: !!contentData?.note,
    values: contentData?.note,
  });
  const watchContent = form.watch('content');
  const { isDirty } = form.formState;
  const { mutate: createNote } = useCreateNote({
    onSuccess: () => {
      form.reset();
      addToast({
        type: 'success',
        title: 'Anteckningen sparad',
      });
      closeModal(false);
    },
    onError: () => {
      addToast({
        type: 'error',
        title: 'Kunde inte spara anteckningen',
      });
    },
  });

  const { mutate: updateNote } = useMutateNote({
    onSuccess: () => {
      form.reset();
      addToast({
        type: 'success',
        title: 'Anteckningen uppdaterad!',
      });
      closeModal(false);
    },
    onError: (err) => {
      addToast({
        type: 'error',
        title: 'Kunde ej uppdatera anteckningen',
        message: `Error - ${err?.message}`,
      });
    },
  });

  const onSubmit = () => {
    const data = form.getDirtyData();
    if (!config?.contentType) {
      return addToast({
        type: 'error',
        title: 'Kunde inte spara anteckningen',
      });
    }
    if (contentData?.note) {
      updateNote({
        body: {
          ...data,
          updatedAt: new Date().toISOString(),
          contentType: { id: config.contentType },
          objectId: contentData?.instanceId,
        },
        id: contentData.note.id as string,
      });
      return;
    }

    createNote({
      body: {
        ...data,
        contentType: { id: config.contentType },
        objectId: contentData?.instanceId,
      },
    });
  };

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <Form {...form}>
        <form className='grid space-y-4' onSubmit={form.handleSubmit(onSubmit)}>
          <DynamicField
            formField={form.fields.content}
            resize={false}
            label='Skriv en anteckning'
          />
          <DynamicField
            formField={form.fields.private}
            label='Gör anteckningen privat'
          />
          <div className='flex w-full justify-end gap-2'>
            <Button
              disabled={
                (contentData?.note && !isDirty) || watchContent?.length === 0
              }
              type='submit'
            >
              {contentData?.note ? 'Uppdatera' : 'Skapa'}
            </Button>
            <Button
              variant='outline'
              onClick={(e) => {
                e.preventDefault(); // Prevents the form from submitting
                if (isDirty && contentData?.note) {
                  setOpen(true);
                } else {
                  form.reset();
                  closeModal(false);
                }
              }}
            >
              Avbryt
            </Button>
          </div>
        </form>
      </Form>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Är du säker?</AlertDialogTitle>
          <AlertDialogDescription>
            Är du säker på att du vill avbryta? Du kommer förlora allt du
            skrivit.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <Button
            onClick={() => {
              form.reset();
              setOpen(false);
              closeModal(false);
            }}
          >
            Bekräfta
          </Button>
          <AlertDialogCancel
            onClick={() => {
              setOpen(false);
            }}
          >
            Avbryt
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export const getNoteModalContent = ({
  closeModal,
  contentData,
}: Props): ModalContent => {
  return {
    title: contentData.note ? 'Redigera anteckning' : 'Skapa anteckning',
    body: <NoteForm closeModal={closeModal} contentData={contentData} />,
  };
};
