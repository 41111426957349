import { DynamicField } from '@/components/form/dynamic-field';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Form } from '@/components/ui/form';
import { useCreateInvoicePayment } from '@/config/accounting/payments/invoicepayment/create';
import { useForm } from '@/hooks/useForm';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useToast } from '@/hooks/useToast';
import { raise } from '@/lib/utils';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import type { ErrorResponse } from '@/requests/types';
import { XMarkIcon } from '@heroicons/react/16/solid';
import {
  InvoiceConfig,
  InvoicePaymentConfig,
  getCleanInvoicePayment,
} from '@pigello/pigello-matrix';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useGetTotalInvoice } from '../hooks/use-get-invoice';

export type CreateManualPaymentResponse = Promise<boolean>;

export type CreateManualPaymentContentData = {
  invoiceId?: string;
  totalAmountToPay: number | undefined;
};

interface Props extends GetModalContentProps {
  closeModal: () => void;
  contentData: CreateManualPaymentContentData;
}

function CreateManualPayment({ closeModal, contentData }: Props) {
  const { invoice, invoiceRecipients, paymentMethod } = useGetTotalInvoice({
    id: contentData.invoiceId ?? raise('No invoice id'),
  });
  const invoiceRecipient = invoiceRecipients?.list?.[0];
  const { mutateAsync, isPending } = useCreateInvoicePayment({});
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const form = useForm({
    defaultValues: getCleanInvoicePayment(),
    config: InvoicePaymentConfig,
    preMutatedValues: {
      company: { id: invoice?.company?.id ?? raise('No company id') },
      invoice: invoice,
      originalInvoice: {
        id: invoice?.id ?? raise('No invoice id'),
      },
      paymentMethod: paymentMethod,
      usedAccountConfig: invoice?.usedAccountConfig,
      registrationDate: DateTime.now().toISODate(),
      paymentDate: DateTime.now().toISODate(),
      value: contentData.totalAmountToPay,
      ...(invoiceRecipient?.tenant && {
        tenant: {
          id: invoiceRecipient.tenant.id,
        },
      }),
      ...(invoiceRecipient?.subTenant && {
        subTenant: {
          id: invoiceRecipient.subTenant.id,
        },
      }),

      ...(invoiceRecipient?.organizationUser && {
        organizationUser: {
          id: invoiceRecipient.organizationUser.id,
        },
      }),
      ...(invoiceRecipient?.organizationUserContactPerson && {
        organizationUserContactPerson: {
          id: invoiceRecipient.organizationUserContactPerson.id,
        },
      }),
    },
  });

  const onSubmit = () => {
    const formData = form.getDirtyData();
    addToast({
      type: 'promise',
      promise: mutateAsync({
        body: formData,
      }),
      loading: 'Skapar manuell betalning...',
      success: () => {
        form.reset();
        queryClient.invalidateQueries({
          queryKey: [InvoiceConfig.modelName, { id: invoice?.id }],
        });
        queryClient.invalidateQueries({
          queryKey: [InvoiceConfig.modelName, 'list'],
        });
        closeModal();
        return 'Manuell betalning skapad';
      },
      error: (error: ErrorResponse) => {
        const field = handleFormErrors(error, form);
        if (field) {
          return field;
        }
        return 'Kunde inte skapa manuell betalning';
      },
    });
  };
  return (
    <Dialog open onOpenChange={() => closeModal()}>
      <DialogContent className='max-w-3xl p-0 sm:min-w-[768px]'>
        <DialogHeader className='flex w-full flex-row items-center justify-between border-b px-4 py-2'>
          <DialogTitle>Registrera manuell inbetalning</DialogTitle>
          <Button
            variant='outline'
            size={'icon-sm'}
            type='button'
            onClick={closeModal}
          >
            <XMarkIcon className='size-4' />
          </Button>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className='grid max-h-[calc(var(--window-keeper-height)-135px)] min-h-[min(400px,calc(var(--window-keeper-height)-175px))] gap-2 overflow-auto p-4'>
              <DynamicField horizontal formField={form.fields.referenceText} />
              <DynamicField horizontal formField={form.fields.ocrNumber} />
              <DynamicField horizontal formField={form.fields.paymentDate} />
              <DynamicField
                horizontal
                formField={form.fields.registrationDate}
              />
              <DynamicField
                horizontal
                formField={form.fields.paymentMethod}
                filters={{
                  eligible_for_companies: {
                    noop: invoice?.company?.id,
                  },
                }}
              />
              <DynamicField horizontal formField={form.fields.value} />
            </div>
            <DialogFooter className='border-t px-4 py-2'>
              <Button type='submit' disabled={isPending}>
                Registrera
              </Button>
              <Button type='button' variant={'outline'} onClick={closeModal}>
                Avbryt
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export const getCreateManualPaymentContent = (props: Props): ModalContent => {
  return {
    title: 'Skicka avi',
    body: <CreateManualPayment {...props} />,
    isCloseOnEscape: true,
    closeButton: true,
    externalModalHandling: true,
  };
};
