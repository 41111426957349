 import type { ContractCancellationReason } from '@pigello/pigello-matrix';

import { ContractCancellationReasonConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetContractCancellationReason = (
  options: useGetGenericInstanceProps<ContractCancellationReason>
) => {
  return useGetInstance<ContractCancellationReason>({
    ...options,
    config: ContractCancellationReasonConfig,
    modelName: 'contractcancellationreason'
  });
};

export const useGetContractCancellationReasonList = (
  options: useGetGenericListProps<ContractCancellationReason>
) => {
  return useGetList<ContractCancellationReason>({
    ...options,
    config: ContractCancellationReasonConfig,
    modelName: 'contractcancellationreason'
  });
};
