import { Loader } from '@/components/ui/loader';

import { useConfig } from '@/hooks/useConfig';
import { toReadableDate, toReadableDateTime } from '@/lib/dates';
import type { ContentType, InferMonitoring } from '@pigello/pigello-matrix';
import {
  ContentTypeToName,
  type BaseField,
  type BaseInstance,
  type ChoiceField,
  type IBaseInstanceConfig,
} from '@pigello/pigello-matrix';

export function findBestPreviewValue<T extends BaseInstance>({
  instance,
  config,
}: {
  instance?: T | InferMonitoring<T>;
  config?:
    | IBaseInstanceConfig<T>
    | IBaseInstanceConfig<InferMonitoring<T>>
    | undefined;
}) {
  if (!config || !instance) return ['id', instance?.id];

  const previewFields = config.previewFields;

  if (!previewFields || previewFields.length === 0) return ['id', instance.id];

  let bestMatch: [string, unknown] | undefined;

  for (let i = 0; i < previewFields.length; i++) {
    if (bestMatch) break;
    const attr = previewFields[i];

    const val = instance[attr as keyof T];
    if (val) {
      bestMatch = [attr as string, val];
    }
  }

  if (bestMatch) return bestMatch;

  return ['id', instance.id];
}

export const getGenericDisplayValue = ({
  fieldConfig,
  value,
  relationConfig,
}: {
  fieldConfig: BaseField;
  value: unknown;
  relationConfig?: IBaseInstanceConfig<BaseInstance>;
}) => {
  if (!fieldConfig) return 'No field config';
  if (fieldConfig.type === 'text') return value ? String(value) : '-';

  if (fieldConfig.type === 'number') return value ? String(value) : '-';

  if (fieldConfig.type === 'boolean') return value ? 'Ja' : 'Nej';

  if (fieldConfig.type === 'relation') {
    return value ? relationConfig?.getDisplayValue(value) : '-';
  }

  if (fieldConfig.type === 'date')
    return value ? toReadableDate(value as string) : '-';
  if (fieldConfig.type === 'dateTime')
    return value ? toReadableDateTime(value as string) : '-';

  if (fieldConfig.type === 'choice')
    return value
      ? (fieldConfig as ChoiceField<string>)?.choices?.find((c) => c.v == value)
          ?.d
      : '-';

  if (fieldConfig.type === 'contentType') {
    return ContentTypeToName?.[(value as { id: ContentType })?.id] ?? '-';
  }
  if (fieldConfig.type === 'manyrelation')
    return value && (value as Array<BaseInstance>).length !== 0
      ? relationConfig?.getDisplayValue((value as Array<BaseInstance>)[0])
      : '-';

  if (fieldConfig.type === 'array') {
    return (value as Array<string>)?.join(', ');
  }

  if (fieldConfig.type === 'uniquetext') {
    return value ? String(value) : '-';
  }

  return 'Unimplemented ' + fieldConfig.type;
};

export const GenericValueDisplay = ({
  fieldConfig,
  value,
}: {
  fieldConfig: BaseField;
  value: unknown;
}) => {
  const { isLoading, config: relationConfig } = useConfig(
    //@ts-expect-error fix this later
    fieldConfig?.relationConfig?.modelName ?? null
  );

  if (isLoading) return <Loader />;

  const displayValue = getGenericDisplayValue({
    fieldConfig,
    value,
    relationConfig,
  });

  return <span className='p'>{displayValue}</span>;
};
