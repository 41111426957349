import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import type { BaseInstance } from '@pigello/pigello-matrix';
import type { Path } from 'react-hook-form';
import type { FieldProps } from '../types';
import { DescriptionTooltip } from './description-tooltip';
import { ibanregex, parseFieldValue } from '../utils';

export function IBANAccount<Instance extends BaseInstance = BaseInstance>({
  formField,
  name,
  placeholder,
  label,
  description,
  className,
  horizontal,
  disabled,
  canHandleField,
}: FieldProps<Instance>) {
  if (horizontal) {
    return (
      <FormField
        rules={{
          required: formField.required && 'Detta fält är obligatoriskt',
        }}
        control={formField.control}
        name={name as Path<Instance>}
        render={({ field }) => (
          <FormItem
            className={cn('flex items-center justify-between', className)}
          >
            <div className='w-1/2 shrink-0'>
              <div className='flex items-center '>
                <FormLabel>
                  {label ?? formField.label}{' '}
                  {formField.required && <span>*</span>}
                </FormLabel>
                {(description || formField.description) && (
                  <DescriptionTooltip
                    description={description ?? formField.description}
                  />
                )}
              </div>

              <FormMessage />
            </div>
            <FormControl>
              <Input
                maxLength={36}
                {...field}
                onChange={(e) =>
                  field.onChange(
                    parseFieldValue({
                      newValue: e.target.value,
                      prevValue: field.value,
                      regex: ibanregex,
                    })
                  )
                }
                value={field.value ?? ''}
                // type='number'
                placeholder={placeholder ?? formField.label}
                disabled={disabled ?? field.disabled ?? !canHandleField}
              />
            </FormControl>
          </FormItem>
        )}
      />
    );
  }
  return (
    <FormField
      rules={{
        required: formField.required && 'Detta fält är obligatoriskt',
      }}
      control={formField.control}
      name={name as Path<Instance>}
      render={({ field }) => (
        <FormItem>
          <div className='flex items-center'>
            <FormLabel>
              {label ?? formField.label} {formField.required && <span>*</span>}
            </FormLabel>
            {(description || formField.description) && (
              <DescriptionTooltip
                description={description ?? formField.description}
              />
            )}
          </div>
          <FormControl>
            <Input
              maxLength={36}
              {...field}
              onChange={(e) =>
                field.onChange(
                  parseFieldValue({
                    newValue: e.target.value,
                    prevValue: field.value,
                    regex: ibanregex,
                  })
                )
              }
              value={field.value ?? ''}
              placeholder={placeholder ?? formField.label}
              disabled={disabled ?? field.disabled ?? !canHandleField}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
