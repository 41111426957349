import { DynamicField } from '@/components/form/dynamic-field';
import { Icons } from '@/components/icons';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { useForm } from '@/hooks/useForm';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { cn, raise } from '@/lib/utils';
import { useGetInstance, useMutateGenericInstance } from '@/requests/hooks';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { mutateGenericInstance } from '@/requests/mutating';
import type { mutateGenericInstanceProps } from '@/requests/types';
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import { getDisplayValue, type BaseInstance } from '@pigello/pigello-matrix';

import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { usePerms } from '@/hooks/usePerms';
import { useToast } from '@/hooks/useToast';
import { useQueryClient } from '@tanstack/react-query';
import type { MouseEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ChapterComp } from '../chapter';
import type { DefaultFormProps } from '../types';

export function DefaultUpdateInstanceForm<
  Instance extends BaseInstance = BaseInstance,
>({
  title,
  chapters,
  onSuccess,
  onError,
  config,
  horizontal,
  preMutatedValues,
  description,
  closeModal,
  id,
  cleanInstance,
  overrideUrl,
}: DefaultFormProps<Instance> & {
  description?: string;
  id: string;
  closeModal: () => void;
}) {
  const { canUpdate, fields } = usePerms<Instance>(config.modelName);
  const canNotHandleAllRequiredFields = chapters?.some((chapter) => {
    return chapter.fields.some(
      (field) =>
        fields[field]?.canHandle === false && config.fields[field]?.required
    );
  });

  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState<string[]>([
    chapters?.[0]?.title ?? '',
  ]);
  const [open, setOpen] = useState(false);
  const { data, isLoading: isLoadingData } = useGetInstance({
    id: id ?? raise('No id provided'),
    modelName: config.modelName,
    nested: getRelationFieldNames(config),
    overrideUrl,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const mutateFn = async (options: mutateGenericInstanceProps<Instance>) => {
    return mutateGenericInstance<Instance>({
      ...options,
      config,
      // options: {
      //   convertRelations: true,
      // },
    });
  };
  const form = useForm<Instance>({
    defaultValues: cleanInstance(),
    values: data,
    isUpdate: true,
    preMutatedValues,
    config,
    disabled: !canUpdate,
    orderedFields: chapters?.map((chapter) => ({
      key: chapter.title,
      fields: chapter.fields,
    })),
  });

  const { mutate, isPending } = useMutateGenericInstance<Instance>(
    mutateFn,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: [config?.monitoringConfig],
        });
        if ('mtMotivation' in data) {
          addToast({
            type: 'warning',
            title: 'Kräver godkännande',
            message: `${config.verboseName} ${getDisplayValue(
              config,
              data
            )} kräver godkännande innan objektet kan sparas.`,
          });
        } else {
          addToast({
            type: 'success',
            title: 'Ändringar sparade',
          });
        }
        onSuccess && onSuccess(data);
        closeModal();
      },
      onError: (error) => {
        const field = handleFormErrors(error, form);
        addToast({
          type: 'error',
          title: 'Något gick fel',
          message: field,
        });

        onError && onError();
      },
    },
    config.modelName
  );

  const onSubmit = () => {
    const data = form.getDirtyData();
    mutate({
      body: { ...data },
      id: id ?? raise('No id provided'),
    });
  };
  const scrollToSection = (section: string) => {
    const element = document.getElementById(section);
    if (!element) return;
    const rect = element.getBoundingClientRect();
    const isFirstElement = section === chapters?.[0]?.title;
    containerRef.current?.scrollTo({
      top: isFirstElement ? 0 : rect.top + containerRef.current.scrollTop - 160,
      behavior: 'smooth',
    });
  };
  const { isDirty } = form.formState;

  const closeAndReset = useCallback(() => {
    form.reset();
    closeModal();
  }, [closeModal, form]);

  const attemptClose = useCallback(
    (
      event:
        | MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
        | KeyboardEvent
    ) => {
      event.preventDefault();
      if (!isDirty) return closeAndReset();
      setOpen(true);
    },

    [closeAndReset, isDirty]
  );

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        attemptClose(e);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, [attemptClose]);
  return (
    <Dialog defaultOpen onOpenChange={closeModal}>
      <DialogContent
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        className='relative m-auto h-[600px] w-[1100px] p-0 fill-mode-forwards sm:max-w-[1200px]'
      >
        <Form {...form}>
          <form
            className='grid h-[598px] grid-cols-5'
            onSubmit={(e) => {
              form.clearErrors();
              form.handleSubmit(onSubmit)(e);
            }}
          >
            <div className='col-span-5 flex h-16 items-center justify-between gap-2 border-b px-4 py-2'>
              <DialogTitle>{title}</DialogTitle>
              <Button
                type='button'
                variant='outline'
                size='icon-sm'
                className='hover:bg-red-500 hover:text-red-200'
                onClick={(e) => {
                  attemptClose(e);
                }}
              >
                <XMarkIcon className='size-4' />
                <span className='sr-only'>Stäng</span>
              </Button>
            </div>

            {chapters && (
              <div className='row-span-5 row-start-2 flex flex-col gap-2 rounded-l-md bg-transparent px-4 pt-8'>
                {chapters.map((chapter) => (
                  <button
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection(chapter.title);
                    }}
                    className={cn(
                      'flex items-center gap-2 rounded-md px-2 py-1 transition-colors hover:bg-primary/5'
                    )}
                    key={chapter.description + chapter.title}
                  >
                    <div
                      className={cn(
                        'size-2 shrink-0 rounded-full bg-border',
                        activeTab.includes(chapter.title) && 'bg-primary'
                      )}
                    />
                    <p className='truncate text-sm'>{chapter.title}</p>
                  </button>
                ))}
              </div>
            )}

            <div
              ref={containerRef}
              className={cn(
                'col-span-4 row-span-4 row-start-2 h-full overflow-auto border-l px-4 pb-8 shadow-[-53px_0px_33px_-57px_rgba(0,0,0,0.05)]',
                {
                  'rounded-none border-none col-span-full': !chapters,
                }
              )}
            >
              {canNotHandleAllRequiredFields && (
                <Alert variant={'warning'} className='my-4 -mb-8'>
                  <ExclamationTriangleIcon className='size-4 text-yellow-600' />
                  <AlertTitle>OBS!</AlertTitle>
                  <AlertDescription>
                    Du har inte behörighet att hantera alla fält på detta
                    objekt. Kontakta din administratör om du ändra detta.
                  </AlertDescription>
                </Alert>
              )}
              {description && (
                <Alert className='mt-8'>
                  <InformationCircleIcon className='size-4 text-primary' />
                  <AlertTitle>Info</AlertTitle>
                  <AlertDescription>{description}</AlertDescription>
                </Alert>
              )}
              {chapters ? (
                chapters.map((chapter, i) => (
                  <ChapterComp<Instance>
                    key={chapter.title}
                    activeTab={activeTab}
                    isFirst={i === 0}
                    hasDescription={!!description}
                    isLast={i === chapters.length - 1}
                    chapter={chapter}
                    containerRef={containerRef}
                    horizontal={horizontal}
                    isLoadingData={isLoadingData}
                    setActiveTab={setActiveTab}
                    orderedFields={form.orderedFields}
                  />
                ))
              ) : (
                <div className='grid gap-3'>
                  <div
                    className={cn(
                      'mt-4 grid grid-cols-2 gap-8',
                      horizontal && 'grid-cols-1'
                    )}
                  >
                    {Object.values(form.fields ?? {}).map((field) => (
                      <DynamicField<Instance>
                        key={field.name.toString()}
                        formField={field}
                        horizontal={horizontal}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div
              className={cn(
                'col-span-4 col-start-2 row-start-6 flex items-center justify-end rounded-br-md border-l border-t bg-background px-4 py-2',
                {
                  'border-l-0 col-start-1 col-span-full': !chapters,
                }
              )}
            >
              <div className='flex items-center gap-2'>
                {canUpdate && (
                  <Button type='submit' disabled={!isDirty || isPending}>
                    Spara
                    {isPending && (
                      <Icons.loader className='ml-2 size-4 animate-spin' />
                    )}
                  </Button>
                )}
                <Button
                  type='button'
                  onClick={(e) => {
                    e.preventDefault();
                    if (!isDirty) return closeAndReset();
                    setOpen(true);
                  }}
                  variant={'outline'}
                >
                  Avbryt
                </Button>
              </div>
            </div>
          </form>
        </Form>
        <AlertDialog open={open} onOpenChange={setOpen}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Är du säker?</AlertDialogTitle>
              <AlertDialogDescription>
                Är du säker på att du vill avbryta? Du kommer förlora allt du
                skrivit.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel
                onClick={() => {
                  setOpen(false);
                }}
              >
                Avbryt
              </AlertDialogCancel>
              <Button
                onClick={() => {
                  setOpen(false);
                  closeAndReset();
                }}
              >
                Bekräfta
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </DialogContent>
    </Dialog>
  );
}
