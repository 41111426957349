'use client';

import InvoiceRowTable from '@/components/data-table/custom-tables/invoice-row-table';
import { InvoiceTableColumnHeader } from '@/components/data-table/custom-tables/invoice-row-table/invoice-table-column-header';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import useFetchAllIds from '@/hooks/use-fetch-all-ids';
import { toMoneyString } from '@/lib/utils';
import {
  CalculatorIcon,
  MagnifyingGlassIcon,
  PlusIcon,
} from '@heroicons/react/16/solid';
import { type Article, ArticleConfig } from '@pigello/pigello-matrix';
import { uniq } from 'lodash';
import { useState } from 'react';
import type { IInvoiceRow } from './types';

export default function PreviewDebitRowSummary({
  debitRows = [],
  noStickyTableHeader,
  hideSearch = false,
  onCreateClicked,
  onEditClicked,
  subtitle,
  roundDecimals,
  adminFee,
  reminderFee,
  debtFee,
}: {
  debitRows?: IInvoiceRow[];
  noStickyTableHeader?: boolean;
  hideSearch?: boolean;
  onCreateClicked?: VoidFunction;
  onEditClicked?: (id: string) => void;
  subtitle?: string | React.ReactNode;
  roundDecimals?: boolean;
  adminFee?: number;
  reminderFee?: number;
  debtFee?: number;
}) {
  const [search, setSearch] = useState('');

  const articleIds = uniq(debitRows?.map((db) => db.article?.id))?.filter(
    (v) => typeof v === 'string'
  );

  const { data: articles, isLoading: articlesLoading } =
    useFetchAllIds<Article>({
      modelName: ArticleConfig.modelName,
      ids: articleIds,
      order: ['-createdAt'],
      idAttr: 'id',
      filters: {},
    });

  // Calculate the highest VAT rate
  const highestVatRate = Math.max(...debitRows.map((row) => row.vat), 0);

  // Calculate VAT for admin fee
  const adminFeeVat = adminFee ? (adminFee * highestVatRate) / 100 : 0;

  const totalDebitIncVat =
    debitRows?.reduce((acc, cur) => {
      const rowTotal = (cur.unit_amount ?? 1) * (cur.unit_price ?? 0);
      const vatAmount = rowTotal * (cur.vat / 100);
      const totalWithVat = rowTotal + vatAmount;

      return acc + totalWithVat;
    }, 0) +
    (adminFee ?? 0) +
    adminFeeVat;

  const totalVat =
    debitRows?.reduce((acc, cur) => {
      const rowTotal = (cur.unit_amount ?? 1) * (cur.unit_price ?? 0);
      const vatAmount = rowTotal * (cur.vat / 100);

      return acc + vatAmount;
    }, 0) + adminFeeVat;

  const totalDebitExVat = debitRows?.reduce((acc, cur) => {
    const rowTotal = (cur.unit_amount ?? 1) * (cur.unit_price ?? 0);
    return acc + rowTotal;
  }, 0);

  return (
    <Card className='overflow-x-auto'>
      <CardHeader className='flex flex-row items-start justify-between'>
        <div>
          <CardTitle className='flex items-center'>
            <CalculatorIcon className='mr-2 size-4' />
            Debiteringsrader
          </CardTitle>

          {subtitle && <div className='mt-1 pl-6 text-xs'>{subtitle}</div>}
        </div>

        {!hideSearch && (
          <div className='flex max-w-[300px] flex-1 items-center space-x-0 overflow-hidden rounded-full border bg-muted pl-2'>
            <MagnifyingGlassIcon className='size-6' />
            <Input
              className=' flex-1 border-none bg-muted focus:outline-none focus-visible:ring-0'
              value={search}
              placeholder='Sök bland rader'
              onChange={({ target: { value } }) => setSearch(value)}
            />
          </div>
        )}

        {onCreateClicked && (
          <Button
            type='button'
            onClick={onCreateClicked}
            variant='secondary'
            size='icon-sm'
          >
            <PlusIcon className='size-4' />
          </Button>
        )}
      </CardHeader>
      <CardContent>
        {!articles?.list.length && (
          <div className='my-4 flex flex-col items-center justify-center font-medium'>
            <div className='text-center text-muted-foreground'>
              {search
                ? 'Hittade inga debiteringsrader som matchar din sökning'
                : 'Det finns inga debiteringsrader att visa'}
            </div>
          </div>
        )}
          <InvoiceRowTable
            isLoading={articlesLoading}
            data={debitRows}
            noStickyTableHeader={noStickyTableHeader}
            onRowClick={onEditClicked ? (id) => onEditClicked(id) : undefined}
            searchValue={search}
            columns={[
              {
                accessorKey: 'title',
                enableGlobalFilter: true,

                meta: {
                  columnVisibilityName: 'Namn',
                },
                header: ({ column }) => (
                  <InvoiceTableColumnHeader column={column} />
                ),
                cell: ({ row }) => (
                  <div className='whitespace-nowrap'>{row.original.title}</div>
                ),
              },
              {
                accessorFn: (original) => {
                  const name =
                    (articles?.list &&
                      articles.list.find((a) => a.id === original.article?.id)
                        ?.name) ??
                    '0';

                  return name;
                },
                id: 'article',

                meta: {
                  columnVisibilityName: 'Artikel',
                },
                header: ({ column }) => (
                  <InvoiceTableColumnHeader column={column} />
                ),
                cell: ({ row }) => {
                  if (!articles?.list) return '-';

                  return articles.list.find(
                    (a) => a.id === row.original.article?.id
                  )?.name;
                },
              },
              {
                accessorFn: (original) => {
                  return (
                    (original.unit_amount ?? 1) * (original.unit_price ?? 0)
                  );
                },

                id: 'sumexvat',
                meta: {
                  columnVisibilityName: 'Belopp ex.Moms',
                },
                header: ({ column }) => (
                  <InvoiceTableColumnHeader
                    className='justify-end text-right'
                    column={column}
                  />
                ),
                cell: ({ row }) => {
                  const rowDebit =
                    (row.original.unit_amount ?? 1) *
                    (row.original.unit_price ?? 0);

                  return (
                    <div className='text-right'>{toMoneyString(rowDebit)}</div>
                  );
                },
              },
              {
                accessorFn: (original) => {
                  const rowDebit =
                    (original.unit_amount ?? 1) * (original.unit_price ?? 0);

                  const vat = rowDebit * (1 + original.vat / 100) - rowDebit;

                  return vat;
                },
                id: 'vat',
                meta: {
                  columnVisibilityName: 'Moms',
                },
                header: ({ column }) => (
                  <InvoiceTableColumnHeader
                    className='justify-end text-right'
                    column={column}
                  />
                ),
                cell: ({ row }) => {
                  const rowDebit =
                    (row.original.unit_amount ?? 1) *
                      (row.original.unit_price ?? 0) +
                    (adminFee ?? 0);

                  const vat =
                    rowDebit * (1 + row.original.vat / 100) - rowDebit;

                  return <div className='text-right'>{toMoneyString(vat)}</div>;
                },
              },
              {
                accessorFn: (original) => {
                  const rowDebit =
                    (original.unit_amount ?? 1) * (original.unit_price ?? 0);

                  const vat = rowDebit * (1 + original.vat / 100) - rowDebit;

                  const total = rowDebit + vat;
                  return total;
                },
                id: 'sumincvat',

                meta: {
                  columnVisibilityName: 'Belopp ink.moms',
                },
                header: ({ column }) => (
                  <InvoiceTableColumnHeader
                    className='justify-end text-right'
                    column={column}
                  />
                ),
                cell: ({ row }) => {
                  const rowDebit =
                    (row.original.unit_amount ?? 1) *
                      (row.original.unit_price ?? 0) +
                    (adminFee ?? 0);

                  const vat =
                    rowDebit * (1 + row.original.vat / 100) - rowDebit;

                  const total = rowDebit + vat;
                  return (
                    <div className='text-right'>{toMoneyString(total)}</div>
                  );
                },
              },
            ]}
          />

        {!!articles?.list.length && (
          <div className='flex flex-row justify-end text-xs'>
            <div className='my-2 grid max-w-[400px]  grid-cols-2 gap-1 rounded-sm border bg-muted p-2'>
              <div className='whitespace-nowrap font-medium'>
                Total ex.Moms:
              </div>
              <div className='whitespace-nowrap text-end'>
                {toMoneyString(totalDebitExVat)}
              </div>

              <div className='whitespace-nowrap font-medium'>Moms:</div>
              <div className='whitespace-nowrap text-end'>
                {toMoneyString(totalVat)}
              </div>
              {adminFee != null && (
                <>
                  <div className='whitespace-nowrap font-medium'>
                    Adminavgift (ink. moms):
                  </div>
                  <div className='text-end'>
                    {toMoneyString(adminFee + adminFeeVat)}
                  </div>
                </>
              )}
              <div className='whitespace-nowrap font-medium'>
                Total ink.Moms:
              </div>
              <div className='text-end'>{toMoneyString(totalDebitIncVat)}</div>
              {roundDecimals && (
                <>
                  <div className='whitespace-nowrap font-medium'>
                    Öresavrundning:
                  </div>
                  <div className='text-end'>
                    {toMoneyString(
                      Math.round(totalDebitIncVat) - totalDebitIncVat
                    )}
                  </div>
                </>
              )}
              {reminderFee != null && (
                <>
                  <div className='whitespace-nowrap font-medium'>
                    Påminnelseavgift:
                  </div>
                  <div className='text-end'>{toMoneyString(reminderFee)}</div>
                </>
              )}
              {debtFee != null && (
                <>
                  <div className='whitespace-nowrap font-medium'>
                    Kravavgift:
                  </div>
                  <div className='text-end'>{toMoneyString(debtFee)}</div>
                </>
              )}
              <hr className='col-span-2 w-full' />
              <div className='font-bold'>Att betala:</div>
              <div className='text-end font-bold'>
                {toMoneyString(
                  roundDecimals
                    ? Math.round(
                        totalDebitIncVat +
                          (adminFee ?? 0) +
                          (reminderFee ?? 0) +
                          (debtFee ?? 0)
                      )
                    : totalDebitIncVat +
                        (adminFee ?? 0) +
                        (reminderFee ?? 0) +
                        (debtFee ?? 0)
                )}
              </div>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
