 import type { Article } from '@pigello/pigello-matrix';

import { ArticleConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useCreateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, createGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { createGenericInstance } from '@/requests/creating';

export const createArticle = async (options: createGenericInstanceProps<Article>) => {
  return createGenericInstance<Article>({
    ...options,
    config: ArticleConfig,
  });
};

export const useCreateArticle = (
  options: UseMutationOptions<Article, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useCreateGenericInstance<Article>(createArticle, options, 'article');
