 import type { InvoiceCreditationConnection } from '@pigello/pigello-matrix';

import { InvoiceCreditationConnectionConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useCreateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, createGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { createGenericInstance } from '@/requests/creating';

export const createInvoiceCreditationConnection = async (options: createGenericInstanceProps<InvoiceCreditationConnection>) => {
  return createGenericInstance<InvoiceCreditationConnection>({
    ...options,
    config: InvoiceCreditationConnectionConfig,
  });
};

export const useCreateInvoiceCreditationConnection = (
  options: UseMutationOptions<InvoiceCreditationConnection, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useCreateGenericInstance<InvoiceCreditationConnection>(createInvoiceCreditationConnection, options, 'invoicecreditationconnection');
