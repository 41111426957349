'use client';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { DialogFooter, DialogHeader } from '@/components/ui/detail-dialog';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import OverlayLoader from '@/components/ui/overlay-loader';
import { cn, toMoneyString, toPercentageString } from '@/lib/utils';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import { postApi } from '@/requests/api';
import { BASE_BACKEND_URL } from '@/requests/constants';
import { getCookies } from '@/requests/cookies';
import type { ErrorResponse } from '@/requests/types';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { DebtInvoiceConfig, InvoiceConfig } from '@pigello/pigello-matrix';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { CardSection } from '../../components/cardSection';
import type { IInvoicePerformResult, IInvoicePreviewResult } from './types';

export type DebtPreviewModalResponse = Promise<boolean>;

export type DebtPreviewContentData = {
  instanceId: string;
};

interface Props extends GetModalContentProps {
  closeModal: (response: Awaited<DebtPreviewModalResponse>) => void;
  contentData: DebtPreviewContentData;
}

function DebtPreviewModalContent({ closeModal, contentData }: Props) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { instanceId } = contentData;

  const { data, isError, error, isPending } = useQuery<
    IInvoicePreviewResult,
    ErrorResponse
  >({
    queryFn: async () => {
      const { cookies } = await getCookies();
      const res = await postApi<IInvoicePreviewResult>({
        url: `${BASE_BACKEND_URL}/accounting/invoices/invoice/debt_invoice_generating/preview/${cookies.organization_id}/`,
        body: JSON.stringify({
          ids: [{ id: instanceId }],
          attempt_auto_attest: false,
        }),
      });

      return res;
    },
    queryKey: ['debtpreview', instanceId],
    staleTime: 10 * 1000,
  });

  useEffect(() => {
    if (isError) {
      toast.error(
        'Något gick fel med förhandsgranskningen. Kontrollera inställningarna och försök igen.',
        {
          description: JSON.stringify(error.errorData) ?? '',
        }
      );
    }
  }, [isError]);

  const { mutate } = useMutation<IInvoicePerformResult, ErrorResponse>({
    mutationFn: async () => {
      setLoading(true);
      const { cookies } = await getCookies();
      const res = await postApi<IInvoicePerformResult>({
        url: `${BASE_BACKEND_URL}/accounting/invoices/invoice/debt_invoice_generating/perform/${cookies.organization_id}/`,
        body: JSON.stringify({
          ids: [{ id: instanceId }],
          attempt_auto_attest: true,
        }),
      });

      return res;
    },
    onSettled: () => {
      setLoading(false);
    },
    onError: () => {
      toast.error('Något gick fel vid genereringen', {
        description: 'Försök igen, kontakta Pigello om problemet kvarstår',
      });
    },
    onSuccess: () => {
      closeModal(false);
      queryClient.invalidateQueries({
        queryKey: [InvoiceConfig.modelName],
      });
      queryClient.invalidateQueries({
        queryKey: [DebtInvoiceConfig.modelName],
      });
      toast.success('Påminnelse genererades');
    },
  });

  const invoiceData = data?.debtinvoice_create?.[0];

  return (
    <>
      <Dialog open onOpenChange={() => closeModal(false)}>
        <DialogContent className={cn('min-w-[600px] gap-0 p-0 transition-all')}>
          {loading && <OverlayLoader />}
          <DialogHeader className='flex flex-row items-center justify-between border-b px-4 py-2'>
            <DialogTitle>Förhandsgranskning av påminnelseavi</DialogTitle>

            <Button
              variant='secondary'
              className='hover:bg-red-500 hover:text-red-200'
              size='icon-sm'
              onClick={() => closeModal(false)}
            >
              <XMarkIcon className='size-4' />
            </Button>
          </DialogHeader>

          <div className=' max-h-[calc(var(--window-keeper-height)-135px)] min-h-[min(400px,calc(var(--window-keeper-height)-175px))] overflow-y-auto p-4'>
            {isPending && <OverlayLoader />}

            {invoiceData && (
              <Card>
                <CardHeader>
                  <CardTitle>
                    {invoiceData?.kind === 0 ? 'Påminnelse' : 'Krav'}
                  </CardTitle>
                </CardHeader>

                <CardContent>
                  <CardSection
                    items={[
                      {
                        title: DebtInvoiceConfig.fields.debtDate.verboseName,
                        content: invoiceData.debt_date,
                      },
                      {
                        title: DebtInvoiceConfig.fields.dueDate.verboseName,
                        content: invoiceData.due_date,
                      },
                      {
                        title: DebtInvoiceConfig.fields.fee.verboseName,
                        content: toMoneyString(invoiceData.fee),
                      },
                      {
                        title:
                          DebtInvoiceConfig.fields.interestRateStartDate
                            .verboseName,
                        content: invoiceData.interest_rate_start_date,
                      },
                      {
                        title:
                          DebtInvoiceConfig.fields.interestRate.verboseName,
                        content: toPercentageString(invoiceData.interest_rate),
                      },
                    ]}
                  />
                </CardContent>
              </Card>
            )}
          </div>

          <DialogFooter className='border-t p-4'>
            <Button variant='outline' onClick={() => closeModal(false)}>
              Stäng
            </Button>
            <Button onClick={() => mutate()}>{'Generera påminnelse'}</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}

export const getDebtPreviewModalContent = ({
  closeModal,
  contentData,
}: Props): ModalContent => {
  return {
    externalModalHandling: true,
    body: (
      <DebtPreviewModalContent
        closeModal={closeModal}
        contentData={contentData}
      />
    ),
  };
};
