import { getGenericDisplayValue } from '@/components/monitoring/components/displayGenericValue';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { cn } from '@/lib/utils';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import { createGenericInstance } from '@/requests/creating';
import {
  useCreateGenericInstance,
  useMutateGenericInstance,
} from '@/requests/hooks';
import { mutateGenericInstance } from '@/requests/mutating';
import { useInstanceStore } from '@/store';
import { ExclamationTriangleIcon } from '@heroicons/react/16/solid';
import {
  type BaseInstance,
  type IBaseInstanceConfig,
} from '@pigello/pigello-matrix';
import { useRouter } from 'next/navigation';
import { useMemo } from 'react';
import { useToast } from '../useToast';
import type { ApprovingPartyMonitoringConfiguration } from './components/monitoring-configuration';
import { ApprovingPartyReplacements } from './components/monitoring-configuration';
import { useArchiveAndReplace } from './use-replace-and-archive';
import type { BlockedBy } from './useArchive';
import { useArchive } from './useArchive';
import { deleteBaseFields } from './utils';

export type ArchiveInstanceModalContentData<Instance extends BaseInstance> = {
  instance?: Instance;
  config: IBaseInstanceConfig<Instance>;
  blockedBy?: BlockedBy;
  returnUrl?: string;
  onSuccessfulArchive?: (data: Instance) => void;
  preProccessFormData?: (
    instance: Partial<BaseInstance> | null
  ) => Partial<BaseInstance>;
};

export interface ArchiveInstanceModalProps<Instance extends BaseInstance>
  extends GetModalContentProps {
  contentData: ArchiveInstanceModalContentData<Instance>;
}

function ArchiveInstanceModal<Instance extends BaseInstance>({
  closeModal,
  contentData: {
    instance,
    config,
    blockedBy,
    returnUrl,
    onSuccessfulArchive,
    preProccessFormData,
  },
}: ArchiveInstanceModalProps<Instance>) {
  const router = useRouter();

  const { getInstance } = useInstanceStore();

  const formValues =
    preProccessFormData?.(getInstance(instance?.id ?? '')) ??
    getInstance(instance?.id ?? '');
  const unProcessedFormValues = getInstance(instance?.id ?? '');

  const { blockedByCount, replacementslist } = useArchive<Instance>({
    config,
    instance,
    blockedBy,
    formValues,
  });

  const { addToast } = useToast();

  const { mutate } = useArchiveAndReplace<Instance>({
    config,
    instance,
    formValues,
    closeModal,
    onSuccessfulArchive,
  });

  const rows = useMemo(() => {
    return Object.entries(unProcessedFormValues ?? {})
      .filter(([key]) => key !== 'id')
      .map(([key, value]) => {
        if (!config?.fields[key as keyof BaseInstance]) return null;
        return [
          config?.fields[key as keyof BaseInstance]?.verboseName,
          getGenericDisplayValue({
            fieldConfig: config?.fields[key as keyof BaseInstance],
            value,
          }),
          getGenericDisplayValue({
            fieldConfig: config?.fields[key as keyof BaseInstance],
            value: instance?.[key as keyof BaseInstance],
          }),
        ];
      });
  }, [config?.fields, unProcessedFormValues, instance]);

  const { mutate: archiveInstance } = useMutateGenericInstance<Instance>(
    async (options) => mutateGenericInstance<Instance>({ ...options, config }),
    {
      onSuccess: () => {
        if (!instance) {
          return addToast({
            title: 'Något gick fel',
            type: 'error',
          });
        }
        if (replacementslist.data.length > 0) {
          return mutate();
        }
        const cleanedInstance = deleteBaseFields(instance) as Instance;
        create({ body: { ...cleanedInstance, ...formValues } });
      },
      onError: () => {
        addToast({
          title: 'Något gick fel',
          message: `Error: ${config?.verboseName} kunde inte arkiveras`,
          type: 'error',
        });
      },
    },
    config.modelName
  );

  const { mutate: create } = useCreateGenericInstance<Instance>(
    async (options) => createGenericInstance<Instance>({ ...options, config }),
    {
      onSuccess: (data) => {
        addToast({
          title: 'Det nya objektet skapades',
          type: 'success',
        });
        closeModal();
        onSuccessfulArchive?.(data);
        returnUrl && router.push(returnUrl);
      },
      onError: () => {
        addToast({
          title: 'Något gick fel',
          message: `Error: ${config?.verboseName} kunde inte arkiveras`,
          type: 'error',
        });
      },
    },
    config.modelName
  );

  return (
    <Dialog defaultOpen={true}>
      <DialogContent size='lg' className='p-0'>
        <DialogHeader>
          <DialogTitle>Detta objekt kan inte redigeras.</DialogTitle>
        </DialogHeader>
        <div className='grid max-h-[600px] gap-4 overflow-auto p-4'>
          <Alert>
            <ExclamationTriangleIcon className='size-5' />
            <AlertTitle>
              {instance && config?.getDisplayValue(instance)} kan inte
              redigeras, inställningen är blockerad av följande händelser
            </AlertTitle>
            <AlertDescription>
              {blockedByCount?.map((inst, idx) => (
                <span key={`${inst.config.verboseNamePlural}-${idx}`}>
                  {inst.count} st{' '}
                  {inst.config.verboseNamePlural?.toLocaleLowerCase()} har
                  fortfarande kopplingar till{' '}
                  {instance && config?.getDisplayValue(instance)}{' '}
                  {idx + 1 < blockedByCount.length ? 'och' : ''}{' '}
                </span>
              ))}
            </AlertDescription>
          </Alert>
          <div className='grid gap-4'>
            <span className='text-sm'>
              För att kunna redigera objektet måste det arkiveras och ersättas
              med ett nytt objekt. Tidigare inställningar och relationer kommer
              att flyttas över till det nya objektet.
            </span>
            <div className='mt-4 overflow-x-auto rounded-md border'>
              <Table>
                <TableHeader className='w-full rounded-t-md'>
                  <TableRow>
                    {['Namn', 'Nytt värde', 'Tidigare värde'].map((header) => (
                      <TableHead
                        key={header}
                        className='whitespace-nowrap rounded-t-md py-2 text-left text-sm font-normal'
                      >
                        {header}
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody className='rounded-b-md'>
                  {rows?.map((row) => {
                    // Remove
                    return (
                      <TableRow
                        className='rounded-b-md last:border-b-0'
                        key={row?.[0]}
                      >
                        {row?.map((val, idx) => {
                          return (
                            <TableCell
                              className={cn('truncate whitespace-nowrap ', {
                                'font-medium': idx === 0,
                              })}
                              key={String(val) + idx}
                            >
                              {val && val !== 'null' ? val : '-'}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
            {config.modelName === 'monitoringconfiguration' && (
              <ApprovingPartyReplacements
                data={
                  replacementslist.data as ApprovingPartyMonitoringConfiguration
                }
                isPending={replacementslist.pending}
              />
            )}
          </div>
        </div>
        <DialogFooter>
          <Button onClick={() => closeModal()} variant={'default'}>
            Avbryt
          </Button>
          <Button
            onClick={() =>
              archiveInstance({
                id: String(instance?.id),
                body: { archived: true } as unknown as Instance,
              })
            }
            variant={'destructive'}
          >
            Arkivera och ersätt
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export const getArchiveInstanceModalContent = ({
  ...props
}: ArchiveInstanceModalProps<BaseInstance>): ModalContent => {
  return {
    body: <ArchiveInstanceModal {...props} />,
    externalModalHandling: true,
  };
};
