'use client';

import { Badge } from '@/components/ui/badge';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { TagConfig, type Tag } from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './relation-item-map';

export const TagItem: RelationFieldProps<Tag> = {
  nested: getRelationFieldNames(TagConfig),
  filters: [],
  columns: null,
  renderItem: (item) => ({
    title: item?.name,
    badge: (
      <Badge
        className='flex cursor-default items-center gap-2 transition-colors hover:bg-muted'
        variant='outline'
      >
        <div
          className='rounded-full p-1.5'
          style={{
            backgroundColor: item?.colorCode?.startsWith('#')
              ? item.colorCode
              : `#${item?.colorCode}`,
          }}
        />
        <div className='flex flex-col py-0.5'>
          <span className='whitespace-nowrap font-medium leading-none'>
            {item?.name}
          </span>
        </div>
      </Badge>
    ),
    descriptionItems: (
      <span className='font-medium'>
        {item.customId ?? 'Inget objekts-id angivet'}
      </span>
    ),
  }),
};
