 import type { File } from '@pigello/pigello-matrix';

import { FileConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, mutateGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { mutateGenericInstance } from '@/requests/mutating';

export const mutateFile = async (
  options: mutateGenericInstanceProps<File>
) => {
  return mutateGenericInstance<File>({
    ...options,
    config: FileConfig,
  });
};

export const useMutateFile = (
  options: UseMutationOptions<File, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useMutateGenericInstance<File>(mutateFile, options, 'file');