import { DropdownList } from '@/app/dashboard/components/dropdown-list';
import { useConfig } from '@/hooks/useConfig';
import { Urls } from '@/lib/urls';
import type { ModelName } from '@pigello/pigello-matrix';

export function OriginatesFromObject({
  id,
  modelName,
}: {
  id: string;
  modelName: ModelName;
}) {
  const { config, isLoading } = useConfig(modelName);
  if (isLoading || !config) {
    return null;
  }
  return (
    <DropdownList
      ids={[id]}
      initialData={{ id }}
      title={config.verboseName}
      detailModalLinkUrl={() => Urls.detail?.contract.overview(modelName, id)}
      config={config}
      fields={['customId']}
    />
  );
}
