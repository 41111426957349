import type { useForm } from '@/hooks/useForm';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { isEqual } from 'lodash';
import type { SetValueConfig } from 'react-hook-form';

/* 
    This is a helper function used to prevent react-hook-forms
    built in function to go haywire with the types using Path.


    example usage: 

    setFormValue(form, 'whileActiveDiscountPercentage', null, {
        shouldDirty: true,
    });

*/

export default function setFormValue<
  Instance extends BaseInstance = BaseInstance,
  Attribute extends keyof Instance = keyof Instance,
>(
  form: ReturnType<typeof useForm<Instance>>,
  attribute: Attribute,
  value: Instance[Attribute] | null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  options?: SetValueConfig
) {
  const values = form.getValues();
  try {
    //@ts-expect-error eternal path error
    form.setValue(attribute, value, {
      shouldDirty: !isEqual(values[attribute], value),
    });
  } catch (e) {
    console.error(e);
  }
}
