'use client';

import EditTag from '@/components/form/tag/edit-tag';
import { Badge } from '@/components/ui/badge';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useDeleteTagUsage } from '@/config/annotating/tagusage/delete';
import { useManyPerms } from '@/hooks/usePerms';
import { cn } from '@/lib/utils';
import { EllipsisHorizontalIcon } from '@heroicons/react/16/solid';
import {
  TagUsageConfig,
  type ModelName,
  type TagUsage,
} from '@pigello/pigello-matrix';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'sonner';

export default function TagDropdown({
  tags,
  modelName,
}: {
  tags: TagUsage[];
  modelName: ModelName;
}) {
  const [tag, setTag] = useState<TagUsage | null>(null);
  const { perms } = useManyPerms([TagUsageConfig.modelName, modelName]);
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const deleteTag = useDeleteTagUsage({});

  const handleDeleteTag = (tagId: string) => {
    toast.promise(deleteTag.mutateAsync({ id: tagId }), {
      loading: 'Raderar tagg...',
      success: () => {
        queryClient.invalidateQueries({
          queryKey: [modelName],
        });
        return 'Taggen har tagits bort';
      },
      error: 'Kunde inte ta bort taggen',
    });
  };

  const canDelete = perms.every((perm) => perm.canDelete);
  const canUpdate = perms.every((perm) => perm.canUpdate);
  const canView = perms.every((perm) => perm.canView);

  if (!canView) {
    return '-';
  }

  return (
    <>
      {tag && (
        <EditTag
          tag={tag}
          closeFunction={() => {
            setOpen(false);
            setTag(null);
          }}
          modelName={modelName}
        />
      )}
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger>
          <Badge
            className='flex cursor-default items-center transition-colors hover:bg-muted'
            variant='outline'
          >
            {tags?.slice(0, 3).map((tag) => (
              <div
                key={tag.id + tag.tag?.id}
                className='-ml-1 rounded-full border border-white p-1'
                style={{
                  backgroundColor: tag.tag?.colorCode?.startsWith('#')
                    ? tag.tag.colorCode
                    : `#${tag.tag?.colorCode}`,
                }}
              />
            ))}
            <span className='ml-1 flex items-center whitespace-nowrap'>
              {tags?.length} taggar{' '}
              <EllipsisHorizontalIcon className='ml-1 size-4' />
            </span>
          </Badge>
        </DropdownMenuTrigger>
        <DropdownMenuContent className='max-h-60 min-w-[210px] overflow-y-auto p-2'>
          {tags?.map((tag) => (
            <DropdownMenuSub key={tag?.id}>
              <DropdownMenuSubTrigger
                onSelect={(e) => e.stopPropagation()}
                disabled={!canDelete && !canUpdate}
                className={cn(
                  !canDelete && !canUpdate && 'pointer-events-none opacity-50'
                )}
              >
                <div
                  className='rounded-full p-1.5'
                  style={{
                    backgroundColor: tag?.tag?.colorCode?.startsWith('#')
                      ? tag?.tag?.colorCode
                      : `#${tag?.tag?.colorCode}`,
                  }}
                />
                <div className='mx-2 flex flex-col'>
                  <span className='whitespace-nowrap text-sm'>
                    {tag?.tag?.name}
                  </span>
                  <span className='text-xs text-foreground/80'>
                    {tag?.startDate || tag?.endDate
                      ? `${tag?.startDate || 'Från start'} - ${
                          tag?.endDate || 'Tillsvidare'
                        }`
                      : 'Tillsvidare'}
                  </span>
                </div>
              </DropdownMenuSubTrigger>

              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {canUpdate && (
                    <DropdownMenuItem
                      onSelect={(e) => {
                        e.stopPropagation();
                        setTag(tag);
                      }}
                    >
                      Lägg till tidsintervall
                    </DropdownMenuItem>
                  )}
                  {canDelete && (
                    <DropdownMenuItem
                      onSelect={(e) => {
                        e.stopPropagation();
                        handleDeleteTag(tag.id);
                      }}
                    >
                      Ta bort tagg
                    </DropdownMenuItem>
                  )}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
