 import type { Note } from '@pigello/pigello-matrix';

import { NoteConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, mutateGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { mutateGenericInstance } from '@/requests/mutating';

export const mutateNote = async (
  options: mutateGenericInstanceProps<Note>
) => {
  return mutateGenericInstance<Note>({
    ...options,
    config: NoteConfig,
  });
};

export const useMutateNote = (
  options: UseMutationOptions<Note, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useMutateGenericInstance<Note>(mutateNote, options, 'note');