import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ChevronDownIcon, PencilIcon } from '@heroicons/react/16/solid';
import type {
  ArticleCategory,
  RelationalFieldValue,
} from '@pigello/pigello-matrix';
import { useState } from 'react';
import { ArticleCategoryForm } from '../categories/category-create-form';

export function CategoryEditDropdown({
  category,
}: {
  category: RelationalFieldValue<ArticleCategory>;
}) {
  const [categoryId, setCategoryId] = useState<string | undefined>();
  if (!category) {
    return '-';
  }
  return (
    <>
      {categoryId && (
        <ArticleCategoryForm
          categoryId={categoryId}
          closeFunction={() => {
            setCategoryId(undefined);
          }}
        />
      )}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className='gap-2 whitespace-nowrap transition-all [&[data-state=open]>svg]:rotate-180'
            variant={'ghost'}
            size={'icon-sm'}
          >
            <span>{category.name}</span>
            <ChevronDownIcon className={'size-4'} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onSelect={() => setCategoryId(category.id)}>
            <PencilIcon className='mr-2 size-4' /> Redigera kategori
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
