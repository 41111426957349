'use client';

import type { ColumnDef } from '@tanstack/react-table';

import { Checkbox } from '@/components/ui/checkbox';

import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { StatusBadge } from '@/components/ui/statusBadge';

import { TableActions } from '@/components/data-table/table-actions';
import { TableTags } from '@/components/data-table/table-tags';
import { toReadableDate } from '@/lib/dates';
import { Urls } from '@/lib/urls';
import { toSqm } from '@/lib/utils';
import {
  OutdoorAreaConfig,
  OutdoorSectionConfig,
  TenantConfig,
  type OutdoorSection,
  type Tenant,
} from '@pigello/pigello-matrix';
import { DropdownList } from '../components/dropdown-list';

export const columns: ColumnDef<OutdoorSection>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'status',
    meta: {
      columnVisibilityName: OutdoorSectionConfig.fields.status.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => <StatusBadge status={row.original.status} />,
  },
  {
    accessorKey: 'customId',
    meta: {
      columnVisibilityName: OutdoorSectionConfig.fields.customId.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.customId ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'popularName',
    meta: {
      columnVisibilityName: OutdoorSectionConfig.fields.popularName.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.popularName}</div>
    ),
  },

  {
    accessorKey: 'created_at',
    meta: {
      columnVisibilityName: OutdoorSectionConfig.fields.createdAt.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.createdAt)}
      </div>
    ),
  },
  {
    accessorKey: 'category',
    meta: {
      columnVisibilityName: OutdoorSectionConfig.fields.category.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {OutdoorSectionConfig.fields.category.choices?.find(
          (c) => c.v == row.original.category
        )?.d ?? '-'}
      </div>
    ),
  },
  {
    accessorKey: 'outdoorArea',
    enableSorting: false,
    meta: {
      columnVisibilityName: OutdoorSectionConfig.fields.outdoorArea.verboseName,
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={OutdoorSectionConfig.fields.outdoorArea.verboseName}
      />
    ),
    cell: ({ row }) => (
      <DropdownList
        ids={[row.original.outdoorArea?.id]}
        initialData={row.original.outdoorArea}
        detailModalLinkUrl={(id) => Urls.detail.outdoorarea.overview(id)}
        config={OutdoorAreaConfig}
        fields={['name']}
      />
    ),
  },
  {
    accessorKey: 'usableArea',
    meta: {
      columnVisibilityName: OutdoorSectionConfig.fields.usableArea.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => <span>{toSqm(row.original.usableArea)}</span>,
  },

  {
    accessorKey: 'tenant',
    enableSorting: false,
    meta: {
      columnVisibilityName: OutdoorSectionConfig.fields.tenant.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        ids={[row.original.tenant?.id]}
        initialData={row.original.tenant}
        detailModalLinkUrl={(id) => Urls.detail.tenant.overview(id)}
        config={TenantConfig}
        fields={['communicationName']}
      />
    ),
  },
  {
    accessorKey: 'biTenants',
    enableSorting: false,
    meta: {
      columnVisibilityName: OutdoorSectionConfig.fields.biTenants.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList<Tenant>
        ids={row.original.biTenants?.map((t) => t.id) ?? []}
        initialData={row.original.biTenants?.[0]}
        detailModalLinkUrl={(id) => Urls.detail.tenant.overview(id)}
        config={TenantConfig}
        fields={['communicationName']}
      />
    ),
  },

  {
    accessorKey: 'tags',
    meta: {
      columnVisibilityName: OutdoorSectionConfig.fields.tags.verboseName,
    },
    header: 'Taggar',
    cell: ({ row }) => (
      <span className='flex gap-2'>
        <TableTags
          tags={row.original.tags}
          modelName={OutdoorSectionConfig.modelName}
        />
      </span>
    ),
  },
  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <TableActions
        instance={row.original}
        config={OutdoorSectionConfig}
        title={row.original.popularName}
      />
    ),
  },
];
