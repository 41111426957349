import { Badge } from '@/components/ui/badge';
import type { InvoicePayment } from '@pigello/pigello-matrix';

export function PaymentStatusBadge({ payment }: { payment: InvoicePayment }) {
  const user = payment.tenant || payment.subTenant || payment.organizationUser;

  const isSystemMatch =
    !!payment?.invoice?.id &&
    !!payment?.matchedDate &&
    (!!payment.primaryMatchMethod || !!payment.secondaryMatchMethod);

  const isAnnulled = payment?.annulled;

  const isUnMatched =
    !payment.invoice?.id &&
    !payment.debtInvoice?.id &&
    ((!!user?.id && !payment.originalInvoice?.id) ||
      (!user?.id && !!payment.originalInvoice?.id));

  const isUnHandled =
    !payment.invoice?.id &&
    !payment.debtInvoice?.id &&
    !user?.id &&
    !payment.originalInvoice?.id;

  const isOverpayment =
    !payment.invoice?.id &&
    !payment.debtInvoice?.id &&
    !!user?.id &&
    !!payment.originalInvoice?.id;

  const isDistributed = !!payment.invoice || !!payment.debtInvoice;

  if (isAnnulled) {
    return <Badge variant='purple'>Borttagen</Badge>;
  }

  if (isUnMatched) {
    return <Badge variant='destructive'>Ej matchad</Badge>;
  }

  if (isUnHandled) {
    return <Badge variant='destructive'>Ej hanterad</Badge>;
  }

  if (isOverpayment) {
    return <Badge variant='yellow'>Överbetalning</Badge>;
  }

  if (isDistributed) {
    return isSystemMatch ? (
      <Badge variant='green'>Nyttjad, systemmatchad</Badge>
    ) : (
      <Badge variant='green'>Nyttjad, manuell</Badge>
    );
  }
  console.error('Unknown payment status', payment.id);
  return <Badge variant={'cyan'}>Ej matchad, okänd</Badge>;
}
