import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { buttonVariants } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Label } from '@/components/ui/label';
import { MultiSelectInput } from '@/components/ui/multi-select-input';
import type { useForm } from '@/hooks/useForm';
import type { DynamicField } from '@/hooks/useForm/types';
import { cn } from '@/lib/utils';
import {
  InformationCircleIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import {
  ApartmentConfig,
  BrfApartmentConfig,
  IndustrialPremisesConfig,
  OutdoorSectionConfig,
  ParkingSpotConfig,
  type BaseInstance,
} from '@pigello/pigello-matrix';
import { useEffect, useState } from 'react';
import { useWatch, type Path, type PathValue } from 'react-hook-form';
import type { FieldProps } from '../types';
import { DescriptionTooltip } from './description-tooltip';

type Category =
  | 'apartmentCategories'
  | 'industrialPremisesCategories'
  | 'parkingSpotCategories'
  | 'outdoorSectionCategories'
  | 'brfApartmentCategories';

const choicesMap: Record<
  Category,
  { v: string | number; d: string }[] | undefined
> = {
  apartmentCategories: ApartmentConfig.fields.category.choices,
  industrialPremisesCategories:
    IndustrialPremisesConfig.fields.category.choices,
  parkingSpotCategories: ParkingSpotConfig.fields.category.choices,
  outdoorSectionCategories: OutdoorSectionConfig.fields.category.choices,
  brfApartmentCategories: BrfApartmentConfig.fields.category.choices,
};

type FormFields<Instance extends BaseInstance> = {
  formFields: DynamicField<Instance>[keyof Instance][];
  form: ReturnType<typeof useForm<Instance>>;
};

export function CategoriesField<Instance extends BaseInstance>({
  formFields,
  name,
  label,
  placeholder,
  description,
  className,
  disabled,
  form,
  canHandleField,
}: Omit<FieldProps<Instance>, 'formField'> & FormFields<Instance>) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Begränsa till kategorier</CardTitle>
      </CardHeader>
      <CardContent className='grid gap-2'>
        {formFields?.map((formField) => (
          <CategoriesFieldItem
            key={formField.name.toString()}
            {...{
              form,
              formField,
              name,
              label,
              placeholder,
              description,
              className,
              disabled,
              formFields,
              canHandleField,
            }}
          />
        ))}
      </CardContent>
    </Card>
  );
}

function CategoriesFieldItem<Instance extends BaseInstance>({
  formField,
  label,
  placeholder,
  description,
  className,
  disabled,
  formFields,
  form,
  canHandleField,
}: FieldProps<Instance> & FormFields<Instance>) {
  const fieldValue = useWatch({
    name: formField.name.toString(),
  }) as string[] | null;
  const [selected, setSelected] = useState<string[]>([]);
  const [renderKey, setRenderKey] = useState(window.crypto.randomUUID());
  const choiceField = formField.config?.fields[formField.name];

  useEffect(() => {
    if (formField.control._defaultValues[formField.name]) {
      setSelected(formField.control._defaultValues[formField.name]);
    }
  }, [formField.control._defaultValues, formField.name]);

  if (!('choices' in choiceField)) {
    return null;
  }

  const { errors } = form.formState;

  const defaultValues =
    choiceField.choices?.map(({ v, d }) => ({
      v,
      d,
    })) ?? [];

  const handleSetValues = () => {
    const otherFields = formFields
      .filter((f) => f.name !== formField.name)
      .map((f) => {
        const values = form.getValues(f.name as Path<Instance>);
        return values;
      });
    const isEveryOtherCategoryNull = otherFields.every((c) => c === null);
    for (const field of formFields) {
      const name = field.name as Path<Instance>;
      const otherValue = form.getValues(name);
      if (selected?.length > 0) {
        if (otherValue === null && field.name !== formField.name) {
          form.setValue(name, [] as PathValue<Instance, Path<Instance>>, {
            shouldDirty: true,
          });
        }
      } else if (otherFields.flat().length === 0 && selected.length === 0) {
        form.setValue(name, null as PathValue<Instance, Path<Instance>>, {
          shouldDirty: true,
        });
      } else if (isEveryOtherCategoryNull && selected.length === 0) {
        form.setValue(name, null as PathValue<Instance, Path<Instance>>, {
          shouldDirty: true,
        });
      }
    }
  };

  const handleResetField = () => {
    setSelected(formField.control._defaultValues[formField.name]);
    setRenderKey(window.crypto.randomUUID());
  };

  const error = errors[formField.name]?.message;

  return (
    <AlertDialog key={formField.name.toString()}>
      <div className='flex items-center justify-between'>
        <div className='grid'>
          <Label
            className={cn(typeof error === 'string' && 'text-destructive')}
          >
            {label ?? formField.label}
          </Label>
          {typeof error === 'string' && (
            <p className={cn('text-sm font-medium text-destructive')}>
              {error}
            </p>
          )}
        </div>
        <AlertDialogTrigger
          className={cn(buttonVariants({ variant: 'outline' }))}
        >
          {getChoicesLabel(fieldValue, formField.name as Category, form)}
        </AlertDialogTrigger>
      </div>
      <AlertDialogContent onEscapeKeyDown={(e) => e.preventDefault()}>
        <Alert>
          <InformationCircleIcon className='size-4' />
          <AlertTitle>
            Begränsa inställningen till specifika kategorier
          </AlertTitle>
          <AlertDescription>
            {formField.description ?? description ?? ''}
          </AlertDescription>
        </Alert>
        <FormField
          key={renderKey}
          control={formField.control}
          rules={{
            required: formField.required && 'Detta fält är obligatoriskt',
          }}
          name={formField.name as Path<Instance>}
          render={({ field }) => (
            <FormItem className={className}>
              <div className='flex items-center justify-between'>
                <FormLabel>
                  {label ?? formField.label}{' '}
                  {formField.required && <span>*</span>}
                </FormLabel>
                {(description || formField.description) && (
                  <DescriptionTooltip
                    description={description ?? formField.description}
                  />
                )}
              </div>
              <FormControl>
                <div className='w-full'>
                  <MultiSelectInput
                    placeholder={`Välj ${placeholder ?? formField.label}`}
                    onSelected={(items) => {
                      const values = items.map((i) => i.v.toString());
                      setSelected(values);
                    }}
                    values={
                      selected?.map((val: string) => ({
                        v: val,
                        d: defaultValues?.find(
                          (d: { v: string }) => d.v === val
                        )?.d,
                      })) ?? []
                    }
                    defaultValues={defaultValues}
                    disabled={disabled ?? field.disabled ?? !canHandleField}
                  />
                  <div className='mt-0 flex justify-end'>
                    {!!selected?.length && (
                      <button
                        disabled={disabled ?? field.disabled ?? !canHandleField}
                        type='button'
                        onClick={() => {
                          setSelected([]);
                          setRenderKey(window.crypto.randomUUID());
                        }}
                        className='mr-2 flex items-center text-end text-xs leading-none'
                      >
                        <XMarkIcon className='mr-1 size-3' />
                        Rensa valda
                      </button>
                    )}
                    <button
                      disabled={disabled ?? field.disabled}
                      type='button'
                      onClick={() => {
                        setSelected(defaultValues.map((i) => i.v.toString()));
                        setRenderKey(window.crypto.randomUUID());
                      }}
                      className='flex items-center text-end text-xs leading-none'
                    >
                      <PlusIcon className='mr-1 size-3' />
                      Välj alla
                    </button>
                  </div>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => handleResetField()}>
            Avbryt
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={
              (fieldValue === null || fieldValue?.length === 0) &&
              (selected?.length === 0 || selected === null)
            }
            onClick={() => {
              form.setValue(
                formField.name as Path<Instance>,
                selected as PathValue<Instance, Path<Instance>>,
                {
                  shouldDirty: true,
                }
              );
              handleSetValues();
            }}
          >
            Spara
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function getChoicesLabel<Instance extends BaseInstance = BaseInstance>(
  value: string[] | null,
  key: Category,
  form: ReturnType<typeof useForm<Instance>>
) {
  const isEveryOtherCategoryEmpty = Object.keys(choicesMap)
    .map((c) => {
      return form.getValues(c as Path<Instance>);
    })
    .flat()
    .every((c) => {
      return c?.length === 0;
    });
  const isEveryCategoryNull = Object.keys(choicesMap).every((c) => {
    return form.getValues(c as Path<Instance>) === null;
  });
  if (
    isEveryCategoryNull ||
    (isEveryOtherCategoryEmpty && value?.length === choicesMap[key]?.length)
  ) {
    return 'Gäller för alla kategorier';
  }
  if (value === null) {
    return 'Inga kategorier';
  }
  if (value.length === 0) {
    return 'Inga kategorier';
  }
  if (value.length === 1) {
    return choicesMap[key]?.find((c) => c.v === value[0])?.d;
  }
  return `${value.length} kategorier valda`;
}
