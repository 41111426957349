'use client';

import SelectTable, {
  type RenderItemProps,
} from '@/components/data-table/custom-tables/select-table';
import { Card } from '@/components/ui/card';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { toReadableAddress } from '@/lib/utils';
import { AtSymbolIcon, MapPinIcon } from '@heroicons/react/16/solid';
import {
  InvoiceRecipientConfig,
  type InvoiceRecipient,
} from '@pigello/pigello-matrix';
import type { ReactNode } from 'react';
import { getActualRecipientFields } from '../../utils';

const SelectRecipientItem = ({
  item,
  onSelected,
}: RenderItemProps<InvoiceRecipient>) => {
  const actualRecipient = getActualRecipientFields(item);

  return (
    <Card
      className='group my-2 cursor-pointer transition-colors hover:bg-muted'
      key={actualRecipient.id}
      onClick={() => onSelected(item?.id)}
    >
      <div className='flex items-center justify-between gap-2'>
        <h3 className='truncate'>{actualRecipient.name ?? '-'}</h3>
      </div>
      <div className='flex items-center gap-2'>
        <MapPinIcon className='size-4' />
        <p className='max-w-[370px] truncate text-sm'>
          {toReadableAddress(actualRecipient.address) ??
            'Ingen address angiven'}
        </p>
      </div>
      <div className='flex items-center gap-2'>
        <AtSymbolIcon className='size-4' />
        <p className='max-w-[370px] truncate text-sm'>
          {actualRecipient.email ?? 'Ingen e-post angiven'}
        </p>
      </div>
    </Card>
  );
};

const SelectRecipientPopover = ({
  children,
  ids,
  onSelected,
}: {
  children: ReactNode;
  ids: string[];
  onSelected: (id: string) => void;
}) => (
  <Popover modal>
    <PopoverTrigger asChild>{children}</PopoverTrigger>
    <PopoverContent
      className='max-h-[300px] w-[319px] overflow-y-auto'
      avoidCollisions={false}
    >
      <SelectTable<InvoiceRecipient>
        onSelected={onSelected}
        modelName={InvoiceRecipientConfig.modelName}
        queryParams={{
          filters: {
            id: { __in: ids.join(',') },
          },
        }}
        tableId='recipientselecttablesendinvoice'
        RenderItem={SelectRecipientItem}
        nested={['tenant']}
        className='h-fit'
      />
    </PopoverContent>
  </Popover>
);

export default SelectRecipientPopover;
