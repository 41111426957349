import { useCallback, useEffect } from 'react';

interface Props {
  isActive: boolean;
  key: string;
  callback: () => void;
}

export const useShortcut = ({ isActive, key, callback }: Props) => {
  const onEscape = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.key === key &&
        isActive &&
        event.target &&
        'tagName' in event.target &&
        event.target.tagName == 'BODY'
      ) {
        callback();
      }
    },
    [isActive, key, callback]
  );

  useEffect(() => {
    document.addEventListener('keydown', onEscape);

    return () => {
      document.removeEventListener('keydown', onEscape);
    };
  }, [onEscape, callback]);

  return onEscape;
};
