 import type { IndexationSettingUsage } from '@pigello/pigello-matrix';

import { IndexationSettingUsageConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, mutateGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { mutateGenericInstance } from '@/requests/mutating';

export const mutateIndexationSettingUsage = async (
  options: mutateGenericInstanceProps<IndexationSettingUsage>
) => {
  return mutateGenericInstance<IndexationSettingUsage>({
    ...options,
    config: IndexationSettingUsageConfig,
  });
};

export const useMutateIndexationSettingUsage = (
  options: UseMutationOptions<IndexationSettingUsage, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useMutateGenericInstance<IndexationSettingUsage>(mutateIndexationSettingUsage, options, 'indexationsettingusage');