import {
  defaultRelationFilter,
  defaultSelectFilter,
  defaultStringFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import {
  CommunityConfig,
  CompanyConfig,
  RealEstateConfig,
} from '@pigello/pigello-matrix';
import baseFilters, { baseTagFilter } from '../../filters';

const realEstateFilters: IFilter[] = [
  {
    title: RealEstateConfig.fields.name.verboseName,
    externalFieldName: RealEstateConfig.fields.name.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: RealEstateConfig.fields.customId.verboseName,
    externalFieldName: RealEstateConfig.fields.customId.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: RealEstateConfig.fields.community.verboseName,
    externalFieldName: RealEstateConfig.fields.community.externalFieldName,
    relationModelName: CommunityConfig.modelName,
    ...defaultRelationFilter,
  },
  {
    title: RealEstateConfig.fields.ownedBy.verboseName,
    externalFieldName: RealEstateConfig.fields.ownedBy.externalFieldName,
    relationModelName: CompanyConfig.modelName,
    ...defaultRelationFilter,
  },
  {
    title: RealEstateConfig.fields.typeCode.verboseName,
    externalFieldName: RealEstateConfig.fields.typeCode.externalFieldName,
    choices: RealEstateConfig.fields.typeCode.choices,
    ...defaultSelectFilter,
  },
  {
    title: `${RealEstateConfig.fields.mainAddress.verboseName} - Gatunamn`,
    externalFieldName: `${RealEstateConfig.fields.mainAddress.externalFieldName}__street`,
    ...defaultStringFilter,
  },
  {
    title: `${RealEstateConfig.fields.mainAddress.verboseName} - Stad`,
    externalFieldName: `${RealEstateConfig.fields.mainAddress.externalFieldName}__city`,
    ...defaultStringFilter,
  },
  {
    title: `${RealEstateConfig.fields.mainAddress.verboseName} - Gatunummer`,
    externalFieldName: `${RealEstateConfig.fields.mainAddress.externalFieldName}__street_number`,
    ...defaultStringFilter,
  },
  {
    title: `${RealEstateConfig.fields.mainAddress.verboseName} - Land`,
    externalFieldName: `${RealEstateConfig.fields.mainAddress.externalFieldName}__country`,
    ...defaultStringFilter,
  },

  ...baseTagFilter,
  ...baseFilters,
];
export default realEstateFilters;
