'use client';
import { useDeleteTagUsage } from '@/config/annotating/tagusage/delete';
import { usePerms } from '@/hooks/usePerms';
import { EllipsisHorizontalIcon } from '@heroicons/react/16/solid';
import type { ModelName, TagUsage } from '@pigello/pigello-matrix';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'sonner';
import EditTag from '../form/tag/edit-tag';
import { Badge } from './badge';
import { Button } from './button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './dropdown-menu';

export function Tag({
  tag,
  modelName,
}: {
  tag: TagUsage;
  modelName: ModelName;
}) {
  const [open, setOpen] = useState(false);
  const [tagEdit, setTagEdit] = useState<TagUsage | null>(null);
  const queryClient = useQueryClient();
  const deleteTag = useDeleteTagUsage({});
  const { canDelete: canDeleteTag, canUpdate: canUpdateTag } =
    usePerms('tagusage');
  const { canUpdate } = usePerms(modelName);

  const handleDeleteTag = (tagId: string) => {
    toast.promise(deleteTag.mutateAsync({ id: tagId }), {
      loading: 'Raderar tagg...',
      success: () => {
        queryClient.invalidateQueries({ queryKey: [modelName] });
        return 'Taggen har tagits bort';
      },
      error: 'Kunde inte ta bort taggen',
    });
  };

  return (
    <>
      {tagEdit && (
        <EditTag
          tag={tagEdit}
          closeFunction={() => {
            setTagEdit(null);
            setOpen(false);
          }}
          modelName={modelName}
        />
      )}
      <Badge
        className='flex cursor-default items-center gap-2 transition-colors hover:bg-muted'
        variant='outline'
      >
        <div
          className='rounded-full p-1.5'
          style={{
            backgroundColor: tag?.tag?.colorCode?.startsWith('#')
              ? tag.tag.colorCode
              : `#${tag?.tag?.colorCode}`,
          }}
        />
        <div className='flex flex-col py-0.5'>
          <span className='whitespace-nowrap font-medium leading-none'>
            {tag?.tag?.name}
          </span>
          <span className='mt-0.5 text-[10px] font-normal leading-none text-foreground/80'>
            {tag?.startDate || tag?.endDate
              ? `${tag?.startDate || 'Från start'} - ${
                  tag?.endDate || 'Tillsvidare'
                }`
              : 'Tillsvidare'}
          </span>
        </div>
        {canUpdate && (canDeleteTag || canUpdateTag) && (
          <DropdownMenu open={open} onOpenChange={setOpen}>
            <DropdownMenuTrigger asChild>
              <Button
                variant={'ghost'}
                size={'icon-sm'}
                className='size-5 min-h-[20px] rounded-full'
              >
                <EllipsisHorizontalIcon className='size-4 min-h-[16px] min-w-[16px] text-foreground' />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align='start'>
              {canUpdateTag && (
                <DropdownMenuItem onClick={() => setTagEdit(tag)}>
                  Lägg till tidsintervall
                </DropdownMenuItem>
              )}
              {canDeleteTag && (
                <DropdownMenuItem onClick={() => handleDeleteTag(tag.id)}>
                  Ta bort tagg
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </Badge>
    </>
  );
}
