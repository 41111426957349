import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { TableActions } from '@/components/data-table/table-actions';
import { TableTags } from '@/components/data-table/table-tags';
import { Badge } from '@/components/ui/badge';
import { toReadableDate } from '@/lib/dates';
import type { IndexationTable } from '@pigello/pigello-matrix';
import { IndexationTableConfig } from '@pigello/pigello-matrix';
import type { ColumnDef } from '@tanstack/react-table';

export const columns: ColumnDef<IndexationTable>[] = [
  {
    accessorKey: 'name',
    meta: {
      columnVisibilityName: IndexationTableConfig.fields.name.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.name}</div>
    ),
  },
  {
    accessorKey: 'customId',
    meta: {
      columnVisibilityName: IndexationTableConfig.fields.customId.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.customId ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'useLastKnownValueAsFallback',
    meta: {
      columnVisibilityName:
        IndexationTableConfig.fields.useLastKnownValueAsFallback.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <Badge
        variant={
          row.original.useLastKnownValueAsFallback ? 'green' : 'destructive'
        }
      >
        {row.original.useLastKnownValueAsFallback ? 'Ja' : 'Nej'}
      </Badge>
    ),
  },

  {
    accessorKey: 'createdAt',
    meta: {
      columnVisibilityName: IndexationTableConfig.fields.createdAt.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.createdAt)}
      </div>
    ),
  },
  {
    accessorKey: 'tags',
    enableSorting: false,
    meta: {
      columnVisibilityName: IndexationTableConfig.fields.tags.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='flex gap-2'>
        <TableTags
          tags={row.original.tags}
          modelName={IndexationTableConfig.modelName}
        />
      </span>
    ),
  },
  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <TableActions
        disableEdit
        instance={row.original}
        config={IndexationTableConfig}
        title={row.original.name}
      />
    ),
  },
];
