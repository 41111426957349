'use client';

import { useShortcut } from '@/hooks/use-shortcut';
import { useTableFilter } from '@/hooks/use-table-filter';
import { ObjectKeys, cn } from '@/lib/utils';
import { PlusCircleIcon, PlusIcon } from '@heroicons/react/16/solid';
import { cloneDeep } from 'lodash';
import { useMemo, useState } from 'react';
import { Button } from '../ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';
import {
  ENUM_FILTER,
  NULL_FILTER,
  OPERATORS,
  RELATION_FILTER,
  type IAppliedFilter,
  type IFilter,
  type IFilterValue,
  type IOperator,
} from './constants';
import CurrentCreatingFilter from './current-creating';
import FilterItem from './filter-item';

export default function TableFilters({
  clientState,
  filters,
  tableId,
}: {
  clientState?: boolean;
  filters: IFilter[];
  tableId: string;
}) {
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [currentCreating, setCurrentCreating] = useState<IFilter | null>(null);
  const { filter, setTableFilter, lastUsedFilter } = useTableFilter({
    isClient: clientState,
    tableId,
  });

  useShortcut({
    isActive: !selectorOpen && !currentCreating,
    key: 'q',
    callback: () => {
      setTimeout(() => {
        // timeout to prevent command input to receiving Q text
        setSelectorOpen(true);
      }, 0);
    },
  });

  const memoizedCurrentCreating = useMemo(() => {
    return currentCreating;
  }, [currentCreating]);

  const createFilter = (val: unknown, explicitFilter?: IFilter) => {
    const filterToCreate = currentCreating || explicitFilter;
    if (!filterToCreate) return;
    setTableFilter({
      ...filter,
      [filterToCreate?.externalFieldName]: {
        ...(filter?.[filterToCreate.externalFieldName] ?? {}),
        [filterToCreate?.defaultOperator]: val,
      },
    });

    setCurrentCreating(null);
  };

  const appliedFilters = useMemo((): IAppliedFilter[] => {
    if (!filter || ObjectKeys(filter ?? {}).length === 0) return [];
    return ObjectKeys(filter ?? {})
      .filter((externalFieldName) => {
        const conf = filters.find(
          (f) => f.externalFieldName === externalFieldName
        );

        if (conf?.kind === ENUM_FILTER) return false;
        return true;
      })
      .reduce<IAppliedFilter[]>((acc, cur) => {
        const operatorVariants = ObjectKeys(filter[cur as string]).map(
          (operator) => {
            return {
              operator,
              value: filter[cur][operator] as string | number,
              externalFieldName: cur as string,
            };
          }
        );

        return [...acc, ...operatorVariants];
      }, []);
  }, [filter]);

  const updateFilterValue = (
    editedFilter: IAppliedFilter,
    newValue: IFilterValue
  ) => {
    if (newValue === '') {
      return removeFilter(editedFilter, editedFilter.operator);
    }

    setTableFilter({
      ...filter,
      [editedFilter.externalFieldName]: {
        ...(filter?.[editedFilter.externalFieldName] ?? {}),
        [editedFilter.operator]: newValue,
      },
    });
  };
  const updateFilterOperator = (
    editedFilter: IAppliedFilter,
    newOperator: IOperator
  ) => {
    setTableFilter({
      ...filter,
      [editedFilter.externalFieldName]: {
        ...(filter?.[editedFilter.externalFieldName] ?? {}),
        [editedFilter.operator]: undefined,
        [newOperator]: OPERATORS.ISNULL.includes(newOperator)
          ? true
          : typeof editedFilter.value === 'boolean'
            ? ''
            : editedFilter.value,
      },
    });
  };
  const removeFilter = (
    editedFilter: IAppliedFilter,
    deletedOperator: IOperator
  ) => {
    const newFilter = cloneDeep(filter);
    if (!newFilter) return;

    delete newFilter[editedFilter.externalFieldName][deletedOperator];

    if (ObjectKeys(newFilter[editedFilter.externalFieldName]).length === 0) {
      delete newFilter[editedFilter.externalFieldName];
    }

    if (ObjectKeys(newFilter ?? {}).length === 0) {
      setTableFilter(null);
    } else if (
      ObjectKeys(newFilter?.[editedFilter.externalFieldName] ?? {}).length > 0
    ) {
      setTableFilter(newFilter);
    } else {
      delete newFilter[editedFilter.externalFieldName];

      setTableFilter(newFilter);
    }
  };

  return (
    <>
      {appliedFilters?.length > 0 &&
        appliedFilters.map((appliedFilter) => {
          const filterConfig = filters.find(
            (f) => f.externalFieldName === appliedFilter.externalFieldName
          );

          if (!filterConfig) return null;
          if (appliedFilter.value === undefined) return null;

          return (
            <FilterItem
              key={`${appliedFilter.externalFieldName}${appliedFilter.operator}`}
              filterConfig={filterConfig}
              filter={appliedFilter}
              updateFilterOperator={(operator) =>
                updateFilterOperator(appliedFilter, operator)
              }
              updateFilterValue={(newVal) =>
                updateFilterValue(appliedFilter, newVal)
              }
              removeFilter={(operator) => removeFilter(appliedFilter, operator)}
            />
          );
        })}

      {memoizedCurrentCreating && (
        <CurrentCreatingFilter
          currentCreating={memoizedCurrentCreating}
          onDone={(val) => {
            if (
              (val !== false && !val) ||
              (memoizedCurrentCreating.kind === RELATION_FILTER &&
                Array.isArray(val) &&
                val.length === 0)
            ) {
              setCurrentCreating(null);
            } else {
              createFilter(val);
            }
          }}
        />
      )}
      <Tooltip>
        <Popover
          open={selectorOpen}
          onOpenChange={(open) => setSelectorOpen(open)}
        >
          <PopoverTrigger asChild>
            <TooltipTrigger asChild>
              <Button
                variant='outline'
                className={cn('rounded-full text-xs ', {
                  'border-0 p-0':
                    !!currentCreating || appliedFilters.length > 0,
                })}
                size={
                  currentCreating || appliedFilters.length > 0
                    ? 'icon-sm'
                    : 'default'
                }
              >
                {currentCreating || appliedFilters.length > 0
                  ? ''
                  : 'Lägg till filter'}

                <PlusCircleIcon
                  className={cn('size-6', {
                    'ml-2 size-4':
                      !currentCreating && appliedFilters.length === 0,
                  })}
                />
              </Button>
            </TooltipTrigger>
          </PopoverTrigger>
          <PopoverContent className='p-0'>
            <Command>
              <CommandInput placeholder='Sök bland filter...' />
              <CommandList>
                <CommandEmpty>Hittade inga filter.</CommandEmpty>
                <CommandGroup>
                  {filters
                    .filter((f) => f.kind !== ENUM_FILTER)
                    .map((filter) => (
                      <CommandItem
                        onSelect={() => {
                          setSelectorOpen(false);

                          if (filter.kind === NULL_FILTER) {
                            createFilter(true, filter);
                          } else {
                            setCurrentCreating(filter);
                          }
                        }}
                        key={filter.externalFieldName}
                        value={filter.title}
                      >
                        {filter.title}
                      </CommandItem>
                    ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>

        <TooltipContent className='max-w-[150px]'>
          <div className='text-sm font-medium'>Tips!</div>
          <div className='text-xs'>
            Tryck på &quot;Q&quot; på tangentbordet för att snabbt lägga till
            filter.
          </div>
        </TooltipContent>
      </Tooltip>

      {ObjectKeys(filter ?? {}).length === 0 &&
        ObjectKeys(lastUsedFilter ?? {}).length > 0 && (
          <Button
            onClick={() => {
              setTableFilter(lastUsedFilter);
            }}
            className='rounded-full text-xs'
            variant='secondary'
          >
            Använd senaste filtrering <PlusIcon className='ml-2 size-4' />
          </Button>
        )}
    </>
  );
}
