import { DynamicField } from '@/components/form/dynamic-field';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Form } from '@/components/ui/form';
import { useGetArticleCategory } from '@/config/accounting/articles/articlecategory/client';
import { useCreateArticleCategory } from '@/config/accounting/articles/articlecategory/create';
import { useMutateArticleCategory } from '@/config/accounting/articles/articlecategory/mutate';
import { useForm } from '@/hooks/useForm';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useToast } from '@/hooks/useToast';
import type { ErrorResponse } from '@/requests/types';
import { XMarkIcon } from '@heroicons/react/16/solid';
import {
  ArticleCategoryConfig,
  getCleanArticleCategory,
} from '@pigello/pigello-matrix';

export function ArticleCategoryForm({
  closeFunction,
  categoryId,
}: {
  closeFunction: VoidFunction;
  categoryId?: string;
}) {
  const { addToast } = useToast();
  const { data, isLoading } = useGetArticleCategory({
    id: categoryId,
    enabled: !!categoryId,
  });
  const form = useForm({
    config: ArticleCategoryConfig,
    isUpdate: !!categoryId,
    values: data,
    defaultValues: getCleanArticleCategory(),
  });

  const { isDirty } = form.formState;

  const { mutateAsync } = useCreateArticleCategory({});
  const { mutateAsync: patchAsync } = useMutateArticleCategory({});

  const onSubmit = () => {
    const data = form.getDirtyData();
    if (categoryId) {
      return addToast({
        type: 'promise',
        promise: patchAsync({
          id: categoryId,
          body: data,
        }),
        loading: 'Uppdaterar kategori...',
        success: () => {
          closeFunction();
          return 'Kategorin uppdaterades';
        },
        error: (error: ErrorResponse) => {
          const field = handleFormErrors(error, form);
          if (field) {
            return field;
          }
          return 'Kunde inte uppdatera kategorin';
        },
      });
    }
    return addToast({
      type: 'promise',
      promise: mutateAsync({
        body: data,
      }),
      loading: 'Skapar kategori...',
      success: () => {
        closeFunction();
        return 'Kategorin skapades';
      },
      error: (error: ErrorResponse) => {
        const field = handleFormErrors(error, form);
        if (field) {
          return field;
        }
        return 'Kunde inte skapa kategorin';
      },
    });
  };

  const closeAndClear = () => {
    form.reset();
    closeFunction();
  };
  return (
    <Dialog
      defaultOpen
      onOpenChange={() => {
        closeAndClear();
      }}
    >
      <DialogContent size='sm' className='p-0'>
        <DialogHeader>
          <DialogTitle>
            {categoryId ? 'Redigera kategori' : 'Skapa kategori'}
          </DialogTitle>
          <Button
            onClick={closeAndClear}
            variant='secondary'
            size='icon-sm'
            type='button'
          >
            <XMarkIcon className='size-4' />
          </Button>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className='grid gap-4 p-4'>
              <DynamicField
                isLoading={isLoading}
                formField={form.fields.name}
              />
              <DynamicField
                horizontal
                isLoading={isLoading}
                formField={form.fields.considerBaseRent}
              />
            </div>
            <DialogFooter>
              <Button
                disabled={isLoading}
                variant={'outline'}
                type='button'
                onClick={closeAndClear}
              >
                Avbryt
              </Button>
              <Button disabled={!isDirty} type='submit'>
                {categoryId ? 'Spara' : 'Skapa'}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
