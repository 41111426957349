import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useMutateTenant } from '@/config/accounts/users/customer/tenant/mutate';
import { useToast } from '@/hooks/useToast';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import type { ErrorResponse } from '@/requests/types';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { useState } from 'react';

export type AnonymizeModalResponse = Promise<boolean>;

export type AnonymizeModalContentData = {
  id: string;
  anonymizedName?: string;
};

interface Props extends GetModalContentProps {
  closeModal: (response: Awaited<AnonymizeModalResponse>) => void;
  contentData: AnonymizeModalContentData;
}

function AnonymizeTenantContent({ closeModal, contentData }: Props) {
  const [anonymizedName, setAnonymizedName] = useState(
    contentData.anonymizedName ?? ''
  );
  const { addToast } = useToast();
  const { mutate } = useMutateTenant({
    invalidateQueries: ['eventlog'],
    onSuccess: () => {
      addToast({
        title: 'Hyresgäst anonymiserad',
        type: 'success',
      });
      closeModal(true);
    },
    onError: (error: ErrorResponse) => {
      addToast({
        title: 'Något gick fel',
        message: `Error: ${error}`,
        type: 'error',
      });
    },
  });
  return (
    <>
      <Button
        className='absolute right-4 top-4'
        variant={'outline'}
        size={'icon'}
        onClick={() => closeModal(false)}
      >
        <XMarkIcon className='size-4' />
      </Button>
      <div className='grid gap-2'>
        <Label htmlFor='anonymized-name'>Anonymiserat namn</Label>
        <Input
          id='anonymized-name'
          value={anonymizedName}
          onChange={(e) => setAnonymizedName(e.target.value)}
          placeholder='Anonymiserat namn'
        />
        <div className='flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-4'>
          <Button
            disabled={!anonymizedName.trim()}
            onClick={() =>
              mutate({
                id: contentData.id,
                body: {
                  anonymized: true,
                  anonymizationName: anonymizedName,
                },
              })
            }
          >
            Bekräfta
          </Button>
          {!contentData.anonymizedName && (
            <Button
              onClick={() =>
                mutate({
                  id: contentData.id,
                  body: {
                    anonymized: true,
                  },
                })
              }
              variant={'outline'}
            >
              Anonymisera utan namn
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export const getAnonymizeModalContent = ({
  closeModal,
  contentData,
}: Props): ModalContent => {
  return {
    title: contentData.anonymizedName
      ? 'Bekräfta det anonymiserade namnet'
      : 'Sätt ett namn på anonymiserad hyresgäst',
    description:
      'Om inget namn anges kommer hyresgästen att anonymiseras utan namn och "Anonymiserad" kommer att visas istället.',
    body: (
      <AnonymizeTenantContent
        closeModal={closeModal}
        contentData={contentData}
      />
    ),
  };
};
