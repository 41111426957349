import {
  defaultBooleanFilter,
  defaultDateFilter,
  defaultEnumFilter,
  defaultNullFilter,
  defaultNumberFilter,
  defaultRelationFilter,
  defaultSelectFilter,
  defaultStringFilter,
  defaultTagFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import {
  ApartmentConfig,
  ApartmentContractRevenueRowConfig,
  ApartmentRevenueRowConfig,
  ArticleConfig,
  BuildingConfig,
  OrganizationUserConfig,
} from '@pigello/pigello-matrix';

const baseFilters: IFilter[] = [
  {
    title: 'Skapades',
    externalFieldName: 'created_at',
    ...defaultDateFilter,
  },
];

export const baseMonitoringFilters: IFilter[] = [
  {
    title: 'Typ',
    externalFieldName: 'mt_action_kind',
    choices: [
      { d: 'Skapades', v: 0 },
      { d: 'Uppdaterades', v: 1 },
      { d: 'Raderades', v: 2 },
    ],
    ...defaultSelectFilter,
  },
  {
    title: 'Motivering',
    externalFieldName: 'mt_motivation',
    ...defaultStringFilter,
  },
  {
    title: 'Förfrågades',
    externalFieldName: 'mt_added_time',
    ...defaultDateFilter,
  },
  {
    title: 'Förfrågades av användare',
    externalFieldName: 'mt_by_organization_user',
    relationModelName: OrganizationUserConfig.modelName,
    ...defaultRelationFilter,
  },
];

export const baseTagFilter: IFilter[] = [
  {
    title: 'Taggar',
    externalFieldName: 'tags',
    relationModelName: 'tag',
    ...defaultTagFilter,
  },
];

const SPACE_STATUS_FILTERS: IFilter['enumChoices'] = [
  {
    filters: [{ operator: '__in', value: '10,11,12' }],
    title: 'Vakant',
    badgeVariant: 'destructive',
  },
  {
    filters: [{ operator: '__in', value: '0,1,2,3,4,5,6,7,8' }],
    title: 'Uthyrd',
    badgeVariant: 'green',
  },

  {
    filters: [{ operator: '__in', value: '1' }],
    title: 'Uthyrd (Tillsvidare)',
    badgeVariant: 'green',
  },
  {
    filters: [{ operator: '__in', value: '2' }],
    title: 'Uthyrd (Tidsbestämt)',
    badgeVariant: 'green',
  },
  {
    filters: [{ operator: '__in', value: '3' }],
    title: 'Uthyrd (Pågående uppsägning för villkorsändring)',
    badgeVariant: 'yellow',
  },
  {
    filters: [{ operator: '__in', value: '4' }],
    title: 'Uthyrd (Pågående uppsägning för avflytt)',
    badgeVariant: 'yellow',
  },
  {
    filters: [{ operator: '__in', value: '5' }],
    title: 'Uthyrd (Uppsagt för villkorsändring)',
    badgeVariant: 'yellow',
  },
  {
    filters: [{ operator: '__in', value: '6' }],
    title: 'Uthyrd (Uppsagt för avflytt)',
    badgeVariant: 'yellow',
  },
  {
    filters: [{ operator: '__in', value: '7,8' }],
    title: 'Uthyrd (Utgående)',
    badgeVariant: 'yellow',
  },
  {
    filters: [{ operator: '__in', value: '9' }],
    title: 'Kommande',
    badgeVariant: 'blue',
  },
  {
    filters: [{ operator: '__in', value: '10' }],
    title: 'Kommande (Ej signerat)',
    badgeVariant: 'purple',
  },
];

export const baseSpaceStatusFilters: IFilter[] = [
  {
    title: ApartmentConfig.fields.status.verboseName,
    externalFieldName: ApartmentConfig.fields.status.externalFieldName,
    enumChoices: SPACE_STATUS_FILTERS,
    ...defaultEnumFilter,
  },
];

export const baseBuildingFilter: IFilter[] = [
  {
    title: ApartmentConfig.fields.building.verboseName,
    externalFieldName: ApartmentConfig.fields.building.externalFieldName,
    relationModelName: BuildingConfig.modelName,
    ...defaultRelationFilter,
  },
];

export const baseAddressFilters: IFilter[] = [
  {
    title: `${ApartmentConfig.fields.address.verboseName} - Gatunamn`,
    externalFieldName: `${ApartmentConfig.fields.address.externalFieldName}__street`,
    ...defaultStringFilter,
  },
  {
    title: `${ApartmentConfig.fields.address.verboseName} - Stad`,
    externalFieldName: `${ApartmentConfig.fields.address.externalFieldName}__city`,
    ...defaultStringFilter,
  },
  {
    title: `${ApartmentConfig.fields.address.verboseName} - Gatunummer`,
    externalFieldName: `${ApartmentConfig.fields.address.externalFieldName}__street_number`,
    ...defaultStringFilter,
  },
  {
    title: `${ApartmentConfig.fields.address.verboseName} - Land`,
    externalFieldName: `${ApartmentConfig.fields.address.externalFieldName}__country`,
    ...defaultStringFilter,
  },
];

export const baseSpaceDebitRowFilters: IFilter[] = [
  {
    title: 'Renovering på objekt',
    externalFieldName:
      ApartmentRevenueRowConfig.fields.spaceRenovationNotation
        .externalFieldName,
    ...defaultNullFilter,
  },
  {
    title: 'Renovering på struktur',
    externalFieldName:
      ApartmentRevenueRowConfig.fields.structureRenovationNotation
        .externalFieldName,
    ...defaultNullFilter,
  },

  {
    title: 'Fördelningsavgift',
    externalFieldName:
      ApartmentRevenueRowConfig.fields.automaticDistributionQuota
        .externalFieldName,
    ...defaultNullFilter,
  },
];

export const baseContractDebitRowFilters: IFilter[] = [
  {
    title: 'Retroaktiv debitering',
    externalFieldName:
      ApartmentContractRevenueRowConfig.fields.fromRetroactiveProposal
        .externalFieldName,
    ...defaultNullFilter,
  },
  {
    title: 'Indexuppräkning',
    externalFieldName:
      ApartmentContractRevenueRowConfig.fields.indexationUsage
        .externalFieldName,
    ...defaultNullFilter,
  },
  {
    title: 'Indexuppräknas',
    externalFieldName:
      ApartmentContractRevenueRowConfig.fields.appliedIndexationUsage
        .externalFieldName,
    ...defaultNullFilter,
  },
  {
    title: 'Omsättningshyra',
    externalFieldName:
      ApartmentContractRevenueRowConfig.fields.customerEarningsSetting
        .externalFieldName,
    ...defaultNullFilter,
  },
  {
    title: 'Böter från sen rapport av omsättning',
    externalFieldName:
      ApartmentContractRevenueRowConfig.fields.isCustomerEarningsReportPenalty
        .externalFieldName,
    ...defaultBooleanFilter,
  },
  {
    title: 'Fördelningsavgift',
    externalFieldName:
      ApartmentContractRevenueRowConfig.fields.automaticDistributionQuota
        .externalFieldName,
    ...defaultNullFilter,
  },
];

export const baseDebitRowFilters: IFilter[] = [
  {
    title: ApartmentRevenueRowConfig.fields.title.verboseName,
    externalFieldName: ApartmentRevenueRowConfig.fields.title.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: ApartmentRevenueRowConfig.fields.article.verboseName,
    externalFieldName:
      ApartmentRevenueRowConfig.fields.article.externalFieldName,
    relationModelName: ArticleConfig.modelName,
    ...defaultRelationFilter,
  },
  {
    title: ApartmentRevenueRowConfig.fields.unitPrice.verboseName,
    externalFieldName:
      ApartmentRevenueRowConfig.fields.unitPrice.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: ApartmentRevenueRowConfig.fields.unitAmount.verboseName,
    externalFieldName:
      ApartmentRevenueRowConfig.fields.unitAmount.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: ApartmentRevenueRowConfig.fields.startDate.verboseName,
    externalFieldName:
      ApartmentRevenueRowConfig.fields.startDate.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: ApartmentRevenueRowConfig.fields.endDate.verboseName,
    externalFieldName:
      ApartmentRevenueRowConfig.fields.endDate.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: ApartmentRevenueRowConfig.fields.customId.verboseName,
    externalFieldName:
      ApartmentRevenueRowConfig.fields.customId.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title:
      ApartmentRevenueRowConfig.fields.periodsAdjustsTotalAmount.verboseName,
    externalFieldName:
      ApartmentRevenueRowConfig.fields.periodsAdjustsTotalAmount
        .externalFieldName,
    ...defaultBooleanFilter,
  },
  {
    title: ApartmentRevenueRowConfig.fields.excemptFromMonths.verboseName,
    externalFieldName:
      ApartmentRevenueRowConfig.fields.excemptFromMonths.externalFieldName,
    choices: ApartmentRevenueRowConfig.fields.excemptFromMonths.choices,
    ...defaultSelectFilter,
  },
  ...baseFilters,
];

export default baseFilters;
