 import type { OrganizationUserFileAccessShare } from '@pigello/pigello-matrix';

import { OrganizationUserFileAccessShareConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useCreateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, createGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { createGenericInstance } from '@/requests/creating';

export const createOrganizationUserFileAccessShare = async (options: createGenericInstanceProps<OrganizationUserFileAccessShare>) => {
  return createGenericInstance<OrganizationUserFileAccessShare>({
    ...options,
    config: OrganizationUserFileAccessShareConfig,
  });
};

export const useCreateOrganizationUserFileAccessShare = (
  options: UseMutationOptions<OrganizationUserFileAccessShare, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useCreateGenericInstance<OrganizationUserFileAccessShare>(createOrganizationUserFileAccessShare, options, 'organizationuserfileaccessshare');
