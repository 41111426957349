import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { Badge } from '@/components/ui/badge';
import {
  ArticleCategoryConfig,
  type ArticleCategory,
} from '@pigello/pigello-matrix';
import type { ColumnDef } from '@tanstack/react-table';
import { ArticleCategoryDropdown } from '../components/article-category-dropdown';

export const columns: ColumnDef<ArticleCategory>[] = [
  {
    accessorKey: 'name',
    meta: {
      columnVisibilityName: ArticleCategoryConfig.fields.name.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.name ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'considerBaseRent',
    meta: {
      columnVisibilityName:
        ArticleCategoryConfig.fields.considerBaseRent.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {row.original.considerBaseRent ? (
          <Badge variant={'orange'}>Bashyra</Badge>
        ) : (
          <Badge variant={'blue'}>Ej bashyra</Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: 'archived',
    meta: {
      columnVisibilityName: ArticleCategoryConfig.fields.archived.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {row.original.archived ? (
          <Badge variant='destructive'>Arkiverad</Badge>
        ) : (
          <Badge variant='green'>Aktiv</Badge>
        )}
      </div>
    ),
  },
  {
    id: 'actions',
    enableHiding: false,
    enableSorting: false,
    cell: ({ row }) => (
      <ArticleCategoryDropdown articleCategory={row.original} />
    ),
  },
];
