 import type { ArticleCategory } from '@pigello/pigello-matrix';

import { ArticleCategoryConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, mutateGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { mutateGenericInstance } from '@/requests/mutating';

export const mutateArticleCategory = async (
  options: mutateGenericInstanceProps<ArticleCategory>
) => {
  return mutateGenericInstance<ArticleCategory>({
    ...options,
    config: ArticleCategoryConfig,
  });
};

export const useMutateArticleCategory = (
  options: UseMutationOptions<ArticleCategory, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useMutateGenericInstance<ArticleCategory>(mutateArticleCategory, options, 'articlecategory');