 import type { File } from '@pigello/pigello-matrix';

import { FileConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetFile = (
  options: useGetGenericInstanceProps<File>
) => {
  return useGetInstance<File>({
    ...options,
    config: FileConfig,
    modelName: 'file'
  });
};

export const useGetFileList = (
  options: useGetGenericListProps<File>
) => {
  return useGetList<File>({
    ...options,
    config: FileConfig,
    modelName: 'file'
  });
};
