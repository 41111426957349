'use client';

import { Chapters } from '@/app/detail/[type]/[id]/[subpage]/chapter-data';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useModal } from '@/hooks/useModal';
import { raise } from '@/lib/utils';
import {
  ChartBarIcon,
  ClipboardDocumentIcon,
  EllipsisHorizontalIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import type {
  ApartmentContract,
  BaseContractRevenueRow,
  BaseSingleSpaceRentableContract,
  BaseSpaceRevenueRow,
  FieldValue,
  IndustrialPremisesContract,
  ModelName,
  OutdoorSectionContract,
  ParkingSpotContract,
} from '@pigello/pigello-matrix';
import {
  type BaseInstance,
  type IBaseInstanceConfig,
} from '@pigello/pigello-matrix';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { toast } from 'sonner';
import DeleteModal from '../delete-modal/delete-modal';
import useRevenueRowHelpers from '../detail/revenue/useRevenueRowHelpers';
import useDetailModalContractHelpers from '../detail/shared/contract/useDetailModalContractHelpers';
import { RenderOnPerms } from '../renderOnPerms';

export function TableActions<Instance extends BaseInstance>({
  instance,
  title,
  config,
  disableEdit,
  disableDelete,
  overrideEdit,
  overrideModelName,
}: {
  instance: Instance;
  title: FieldValue<string>;
  config: IBaseInstanceConfig<Instance>;
  overrideModelName?: ModelName | string;
  disableEdit?: boolean;
  disableDelete?: boolean;
  overrideEdit?: (router: AppRouterInstance) => void;
}) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { openUpdateSingleInstanceModal } = useModal();

  const {
    isContract,
    canCancel,

    openCancelContractModal,
    latestCancellationTime,
  } = useDetailModalContractHelpers(
    config.modelName,
    instance.id as string,
    instance as unknown as BaseSingleSpaceRentableContract
  );

  const {
    isRevenueRow,
    isIndexable,
    openIndexationUsageModal,
    canEdit: canEditRevenueRow,
    hasIndexUsage,
    indexUsageId,
  } = useRevenueRowHelpers(
    config.modelName,
    instance?.id as string,
    instance as unknown as BaseContractRevenueRow | BaseSpaceRevenueRow
  );
  const copyIdToClipboard = () => {
    try {
      navigator.clipboard.writeText(
        instance.id ?? raise('No id provided in actions')
      );
      toast.success(`ID ${instance.id} har kopierats till urklipp`);
    } catch (err) {
      toast.error(`ID ${instance.id} kunde inte kopieras till urklipp`);
    }
  };
  const router = useRouter();
  return (
    <>
      {deleteOpen && (
        <DeleteModal
          id={instance.id as string}
          modelName={config.modelName}
          setOpen={() => setDeleteOpen(false)}
        />
      )}

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button data-action='true' variant={'ghost'} size={'icon-sm'}>
            <EllipsisHorizontalIcon className='size-4' />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='end'>
          <RenderOnPerms modelName={config.modelName} canUpdate={true}>
            {(canEditRevenueRow || !isRevenueRow) && !disableEdit && (
              <DropdownMenuItem
                onClick={
                  overrideEdit
                    ? () => overrideEdit(router)
                    : () =>
                        openUpdateSingleInstanceModal({
                          title: `Redigera ${title}`,
                          config,
                          id: instance.id ?? raise('No id provided in actions'),
                          chapters:
                            // @ts-expect-error TODO: fix this
                            Chapters?.[overrideModelName] ??
                            // @ts-expect-error TODO: fix this
                            Chapters?.[config.modelName],
                        })
                }
              >
                <PencilIcon className='mr-2 size-4' />
                Redigera
              </DropdownMenuItem>
            )}

            {isContract && canCancel && (
              <DropdownMenuItem
                onClick={() =>
                  openCancelContractModal({
                    instanceId: instance.id as string,
                    modelName: config.modelName,

                    contract: instance as unknown as
                      | ApartmentContract
                      | IndustrialPremisesContract
                      | OutdoorSectionContract
                      | ParkingSpotContract,
                    latestCancellationTime,
                  })
                }
              >
                <XMarkIcon className='mr-2 size-4' />
                Säg upp avtal
              </DropdownMenuItem>
            )}
            {isRevenueRow && (
              <>
                {isIndexable && (
                  <DropdownMenuItem
                    onClick={() =>
                      openIndexationUsageModal({
                        baseRowModelName: config.modelName,
                        baseRowId: instance.id as string,
                      })
                    }
                  >
                    <ChartBarIcon className='mr-2 size-4' />
                    Lägg till indexuppräkning
                  </DropdownMenuItem>
                )}

                {hasIndexUsage && !!indexUsageId && (
                  <DropdownMenuItem
                    onClick={() =>
                      openIndexationUsageModal({
                        instanceId: indexUsageId,
                      })
                    }
                  >
                    <ChartBarIcon className='mr-2 size-4' />
                    Redigera indexuppräkning
                  </DropdownMenuItem>
                )}
              </>
            )}
          </RenderOnPerms>
          <DropdownMenuItem onClick={copyIdToClipboard}>
            <ClipboardDocumentIcon className='mr-2 size-4' />
            Kopiera ID
          </DropdownMenuItem>
          <RenderOnPerms modelName={config.modelName} canDelete={true}>
            {!disableDelete && (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={() => setDeleteOpen(true)}>
                  <TrashIcon className='mr-2 h-4' /> Radera
                </DropdownMenuItem>
              </>
            )}
          </RenderOnPerms>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
