'use client';
import { cn } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  SignableDocumentConfig,
  type SignableDocument,
} from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './relation-item-map';

export const SignableDocumentItem: RelationFieldProps<SignableDocument> = {
  nested: getRelationFieldNames(SignableDocumentConfig),
  filters: [],
  columns: null,
  renderItem: (item, isRelationField) => ({
    title: item?.name,
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <span className='font-medium'>
          {SignableDocumentConfig.fields.category.choices?.find(
            (c) => c.v == item.category
          )?.d ?? 'Saknar kategori'}
        </span>
        {!isRelationField && <Dot />}
        <span>
          {item.contentType == null
            ? 'Saknar typ'
            : SignableDocumentConfig?.verboseName}
        </span>
      </div>
    ),
  }),
};
