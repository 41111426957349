import { DateTimePicker } from '@/components/form/dynamic-field/components/date-field/date-time-picker';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useToast } from '@/hooks/useToast';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import { useMutateGenericInstance } from '@/requests/hooks';
import { mutateGenericInstance } from '@/requests/mutating';
import type { ErrorResponse } from '@/requests/types';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { parseDate } from '@internationalized/date';
import {
  type BaseContract,
  type BaseInstance,
  type BaseSingleSpaceRentableContract,
  type IBaseInstanceConfig,
  type ModelName,
} from '@pigello/pigello-matrix';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import useDetailModalContractHelpers from './useDetailModalContractHelpers';

export type ManualSignContractModalContentData<Instance extends BaseInstance> =
  {
    modelName: ModelName;
    id: string;
    config: IBaseInstanceConfig<Instance>;
    data?: BaseSingleSpaceRentableContract;
  };

interface Props<Instance extends BaseInstance = BaseInstance>
  extends GetModalContentProps {
  closeModal: () => void;
  contentData: ManualSignContractModalContentData<Instance>;
}

function ManualSignContractModal<Instance extends BaseInstance = BaseInstance>({
  closeModal,
  contentData: { modelName, id, data, config },
}: Props<Instance>) {
  const { addToast } = useToast();

  const { latestSignDate } = useDetailModalContractHelpers(modelName, id, data);
  const [error, setError] = useState<string | undefined>();
  const [signDate, setSignDate] = useState<string | null>(null);
  const mutation = useMutateGenericInstance<Instance>(
    async (options) =>
      mutateGenericInstance<Instance>({ ...options, config: config }),
    {},
    modelName
  );

  useEffect(() => {
    if (signDate) {
      return;
    }

    if (latestSignDate && !data?.signedTime) {
      return setSignDate(latestSignDate.toISODate());
    }
    if (data?.signedTime) {
      setSignDate(data.signedTime);
    }
  }, [data?.signedTime, latestSignDate, signDate]);

  const maxValue = parseDate(
    latestSignDate?.isValid
      ? latestSignDate.toISODate()
      : DateTime.now().toISODate()
  );
  const signedTime = DateTime.fromISO(data?.signedTime ?? '');
  const defaultValue = signedTime.isValid
    ? parseDate(signedTime.toISODate())
    : maxValue;

  const onSubmit = () => {
    addToast({
      type: 'promise',
      promise: mutation.mutateAsync({
        id,
        body: {
          signedTime: signDate,
        } as Partial<Instance> & BaseContract,
      }),
      loading: data?.signedTime
        ? 'Uppdaterar avtalet...'
        : 'Signerar avtalet...',
      success: () => {
        closeModal();
        return data?.signedTime ? 'Avtalet uppdaterat' : 'Avtalet signerat';
      },
      error: (error: ErrorResponse) => {
        const field = handleFormErrors(error);
        setError(field);
        return field;
      },
    });
  };

  return (
    <Dialog defaultOpen>
      <DialogContent
        onInteractOutside={closeModal}
        size='sm'
        className='gap-0 p-0'
      >
        <DialogHeader>
          <DialogTitle>
            {data?.signedTime ? 'Uppdatera' : 'Signera manuellt'}
          </DialogTitle>
          <Button variant='outline' size={'icon-sm'} onClick={closeModal}>
            <XMarkIcon className='size-4' />
          </Button>
        </DialogHeader>
        <div className='w-full p-4'>
          <DateTimePicker
            label='Signeringsdatum'
            defaultValue={defaultValue}
            errorMessage={error ? error : undefined}
            isInvalid={!!error}
            // maxValue={maxValue}
            shouldCloseOnSelect={true}
            onChange={(date) => {
              if (!date) return;
              setSignDate(date.toString());
            }}
          />
        </div>
        <DialogFooter>
          <Button type='button' variant={'outline'} onClick={closeModal}>
            Avbryt
          </Button>
          <Button disabled={!signDate || mutation.isPending} onClick={onSubmit}>
            {data?.signedTime ? 'Uppdatera' : 'Signera'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export const getManualSignContractModalContent = (
  props: Props
): ModalContent => {
  return {
    body: <ManualSignContractModal {...props} />,
    isCloseOnEscape: true,
    closeButton: true,
    externalModalHandling: true,
  };
};
