'use client';

import { useGetIndexationSettingUsage } from '@/config/revenue/indexation/indexationsettingusage/client';
import { useCreateIndexationSettingUsage } from '@/config/revenue/indexation/indexationsettingusage/create';
import { useMutateIndexationSettingUsage } from '@/config/revenue/indexation/indexationsettingusage/mutate';
import { useForm } from '@/hooks/useForm';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useToast } from '@/hooks/useToast';
import setFormValue from '@/lib/set-form-value';
import { cn } from '@/lib/utils';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import type { ErrorResponse } from '@/requests/types';
import { XMarkIcon } from '@heroicons/react/16/solid';
import type { ModelName } from '@pigello/pigello-matrix';
import {
  ApartmentContractRevenueRowConfig,
  IndexationSettingUsageConfig,
  IndustrialPremisesContractRevenueRowConfig,
  OtherContractRevenueRowConfig,
  OutdoorSectionContractRevenueRowConfig,
  ParkingSpotContractRevenueRowConfig,
  getCleanIndexationSettingUsage,
} from '@pigello/pigello-matrix';
import { useEffect, useMemo, useState } from 'react';
import { DynamicField } from '../form/dynamic-field';
import { Button } from '../ui/button';
import { Card, CardContent } from '../ui/card';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog';
import { Form } from '../ui/form';

export type IndexationUsageModalContentData = {
  instanceId?: string; // only for edit
  baseRowId?: string; // only for create
  baseRowModelName?: ModelName; // only for create
};

interface Props extends GetModalContentProps {
  closeModal: () => void;
  contentData: IndexationUsageModalContentData;
}

const relationKeyMap: Partial<Record<ModelName, string>> = {
  [ApartmentContractRevenueRowConfig.modelName]: 'apartmentContractRevenueRow',
  [IndustrialPremisesContractRevenueRowConfig.modelName]:
    'industrialPremisesContractRevenueRow',
  [OutdoorSectionContractRevenueRowConfig.modelName]:
    'outdoorSectionContractRevenueRow',
  [ParkingSpotContractRevenueRowConfig.modelName]:
    'parkingSpotContractRevenueRow',
  [OtherContractRevenueRowConfig.modelName]: 'otherContractRevenueRow',
};

function IndexationUsageModal({ closeModal, contentData }: Props) {
  const { instanceId, baseRowId, baseRowModelName } = contentData;

  const [kind, setKind] = useState<'firm' | 'quota' | null>(null);

  const isUpdate = !!instanceId;

  const { addToast } = useToast();

  const relationKey = baseRowModelName
    ? relationKeyMap[baseRowModelName]
    : undefined;

  const { data: existingInstance } = useGetIndexationSettingUsage({
    id: instanceId,
    enabled: isUpdate,
    nested: ['setting'],
  });

  const form = useForm({
    config: IndexationSettingUsageConfig,
    defaultValues: getCleanIndexationSettingUsage(),
    isUpdate: isUpdate,
    values: existingInstance,
    preMutatedValues: isUpdate
      ? undefined
      : {
          [String(relationKey)]: { id: baseRowId },
        },
    orderedFields: [
      {
        key: 'firm',
        fields: ['revenueBaseValueFirmValue', 'overrideBaseYear'],
      },
      {
        key: 'quota',
        fields: ['revenueBaseValueQuota', 'overrideBaseYear'],
      },
    ],
  });

  useEffect(() => {
    if (isUpdate && existingInstance) {
      if (existingInstance.revenueBaseValueFirmValue != null) {
        setKind('firm');
      } else {
        setKind('quota');
      }
    }
  }, [isUpdate, existingInstance]);

  const updateMutation = useMutateIndexationSettingUsage({
    invalidateQueries: [
      'apartmentcontractrevenuerow',
      'industrialpremisescontractrevenuerow',
      'outdoorsectioncontractrevenuerow',
      'parkingspotcontractrevenuerow',
      'othercontractrevenuerow',
    ],
  });

  const createMutation = useCreateIndexationSettingUsage({
    invalidateQueries: baseRowModelName ? [baseRowModelName] : undefined,
  });

  const formValues = useMemo(() => {
    return form.getValues();
  }, [form]);

  const { isDirty } = form.formState;

  const canSubmit = () => {
    return !!formValues.setting && !!kind && isDirty;
  };

  const onSubmit = () => {
    const data = form.getDirtyData();
    if (isUpdate) {
      return addToast({
        type: 'promise',
        promise: updateMutation.mutateAsync({
          body: data,
          id: instanceId,
        }),
        loading: 'Sparar...',
        success: () => {
          closeModal();
          return 'Indexuppräkningsanvändning uppdaterades';
        },
        error: (error: ErrorResponse) => {
          const field = handleFormErrors(error, form);
          return field;
        },
      });
    }
    addToast({
      type: 'promise',
      promise: createMutation.mutateAsync({ body: data }),
      loading: 'Sparar...',
      success: () => {
        closeModal();
        return 'Indexuppräkningsanvändning skapades';
      },
      error: (error: ErrorResponse) => {
        const field = handleFormErrors(error, form);
        return field;
      },
    });
  };

  const closeAndClear = () => {
    closeModal();
    form.reset();
  };

  return (
    <Dialog open onOpenChange={() => closeAndClear}>
      <DialogContent size='md' className={'p-0'}>
        <DialogHeader>
          <DialogTitle>
            Hantera inställning för indexuppräkning på rad
          </DialogTitle>
          <Button onClick={closeAndClear} variant={'outline'} size={'icon-sm'}>
            <XMarkIcon className='size-4' />
          </Button>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={(e) => {
              form.clearErrors();
              form.handleSubmit(onSubmit)(e);
            }}
          >
            <div className='grid grid-cols-2 gap-6 p-4'>
              <DynamicField
                horizontal
                formField={form.fields.setting}
                className='col-span-2'
              />
              <hr className='col-span-2 bg-foreground ' />

              <button
                type='button'
                onClick={() => {
                  setFormValue(form, 'revenueBaseValueQuota', null, {
                    shouldDirty: true,
                  });
                  setKind('firm');
                }}
              >
                <Card
                  className={cn({
                    'bg-accent/70': kind === 'firm',
                  })}
                >
                  <CardContent>
                    <div className='font-medium'>Fast värde</div>
                    <div className='text-xs'>
                      Ange ett fast värde att räkna upp
                    </div>
                  </CardContent>
                </Card>
              </button>
              <button
                type='button'
                onClick={() => {
                  setFormValue(form, 'revenueBaseValueFirmValue', null, {
                    shouldDirty: true,
                  });
                  setFormValue(form, 'useRevenueRowAsMaxBaseValue', null, {
                    shouldDirty: true,
                  });

                  setKind('quota');
                }}
              >
                <Card
                  className={cn({
                    'bg-accent/70': kind === 'quota',
                  })}
                >
                  <CardContent>
                    <div className='font-medium'>Andel av basrad</div>
                    <div className='text-xs'>
                      Ange en andel av basraden att räkna upp
                    </div>
                  </CardContent>
                </Card>
              </button>

              {kind === 'firm' && (
                <>
                  <DynamicField
                    horizontal
                    formField={form.fields.useRevenueRowAsMaxBaseValue}
                    className='col-span-2'
                  />
                  <hr className='col-span-2 bg-foreground ' />
                  {form.orderedFields.firm?.map((field) => (
                    <DynamicField formField={field} key={field.name} />
                  ))}
                </>
              )}

              {kind === 'quota' && (
                <>
                  {form.orderedFields.quota?.map((field) => (
                    <DynamicField formField={field} key={field.name} />
                  ))}
                </>
              )}
            </div>

            <DialogFooter>
              <Button
                type='button'
                variant='outline'
                onClick={() => closeAndClear()}
              >
                Avbryt
              </Button>
              <Button disabled={!canSubmit()}>Spara</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export const getIndexationUsageModalContent = ({
  closeModal,
  contentData,
}: Props): ModalContent => {
  return {
    externalModalHandling: true,
    body: (
      <IndexationUsageModal closeModal={closeModal} contentData={contentData} />
    ),
  };
};
