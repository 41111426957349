import { DynamicField } from '@/components/form/dynamic-field';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Form } from '@/components/ui/form';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useDeleteOrganizationUserFileAccessShare } from '@/config/documents/filehandling/organizationuserfileaccessshare/delete';
import type { useForm } from '@/hooks/useForm';
import { cn } from '@/lib/utils';
import {
  CheckIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import {
  OrganizationUserFileAccessShareConfig,
  type OrganizationUserFileAccessShare,
} from '@pigello/pigello-matrix';
import type { Dispatch, SetStateAction } from 'react';
import { toast } from 'sonner';

interface Props {
  item: OrganizationUserFileAccessShare | undefined;
  isEdit: boolean;
  isSavingUser: boolean;
  addUser: boolean;
  setAddUser?: Dispatch<SetStateAction<boolean>>;
  setEditUser: Dispatch<SetStateAction<string | undefined>>;
  form: ReturnType<typeof useForm<OrganizationUserFileAccessShare>>;
  onSubmit: () => void;
  orgUsersAlreadyAdded: (string | undefined)[] | undefined;
  canViewOrganizationUser: boolean;
  canDelete: boolean;
  canUpdate: boolean;
}

export function OrganizationUserFileAccessCard({
  item,
  isEdit,
  setEditUser,
  form,
  onSubmit,
  setAddUser,
  addUser,
  isSavingUser,
  orgUsersAlreadyAdded,
  canViewOrganizationUser,
  canDelete,
  canUpdate,
}: Props) {
  const { mutateAsync, isPending: isDeleting } =
    useDeleteOrganizationUserFileAccessShare({});
  const onDelete = () => {
    if (!item) return;
    toast.promise(mutateAsync({ id: item?.id }), {
      loading: 'Tar bort...',
      success: () => {
        setEditUser(undefined);
        form.reset();
        return 'Användare borttagen';
      },
      error: 'Kunde inte ta bort användare',
    });
  };
  if (isEdit || !item) {
    return (
      <Form {...form}>
        <form id={'user-access-form'} onSubmit={form.handleSubmit(onSubmit)}>
          <Card initial={false} className='flex flex-row gap-4'>
            <Avatar className='size-10'>
              <AvatarImage
                className='object-cover'
                src={addUser ? undefined : item?.user?.profilePicture?.get}
                alt={addUser ? 'Ny användare' : item?.user?.communicationName}
              />
              <AvatarFallback className='text-xs'>
                {addUser
                  ? 'Ny'
                  : `${item?.user?.firstName?.[0] ?? 'N'}${item?.user?.lastName?.[0] ?? 'A'}`}
              </AvatarFallback>
            </Avatar>
            <div className='grid flex-1 gap-1'>
              {addUser ? (
                <DynamicField
                  label={' '}
                  className='space-y-0'
                  formField={form.fields.user}
                  filters={{
                    id: {
                      '__in!': orgUsersAlreadyAdded?.join(','),
                    },
                  }}
                />
              ) : (
                <div className='flex gap-4'>
                  <div className='grid'>
                    <span className='text-sm font-medium'>
                      {item?.user?.communicationName}
                    </span>
                    <span className='text-sm'>
                      {!canViewOrganizationUser
                        ? 'Saknar behörigheter'
                        : item?.user?.email
                          ? item?.user?.email
                          : 'Email saknas'}
                    </span>
                  </div>
                </div>
              )}
              <div className='flex items-center justify-start gap-2'>
                <DynamicField formField={form.fields.canCreate} />
                <DynamicField formField={form.fields.canDelete} />
                <DynamicField formField={form.fields.canUpdate} />
              </div>
            </div>
            <div className='flex items-center gap-1 self-start justify-self-end slide-in-from-bottom-3'>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    disabled={isSavingUser}
                    variant={'outline'}
                    size={'icon-sm'}
                    type='submit'
                  >
                    <CheckIcon className='size-4' />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Spara</TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    disabled={isSavingUser}
                    variant={'outline'}
                    size={'icon-sm'}
                    onClick={() => {
                      setAddUser?.(false);
                      setEditUser(undefined);
                      form.reset();
                    }}
                    type='button'
                  >
                    <XMarkIcon className='size-4' />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Avbryt</TooltipContent>
              </Tooltip>
            </div>
          </Card>
        </form>
      </Form>
    );
  }

  return (
    <Card
      initial={false}
      className={cn('flex flex-row gap-4', addUser ? 'opacity-50' : '')}
    >
      <Avatar className='size-10'>
        <AvatarImage
          className='object-cover'
          src={item.user?.profilePicture?.get}
          alt={item.user?.communicationName}
        />
        <AvatarFallback className='text-xs'>
          {item.user?.firstName?.[0] ?? 'N'}
          {item.user?.lastName?.[0] ?? 'A'}
        </AvatarFallback>
      </Avatar>
      <div className='grid flex-1 gap-1'>
        <div className='flex gap-4'>
          <div className='grid'>
            <span className='text-sm font-medium'>
              {item.user?.communicationName}
            </span>
            <span className='text-sm'>
              {!canViewOrganizationUser
                ? 'Saknar behörigheter'
                : item.user?.email
                  ? item.user?.email
                  : 'Email saknas'}
            </span>
          </div>
        </div>
        <div className='flex h-[50.5px] items-center justify-start gap-2'>
          <div className='grid space-y-2'>
            <Label>
              {
                OrganizationUserFileAccessShareConfig.fields.canCreate
                  .verboseName
              }
            </Label>
            <Switch disabled checked={item.canCreate} />
          </div>
          <div className='grid space-y-2'>
            <Label>
              {
                OrganizationUserFileAccessShareConfig.fields.canDelete
                  .verboseName
              }
            </Label>
            <Switch disabled checked={item.canDelete} />
          </div>
          <div className='grid space-y-2'>
            <Label>
              {
                OrganizationUserFileAccessShareConfig.fields.canUpdate
                  .verboseName
              }
            </Label>
            <Switch disabled checked={item.canUpdate} />
          </div>
        </div>
      </div>
      <div className='flex items-center gap-1 self-start justify-self-end'>
        {canUpdate && (
          <Button
            disabled={isDeleting || isSavingUser || addUser}
            className='self-start'
            variant={'outline'}
            size={'icon-sm'}
            onClick={() => {
              setAddUser?.(false);
              setEditUser(item.user?.id);
            }}
          >
            <PencilIcon className='size-4' />
          </Button>
        )}
        {canDelete && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                disabled={isDeleting || isSavingUser || addUser}
                variant={'outline'}
                size={'icon-sm'}
                type='button'
              >
                <TrashIcon className='size-4' />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align='end'>
              <DropdownMenuItem onSelect={onDelete}>
                Ta bort användare
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
    </Card>
  );
}
