'use client';
import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/16/solid';
import type { Column } from '@tanstack/react-table';

import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

interface DataTableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title?: string;
  isMulti?: boolean;
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
  className,
  isMulti = true,
}: DataTableColumnHeaderProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return (
      <div className={cn(className, 'whitespace-nowrap')}>
        {title ?? column.columnDef.meta?.columnVisibilityName}
      </div>
    );
  }
  return (
    <div
      className={cn('flex items-center space-x-2 whitespace-nowrap', className)}
    >
      <Button
        variant='ghost'
        size='sm'
        className='-ml-3 h-8 whitespace-nowrap font-medium data-[state=open]:bg-accent'
        onClick={() => column.toggleSorting(undefined, isMulti)}
      >
        <span className='whitespace-nowrap'>
          {title ?? column.columnDef.meta?.columnVisibilityName}
        </span>
        {column.getIsSorted() === 'asc' ? (
          <BarsArrowDownIcon className='ml-2 size-4' />
        ) : column.getIsSorted() === 'desc' ? (
          <BarsArrowUpIcon className='ml-2 size-4' />
        ) : (
          <ChevronUpDownIcon className='ml-2 size-4' />
        )}
      </Button>
    </div>
  );
}
