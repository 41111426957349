import { DynamicField } from '@/components/form/dynamic-field';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Form } from '@/components/ui/form';
import OverlayLoader from '@/components/ui/overlay-loader';
import { useGetFileList } from '@/config/documents/filehandling/file/client';
import { useMutateFile } from '@/config/documents/filehandling/file/mutate';
import { useCreateFileAccessShare } from '@/config/documents/filehandling/fileaccessshare/create';
import { useGetFolderList } from '@/config/documents/filehandling/folder/client';
import { useGetOrganizationUserFileAccessShareList } from '@/config/documents/filehandling/organizationuserfileaccessshare/client';
import { useCreateOrganizationUserFileAccessShare } from '@/config/documents/filehandling/organizationuserfileaccessshare/create';
import { useMutateOrganizationUserFileAccessShare } from '@/config/documents/filehandling/organizationuserfileaccessshare/mutate';
import { useForm } from '@/hooks/useForm';
import { usePerms } from '@/hooks/usePerms';
import { ObjectEntries, cn } from '@/lib/utils';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/16/solid';
import {
  OrganizationUserFileAccessShareConfig,
  getCleanFileAccessShare,
  getCleanOrganizationUserFileAccessShare,
  type File,
} from '@pigello/pigello-matrix';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'sonner';
import { OrganizationUserFileAccessCard } from './organization-user-file-access-card';

export type FilePermissionsModalContentData = {
  file: File;
};

interface Props extends GetModalContentProps {
  closeModal: () => void;
  contentData: FilePermissionsModalContentData;
}

function FilePermissionsModal({
  closeModal,
  contentData: { file: referencedFile },
}: Props) {
  const [addUser, setAddUser] = useState(false);
  const [createAccessShare, setCreateAccessShare] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [editUser, setEditUser] = useState<string>();
  const {
    canView: canViewOrganizationUser,
    canUpdate: canUpdateOrganizationUser,
  } = usePerms('organizationuser');
  const { canUpdate, canDelete } = usePerms('folder');
  const { data: files, isPending: isLoadingFile } = useGetFileList({
    overrideUrl: referencedFile?.notationContentType?.id,
    queryParams: {
      page: 1,
      pageSize: 1,
      filters: {
        id: {
          noop: referencedFile?.id,
        },
        notation_content_type: {
          noop: referencedFile?.notationContentType?.id,
        },
        notion_object_id: {
          noop: referencedFile?.notationObjectId,
        },
        ...(referencedFile.accessShare && {
          access_share: {
            noop: referencedFile?.accessShare?.id,
          },
        }),
      },
    },
    nested: ['accessShare'],
  });
  const file = files?.list[0];

  const { data: numberOfFilesUsingSetting } = useGetFileList({
    overrideUrl: file?.notationContentType?.id,
    queryParams: {
      page: 1,
      pageSize: 1,
      filters: {
        ...(file?.accessShare?.id && {
          access_share: {
            noop: file?.accessShare?.id,
          },
        }),
      },
    },
    enabled: !!file?.accessShare?.id,
  });
  const { data: numberOfFoldersUsingSetting } = useGetFolderList({
    overrideUrl: file?.notationContentType?.id,
    queryParams: {
      page: 1,
      pageSize: 1,
      filters: {
        ...(file?.accessShare?.id && {
          access_share: {
            noop: file?.accessShare?.id,
          },
        }),
      },
    },
    enabled: !!file?.accessShare?.id,
  });
  const { data: organizationUserFileAccessList, isFetching: isFetchingUsers } =
    useGetOrganizationUserFileAccessShareList({
      queryParams: {
        filters: {
          file_access_share: {
            noop: file?.accessShare?.id,
          },
        },
        order: ['createdAt'],
      },
      nested: ['user', 'fileAccessShare'],
      enabled: !!file?.accessShare?.id,
    });

  const {
    mutateAsync: createOrganizationFileAccessShare,
    isPending: isCreatingUser,
  } = useCreateOrganizationUserFileAccessShare({});

  const {
    mutateAsync: mutateOrganizationFileAccessShare,
    isPending: isSavingUser,
  } = useMutateOrganizationUserFileAccessShare({});

  const { mutate: mutateFile } = useMutateFile({
    onSuccess: () => {
      setCreateAccessShare(false);
      toast.success('Tillagt på mappen!');
    },
    onError: () => {
      toast.error('Något gick fel');
    },
  });

  const { mutateAsync: mutateFileAsync } = useMutateFile({});

  const { data: usersForFileAccessShare } =
    useGetOrganizationUserFileAccessShareList({
      queryParams: {
        page: 1,
        pageSize: 100,
      },
      nested: ['user', 'fileAccessShare'],
      enabled: !file?.accessShare?.id,
    });

  const mergedUsers = Object.groupBy(
    usersForFileAccessShare?.list ?? [],
    (item) => item.fileAccessShare?.id ?? 'no-id'
  );

  useEffect(() => {
    const element = scrollRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, [addUser]);

  const { mutate: createFileAccessShare } = useCreateFileAccessShare({
    onSuccess: (data) => {
      mutateFile({
        id: referencedFile.id,
        body: {
          accessShare: {
            id: data.id,
          },
        },
      });
    },
    onError: () => {
      toast.error('Något gick fel');
    },
  });

  const form = useForm({
    defaultValues: getCleanOrganizationUserFileAccessShare(),
    config: OrganizationUserFileAccessShareConfig,
    values: organizationUserFileAccessList?.list.find(
      (item) => item.user?.id === editUser
    ),
    isUpdate: !!editUser && !addUser,
  });
  const accessShareForm = useForm({
    defaultValues: getCleanFileAccessShare(),
    config: OrganizationUserFileAccessShareConfig,
  });

  const onSubmitOrganizationUser = () => {
    const data = form.getDirtyData();
    const isEdit = !!organizationUserFileAccessList?.list.find(
      (item) => item.user?.id === editUser
    );
    if (!file?.accessShare?.id) {
      toast.error('Mappen är inte delad');
      return;
    }
    const orgFileId = organizationUserFileAccessList?.list.find(
      (item) => item.user?.id === editUser
    )?.id;
    if (!orgFileId && isEdit) {
      toast.error('Användaren har inte tillgång till mappen');
      return;
    }
    if (isEdit && orgFileId) {
      toast.promise(
        mutateOrganizationFileAccessShare({
          id: orgFileId,
          body: data,
        }),
        {
          loading: 'Sparar användare...',
          success: () => {
            setEditUser(undefined);
            form.reset();
            return 'Användare sparad!';
          },
          error: 'Något gick fel',
        }
      );
    } else {
      toast.promise(
        createOrganizationFileAccessShare({
          body: { ...data, fileAccessShare: { id: file.accessShare.id } },
        }),
        {
          loading: 'Sparar användare...',
          success: () => {
            setAddUser(false);
            form.reset();
            return 'Användare sparad!';
          },
          error: 'Något gick fel',
        }
      );
    }
  };
  const onCreateFileAccessShare = () => {
    const data = accessShareForm.getDirtyData();
    createFileAccessShare({ body: data });
  };

  const onAppendExistingFileAccessShare = (id: string) => {
    if (id === 'no-id') {
      toast.error('Kunde inte hitta rätt inställning vid skapandet.');
      return;
    }
    toast.promise(
      mutateFileAsync({
        id: referencedFile.id,
        body: { accessShare: { id } },
      }),
      {
        loading: 'Lägger till...',
        success: () => {
          return 'Tillagd på mappen!';
        },
        error: 'Något gick fel',
      }
    );
  };

  const { isDirty } = form.formState;

  const clearAndClose = () => {
    form.reset();
    closeModal();
  };

  const isPublic = !file?.accessShare?.id;
  const orgUsersAlreadyAdded = organizationUserFileAccessList?.list.map(
    (item) => item.user?.id
  );
  const isNotPublicWithNoUsers =
    !isPublic &&
    !isFetchingUsers &&
    !organizationUserFileAccessList?.list.length;

  return (
    <>
      <Dialog open onOpenChange={clearAndClose}>
        <DialogContent
          size='md'
          className={'p-0 transition-all'}
          onInteractOutside={(e) => {
            e.preventDefault();
            if (!createAccessShare) {
              clearAndClose();
            }
          }}
        >
          <DialogHeader>
            <DialogTitle>
              Redigera behörigheter till {file?.originalName}
            </DialogTitle>

            <Button variant='secondary' size='icon-sm' onClick={clearAndClose}>
              <XMarkIcon className='size-4' />
            </Button>
          </DialogHeader>
          <div
            ref={scrollRef}
            className={cn('relative h-[500px] overflow-auto p-4')}
          >
            {(isFetchingUsers || isLoadingFile) && <OverlayLoader />}
            {isPublic && !isFetchingUsers && !isLoadingFile && (
              <>
                <Alert>
                  <InformationCircleIcon className='size-4' />
                  <AlertTitle>
                    Denna mapp är publik och kan ses av alla.
                  </AlertTitle>
                  <AlertDescription>
                    Välj en av de redan existerande inställningar eller klicka
                    på &quot;Skapa ny inställning&quot; för att skapa en ny
                  </AlertDescription>
                </Alert>
                <div className='mt-2 grid gap-2'>
                  {ObjectEntries(mergedUsers ?? {}).map(([key, items]) => (
                    <Card key={key}>
                      <div className='flex items-center justify-between'>
                        <div className='flex items-center'>
                          {items?.slice(0, 5).map((orgSetting, i) =>
                            i < 4 ? (
                              <Avatar
                                key={orgSetting.id}
                                className={cn(
                                  'border border-border',
                                  i === 0 && 'z-0 size-8 rounded-full',
                                  i === 1 && 'z-10 -ml-3.5 size-8 rounded-full',
                                  i === 2 && 'z-20 -ml-3.5 size-8 rounded-full',
                                  i === 3 && 'z-30 -ml-3.5 size-8 rounded-full'
                                )}
                              >
                                <AvatarImage
                                  width={32}
                                  height={32}
                                  src={orgSetting?.user?.profilePicture?.get}
                                  alt={
                                    orgSetting?.user?.communicationName ??
                                    'Användare'
                                  }
                                  className={'object-cover'}
                                />
                                <AvatarFallback>
                                  {orgSetting?.user?.firstName?.[0]}
                                  {orgSetting?.user?.lastName?.[0]}
                                </AvatarFallback>
                              </Avatar>
                            ) : (
                              <div
                                className='z-40 -ml-3.5 flex size-8 items-center justify-center rounded-full border border-border bg-muted'
                                key={key}
                              >
                                +{items.length - 4}
                              </div>
                            )
                          )}
                          <div className='ml-2'>
                            {mergedUsers[key]?.find(
                              (setting) => setting.fileAccessShare?.id === key
                            )?.fileAccessShare?.customId ??
                              'Namnlös inställning'}
                            <div className='text-sm font-medium'>
                              Denna inställning innehåller {items?.length}{' '}
                              användare
                            </div>
                          </div>
                        </div>
                        <Button
                          onClick={() => onAppendExistingFileAccessShare(key)}
                        >
                          Välj
                        </Button>
                      </div>
                    </Card>
                  ))}
                </div>
              </>
            )}
            {isNotPublicWithNoUsers && (
              <Alert className='mb-4'>
                <InformationCircleIcon className='size-4' />
                <AlertTitle>
                  Det finns inga användare med tillgång till denna mapp.
                </AlertTitle>
                <AlertDescription>
                  Lägg till användare för att ge dem tillgång.
                </AlertDescription>
              </Alert>
            )}
            <div className='grid grid-cols-1 gap-2'>
              {!isPublic && (
                <>
                  <Alert className='col-span-full'>
                    <InformationCircleIcon className='size-4' />
                    <AlertTitle>
                      Bara användare som ligger i inställningen{' '}
                      {file.accessShare?.customId} har tillgång till filen
                    </AlertTitle>
                    <AlertDescription>
                      Lägg till användare för att ge dem tillgång. Kom ihåg att
                      när du redigerar användare kommer det att påverka alla
                      mappar och filer som delar denna inställning. Redigerande
                      påverkar just nu{' '}
                      {numberOfFilesUsingSetting?.meta.total_amount ?? 0}{' '}
                      {numberOfFilesUsingSetting?.meta.total_amount === 1
                        ? 'fil'
                        : 'filer'}{' '}
                      och {numberOfFoldersUsingSetting?.meta.total_amount ?? 0}{' '}
                      {numberOfFoldersUsingSetting?.meta.total_amount === 1
                        ? 'mapp'
                        : 'mappar'}
                      .
                    </AlertDescription>
                  </Alert>
                  {organizationUserFileAccessList?.list.map((item) => (
                    <OrganizationUserFileAccessCard
                      key={item.id}
                      form={form}
                      item={item}
                      setEditUser={setEditUser}
                      isEdit={item.user?.id === editUser}
                      onSubmit={onSubmitOrganizationUser}
                      isSavingUser={isSavingUser || isCreatingUser}
                      setAddUser={setAddUser}
                      addUser={addUser}
                      orgUsersAlreadyAdded={orgUsersAlreadyAdded}
                      canViewOrganizationUser={canViewOrganizationUser}
                      canDelete={canDelete}
                      canUpdate={canUpdate}
                    />
                  ))}
                  {addUser && (
                    <OrganizationUserFileAccessCard
                      form={form}
                      item={undefined}
                      setEditUser={setEditUser}
                      setAddUser={setAddUser}
                      isEdit={true}
                      onSubmit={onSubmitOrganizationUser}
                      isSavingUser={isSavingUser || isCreatingUser}
                      addUser={addUser}
                      orgUsersAlreadyAdded={orgUsersAlreadyAdded}
                      canViewOrganizationUser={canViewOrganizationUser}
                      canDelete={canDelete}
                      canUpdate={canUpdate}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <DialogFooter>
            {isPublic &&
            !organizationUserFileAccessList?.list.length &&
            !isLoadingFile ? (
              <Button
                onClick={() => setCreateAccessShare(true)}
                type='button'
                className='self-start'
              >
                Skapa ny inställning
              </Button>
            ) : (
              canViewOrganizationUser &&
              canUpdateOrganizationUser && (
                <>
                  {addUser || editUser ? (
                    <Button
                      disabled={isSavingUser || isCreatingUser || !isDirty}
                      type='submit'
                      form='user-access-form'
                      className='self-start'
                    >
                      Spara
                    </Button>
                  ) : (
                    <Button
                      disabled={isFetchingUsers || !!editUser}
                      onClick={() => {
                        setAddUser(true);
                      }}
                      type='button'
                      className='self-start'
                    >
                      Lägg till användare
                    </Button>
                  )}
                </>
              )
            )}
            <Button variant={'outline'} onClick={() => closeModal()}>
              Stäng
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <AlertDialog open={createAccessShare} onOpenChange={setCreateAccessShare}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Skapa en ny inställning för mappen
            </AlertDialogTitle>
          </AlertDialogHeader>
          <Form {...accessShareForm}>
            <form
              onSubmit={accessShareForm.handleSubmit(onCreateFileAccessShare)}
            >
              <DynamicField
                label='Namn'
                placeholder='Namn på inställning'
                formField={accessShareForm.fields.customId}
              />
              <AlertDialogFooter className='mt-4'>
                <Button type='submit'>Skapa inställning</Button>
                <AlertDialogCancel
                  onClick={() => {
                    setCreateAccessShare(false);
                    accessShareForm.reset();
                  }}
                >
                  Avbryt
                </AlertDialogCancel>
              </AlertDialogFooter>
            </form>
          </Form>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export const getFilePermissionsModalContent = (props: Props): ModalContent => {
  return {
    body: <FilePermissionsModal {...props} />,
    externalModalHandling: true,
  };
};
