import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import React from 'react';
import { Button } from './button';

export type ConfirmModalResponse = Promise<boolean>;

export type ConfirmModalContentData = {
  title?: string;
  message?: React.ReactNode;
  body?: React.ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

interface Props extends GetModalContentProps {
  closeModal: (response: Awaited<ConfirmModalResponse>) => void;
  contentData: ConfirmModalContentData;
}

export const getConfirmModalContent = ({
  closeModal,
  contentData,
}: Props): ModalContent => {
  const actions = (
    <>
      <Button
        type='button'
        variant='outline'
        onClick={() => {
          contentData?.onCancel?.();
          closeModal(false);
        }}
      >
        {contentData?.cancelButtonText ?? 'Avbryt'}
      </Button>
      <Button
        type='button'
        variant='default'
        onClick={() => {
          contentData?.onConfirm?.();
          closeModal(true);
        }}
      >
        {contentData?.confirmButtonText ?? 'Bekräfta'}
      </Button>
    </>
  );

  return {
    title: contentData?.title ?? 'Bekräfta',
    description:
      contentData?.message ?? 'Är du säker på att du vill fortsätta?',
    body: contentData?.body,
    actions,
  };
};
