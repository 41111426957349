import {
  DATE_FILTER,
  type IFilter,
} from '@/components/table-filters/constants';

const baseRevenueIncreaseFilters: IFilter[] = [
  {
    title: 'Senaste avgiftsändringen',
    externalFieldName: 'revenue_increase_created_at',
    operators: ['__lte'],
    defaultOperator: '__lte',
    kind: DATE_FILTER,
  },
  {
    title: 'Ej avgiftsändrad sedan',
    externalFieldName: 'revenue_increase_not_created',
    operators: ['__gte'],
    defaultOperator: '__gte',
    kind: DATE_FILTER,
  },
];

export default baseRevenueIncreaseFilters;
