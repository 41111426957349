import { handleFile } from '@/components/form/dynamic-field/utils';
import type { InputProps } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { type Dispatch, type SetStateAction } from 'react';
import type { ControllerRenderProps, Path } from 'react-hook-form';

type HiddenFileProps<Instance extends BaseInstance> = InputProps & {
  field?: ControllerRenderProps<any, Path<Instance>>;
  setIsDragged?: Dispatch<SetStateAction<boolean>>;
  accept?: string;
  onFileSelected?: ({
    name,
    md5_hash,
    file,
  }: {
    name: string;
    md5_hash: string;
    file: File;
  }) => void;
};

export function HiddenInput<Instance extends BaseInstance = BaseInstance>({
  field,
  setIsDragged,
  onFileSelected,
  accept,
  className
}: HiddenFileProps<Instance>) {
  const convertFile = async (file: File) => {
    if (!file) return;
    const data = await handleFile(file);
    return data;
  };

  return (
    <input
      onDragEnter={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        if (setIsDragged) {
          setIsDragged(true);
        }
      }}
      onDragLeave={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        if (setIsDragged) {
          setIsDragged(false);
        }
      }}
      onDragOver={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();
      }}
      onDrop={async (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        const dt = evt.dataTransfer;
        const files = dt.files;

        if (!files?.length) return;

        const data = await convertFile(files[0]);

        if (onFileSelected && data) {
          onFileSelected(data);
        }

        if (field) {
          field.onChange(data);
        }

        if (setIsDragged) {
          setIsDragged(false);
        }
      }}
      id={`dropzone-file`}
      type='file'
      className={cn('absolute inset-0 opacity-0', className)}
      accept={accept || '*'}
      {...(field ?? {})}
      value={''}
      onChange={async (evt) => {
        if (!evt.target?.files?.length) return;
        const data = await convertFile(evt.target.files?.[0]);

        if (onFileSelected && data) {
          onFileSelected(data);
        }
        if (field) {
          field.onChange(data);
        }
      }}
    />
  );
}
