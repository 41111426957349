 import type { DebtInvoice } from '@pigello/pigello-matrix';

import { DebtInvoiceConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetDebtInvoice = (
  options: useGetGenericInstanceProps<DebtInvoice>
) => {
  return useGetInstance<DebtInvoice>({
    ...options,
    config: DebtInvoiceConfig,
    modelName: 'debtinvoice'
  });
};

export const useGetDebtInvoiceList = (
  options: useGetGenericListProps<DebtInvoice>
) => {
  return useGetList<DebtInvoice>({
    ...options,
    config: DebtInvoiceConfig,
    modelName: 'debtinvoice'
  });
};
