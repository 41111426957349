'use client';

import { columns } from '@/app/dashboard/structural/segments/columns';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { SegmentConfig, type Segment } from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './relation-item-map';

export const SegmentItem: RelationFieldProps<Segment> = {
  nested: getRelationFieldNames(SegmentConfig),
  filters: [],
  columns: columns,
  renderItem: (item, isRelationField) => ({
    title: item?.name,
    badge: (
      <Badge variant={'green'}>
        Gäller för {item?.realestates?.length} fastigheter
      </Badge>
    ),
    descriptionItems: (
      <div className='grid grid-cols-2'>
        <div
          className={cn(
            'flex w-full items-center',
            isRelationField && 'justify-between'
          )}
        >
          {item.apartmentCategories && item.apartmentCategories?.length > 0 && (
            <span>
              {item.apartmentCategories?.length} lägenhetskategori(er)
            </span>
          )}
          {item.brfApartmentCategories &&
            item.brfApartmentCategories?.length > 0 && (
              <span>
                {item.brfApartmentCategories?.length} brf-lägenhetskategori(er)
              </span>
            )}
        </div>
        <div
          className={cn(
            'flex w-full items-center',
            isRelationField && 'justify-between'
          )}
        >
          {item.industrialPremisesCategories &&
            item.industrialPremisesCategories?.length > 0 && (
              <span>
                {item.industrialPremisesCategories?.length} lokalkategori(er)
              </span>
            )}
          {item.commonAreaCategories &&
            item.commonAreaCategories?.length > 0 && (
              <span>{item.commonAreaCategories?.length} gemensam yta(er)</span>
            )}
        </div>
        {item.parkingSpotCategories &&
          item.parkingSpotCategories?.length > 0 && (
            <span>
              {item.parkingSpotCategories?.length} fordonsplatskategori(er)
            </span>
          )}
      </div>
    ),
  }),
};
