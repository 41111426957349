 import type { InvoiceCreditationConnection } from '@pigello/pigello-matrix';

import { InvoiceCreditationConnectionConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetInvoiceCreditationConnection = (
  options: useGetGenericInstanceProps<InvoiceCreditationConnection>
) => {
  return useGetInstance<InvoiceCreditationConnection>({
    ...options,
    config: InvoiceCreditationConnectionConfig,
    modelName: 'invoicecreditationconnection'
  });
};

export const useGetInvoiceCreditationConnectionList = (
  options: useGetGenericListProps<InvoiceCreditationConnection>
) => {
  return useGetList<InvoiceCreditationConnection>({
    ...options,
    config: InvoiceCreditationConnectionConfig,
    modelName: 'invoicecreditationconnection'
  });
};
