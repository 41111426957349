'use client';

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { LinkIcon } from '@heroicons/react/16/solid';
import PreviewCoInvoice from './preview-coinvoice';
import type { ICoinvoiceResult } from './types';

export default function PreviewCoInvoicing({
  coInvoiceData,
}: {
  coInvoiceData: ICoinvoiceResult[];
}) {
  return (
    <Card>
      <CardHeader>
        <CardTitle className='flex items-center'>
          <LinkIcon className='mr-2 size-4' />
          Samaviseringsnoteringar
        </CardTitle>
      </CardHeader>
      <CardContent className='grid grid-cols-2 items-start gap-4'>
        {coInvoiceData?.map((coInvoice) => (
          <PreviewCoInvoice coInvoice={coInvoice} key={coInvoice.id} />
        ))}
      </CardContent>
    </Card>
  );
}
