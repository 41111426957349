'use client';

import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { TableActions } from '@/components/data-table/table-actions';
import { TableTags } from '@/components/data-table/table-tags';
import { Checkbox } from '@/components/ui/checkbox';
import { Urls } from '@/lib/urls';
import { toReadableAddress } from '@/lib/utils';
import {
  CommunityConfig,
  CompanyConfig,
  RealEstateConfig,
  type RealEstate,
} from '@pigello/pigello-matrix';
import type { ColumnDef } from '@tanstack/react-table';
import { DropdownList } from '../../components/dropdown-list';

export const columns: ColumnDef<RealEstate>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'customId',
    meta: {
      columnVisibilityName: RealEstateConfig.fields.customId.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.customId ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'name',
    meta: {
      columnVisibilityName: RealEstateConfig.fields.name.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.name}</div>
    ),
  },
  {
    accessorKey: 'ownedBy',
    enableSorting: false,
    meta: {
      columnVisibilityName: RealEstateConfig.fields.ownedBy.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        config={CompanyConfig}
        detailModalLinkUrl={(id) => Urls.detail.company.overview(id)}
        fields={['name']}
        ids={[row.original.ownedBy?.id]}
        initialData={row.original.ownedBy}
      />
    ),
  },
  {
    accessorKey: 'typeCode',
    meta: {
      columnVisibilityName: RealEstateConfig.fields.typeCode.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {RealEstateConfig.fields.typeCode.choices?.find(
          (c) => c.v == row.original.typeCode
        )?.d ?? '-'}
      </div>
    ),
  },
  {
    accessorKey: 'community',
    enableSorting: false,
    meta: {
      columnVisibilityName: RealEstateConfig.fields.community.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        config={CommunityConfig}
        detailModalLinkUrl={(id) => Urls.detail.community.overview(id)}
        fields={['name']}
        ids={[row.original.community?.id]}
        initialData={row.original.community}
      />
    ),
  },
  {
    accessorKey: 'mainAddress',
    enableSorting: false,
    meta: {
      columnVisibilityName: RealEstateConfig.fields.mainAddress.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='whitespace-nowrap'>
        {toReadableAddress(row.original.mainAddress)}
      </span>
    ),
  },
  {
    accessorKey: 'tags',
    meta: {
      columnVisibilityName: 'Taggar',
    },
    header: 'Taggar',
    cell: ({ row }) => (
      <span className='flex gap-2'>
        <TableTags
          tags={row.original.tags}
          modelName={RealEstateConfig.modelName}
        />
      </span>
    ),
  },
  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <TableActions
        instance={row.original}
        config={RealEstateConfig}
        title={row.original.name}
      />
    ),
  },
];
