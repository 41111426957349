import { usePerms } from '@/hooks/usePerms';
import type { ModelName } from '@pigello/pigello-matrix';
import { useMemo, type ReactNode } from 'react';

interface RenderOnPermsProps {
  children: ReactNode;
  canDelete?: boolean;
  canCreate?: boolean;
  canUpdate?: boolean;
  canView?: boolean;
  modelName: ModelName;
}

export function RenderOnPerms({
  children,
  canDelete,
  canCreate,
  canUpdate,
  canView,
  modelName,
}: RenderOnPermsProps) {
  const {
    canCreate: create,
    canDelete: canYeet,
    canUpdate: update,
    canView: view,
  } = usePerms(modelName);
  // render children conditionally based on permissions
  const shouldRender = useMemo(() => {
    const perms = [canCreate, canUpdate, canDelete, canView];
    const permsForModelName = [create, update, canYeet, view];
    return perms.every((perm, i) => {
      if (perm === undefined) return true;
      return perm === permsForModelName[i];
    });
  }, [canCreate, canDelete, canUpdate, canView, canYeet, create, update, view]);

  if (!shouldRender) return null;

  return <>{children}</>;
}
