'use client';

import type { ColumnDef } from '@tanstack/react-table';

import { Checkbox } from '@/components/ui/checkbox';

import { ArticleDropdown } from '@/app/settings/modules/invoicing/articles/components/article-columns-dropdown';
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { TableActions } from '@/components/data-table/table-actions';
import { TableTags } from '@/components/data-table/table-tags';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
import type { SensorSpecification } from '@pigello/pigello-matrix';
import {
  PriceGroupConfig,
  SensorConfig,
  SensorSpecificationConfig,
} from '@pigello/pigello-matrix';

export const columns: ColumnDef<SensorSpecification>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'name',
    meta: {
      columnVisibilityName: PriceGroupConfig.fields.name.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.name ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'unitName',
    meta: {
      columnVisibilityName:
        SensorSpecificationConfig.fields.unitName.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.unitName ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'billingArticle',
    enableSorting: false,
    meta: {
      columnVisibilityName:
        SensorSpecificationConfig.fields.billingArticle.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) =>
      row.original.billingArticle ? (
        <ArticleDropdown
          triggerTitle={
            <div className='flex items-center gap-2'>
              {row.original.billingArticle.name}{' '}
              <ChevronDownIcon className='size-4' />
            </div>
          }
          article={row.original.billingArticle}
        />
      ) : (
        '-'
      ),
  },
  {
    accessorKey: 'tags',
    enableSorting: false,
    meta: {
      columnVisibilityName: SensorConfig.fields.tags.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='flex gap-2'>
        <TableTags
          tags={row.original.tags}
          modelName={SensorConfig.modelName}
        />
      </span>
    ),
  },
  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <TableActions
        instance={row.original}
        config={SensorSpecificationConfig}
        title={row.original.name}
      />
    ),
  },
];
