/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {
  BaseInstance,
  IBaseInstanceConfig,
  InferMonitoring,
  ManyBaseRelationalFieldValue,
  ManyRelationalFieldValue,
  ModelName,
  NonNullRelationalFieldValue,
} from '@pigello/pigello-matrix';
import { getConfig } from '@pigello/pigello-matrix';
import { toExternalFieldNames, toInternalFieldNames } from './instanceMapper';
import { postInstancesData } from './mutating';

import { cloneDeep, isEqual } from 'lodash';
import { deleteGenericInstance } from './deleting';

type BulkInstanceWrapperState = Record<string, any>;
type BulkInstanceWrapperSubscribeCallback<Instance extends BaseInstance> = (
  instance: BulkInstanceWrapper<Instance>
) => void;

export type BulkInstanceWrapperErrors<Instance extends BaseInstance> = Array<{
  fieldName: keyof Instance;
  message: string;
}>;

const BULK_INSTANCE_WRAPPER_IDENTIFIER_PREFIX = 'bulkWrapperInstance_';

//has to have one of these because of monitoring, especially in bulk modal.
export class BulkInstanceWrapper<Instance extends BaseInstance> {
  instanceId: string;
  identifier: string;
  instance: Partial<Instance>;
  monitoredInstance?: Partial<InferMonitoring<Instance>>; //Make cool type for this when ready

  initialInstance: Partial<Instance>;

  mutatedFields: Array<keyof Instance> = [];

  private _errors: BulkInstanceWrapperErrors<Instance> = [];

  private _bulk: BulkMutate<Instance> | null = null;

  private _state: BulkInstanceWrapperState = {};

  private _subscriptions: Map<
    string,
    BulkInstanceWrapperSubscribeCallback<Instance>
  > = new Map();

  constructor(instance: Partial<Instance>, markAsMutated = false) {
    this.instanceId = instance.id!;
    this.identifier = `${BULK_INSTANCE_WRAPPER_IDENTIFIER_PREFIX}${crypto.randomUUID()}`;

    //check if is monitoring instance
    this.instance = instance;

    this.initialInstance = cloneDeep(this.instance);

    if (markAsMutated) {
      for (const key in this.activeInstance) {
        this.mutatedFields.push(key as keyof Instance);
      }
    }
  }

  setMonitorInstance(instance: InferMonitoring<Instance>) {
    this.monitoredInstance = instance;
  }

  copyInstance() {
    const copy = new BulkInstanceWrapper(cloneDeep(this.activeInstance));

    copy.setBulk(this.getBulk());

    copy.mutatedFields = this.mutatedFields.slice();

    return copy;
  }

  setState(newState: Partial<BulkInstanceWrapperState>) {
    this._state = {
      ...this._state,
      ...newState,
    };

    this.inform();
  }

  getState() {
    return this._state;
  }

  setBulk(bulk: BulkMutate<Instance>) {
    this._bulk = bulk;
  }

  getBulk() {
    if (!this._bulk)
      throw Error(
        'Tried to get parent bulk on a bulk instance without a registered parent'
      );

    return this._bulk;
  }

  hasBulk() {
    if (!this._bulk) return false;
    return true;
  }

  isClean() {
    if (!this.activeInstance.id) return true;

    if (this.activeInstance.id.length === 0) return true;

    return false;
  }

  setErrors(errors: BulkInstanceWrapperErrors<Instance>) {
    this._errors = errors;

    this.inform();
  }

  getMutatedValues() {
    const data: Partial<Instance> | Partial<InferMonitoring<Instance>> = {};

    for (const fieldName of this.mutatedFields) {
      data[fieldName] = this.activeInstance[fieldName];
    }

    return data;
  }

  getErrors() {
    return this._errors;
  }

  hasErrors() {
    return this._errors.length !== 0;
  }

  setActiveInstance(
    instance: Partial<Instance> | Partial<InferMonitoring<Instance>>
  ) {
    if (this.isMonitored()) {
      this.monitoredInstance = instance as Partial<InferMonitoring<Instance>>;
    } else {
      this.instance = instance;
    }
  }

  get activeInstance(): Partial<Instance> | Partial<InferMonitoring<Instance>> {
    //or monitoring instance
    //return monitoring instance if we have one.

    if (this.isMonitored()) return this.monitoredInstance!;

    return this.instance;
  }

  isMonitored() {
    return Boolean(this.monitoredInstance);
  }

  public setValue(key: keyof Instance, value: any) {
    this.activeInstance[key] = value;

    this.inform();
  }

  private inform() {
    for (const callback of this._subscriptions.values()) {
      callback(this);
    }
  }

  subscribe(callback: BulkInstanceWrapperSubscribeCallback<Instance>) {
    const subscriptionIdentifier = crypto.randomUUID();

    this._subscriptions.set(subscriptionIdentifier, callback);

    return subscriptionIdentifier;
  }

  unsubscribe(subscriptionIdentifier: string) {
    this._subscriptions.delete(subscriptionIdentifier);
  }

  cleanup() {
    this._subscriptions = new Map();
  }
}

declare global {
  interface Window {
    _bulks?: Map<string, BulkMutate<any>>;
  }
}

export class BulkMutate<Instance extends BaseInstance> {
  private _config!: IBaseInstanceConfig<Instance>;

  private modelName: ModelName;

  private _initCalled: boolean;

  private registeredFields: Array<keyof Instance> | null = null;

  private sendOnlyMutatedFields: boolean;

  private _instances: BulkInstanceWrapper<Instance>[] = [];

  private _canSaveTimes = Infinity;
  private _timesSaved = 0;

  private _trigger: (() => void) | null = null;

  private _expectBulkInstancesAsValue = false;

  private mountIdentifier: string | null = null;

  hasBeenSaved = false;

  constructor(modelName: ModelName, config?: IBaseInstanceConfig<Instance>) {
    if (modelName === 'unset')
      throw Error("Cannot initialize bulk with model name 'unset'!");

    this._initCalled = false;

    this.sendOnlyMutatedFields = true;

    this.modelName = modelName;

    if (config) {
      this.makeUsable(config);
    }
  }

  isUsable() {
    if (!this._initCalled) throw Error("BulkMutate hasn't been inited");
    return this._initCalled;
  }

  //Must call init!
  async init() {
    return this.makeUsable(await getConfig<Instance>(this.modelName));
  }

  private makeUsable(config: IBaseInstanceConfig<Instance>) {
    if (this._initCalled) throw Error('Cannot init BulkMutate twice');

    this._config = config;
    this._initCalled = true;

    return this;
  }

  public getMountIdentifier() {
    return this.mountIdentifier;
  }

  public mount(_identifier?: string) {
    this.isUsable();

    const identifier = _identifier || `mounted-bulk-${crypto.randomUUID()}`;

    if (!window._bulks) {
      window._bulks = new Map();
    }

    window._bulks.set(identifier, this);

    this.mountIdentifier = identifier;

    return identifier;
  }

  public unmount() {
    if (!window._bulks || !this.mountIdentifier) return;

    window._bulks?.delete(this.mountIdentifier);
  }

  static getMountedBulk<Instance extends BaseInstance>(identifier: string) {
    if (!window._bulks) {
      console.warn(
        'Tried to get mounted bulk w/ identifier',
        identifier,
        'but no bulks have been mounted'
      );

      return null;
    }

    return window._bulks.get(identifier) as BulkMutate<Instance>;
  }

  static findBulkByModelName(modelName: ModelName) {
    if (!window._bulks) {
      return null;
    }

    for (const value of window._bulks.values()) {
      if (value.config.modelName === modelName) return value;
    }

    return null;
  }

  static clean() {
    if (!window._bulks) return;

    for (const bulk of window._bulks.values()) {
      bulk.unmount();
      bulk.clear();
    }
  }

  public trigger() {
    if (!this._trigger) return;

    this._trigger();
  }

  public setTrigger(trigger: () => void) {
    this.isUsable();
    this._trigger = trigger;
  }

  public removeTrigger() {
    this.isUsable();
    this._trigger = null;
  }

  get config() {
    this.isUsable();
    return this._config;
  }

  get instances() {
    return this._instances.map((instance) => instance.activeInstance);
  }

  get monitoredInstances() {
    return this._instances
      .filter((instance) => instance.isMonitored())
      .map((instance) => instance.activeInstance);
  }

  get bulkInstances() {
    return this._instances;
  }

  //default = true
  saveOnlyMutations(bool: boolean) {
    this.sendOnlyMutatedFields = bool;
  }

  setFields(fields: Array<keyof Instance>) {
    this.isUsable();
    this.registeredFields = fields;
    return this;
  }

  private createNewWrapper(instance: Partial<Instance>, markAsMutated = false) {
    const newWrapper = new BulkInstanceWrapper(instance, markAsMutated);

    newWrapper.setBulk(this);

    return newWrapper;
  }

  public insertBulkInstance(
    bulkInstance: BulkInstanceWrapper<Instance>,
    index: number
  ) {
    this.isUsable();

    if (bulkInstance.getBulk().config.modelName !== this.config.modelName)
      return;

    if (
      this.bulkInstances.find(
        (inst) => inst.identifier === bulkInstance.identifier
      )
    )
      return;

    if (index > this._instances.length) return;

    this._instances = [
      ...this._instances.slice(0, index),
      bulkInstance,
      ...this._instances.slice(index, this._instances.length),
    ];

    this.trigger();
  }

  public pushBulkInstance(bulkInstance: BulkInstanceWrapper<Instance>) {
    this.isUsable();

    if (bulkInstance.getBulk().config.modelName !== this.config.modelName)
      return;

    if (
      this.bulkInstances.find(
        (inst) => inst.identifier === bulkInstance.identifier
      )
    )
      return;

    this._instances.push(bulkInstance);

    this.trigger();
  }

  push(instance: Partial<Instance>) {
    this.isUsable();
    this._instances.push(this.createNewWrapper(instance));

    this.trigger();
  }

  pushAsMutated(instance: Partial<Instance>, skipTrigger = false) {
    this.isUsable();
    this._instances.push(this.createNewWrapper(instance, true));

    if (skipTrigger) {
      return;
    }

    this.trigger();
  }

  setBulkInstances(bulkInstances: BulkInstanceWrapper<Instance>[]) {
    this.isUsable();

    this._instances = bulkInstances;

    this.trigger();
  }

  set(instances: Partial<Instance>[]) {
    this.isUsable();

    this._instances = instances.map((instance) =>
      this.createNewWrapper(instance)
    );

    this.trigger();
  }

  setAsMutated(instances: Partial<Instance>[]) {
    this.isUsable();

    this._instances = instances.map((instance) =>
      this.createNewWrapper(instance, true)
    );

    this.trigger();
  }

  limitSaves(saves: number) {
    if (saves < 1) return;
    this._canSaveTimes = saves;
  }

  getInstance(identifier: string) {
    this.isUsable();

    return this.bulkInstances.find((inst) => inst.identifier === identifier);
  }

  subscribeToInstance(
    identifier: string,
    callback: BulkInstanceWrapperSubscribeCallback<Instance>
  ) {
    const instance = this.getInstance(identifier);

    if (!instance) return '';

    return instance.subscribe(callback);
  }

  unsubscribeToInstance(
    instanceIdentifier: string,
    subscriptionIdentifier: string
  ) {
    const instance = this.getInstance(instanceIdentifier);

    if (!instance) return;

    instance.unsubscribe(subscriptionIdentifier);
  }

  updateInstance(
    instance: BulkInstanceWrapper<Instance>,
    newData: Partial<Instance>
  ) {
    this.isUsable();

    const inst = this.bulkInstances.find(
      (i) => i.identifier === instance.identifier
    );

    if (!inst)
      throw Error(
        `Unable to find bulk instance with identifier ${instance.identifier}`
      );

    for (const [key, value] of Object.entries(newData)) {
      if (isEqual(value, inst.initialInstance[key as keyof Instance])) {
        if (inst.mutatedFields.includes(key as keyof Instance)) {
          //revert mutate values if same as initial
          inst.mutatedFields.splice(
            inst.mutatedFields.indexOf(key as keyof Instance),
            1
          );
        }
      } else if (!inst.mutatedFields.includes(key as keyof Instance)) {
        inst.mutatedFields.push(key as keyof Instance);
      }
      inst.setValue(key as keyof Instance, value);
    }

    this.trigger();
  }

  markFieldAsMutated(
    instance: BulkInstanceWrapper<Instance>,
    fieldName: keyof Instance
  ) {
    this.isUsable();

    const inst = this.bulkInstances.find(
      (i) => i.identifier === instance.identifier
    );

    if (!inst)
      throw Error(
        `Unable to find bulk instance with identifier ${instance.identifier}`
      );

    if (inst.mutatedFields.includes(fieldName)) return;

    instance.mutatedFields.push(fieldName);
  }

  //this will just remove the instance from this bulk. not actually delete it
  public removeInstance(bulkInstance: BulkInstanceWrapper<Instance>) {
    this.isUsable();

    const instanceIndex = this.bulkInstances.findIndex(
      (i) => i.identifier === bulkInstance.identifier
    );

    if (instanceIndex === -1)
      throw Error(
        `Unable to find bulk instance with identifier ${bulkInstance.identifier}`
      );

    bulkInstance.cleanup();

    this._instances.splice(instanceIndex, 1);

    this.trigger();
  }

  resetField(
    bulkInstance: BulkInstanceWrapper<Instance>,
    fieldName: keyof Instance
  ) {
    this.isUsable();

    console.log('resetting field', fieldName);

    const inst = this.bulkInstances.find(
      (i) => i.identifier === bulkInstance.identifier
    );

    if (!inst)
      throw Error(
        `Unable to find bulk instance with identifier ${bulkInstance.identifier}`
      );

    if (!inst.mutatedFields.includes(fieldName)) return;

    const initialValue = inst.initialInstance[fieldName];

    if (
      initialValue === undefined &&
      inst.activeInstance[fieldName] === undefined
    )
      return;

    inst.activeInstance[fieldName] = initialValue as Instance[keyof Instance];

    inst.mutatedFields.splice(inst.mutatedFields.indexOf(fieldName), 1);

    this.trigger();
  }

  getBulkInstancesWithErrors() {
    return this.bulkInstances.filter(
      (bulkInstance) => bulkInstance.getErrors().length !== 0
    );
  }

  getMonitoredBulkInstances() {
    return this.bulkInstances.filter((bulkInstance) =>
      bulkInstance.isMonitored()
    );
  }

  hasErrors() {
    for (const bulkInstance of this.bulkInstances) {
      if (bulkInstance.hasErrors()) return true;
    }

    return false;
  }

  public hasBulkInstancesAsValues() {
    return this._expectBulkInstancesAsValue;
  }

  public expectBulkInstancesAsValue() {
    this._expectBulkInstancesAsValue = true;
  }

  async build() {
    const instancesToSend: Partial<Instance>[] = [];
    const instanceIndexesSent: number[] = [];

    //as to remap all manyrelations from bulk instances to their actual instances

    //only works for clean instances at the moment.
    if (this.sendOnlyMutatedFields) {
      let index = 0;
      for (const bulkInstance of this.bulkInstances) {
        const partialInstance: Partial<Instance> = {};

        for (const internalFieldName in bulkInstance.activeInstance) {
          if (
            !bulkInstance.mutatedFields.includes(
              internalFieldName as keyof Instance
            )
          )
            continue;

          let currentValue =
            bulkInstance.activeInstance[internalFieldName as keyof Instance];

          const fieldConfig =
            this.config.fields[
              internalFieldName as keyof typeof this.config.fields
            ];

          if (
            fieldConfig?.type === 'manyrelation' &&
            this._expectBulkInstancesAsValue &&
            this.config &&
            !fieldConfig.isAddressField
          ) {
            //@ts-expect-error its just the bulk modal that requires this.
            currentValue = (
              currentValue as ManyBaseRelationalFieldValue<Instance>
            )
              .filter((someInstance) => someInstance != null)
              .map((someInstance) => {
                if ('identifier' in someInstance) {
                  if (
                    (someInstance['identifier'] as string).startsWith(
                      BULK_INSTANCE_WRAPPER_IDENTIFIER_PREFIX
                    )
                  ) {
                    const bulkInstance =
                      someInstance as unknown as BulkInstanceWrapper<Instance>;

                    return { id: bulkInstance.activeInstance.id };
                  }
                } else {
                  return { id: someInstance.id };
                }

                return someInstance;
              });
          } else if (
            fieldConfig.type === 'relation' &&
            !fieldConfig.isAddressField
          ) {
            const bulk = BulkMutate.getMountedBulk(
              //@ts-expect-error will be refactored
              currentValue.__BULK_IDENTIFIER__
            );

            const otherBulkInstance = bulk?.getInstance(
              //@ts-expect-error will be refactored
              currentValue.__BULK_INSTANCE_IDENTIFIER__
            );

            if (otherBulkInstance != null) {
              //@ts-expect-error will be refactored
              currentValue = {
                id: otherBulkInstance.activeInstance.id,
              };
            } else {
              currentValue = {
                id: (currentValue as NonNullRelationalFieldValue<Instance>).id,
              } as (
                | Partial<Instance>
                | Partial<InferMonitoring<Instance>>
              )[keyof Instance];
            }
          } else if (
            fieldConfig.type === 'manyrelation' &&
            !fieldConfig.isAddressField
          ) {
            const val = currentValue as ManyRelationalFieldValue<BaseInstance>;

            if (val && val.length !== 0) {
              //@ts-expect-error FIX!
              currentValue = val.map((inst) => ({ id: inst.id }));
            }
          }

          //@ts-expect-error hej
          partialInstance[
            // @ts-expect-error NAH
            internalFieldName as keyof typeof this.config.fields
          ] = currentValue;
        }

        if (
          bulkInstance.activeInstance.id &&
          bulkInstance.activeInstance.id.length !== 0
        ) {
          partialInstance.id = bulkInstance.activeInstance.id;
        }

        if (Object.keys(partialInstance).length !== 0) {
          instancesToSend.push(partialInstance);
          instanceIndexesSent.push(index);
        }

        index++;
      }
    } else {
      // instancesToSend = this.instances;
      for (let i = 0; i < this.instances.length; i++) {
        const partialInstance: Partial<Instance> = {};

        let fieldName: keyof typeof partialInstance;
        for (fieldName in this.instances[i]) {
          const currentValue = this.instances[i][fieldName];

          if (typeof currentValue === 'string' && currentValue.length !== 0) {
            //@ts-expect-error will be refactored
            partialInstance[fieldName] = currentValue;
          }

          if (Array.isArray(currentValue) && currentValue.length !== 0) {
            //@ts-expect-error will be refactored
            partialInstance[fieldName] = currentValue;
          }

          if (
            typeof currentValue === 'object' &&
            currentValue !== null &&
            Object.keys(currentValue).length !== 0
          ) {
            //@ts-expect-error will be refactored
            partialInstance[fieldName] = currentValue;
          }

          if (typeof currentValue === 'boolean') {
            //@ts-expect-error will be refactored
            partialInstance[fieldName] = currentValue;
          }

          if (typeof currentValue === 'number')
            //@ts-expect-error will be refactored
            partialInstance[fieldName] = currentValue;

          if (currentValue === null) {
            //@ts-expect-error will be refactored
            partialInstance[fieldName] = currentValue;
          }
        }

        if (Object.keys(partialInstance).length !== 0) {
          instancesToSend.push(partialInstance);
          instanceIndexesSent.push(i);
        }
      }
    }

    const data = [];

    for (const instance of instancesToSend) {
      const externalName = await toExternalFieldNames(this.config, instance);
      data.push(externalName);
    }

    return { data, instanceIndexesSent };
  }

  public clearInstances() {
    this._instances = [];
  }

  public clear() {
    this.clearInstances();
    //@ts-expect-error clearing
    this._config = null;
  }

  overrideUrl = '';
  shouldOverrideUrl = false;

  public setOverrideUrl(url: string) {
    this.overrideUrl = url;
    this.shouldOverrideUrl = true;
  }

  skipOrganizationOnFieldNames: (keyof Instance)[] = [];

  setSkipOrganizationOnFieldNames(keys: (keyof Instance)[]) {
    this.skipOrganizationOnFieldNames = keys;
  }

  async save() {
    if (this._timesSaved >= this._canSaveTimes)
      return { didSend: false, err: false };

    const { data, instanceIndexesSent } = await this.build();

    if (data.length === 0) return { didSend: false, err: false };

    this.hasBeenSaved = true;

    const skipOrgOnExternalFieldNames: string[] =
      this.skipOrganizationOnFieldNames.map(
        (fieldName) => this.config.fields[fieldName].externalFieldName
      );

    const res = await postInstancesData({
      config: this.config,
      data,
      overrideUrl: this.shouldOverrideUrl ? this.overrideUrl : undefined,
      skipOrganizationOnExternalFieldNames: skipOrgOnExternalFieldNames,
    });

    return this.handleRequestResponse(res, instanceIndexesSent);
  }

  async delete() {
    const data = [];
    const instanceIndexesSent = [];

    let index = 0;
    for (const bulkInstance of this.bulkInstances) {
      if (bulkInstance.activeInstance.id) {
        data.push(bulkInstance.activeInstance.id);
        instanceIndexesSent.push(index);
      }
      index++;
    }

    if (data.length === 0) return { didSend: false, err: false };

    for (const instId of data) {
      await deleteGenericInstance({
        modelName: this.config.modelName,
        id: instId,
      });
    }

    return { didSend: true, err: false };
  }

  private async handleRequestResponse(
    res:
      | {
          err: boolean;
          data: {
            [key: string]: any;
          };
        }
      | (Instance | InferMonitoring<Instance>)[],
    instanceIndexesSent: number[]
  ) {
    if ('err' in res) {
      if (!res.data?.json) return { didSend: true, err: true };

      if (!Array.isArray(res.data.json)) return { didSend: true, err: true };

      let index = -1;
      for (const externalObj of res.data.json) {
        index++;
        if (Object.keys(externalObj).length === 0) continue;

        const errorDataForInstance = await toInternalFieldNames(
          this.config,
          externalObj
        );

        const instanceErrors: BulkInstanceWrapperErrors<Instance> = [];

        for (const fieldName in errorDataForInstance) {
          if (errorDataForInstance[fieldName] !== undefined) {
            instanceErrors.push({
              fieldName: fieldName as keyof Instance,
              message: (errorDataForInstance[fieldName] as string[])[0],
            });
          }
        }

        const instance = this.bulkInstances[instanceIndexesSent[index]];

        instance.setErrors(instanceErrors);
      }

      return { didSend: true, err: true, gotErrors: true };
    }

    let index = 0;
    for (const instanceData of res) {
      const data = await toInternalFieldNames(this.config, instanceData);

      if ('mtAddedTime' in instanceData) {
        this.bulkInstances[instanceIndexesSent[index]].setMonitorInstance(
          data as InferMonitoring<Instance>
        );
      } else {
        this.updateInstance(this.bulkInstances[instanceIndexesSent[index]], {
          ...data,
        } as Partial<Instance>);
      }

      index++;
    }

    return { didSend: true, err: false, rawResult: res };
  }
}
