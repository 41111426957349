'use client';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Form } from '@/components/ui/form';
import { useCreateAddress } from '@/config/common/address/create';
import { useForm } from '@/hooks/useForm';
import { handleFormErrors } from '@/hooks/useForm/utils';
import {
  AddressConfig,
  getCleanAddress,
  type Address,
} from '@pigello/pigello-matrix';
import { toast } from 'sonner';
import { DynamicField } from '../../..';

export default function CreateAddressInFieldModal({
  closeFunction,
  successCallback,
}: {
  closeFunction: VoidFunction;
  successCallback: (data: Address) => void;
}) {
  const form = useForm({
    config: AddressConfig,
    defaultValues: getCleanAddress(),
    isUpdate: false,
    orderedFields: [
      {
        key: 'address',
        fields: [
          'street',
          'streetNumber',
          'postalCode',
          'city',
          'country',
          'customId',
        ],
      },
    ],
  });

  const closeAndReset = () => {
    form.reset();
    closeFunction();
  };
  const { mutateAsync: createAsync } = useCreateAddress({});

  const onSubmit = () => {
    const data = form.getDirtyData();

    toast.promise(createAsync({ body: data }), {
      loading: 'Sparar adress...',
      success: (data) => {
        successCallback(data);
        closeAndReset();
        return 'Adressen sparades och la till i formuläret';
      },
      error: (err) => {
        const field = handleFormErrors(err, form);

        return `Adressen kunde inte sparas: ${field}`;
      },
    });
  };

  return (
    <Dialog defaultOpen onOpenChange={closeAndReset}>
      <DialogContent size='sm' className='gap-0 p-0'>
        <Form {...form}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              form.clearErrors();
              form.handleSubmit(onSubmit)(e);
            }}
          >
            <DialogHeader className='border-b p-4'>
              <DialogTitle>Skapa ny adress</DialogTitle>
            </DialogHeader>

            <div className='grid p-4'>
              <div className='grid grid-cols-2 gap-4'>
                {form.orderedFields.address.map((field) => (
                  <DynamicField key={field.name} formField={field} />
                ))}
              </div>
            </div>

            <DialogFooter className='border-t p-4'>
              <Button type='button' variant='outline' onClick={closeAndReset}>
                Avbryt
              </Button>
              <Button>Spara</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
