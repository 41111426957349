import DeleteModal from '@/components/delete-modal/delete-modal';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useMutateArticle } from '@/config/accounting/articles/article/mutate';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useToast } from '@/hooks/useToast';
import type { ErrorResponse } from '@/requests/types';
import {
  ClipboardDocumentIcon,
  EllipsisHorizontalIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/16/solid';
import {
  ArticleConfig,
  type Article,
  type RelationalFieldValue,
} from '@pigello/pigello-matrix';
import { useState, type ReactNode } from 'react';
import { ArticleRentForm } from '../components/article-rent-form';
import { ArticleConsumableForm } from './article-consumable-form';

export function ArticleDropdown({
  article,
  triggerTitle,
}: {
  article: RelationalFieldValue<Article>;
  triggerTitle?: ReactNode;
}) {
  const [open, setOpen] = useState(false);
  const { addToast } = useToast();
  const { mutateAsync } = useMutateArticle({});
  const [deleteId, setDeleteId] = useState<string>();
  const [editId, setEditId] = useState<string>();
  const copyIdToClipboard = (id: string) => {
    addToast({
      type: 'promise',
      promise: navigator.clipboard.writeText(id),
      loading: 'Kopierar id till urklipp...',
      success: () => 'Id har kopierats till urklipp',
      error: () => 'Kunde inte kopiera id till urklipp',
    });
  };
  const handleArchive = (id: string) => {
    addToast({
      type: 'promise',
      promise: mutateAsync({
        id: id,
        body: { archived: true },
      }),
      loading: 'Arkiverar artikel...',
      success: () => {
        setDeleteId(undefined);
        return 'Artikeln har arkiverats';
      },
      error: (error: ErrorResponse) => {
        const field = handleFormErrors(error);
        if (field) {
          return field;
        }
        return 'Kunde inte arkivera artikeln';
      },
    });
  };
  return (
    <>
      {deleteId && (
        <DeleteModal
          id={deleteId}
          modelName={ArticleConfig.modelName}
          setOpen={() => setDeleteId(undefined)}
          onError={(error) => {
            if (
              'archived' in error.errorData &&
              typeof error.errorData.archived === 'string'
            ) {
              addToast(
                {
                  type: 'warning',
                  title: 'Raderingen misslyckades',
                  message: error.errorData.archived,
                },
                {
                  duration: 10000,
                  action: {
                    label: 'Arkivera',
                    onClick: () => handleArchive(deleteId),
                  },
                }
              );
            }
          }}
        />
      )}
      {editId && article?.invoiceTypes === 0 && (
        <ArticleRentForm
          articleId={editId}
          closeFunction={() => setEditId(undefined)}
        />
      )}
      {editId && article?.invoiceTypes === 2 && (
        <ArticleConsumableForm
          articleId={editId}
          closeFunction={() => setEditId(undefined)}
        />
      )}
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger disabled={!article?.id} asChild>
          <Button
            variant={'ghost'}
            size={'icon-sm'}
            className='whitespace-nowrap'
          >
            {triggerTitle ?? <EllipsisHorizontalIcon className='size-4' />}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='end'>
          <DropdownMenuItem onSelect={() => setEditId(article?.id)}>
            <PencilIcon className='mr-2 size-4' /> Redigera
          </DropdownMenuItem>
          {article?.id && (
            <DropdownMenuItem onSelect={() => copyIdToClipboard(article?.id)}>
              <ClipboardDocumentIcon className='mr-2 size-4' /> Kopiera id
            </DropdownMenuItem>
          )}
          <DropdownMenuSeparator />
          <DropdownMenuItem onSelect={() => setDeleteId(article?.id)}>
            <TrashIcon className='mr-2 size-4' /> Radera
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
