import { Button } from '@/components/ui/button';
import { labelVariants } from '@/components/ui/label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import {
  CalendarIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import { useRef } from 'react';
import { useDateRangePicker, type DateValue } from 'react-aria';
import {
  useDateRangePickerState,
  type DateRangePickerStateOptions,
} from 'react-stately';
import { DateField } from '../date-field/date-field';
import { RangeCalendar } from './range-calendar';

export function DateRangePicker(props: DateRangePickerStateOptions<DateValue>) {
  const state = useDateRangePickerState(props);
  const ref = useRef<HTMLDivElement>(null);
  const {
    groupProps,
    labelProps,
    startFieldProps,
    endFieldProps,
    buttonProps,
    calendarProps,
    dialogProps,
  } = useDateRangePicker(props, state, ref);

  return (
    <div className='relative inline-flex w-full flex-col gap-2 text-left'>
      <span {...labelProps} className={cn(labelVariants())}>
        {props.label}
      </span>
      <div {...groupProps} ref={ref} className='flex'>
        <div className='flex h-10 flex-1 items-center justify-between rounded-l-md border border-r-0'>
          <div className='flex items-center'>
            <DateField
              {...startFieldProps}
              hideResetButton
              className='border-none shadow-none'
            />
            <span aria-hidden='true' className='pl-2'>
              -
            </span>
            <DateField
              {...endFieldProps}
              hideResetButton
              className='border-none pr-2 shadow-none'
            />
          </div>
          {(state.value?.start || state.value?.end) && (
            <Button
              variant={'ghost'}
              size={'icon'}
              className='mr-2'
              onClick={() => state.setValue(null)}
            >
              <XMarkIcon className='size-4' />
            </Button>
          )}
          {state.isInvalid && (
            <ExclamationTriangleIcon className='absolute right-1 size-6 text-red-500' />
          )}
        </div>
        <Popover open={state.isOpen} onOpenChange={state.setOpen}>
          <PopoverTrigger asChild>
            <Button
              {...buttonProps}
              variant='outline'
              className='h-10 rounded-l-none'
              disabled={props.isDisabled}
            >
              <CalendarIcon className='size-5' />
            </Button>
          </PopoverTrigger>
          <PopoverContent onInteractOutside={() => state.setOpen(false)}>
            <div {...dialogProps}>
              <RangeCalendar {...calendarProps} />
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}
