import { Badge } from '@/components/ui/badge';
import type { Tenant } from '@pigello/pigello-matrix';

const isActive = [0, 1, 2, 3, 4, 5, 6, 7, 8, 11];

export function TenantBadge({ tenant }: { tenant: Tenant }) {
  if (tenant.status == null) {
    return null;
  }
  if (isActive.includes(tenant.status)) {
    return <Badge variant={'green'}>Aktiv</Badge>;
  }

  if (
    !tenant.earliestActiveDate &&
    (tenant.status === 12 || tenant.status === 10)
  ) {
    return <Badge variant={'blue'}>Potentiell</Badge>;
  }

  if (
    tenant.status === 12 ||
    (tenant.latestActiveDate && new Date(tenant.latestActiveDate) < new Date())
  ) {
    return <Badge variant={'gray'}>Tidigare</Badge>;
  }

  if (tenant.status === 9) {
    return <Badge variant={'blue'}>Kommande</Badge>;
  }

  return (
    <Badge variant={'orange'}>
      {tenant.status} - {tenant.earliestActiveDate} - {tenant.latestActiveDate}
    </Badge>
  );
}
