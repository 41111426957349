 import type { Note } from '@pigello/pigello-matrix';

import { NoteConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useCreateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, createGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { createGenericInstance } from '@/requests/creating';

export const createNote = async (options: createGenericInstanceProps<Note>) => {
  return createGenericInstance<Note>({
    ...options,
    config: NoteConfig,
  });
};

export const useCreateNote = (
  options: UseMutationOptions<Note, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useCreateGenericInstance<Note>(createNote, options, 'note');
