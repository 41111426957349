'use client';

import { useConfig } from '@/hooks/useConfig';
import { raise } from '@/lib/utils';
import { useGetList } from '@/requests/hooks';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { TAG_FILTER, type IAppliedFilter, type IFilter } from './constants';

export default function RelationDisplay<T extends BaseInstance>({
  filterConfig,
  filter,
}: {
  filterConfig: IFilter;
  filter: IAppliedFilter;
}) {
  if (!filterConfig.relationModelName)
    raise('Modelname missing from filter config');

  const { data: values } = useGetList<T>({
    modelName: filterConfig.relationModelName,
    queryParams: {
      filters: {
        id: { __in: (filter.value as unknown as string[]).join(',') },
      },
    },
    enabled: Array.isArray(filter.value),
  });

  const { config } = useConfig<T>(filterConfig.relationModelName);

  if (!config || !values?.list) return 'Laddar...';

  return (
    <div className='flex items-center'>
      {filterConfig.kind === TAG_FILTER && 'colorCode' in values.list[0] && (
        <div className='flex items-center space-x-2'>
          {' '}
          <div
            className='rounded-full p-1.5'
            style={{
              // @ts-expect-error color code is funky
              backgroundColor: values.list[0].colorCode?.startsWith('#')
                ? values.list[0].colorCode
                : `#${values.list[0]?.colorCode}`,
            }}
          />{' '}
          <div>
            {values.list[0][config.displayFieldName as keyof T] as string}
          </div>
        </div>
      )}
      {filterConfig.kind !== TAG_FILTER &&
        (values.list[0][config.displayFieldName as keyof T] as string)}{' '}
      {values.list.length > 1 ? `+${values.list.length - 1}` : ''}
    </div>
  );
}
