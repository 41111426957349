import { cn } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  QuotaCollectionConfig,
  type QuotaCollection,
} from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './relation-item-map';
export const QuotaCollectionItem: RelationFieldProps<QuotaCollection> = {
  columns: [],
  nested: getRelationFieldNames(QuotaCollectionConfig),
  filters: [],
  renderItem: (item, isRelationField) => ({
    title: item?.name,
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <span className='font-medium'>{item.idNumber}</span>
      </div>
    ),
  }),
};
