import { columns } from '@/app/dashboard/parking-spots/columns';
import parkingSpotFilters from '@/app/dashboard/parking-spots/filters';
import { StatusBadge } from '@/components/ui/statusBadge';
import { cn, toReadableAddress, toSqm } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { ParkingSpotConfig, type ParkingSpot } from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './relation-item-map';

export const ParkingSpotItem: RelationFieldProps<ParkingSpot> = {
  nested: getRelationFieldNames(ParkingSpotConfig),
  filters: parkingSpotFilters,
  columns: columns,
  renderItem: (item, isRelationField) => ({
    title: item.customId ?? item?.popularName ?? 'Ingen namn angivet',
    badge: <StatusBadge type='object' status={item.status} />,
    descriptionItems: (
      <div
        className={cn(
          isRelationField ? 'grid w-full' : 'flex flex-row items-center'
        )}
      >
        <span className='font-medium'>
          {item.address
            ? toReadableAddress(item.address, true, false)
            : 'Ingen adress angiven'}
        </span>

        {!isRelationField && <Dot />}
        <div
          className={cn(
            'flex w-full items-center',
            isRelationField && 'justify-between'
          )}
        >
          <span className='text-xs'>
            {' '}
            {ParkingSpotConfig.fields.category.choices?.find(
              (c) => c.v == item.category
            )?.d ?? 'Ingen kategori angiven'}
          </span>
          {!isRelationField && <Dot />}
          <span>
            {item.usableArea ? toSqm(item.usableArea) : 'Ingen area angiven'}
          </span>
        </div>
      </div>
    ),
  }),
};
