'use client';

import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { TableActions } from '@/components/data-table/table-actions';
import { TableTags } from '@/components/data-table/table-tags';
import { Checkbox } from '@/components/ui/checkbox';
import { StatusBadge } from '@/components/ui/statusBadge';
import { Urls } from '@/lib/urls';
import { toReadableAddress, toSqm } from '@/lib/utils';
import {
  BuildingConfig,
  ParkingLotConfig,
  ParkingSpotConfig,
  TenantConfig,
  type ParkingSpot,
  type Tenant,
} from '@pigello/pigello-matrix';
import type { ColumnDef } from '@tanstack/react-table';
import { DropdownList } from '../components/dropdown-list';

export const columns: ColumnDef<ParkingSpot>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'status',
    meta: {
      columnVisibilityName: ParkingSpotConfig.fields.status.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return <StatusBadge status={row.original.status} />;
    },
  },
  {
    accessorKey: 'customId',
    meta: {
      columnVisibilityName: ParkingSpotConfig.fields.customId.verboseName,

      isStaticFilter: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.customId ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'popularName',
    meta: {
      columnVisibilityName: ParkingSpotConfig.fields.popularName.verboseName,

      isStaticFilter: true,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.popularName}</div>
    ),
  },
  {
    accessorKey: 'address',
    enableSorting: false,
    meta: {
      columnVisibilityName: ParkingSpotConfig.fields.address.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='whitespace-nowrap'>
        {toReadableAddress(row.original.address)}
      </span>
    ),
  },
  {
    accessorKey: 'tenant',
    enableSorting: false,
    meta: {
      columnVisibilityName: ParkingSpotConfig.fields.tenant.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        ids={[row.original.tenant?.id]}
        initialData={row.original.tenant}
        detailModalLinkUrl={(id) => Urls.detail.tenant.overview(id)}
        config={TenantConfig}
        fields={['communicationName']}
      />
    ),
  },
  {
    accessorKey: 'biTenants',
    enableSorting: false,
    meta: {
      columnVisibilityName: ParkingSpotConfig.fields.biTenants.verboseName,
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={ParkingSpotConfig.fields.biTenants.verboseName}
      />
    ),
    cell: ({ row }) => (
      <DropdownList<Tenant>
        ids={row.original.biTenants.map((bt) => bt.id)}
        initialData={row.original.biTenants[0]}
        detailModalLinkUrl={(id) => Urls.detail.tenant.overview(id)}
        config={TenantConfig}
        fields={['communicationName']}
      />
    ),
  },
  {
    accessorKey: 'parkingLot',
    meta: {
      columnVisibilityName: ParkingSpotConfig.fields.parkingLot.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        ids={[row.original.parkingLot?.id]}
        initialData={row.original.parkingLot}
        detailModalLinkUrl={(id) => Urls.detail.parkinglot.overview(id)}
        config={ParkingLotConfig}
        fields={['name']}
      />
    ),
  },
  {
    accessorKey: 'building',
    enableSorting: false,
    meta: {
      columnVisibilityName: ParkingSpotConfig.fields.building.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        ids={[row.original.building?.id]}
        initialData={row.original.building}
        detailModalLinkUrl={(id) => Urls.detail.building.overview(id)}
        config={BuildingConfig}
        fields={['name']}
      />
    ),
  },
  {
    accessorKey: 'floors',
    meta: {
      columnVisibilityName: ParkingSpotConfig.fields.floors.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='text-end'>{row.original.floors?.join(', ')}</div>
    ),
  },
  {
    accessorKey: 'usableArea',
    meta: {
      columnVisibilityName: ParkingSpotConfig.fields.usableArea.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='text-end'>{toSqm(row.original.usableArea)}</div>
    ),
  },
  {
    accessorKey: 'biArea',
    meta: {
      columnVisibilityName: ParkingSpotConfig.fields.biArea.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='text-end'>{toSqm(row.original.biArea)}</div>
    ),
  },
  {
    accessorKey: 'category',
    meta: {
      columnVisibilityName: ParkingSpotConfig.fields.category.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span>
        {ParkingSpotConfig.fields.category.choices?.find(
          (choice) => choice.v === row.original.category
        )?.d ?? '-'}
      </span>
    ),
  },

  {
    accessorKey: 'tags',
    meta: {
      columnVisibilityName: 'Taggar',
    },
    header: 'Taggar',
    cell: ({ row }) => (
      <span className='flex gap-2'>
        <TableTags
          tags={row.original.tags}
          modelName={ParkingSpotConfig.modelName}
        />
      </span>
    ),
  },

  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <TableActions
        instance={row.original}
        config={ParkingSpotConfig}
        title={row.original.popularName}
      />
    ),
  },
];
