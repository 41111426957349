import type { IFilter } from '@/components/table-filters/constants';
import { type BaseInstance, type ModelName } from '@pigello/pigello-matrix';
import type { ColumnDef } from '@tanstack/react-table';
import { ApartmentItem } from './apartment';
import { ApartmentContractItem } from './apartment-contract';
import { ArticleItem } from './article';
import { ArticleCategoryItem } from './article-category-item';
import { BrfCompanyItem } from './brf-company';
import { BuildingItem } from './building';
import { CancellationReasonItem } from './cancellation-reason';
import { CommonAreaItem } from './common-area';
import { CommunityItem } from './community';
import { CompanyItem } from './company';
import { IndexationTableItem } from './indexation-table';
import { IndustrialPremisesItem } from './industrial-premises';
import { InvoiceItem } from './invoice';
import { OrganizationUserItem } from './organization-user';
import { OtherContractItem } from './other-contract';
import { OutdoorAreaItem } from './outdoor-area';
import { OutdoorSectionItem } from './outdoor-section';
import { OutdoorSectionContractItem } from './outdoor-section-contract';
import { ParkingLotItem } from './parking-lot';
import { ParkingSpotItem } from './parking-spot';
import { ParkingSpotContractItem } from './parking-spot-contract';
import { PaymentMethodItem } from './paymentmethod';
import { QuotaCollectionItem } from './quota-collection';
import { QuotaFeeItem } from './quotafee-item';
import { RealestateItem } from './real-estate';
import { RegionItem } from './region';
import { RevenueRowItem } from './revenue-row-item';
import { RoleItem } from './role';
import { SegmentItem } from './segment';
import { SensorSpecificationItem } from './sensor-specification';
import { SignableDocumentItem } from './signable-document';
import { TagItem } from './tag';
import { TaxationRecordItem } from './taxation-record';
import { TenantItem } from './tenant';
import { WorkEngagementItem } from './workengagement';

export type RelationFieldProps<T extends BaseInstance = BaseInstance> = {
  nested?: Array<keyof T>;
  filters?: IFilter[];
  columns: ColumnDef<T>[] | null;
  renderItem: (
    item: T,
    isRelationField?: boolean,
    modelName?: ModelName
  ) => {
    title: string | JSX.Element;
    avatar?: JSX.Element;
    badge?: JSX.Element;
    descriptionItems: JSX.Element;
  };
};

export function getSelectItemFromModelName<T extends BaseInstance>(
  modelName: ModelName
): RelationFieldProps<T> {
  switch (modelName) {
    case 'article':
      return ArticleItem as unknown as RelationFieldProps<T>;
    case 'building':
      return BuildingItem as unknown as RelationFieldProps<T>;
    case 'outdoorarea':
      return OutdoorAreaItem as unknown as RelationFieldProps<T>;
    case 'parkinglot':
      return ParkingLotItem as unknown as RelationFieldProps<T>;
    case 'apartment':
      return ApartmentItem as unknown as RelationFieldProps<T>;
    case 'industrialpremises':
      return IndustrialPremisesItem as unknown as RelationFieldProps<T>;
    case 'parkingspot':
      return ParkingSpotItem as unknown as RelationFieldProps<T>;
    case 'outdoorsection':
      return OutdoorSectionItem as unknown as RelationFieldProps<T>;
    case 'commonarea':
      return CommonAreaItem as unknown as RelationFieldProps<T>;
    case 'region':
      return RegionItem as unknown as RelationFieldProps<T>;
    case 'realestate':
      return RealestateItem as unknown as RelationFieldProps<T>;
    case 'community':
      return CommunityItem as unknown as RelationFieldProps<T>;
    case 'company':
      return CompanyItem as unknown as RelationFieldProps<T>;
    case 'tenant':
      return TenantItem as unknown as RelationFieldProps<T>;
    case 'apartmentcontract':
      return ApartmentContractItem as unknown as RelationFieldProps<T>;
    case 'outdoorsectioncontract':
      return OutdoorSectionContractItem as unknown as RelationFieldProps<T>;
    case 'parkingspotcontract':
      return ParkingSpotContractItem as unknown as RelationFieldProps<T>;
    case 'industrialpremisescontract':
      return IndustrialPremisesItem as unknown as RelationFieldProps<T>;
    case 'organizationuser':
      return OrganizationUserItem as unknown as RelationFieldProps<T>;
    case 'contractcancellationreason':
      return CancellationReasonItem as unknown as RelationFieldProps<T>;
    case 'sensorspecification':
      return SensorSpecificationItem as unknown as RelationFieldProps<T>;
    case 'othercontract':
      return OtherContractItem as unknown as RelationFieldProps<T>;
    case 'workengagement':
      return WorkEngagementItem as unknown as RelationFieldProps<T>;
    case 'signabledocument':
      return SignableDocumentItem as unknown as RelationFieldProps<T>;
    case 'tag':
      return TagItem as unknown as RelationFieldProps<T>;
    case 'indexationtable':
      return IndexationTableItem as unknown as RelationFieldProps<T>;
    case 'role':
      return RoleItem as unknown as RelationFieldProps<T>;
    case 'segment':
      return SegmentItem as unknown as RelationFieldProps<T>;
    case 'invoice':
      return InvoiceItem as unknown as RelationFieldProps<T>;
    case 'paymentmethod':
      return PaymentMethodItem as unknown as RelationFieldProps<T>;
    case 'articlecategory':
      return ArticleCategoryItem as unknown as RelationFieldProps<T>;
    case 'quotacollection':
      return QuotaCollectionItem as unknown as RelationFieldProps<T>;
    case 'brfcompany':
      return BrfCompanyItem as unknown as RelationFieldProps<T>;
    case 'taxationrecord':
      return TaxationRecordItem as unknown as RelationFieldProps<T>;
    case 'apartmentrevenuerow':
      return RevenueRowItem as unknown as RelationFieldProps<T>;
    case 'quotafee':
      return QuotaFeeItem as unknown as RelationFieldProps<T>;
    default:
      return {
        filters: [],
        columns: [],
        renderItem: () => ({
          title: 'Not implemented',
          badge: undefined,
          descriptionItems: <>{modelName}</>,
        }),
      };
  }
}
