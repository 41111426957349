'use client';

import type { QueryParams } from '@/requests/api/types';
import type { ListResponse } from '@/requests/types';
import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { fetchAllInstances } from './use-fetch-all-instances';
import { parseJsonFilter } from './use-table-filter/utils';

const MAX_BYTES_IN_QUERY_PARAMETERS = 1980; // circa 50 ids
const getBytesOfString = (str: string) => new Blob([str]).size;

export function getIdArrays<Instance extends BaseInstance>({
  filters,
  order,
  nested,
  slim,
  modelName,
  ids,
  idAttr,
}: {
  filters: QueryParams['filters'];
  order: QueryParams['order'];
  nested?: (keyof Instance)[] | undefined;
  slim?: boolean;
  modelName: ModelName;
  ids: string[];
  idAttr: string;
}) {
  const totalBytesInIdFilter = getBytesOfString(ids.join(','));
  const canFetchAllInOnePage =
    totalBytesInIdFilter <= MAX_BYTES_IN_QUERY_PARAMETERS;

  // if we cant fit all ids in the byte limit, we need to split it in half and try again until we can
  if (!canFetchAllInOnePage) {
    const multipleValues = [
      ids.slice(0, Math.floor(ids.length / 2)),
      ids.slice(Math.floor(ids.length / 2)),
    ];
    let promises: ReturnType<typeof fetchAllInstances>[] = [];

    for (const valueArr of multipleValues) {
      const result = getIdArrays({
        modelName,
        nested,
        filters,
        ids: valueArr,
        order,
        slim,
        idAttr,
      });

      promises = [...promises, ...result];
    }
    return promises;
  } else {
    // here we're under the byte limit, return a promise
    return [
      fetchAllInstances<Instance>({
        filters: {
          [idAttr]: { __in: ids.join(',') },
          ...filters,
        },
        nested,
        modelName,
        slim,
        order,
      }),
    ];
  }
}

export async function fetchAllIds<Instance extends BaseInstance>({
  modelName,
  filters,
  order,
  nested,
  slim,
  ids,
  idAttr,
}: {
  modelName: ModelName;
  filters: QueryParams['filters'];
  order: QueryParams['order'];
  nested?: (keyof Instance)[] | undefined;
  slim?: boolean;
  ids: string[];
  idAttr: string;
}) {
  const promises = getIdArrays({
    filters,
    idAttr,
    ids,
    modelName,
    order,
    nested,
    slim,
  });

  const result = await Promise.all(promises);

  return {
    list: result.map((r) => r.list).flat(),
    meta: {
      page_amount: 1,
      page_size: result.length,
      total_amount: result.length,
    },
  };
}

export default function useFetchAllIds<Instance extends BaseInstance>(
  options: Omit<
    UseQueryOptions<unknown, unknown, ListResponse<Instance>, Array<string>>,
    'queryKey' | 'queryFn'
  > & {
    modelName: ModelName;
    order: QueryParams['order'];
    filters: QueryParams['filters'];
    nested?: (keyof Instance)[] | undefined;
    slim?: boolean;
    ids: string[];
    idAttr: string;
  }
) {
  const result = useQuery({
    queryFn: async () =>
      await fetchAllIds({
        modelName: options.modelName,
        filters: options.filters,
        order: options.order,
        nested: options.nested,
        slim: options.slim,
        idAttr: options.idAttr,
        ids: options.ids,
      }),
    queryKey: [
      options.modelName,
      'allids',
      options.idAttr,
      options.order ? options.order?.join(',') : 'noorder',
      options.filters ? parseJsonFilter(options.filters) : 'nofilters',
      options.slim ? 'slim' : '',
    ],
    ...options,
  });

  return result;
}
