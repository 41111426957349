 import type { SubTenant } from '@pigello/pigello-matrix';

import { SubTenantConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetSubTenant = (
  options: useGetGenericInstanceProps<SubTenant>
) => {
  return useGetInstance<SubTenant>({
    ...options,
    config: SubTenantConfig,
    modelName: 'subtenant'
  });
};

export const useGetSubTenantList = (
  options: useGetGenericListProps<SubTenant>
) => {
  return useGetList<SubTenant>({
    ...options,
    config: SubTenantConfig,
    modelName: 'subtenant'
  });
};
