'use client';

import { toReadableDateTime } from '@/lib/dates';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import {
  WorkEngagementConfig,
  type WorkEngagement,
} from '@pigello/pigello-matrix';
import type { RelationFieldProps } from './relation-item-map';

export const WorkEngagementItem: RelationFieldProps<WorkEngagement> = {
  nested: getRelationFieldNames(WorkEngagementConfig),
  filters: [],
  columns: null,
  renderItem: (item) => ({
    title: item?.name,
    descriptionItems: (
      <div className='flex w-full items-center justify-between'>
        <span className='font-medium'>
          Skapad {toReadableDateTime(item.createdAt)}
        </span>
      </div>
    ),
  }),
};
