import { useGetPaymentMethodList } from '@/config/accounting/accounttypes/paymentmethod/client';
import { useGetInvoiceCreditationConnectionList } from '@/config/accounting/creditations/invoicecreditationconnection/client';
import { useGetDebtInvoiceList } from '@/config/accounting/debt/debtinvoice/client';
import { useGetInvoice } from '@/config/accounting/invoices/invoice/client';
import { useGetInvoiceRecipientList } from '@/config/accounting/invoices/invoicerecipient/client';
import { useGetInvoiceRowList } from '@/config/accounting/invoices/invoicerow/client';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { InvoiceConfig } from '@pigello/pigello-matrix';
import { useMemo } from 'react';

const REMINDER_TYPES = {
  REMINDER: 0,
  DEBT: 1,
};

export function useGetTotalInvoice({ id }: { id: string }) {
  const { data: invoiceRows, isPending: isLoadingInvoiceRows } =
    useGetInvoiceRowList({
      queryParams: {
        filters: {
          invoice: { noop: id },
        },
      },
      nested: ['article'],
    });
  const { data: invoice, isPending: isLoadingInvoice } = useGetInvoice({
    id: id,
    nested: getRelationFieldNames(InvoiceConfig),
  });
  const isCreditation = (invoice?.calculatedRowData?.rows_sum_net ?? 0) < 0;

  const { data: sourceCreditationConnections } =
    useGetInvoiceCreditationConnectionList({
      queryParams: {
        page: 1,
        pageSize: 1,
        filters: {
          source_invoice: { noop: id },
        },
      },
      nested: ['targetInvoice', 'sourceInvoice'],
      enabled: !!id && isCreditation,
    });
  const sourceCreditationConnection = sourceCreditationConnections?.list?.[0];
  const { data: targetCreditationConnections } =
    useGetInvoiceCreditationConnectionList({
      queryParams: {
        page: 1,
        pageSize: 1,
        filters: {
          target_invoice: { noop: id },
        },
      },
      nested: ['sourceInvoice', 'targetInvoice'],
      enabled: !!id && !isCreditation,
    });
  const targetCreditationConnection = targetCreditationConnections?.list?.[0];

  const { data: invoiceRecipients, isPending: isLoadingInvoiceRecipients } =
    useGetInvoiceRecipientList({
      queryParams: {
        filters: {
          invoice: {
            noop: id,
          },
        },
        order: ['priority'],
      },
      nested: [
        'tenant',
        'subTenant',
        'organizationUser',
        'organizationUserContactPerson',
      ],
    });

  const { data: paymentMethod, isLoading: isLoadingPaymentMethod } =
    useGetPaymentMethodList({
      queryParams: {
        filters: {
          is_primary_payment_method: { noop: true },
          ...(invoice?.company?.id && {
            eligible_for_companies: { noop: invoice?.company?.id },
          }),
        },
      },
      enabled: !!invoice?.company?.id,
    });

  const { data: debtTypeInvoices, isLoading: isLoadingDebtTypeInvoices } =
    useGetDebtInvoiceList({
      queryParams: {
        page: 1,
        pageSize: 25,
        filters: {
          invoice: { noop: id },
        },
      },
      enabled: !!id,
    });

  const [reminderInvoices, debtInvoices] = useMemo(() => {
    if (!debtTypeInvoices || debtTypeInvoices.list.length === 0)
      return [[], []];

    const reminders = debtTypeInvoices.list.filter(
      (i) => i.kind === REMINDER_TYPES.REMINDER
    );
    const debt = debtTypeInvoices.list.filter(
      (i) => i.kind === REMINDER_TYPES.DEBT
    );

    return [reminders, debt];
  }, [debtTypeInvoices]);

  const isLoading =
    isLoadingInvoiceRows ||
    isLoadingInvoice ||
    isLoadingInvoiceRecipients ||
    isLoadingPaymentMethod ||
    isLoadingDebtTypeInvoices;

  return {
    invoiceRows,
    invoice,
    invoiceRecipients,
    paymentMethod: paymentMethod?.list?.[0],
    isLoading,
    reminderInvoices,
    debtInvoices,
    hasReminders: reminderInvoices.length > 0,
    hasDebt: debtInvoices.length > 0,
    isCreditation,
    sourceCreditationConnection, // will be defined for credit invoices that have been connected
    targetCreditationConnection, // will be defined for debit invoices that has been credited
  };
}
