import { DynamicField } from '@/components/form/dynamic-field';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { useMutateFile } from '@/config/documents/filehandling/file/mutate';
import { useForm } from '@/hooks/useForm';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useGetSelf } from '@/hooks/useGetSelf';
import { useToast } from '@/hooks/useToast';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import type { ErrorResponse } from '@/requests/types';
import { FileConfig, getCleanFile, type File } from '@pigello/pigello-matrix';

export type ChangeFileNameModalContentData = {
  file: File;
};

interface Props extends GetModalContentProps {
  closeModal: () => void;
  contentData: ChangeFileNameModalContentData;
}

function ChangeFileNameModal({ closeModal, contentData }: Props) {
  const form = useForm({
    config: FileConfig,
    defaultValues: getCleanFile(),
    values: contentData.file,
    isUpdate: true,
  });
  const { data: self } = useGetSelf();
  const { mutateAsync: mutateFile } = useMutateFile({});
  const { addToast } = useToast();
  const { isDirty } = form.formState;
  const onSubmit = () => {
    const data = form.getDirtyData();

    addToast({
      type: 'promise',
      promise: mutateFile({ id: contentData.file.id, body: data }),
      loading: 'Sparar...',
      success: () => {
        closeModal();
        form.reset();
        return 'Sparat!';
      },
      error: (error: ErrorResponse) => {
        const field = handleFormErrors(error, form);
        if (field) {
          return field;
        }
        return 'Något gick fel';
      },
    });
  };
  return (
    <div>
      <Form {...form}>
        <form className='grid gap-4' onSubmit={form.handleSubmit(onSubmit)}>
          <DynamicField formField={form.fields.originalName} />
          {contentData.file.createdBy?.id === self?.id && (
            <DynamicField formField={form.fields.private} />
          )}
          <div className='flex items-center justify-end gap-4'>
            <Button
              onClick={() => closeModal()}
              type='button'
              variant={'outline'}
            >
              Avbryt
            </Button>
            <Button disabled={!isDirty}>Spara</Button>
          </div>
        </form>
      </Form>
    </div>
  );
}

export const getChangeFileNameModalContent = ({
  closeModal,
  contentData,
}: Props): ModalContent => {
  return {
    title: 'Redigera fil',
    description: 'Ändra filnamn och inställningar för filen.',
    body: (
      <ChangeFileNameModal closeModal={closeModal} contentData={contentData} />
    ),
  };
};
