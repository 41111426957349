'use client';

import { Badge } from '@/components/ui/badge';
import { ObjectValues } from '@/lib/utils';
import type { Invoice } from '@pigello/pigello-matrix';
import { DateTime } from 'luxon';

export function InvoiceStatus({ invoice }: { invoice: Invoice }) {
  if (invoice.closedTime) {
    return <Badge variant={'green'}>Slutbetald</Badge>;
  }
  if (
    invoice.reminderAmount &&
    invoice.reminderAmount > 0 &&
    invoice.attestedDate &&
    DateTime.now() > DateTime.fromISO(invoice.dueDate)
  ) {
    return <Badge variant={'purple'}>Påminnelse</Badge>;
  }

  if (
    invoice.reminderAmount &&
    invoice.reminderAmount === 0 &&
    DateTime.now() > DateTime.fromISO(invoice.dueDate) &&
    !invoice.closedTime &&
    invoice.attestedDate
  ) {
    return <Badge variant={'destructive'}>Förfallen</Badge>;
  }

  if (
    invoice.reminderAmount === 0 &&
    DateTime.now() > DateTime.fromISO(invoice.dueDate) &&
    !invoice.closedTime &&
    invoice.attestedDate
  ) {
    if (
      ObjectValues(
        (
          invoice.debtInvoiceCounts as unknown as {
            debt_stages: { [key: string]: number };
          }
        )?.debt_stages ?? {}
      ).some((debt) => debt > 0)
    ) {
      return (
        <Badge variant={'destructive'}>Förfallen (Manuellt hanterad)</Badge>
      );
    }

    return <Badge variant={'destructive'}>Förfallen (Ej hanterad)</Badge>;
  }

  if (invoice.distributionDate && invoice.attestedDate && !invoice.closedTime) {
    return <Badge variant={'purple'}>Skickad</Badge>;
  }

  if (!invoice.attestedDate) {
    return <Badge variant={'yellow'}>Ej attesterad</Badge>;
  } else {
    return <Badge variant={'green'}>Attesterad</Badge>;
  }
}

// Created: "Ej attesterad",
// ReminderInvoiceSent: "Påminnelse",
// Manual: "Förfallen (Ej hanterad)",
// Completed: "Slutbetald",
// Cancelled: "Avbruten", // read only attribute?????
// InvoiceSent: "Skickad",
// Attested: "Attesterad",
// CreditInvoice: "Kreditfaktura", // is credit invoice bool read only
// SentToDebtCollection: "Kravhantering", // read only attribute säger hur mpnga debt den har
