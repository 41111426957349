'use client';

import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { TableActions } from '@/components/data-table/table-actions';
import { TableTags } from '@/components/data-table/table-tags';
import { Checkbox } from '@/components/ui/checkbox';
import { StatusBadge } from '@/components/ui/statusBadge';
import { toReadableDate } from '@/lib/dates';
import { Urls } from '@/lib/urls';
import { toReadableAddress, toSqm } from '@/lib/utils';
import type { Tenant } from '@pigello/pigello-matrix';
import {
  BuildingConfig,
  IndustrialPremisesConfig,
  TenantConfig,
  type IndustrialPremises,
} from '@pigello/pigello-matrix';
import type { ColumnDef } from '@tanstack/react-table';
import { DropdownList } from '../components/dropdown-list';

export const columns: ColumnDef<IndustrialPremises>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'status',
    meta: {
      columnVisibilityName: IndustrialPremisesConfig.fields.status.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => {
      return <StatusBadge status={row.original.status} />;
    },
  },
  {
    accessorKey: 'popularName',
    meta: {
      columnVisibilityName:
        IndustrialPremisesConfig.fields.popularName.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.popularName}</div>
    ),
  },
  {
    accessorKey: 'category',
    meta: {
      columnVisibilityName:
        IndustrialPremisesConfig.fields.category.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {IndustrialPremisesConfig.fields.category.choices?.find(
          (c) => c.v == row.original.category
        )?.d ?? '-'}
      </div>
    ),
  },
  {
    accessorKey: 'address',
    enableSorting: false,
    meta: {
      columnVisibilityName: IndustrialPremisesConfig.fields.address.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='whitespace-nowrap'>
        {toReadableAddress(row.original.address)}
      </span>
    ),
  },
  {
    accessorKey: 'building',
    enableSorting: false,
    meta: {
      columnVisibilityName:
        IndustrialPremisesConfig.fields.building.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        ids={[row.original.building?.id]}
        initialData={row.original.building}
        detailModalLinkUrl={(id) => Urls.detail.building.overview(id)}
        config={BuildingConfig}
        fields={['name']}
      />
    ),
  },
  {
    accessorKey: 'floors',
    meta: {
      columnVisibilityName: IndustrialPremisesConfig.fields.floors.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='text-end'>{row.original.floors?.join(', ')}</div>
    ),
  },
  {
    accessorKey: 'usableArea',
    meta: {
      columnVisibilityName:
        IndustrialPremisesConfig.fields.usableArea.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='text-end'>{toSqm(row.original.usableArea)}</div>
    ),
  },
  {
    accessorKey: 'biArea',
    meta: {
      columnVisibilityName: IndustrialPremisesConfig.fields.biArea.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='text-end'>{toSqm(row.original.biArea)}</div>
    ),
  },

  {
    accessorKey: 'isFurnished',
    meta: {
      columnVisibilityName:
        IndustrialPremisesConfig.fields.isFurnished.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='text-end'>{row.original.isFurnished ? 'Ja' : 'Nej'}</div>
    ),
  },

  {
    accessorKey: 'customId',
    meta: {
      columnVisibilityName:
        IndustrialPremisesConfig.fields.customId.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.customId}</div>
    ),
  },
  {
    accessorKey: 'createdAt',
    meta: {
      columnVisibilityName:
        IndustrialPremisesConfig.fields.createdAt.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {toReadableDate(row.original.createdAt)}
      </div>
    ),
  },

  {
    accessorKey: 'tenant',
    enableSorting: false,
    meta: {
      columnVisibilityName: IndustrialPremisesConfig.fields.tenant.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        ids={[row.original.tenant?.id]}
        initialData={row.original.tenant}
        detailModalLinkUrl={(id) => Urls.detail.tenant.overview(id)}
        config={TenantConfig}
        fields={['communicationName']}
      />
    ),
  },
  {
    accessorKey: 'biTenants',
    enableSorting: false,
    meta: {
      columnVisibilityName:
        IndustrialPremisesConfig.fields.biTenants.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList<Tenant>
        ids={row.original.biTenants?.map((t) => t.id) ?? []}
        initialData={row.original.biTenants?.[0]}
        detailModalLinkUrl={(id) => Urls.detail.tenant.overview(id)}
        config={TenantConfig}
        fields={['communicationName']}
      />
    ),
  },
  {
    accessorKey: 'tags',
    enableSorting: false,
    meta: {
      columnVisibilityName: IndustrialPremisesConfig.fields.tags.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='flex gap-2'>
        <TableTags
          tags={row.original.tags}
          modelName={IndustrialPremisesConfig.modelName}
        />
      </span>
    ),
  },

  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <TableActions
        instance={row.original}
        config={IndustrialPremisesConfig}
        title={row.original.popularName}
      />
    ),
  },
];
