import {
  defaultStringFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import { CompanyConfig } from '@pigello/pigello-matrix';
import baseFilters, { baseTagFilter } from '../filters';

const companyFilters: IFilter[] = [
  {
    title: CompanyConfig.fields.name.verboseName,
    externalFieldName: CompanyConfig.fields.name.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: CompanyConfig.fields.orgNo.verboseName,
    externalFieldName: CompanyConfig.fields.orgNo.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: CompanyConfig.fields.customId.verboseName,
    externalFieldName: CompanyConfig.fields.customId.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: CompanyConfig.fields.sniNumber.verboseName,
    externalFieldName: CompanyConfig.fields.sniNumber.externalFieldName,
    ...defaultStringFilter,
  },

  {
    title: `${CompanyConfig.fields.postalAddress.verboseName} - Gatunamn`,
    externalFieldName: `${CompanyConfig.fields.postalAddress.externalFieldName}__street`,
    ...defaultStringFilter,
  },
  {
    title: `${CompanyConfig.fields.postalAddress.verboseName} - Stad`,
    externalFieldName: `${CompanyConfig.fields.postalAddress.externalFieldName}__city`,
    ...defaultStringFilter,
  },
  {
    title: `${CompanyConfig.fields.postalAddress.verboseName} - Gatunummer`,
    externalFieldName: `${CompanyConfig.fields.postalAddress.externalFieldName}__street_number`,
    ...defaultStringFilter,
  },
  {
    title: `${CompanyConfig.fields.postalAddress.verboseName} - Land`,
    externalFieldName: `${CompanyConfig.fields.postalAddress.externalFieldName}__country`,
    ...defaultStringFilter,
  },
  {
    title: `${CompanyConfig.fields.visitingAddress.verboseName} - Gatunamn`,
    externalFieldName: `${CompanyConfig.fields.visitingAddress.externalFieldName}__street`,
    ...defaultStringFilter,
  },
  {
    title: `${CompanyConfig.fields.visitingAddress.verboseName} - Stad`,
    externalFieldName: `${CompanyConfig.fields.visitingAddress.externalFieldName}__city`,
    ...defaultStringFilter,
  },
  {
    title: `${CompanyConfig.fields.visitingAddress.verboseName} - Gatunummer`,
    externalFieldName: `${CompanyConfig.fields.visitingAddress.externalFieldName}__street_number`,
    ...defaultStringFilter,
  },
  {
    title: `${CompanyConfig.fields.visitingAddress.verboseName} - Land`,
    externalFieldName: `${CompanyConfig.fields.visitingAddress.externalFieldName}__country`,
    ...defaultStringFilter,
  },
  {
    title: `${CompanyConfig.fields.registeredAddress.verboseName} - Gatunamn`,
    externalFieldName: `${CompanyConfig.fields.registeredAddress.externalFieldName}__street`,
    ...defaultStringFilter,
  },
  {
    title: `${CompanyConfig.fields.registeredAddress.verboseName} - Stad`,
    externalFieldName: `${CompanyConfig.fields.registeredAddress.externalFieldName}__city`,
    ...defaultStringFilter,
  },
  {
    title: `${CompanyConfig.fields.registeredAddress.verboseName} - Gatunummer`,
    externalFieldName: `${CompanyConfig.fields.registeredAddress.externalFieldName}__street_number`,
    ...defaultStringFilter,
  },
  {
    title: `${CompanyConfig.fields.registeredAddress.verboseName} - Land`,
    externalFieldName: `${CompanyConfig.fields.registeredAddress.externalFieldName}__country`,
    ...defaultStringFilter,
  },

  ...baseTagFilter,
  ...baseFilters,
];

export default companyFilters;
