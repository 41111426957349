'use client';

import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { TableActions } from '@/components/data-table/table-actions';
import { TableTags } from '@/components/data-table/table-tags';
import { Checkbox } from '@/components/ui/checkbox';
import { Urls } from '@/lib/urls';
import { toReadableAddress } from '@/lib/utils';
import {
  CommunityConfig,
  RegionConfig,
  type Community,
} from '@pigello/pigello-matrix';
import type { ColumnDef } from '@tanstack/react-table';
import { DropdownList } from '../../components/dropdown-list';

export const columns: ColumnDef<Community>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'customId',
    meta: {
      columnVisibilityName: CommunityConfig.fields.customId.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.customId ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'name',
    meta: {
      columnVisibilityName: CommunityConfig.fields.name.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.name}</div>
    ),
  },
  {
    accessorKey: 'primaryPurposeType',
    meta: {
      columnVisibilityName:
        CommunityConfig.fields.primaryPurposeType.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>
        {CommunityConfig.fields.primaryPurposeType.choices?.find(
          (c) => c.v == row.original.primaryPurposeType
        )?.d ?? '-'}
      </div>
    ),
  },
  {
    accessorKey: 'region',
    enableSorting: false,
    meta: {
      columnVisibilityName: CommunityConfig.fields.region.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        ids={[row.original.region?.id]}
        initialData={row.original.region}
        detailModalLinkUrl={(id) => Urls.detail.region.overview(id)}
        config={RegionConfig}
        fields={['name']}
      />
    ),
  },
  {
    accessorKey: 'mainAddress',
    enableSorting: false,
    meta: {
      columnVisibilityName: CommunityConfig.fields.mainAddress.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='whitespace-nowrap'>
        {toReadableAddress(row.original.mainAddress)}
      </span>
    ),
  },
  {
    accessorKey: 'tags',
    meta: {
      columnVisibilityName: 'Taggar',
    },
    header: 'Taggar',
    cell: ({ row }) => (
      <span className='flex gap-2'>
        <TableTags
          tags={row.original.tags}
          modelName={CommunityConfig.modelName}
        />
      </span>
    ),
  },
  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <TableActions
        instance={row.original}
        config={CommunityConfig}
        title={row.original.name}
      />
    ),
  },
];
