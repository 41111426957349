 import type { FileAccessShare } from '@pigello/pigello-matrix';

import { FileAccessShareConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useCreateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, createGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { createGenericInstance } from '@/requests/creating';

export const createFileAccessShare = async (options: createGenericInstanceProps<FileAccessShare>) => {
  return createGenericInstance<FileAccessShare>({
    ...options,
    config: FileAccessShareConfig,
  });
};

export const useCreateFileAccessShare = (
  options: UseMutationOptions<FileAccessShare, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useCreateGenericInstance<FileAccessShare>(createFileAccessShare, options, 'fileaccessshare');
