 import type { Invoice } from '@pigello/pigello-matrix';

import { InvoiceConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useCreateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, createGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { createGenericInstance } from '@/requests/creating';

export const createInvoice = async (options: createGenericInstanceProps<Invoice>) => {
  return createGenericInstance<Invoice>({
    ...options,
    config: InvoiceConfig,
  });
};

export const useCreateInvoice = (
  options: UseMutationOptions<Invoice, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useCreateGenericInstance<Invoice>(createInvoice, options, 'invoice');
