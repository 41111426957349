import { XMarkIcon } from '@heroicons/react/16/solid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import SelectTable from '../data-table/custom-tables/select-table';
import { Button } from '../ui/button';
import { DialogHeader } from '../ui/detail-dialog';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from '../ui/dialog';
import OverlayLoader from '../ui/overlay-loader';
import type { IFilter, IFilterValue } from './constants';

export default function RelationModal({
  filter,
  initialValue,
  onDone,
}: {
  filter: IFilter;
  initialValue: IFilterValue;
  onDone: (val: string[]) => void;
}) {
  const [value, setValue] = useState<string[] | null>(null);

  useEffect(() => {
    if (initialValue && !value && Array.isArray(initialValue)) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const updateValue = useCallback(
    (selected: string) => {
      if ((value ?? []).includes(selected)) {
        setValue((value ?? []).filter((v) => v != selected));
      } else {
        setValue([...(value ?? []), selected]);
      }
    },
    [value]
  );

  const handleDone = useCallback(
    (val: string[] | null) => onDone(val ?? []),
    [onDone]
  );

  // const RenderItem = useMemo(() => {
  //   if (!filter.relationModelName) return null;
  //   return getSelectItemFromModelName(filter.relationModelName);
  // }, [filter.relationModelName]);

  const memoizedModelName = useMemo(() => {
    return filter.relationModelName;
  }, [filter]);

  const MemoizedSelect = useMemo(() => {
    if (!memoizedModelName) return <OverlayLoader />;

    return (
      <SelectTable
        tableId={`${memoizedModelName}`}
        onSelected={updateValue}
        isMultiSelect
        queryParams={
          filter.relationDefaultFilters
            ? { filters: filter.relationDefaultFilters }
            : undefined
        }
        selected={value ?? []}
        modelName={memoizedModelName}
      />
    );
  }, [memoizedModelName, value, updateValue]);

  if (!filter.relationModelName) return;
  return (
    <Dialog key='0' defaultOpen onOpenChange={() => handleDone(value)}>
      <DialogContent className='min-w-[600px] gap-0 p-0'>
        <DialogHeader className='flex flex-row items-center justify-between p-2'>
          <DialogTitle>Välj filtrering</DialogTitle>
          <Button
            onClick={() => handleDone(value)}
            variant='secondary'
            size='icon-sm'
          >
            <XMarkIcon className='size-4' />
          </Button>
          <DialogDescription className='sr-only'>
            Filtrera på {filter.title}
          </DialogDescription>
        </DialogHeader>
        <div className='max-h-[min(800px,calc(var(--window-keeper-height)-175px))] min-h-[500px] min-w-[600px] overflow-y-auto'>
          {MemoizedSelect}
        </div>
        <DialogFooter className='p-4'>
          <Button onClick={() => handleDone(value)}>Klar</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
