 import type { InvoiceRow } from '@pigello/pigello-matrix';

import { InvoiceRowConfig } from "@pigello/pigello-matrix"



import { fetchGenericInstance, fetchGenericList } from '@/requests/fetching';
import type { getGenericInstanceProps, getGenericListProps } from '@/requests/types';

export const getInvoiceRow = async (
  options: getGenericInstanceProps<InvoiceRow>
) => {
  return fetchGenericInstance<InvoiceRow>({
    ...options,
    config: InvoiceRowConfig,
  });
};

export const getInvoiceRowList = async (
  options: getGenericListProps<InvoiceRow>
) => {
  return fetchGenericList<InvoiceRow>({
    ...options,
    config: InvoiceRowConfig,
  });
};
