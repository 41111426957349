 import type { OrganizationUserFileAccessShare } from '@pigello/pigello-matrix';

import { OrganizationUserFileAccessShareConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useDeleteGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, deleteGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { deleteGenericInstance } from '@/requests/deleting';

export const deleteOrganizationUserFileAccessShare = async (
  options: deleteGenericInstanceProps<OrganizationUserFileAccessShare>
) => {
  return deleteGenericInstance<OrganizationUserFileAccessShare>({
    ...options,
    config: OrganizationUserFileAccessShareConfig,
  });
};

export const useDeleteOrganizationUserFileAccessShare = (
  options: UseMutationOptions<OrganizationUserFileAccessShare, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useDeleteGenericInstance<OrganizationUserFileAccessShare>(deleteOrganizationUserFileAccessShare, options, 'organizationuserfileaccessshare');