import { columns } from '@/app/dashboard/tenants/columns';
import tenantFilters from '@/app/dashboard/tenants/filters';
import { StatusBadge } from '@/components/ui/statusBadge';
import { cn } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { TenantConfig, type Tenant } from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './relation-item-map';

export const TenantItem: RelationFieldProps<Tenant> = {
  filters: tenantFilters,
  columns: columns,
  nested: getRelationFieldNames(TenantConfig),
  renderItem: (item, isRelationField) => ({
    title: item?.communicationName
      ? item?.communicationName
      : item.anonymized
        ? (item.anonymizationName as string)
        : 'Saknar behörighet att se namn',
    badge:
      item.status != null ? (
        <StatusBadge
          className='font-medium'
          type='tenant'
          status={item.status}
        />
      ) : undefined,
    descriptionItems: (
      <div
        className={cn(
          'flex w-full items-center',
          isRelationField && 'justify-between'
        )}
      >
        <span>
          {item.isCompany
            ? item.orgNo ?? 'Inget Org.nr angivet'
            : item.ssn ?? 'Inget personnummer angivet'}
        </span>
        {!isRelationField && <Dot />}
        <span>{item?.customId ?? 'Inget objekts-id angivet'}</span>
      </div>
    ),
  }),
};
