'use client';

import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { usePerms } from '@/hooks/usePerms';
import { cn, toReadableAddress } from '@/lib/utils';
import { useGetList } from '@/requests/hooks';
import {
  CheckIcon,
  ChevronUpDownIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import { type BaseInstance } from '@pigello/pigello-matrix';
import { keepPreviousData } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import type { Path } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import type { FieldProps } from '../../types';
import { DescriptionTooltip } from '../description-tooltip';
import CreateAddressInFieldModal from './components/create-modal';

export function AddressField<Instance extends BaseInstance = BaseInstance>({
  formField,
  name,
  placeholder,
  label,
  description,
  className,
  horizontal,
  disabled,
  overrideRequired,
  overrideRelationConfigModelName,
  canHandleField,
}: FieldProps<Instance>) {
  const [search, setSearch] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const debouncedSearch = useDebounceCallback(setSearch, 300);
  const relationConfigName = formField.config?.fields[formField.name];
  const btnRef = useRef<HTMLButtonElement | null>(null);

  const isNullable =
    formField.config.fields?.[name]?.nullable != null
      ? formField.config.fields?.[name]?.nullable
      : true;

  if (!('relationConfig' in relationConfigName)) {
    throw new Error('relationConfig is not in relationConfigName');
  }
  const relationConfigModelName =
    overrideRelationConfigModelName ?? relationConfigName.relationConfig;

  const { canCreate: canCreateAddress } = usePerms<Instance>(
    relationConfigModelName
  );
  const { data, isLoading, isFetching } = useGetList<Instance>({
    modelName: relationConfigModelName,
    queryParams: {
      page: 1,
      pageSize: 25,
      search,
    },
    placeholderData: keepPreviousData,
  });

  return (
    <FormField
      control={formField.control}
      name={name as Path<Instance>}
      render={({ field }) => {
        const clearField = () => {
          field.onChange(null);
        };

        return (
          <>
            {expanded && (
              <CreateAddressInFieldModal
                closeFunction={() => setExpanded(false)}
                successCallback={(newAddress) => {
                  field.onChange(newAddress);
                }}
              />
            )}
            <FormItem
              className={cn(
                'flex flex-col justify-start gap-2 space-y-2',
                horizontal && 'flex-row',
                className
              )}
            >
              <div className={cn('w-1/2 shrink-0', !horizontal && 'w-full')}>
                <div className='flex w-full flex-col'>
                  <div className='flex items-center justify-between'>
                    <FormLabel>
                      {label ?? formField.label}{' '}
                      {(overrideRequired != null
                        ? overrideRequired
                        : formField.required) && <span>*</span>}
                    </FormLabel>
                    <div>
                      {canCreateAddress && (
                        <Tooltip>
                          <TooltipTrigger
                            asChild
                            disabled={
                              disabled ?? field.disabled ?? !canHandleField
                            }
                            type='button'
                            onClick={(e) => {
                              e.preventDefault();
                              setExpanded(true);
                            }}
                            className='flex items-center justify-center rounded-full transition-colors hover:bg-muted hover:text-foreground'
                          >
                            <Button
                              variant='link'
                              className='flex items-center gap-1 whitespace-nowrap p-0 text-xs'
                              size='icon'
                            >
                              Skapa ny
                              <PlusIcon className='size-4' />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>Skapa ny adress</TooltipContent>
                        </Tooltip>
                      )}
                      {(description || formField.description) && (
                        <DescriptionTooltip
                          description={description ?? formField.description}
                        />
                      )}
                    </div>
                  </div>
                  <FormMessage />
                </div>
              </div>
              <Popover
                modal
                open={open}
                onOpenChange={(val) => {
                  if (!val) {
                    debouncedSearch('');
                  }
                  setOpen(val);
                }}
              >
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant='outline'
                      ref={btnRef}
                      role='combobox'
                      disabled={disabled ?? field.disabled ?? !canHandleField}
                      className={cn(
                        '!mt-0 min-h-10 w-full justify-between text-left font-normal shadow-input',
                        !field.value?.id && 'text-muted-foreground',
                        horizontal && 'w-1/2 self-end'
                      )}
                    >
                      {field.value?.id && data?.list
                        ? toReadableAddress(
                            data.list.find((d) => d.id === field.value.id),
                            true,
                            false
                          )
                        : label ?? `Välj en ${formField.label}`}
                      <div className='flex items-center'>
                        {isNullable && field.value && (
                          <span
                            title='Rensa fältet'
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                clearField();
                              }
                            }}
                            role='button'
                            tabIndex={0}
                            onClick={(e) => {
                              e.stopPropagation();
                              clearField();
                            }}
                          >
                            <XMarkIcon className='size-4 opacity-50 transition-opacity hover:opacity-100' />
                          </span>
                        )}
                        <ChevronUpDownIcon className='ml-2 size-4 shrink-0 opacity-50' />
                      </div>
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent
                  style={{ width: btnRef.current?.offsetWidth }}
                  className='max-h-60 w-auto p-0'
                >
                  <Command shouldFilter={false}>
                    <CommandInput
                      isLoading={isLoading || isFetching}
                      placeholder={
                        placeholder ??
                        `Sök efter ${label ?? formField.label}...`
                      }
                      onValueChange={debouncedSearch}
                    />
                    <CommandEmpty>Inga sökningar hittades...</CommandEmpty>
                    <CommandList>
                      <CommandGroup>
                        {data && data?.list.length > 0 ? (
                          data?.list.map((item) => (
                            <CommandItem
                              value={item.id}
                              key={item.id}
                              onSelect={() => {
                                if (item.id !== field.value?.id) {
                                  field.onChange({ id: item.id });
                                } else {
                                  field.onChange(null);
                                }
                                setOpen(false);
                              }}
                            >
                              <CheckIcon
                                className={cn(
                                  'mr-2 size-4',
                                  item.id === field.value?.id
                                    ? 'opacity-100'
                                    : 'opacity-0'
                                )}
                              />
                              {toReadableAddress(item, true, false)}
                            </CommandItem>
                          ))
                        ) : (
                          <CommandItem className='opacity-50' disabled>
                            Ingen {formField.label.toLowerCase()} hittades...
                          </CommandItem>
                        )}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            </FormItem>
          </>
        );
      }}
    />
  );
}
