 import type { OrganizationUserFileAccessShare } from '@pigello/pigello-matrix';

import { OrganizationUserFileAccessShareConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, mutateGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { mutateGenericInstance } from '@/requests/mutating';

export const mutateOrganizationUserFileAccessShare = async (
  options: mutateGenericInstanceProps<OrganizationUserFileAccessShare>
) => {
  return mutateGenericInstance<OrganizationUserFileAccessShare>({
    ...options,
    config: OrganizationUserFileAccessShareConfig,
  });
};

export const useMutateOrganizationUserFileAccessShare = (
  options: UseMutationOptions<OrganizationUserFileAccessShare, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useMutateGenericInstance<OrganizationUserFileAccessShare>(mutateOrganizationUserFileAccessShare, options, 'organizationuserfileaccessshare');