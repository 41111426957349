import { Icons } from '@/components/icons';
import { Button } from '@/components/ui/button';
import { Dialog, DialogOverlay, DialogPortal } from '@/components/ui/dialog';
import { cn } from '@/lib/utils';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { DialogContent } from '@radix-ui/react-dialog';
import NextImage from 'next/image';
import { useEffect, useState } from 'react';

export type ImagePreviewModalContentData = {
  title: string;
  url?: string;
};

interface Props extends GetModalContentProps {
  closeModal: () => void;
  contentData: ImagePreviewModalContentData;
}

function ImagePreviewModal({ closeModal, contentData }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [fakeImage, setFakeImage] = useState<{
    height: number;
    width: number;
  }>();

  useEffect(() => {
    (function createMyFakeImage() {
      const img = new Image();
      img.src = contentData.url ?? '';
      img.onload = () => {
        setFakeImage({
          height: img.height,
          width: img.width,
        });
      };
    })();
    return () => {
      setFakeImage(undefined);
    };
  }, [contentData.url]);

  return (
    <Dialog open onOpenChange={() => closeModal()}>
      <DialogPortal>
        <DialogOverlay className='bg-black/70'>
          <div className='fixed left-0 top-0 z-[100] flex items-center gap-4 p-4'>
            <Button
              onClick={() => closeModal()}
              variant={'secondary'}
              size={'icon-sm'}
            >
              <XMarkIcon className='size-6' />
            </Button>
            <span className='font-medium text-white'>{contentData.title}</span>
          </div>
        </DialogOverlay>
        <DialogContent
          className={cn(
            'fixed z-50 flex items-center justify-center animate-in data-[state=open]:fade-in-90 data-[state=open]:slide-in-from-bottom-10 sm:zoom-in-90 data-[state=open]:sm:slide-in-from-bottom-0'
          )}
        >
          {isLoading && (
            <div
              className='fixed inset-0 flex 
             flex-col items-center justify-center gap-2'
            >
              <Icons.loader className='size-10 animate-spin text-white' />
              <span className='whitespace-nowrap font-medium text-white'>
                Laddar bild...
              </span>
            </div>
          )}
          {isError && (
            <div className='fixed inset-0 flex items-center justify-center'>
              <span className='whitespace-nowrap text-white'>
                Failed to load image
              </span>
            </div>
          )}
          <div className='relative overflow-y-clip p-8'>
            {!isError && fakeImage?.height && fakeImage.width && (
              <NextImage
                width={fakeImage?.width}
                height={fakeImage?.height}
                onLoad={() => setIsLoading(false)}
                onError={() => {
                  setIsLoading(false);
                  setIsError(true);
                }}
                className='h-auto max-h-[calc(100vh-144px)] w-full max-w-screen-lg object-contain'
                src={contentData.url ?? ''}
                alt={contentData.title}
              />
            )}
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}

export const getImagePreviewModalContent = ({
  closeModal,
  contentData,
}: Props): ModalContent => {
  return {
    isCloseOnEscape: true,
    externalModalHandling: true,
    body: (
      <ImagePreviewModal closeModal={closeModal} contentData={contentData} />
    ),
  };
};
