import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { useState } from 'react';

export function SegmentPopover({
  title,
  emptyTitle,
  data,
  fields,
}: {
  title?: string;
  data: string[];
  fields: string[];
  emptyTitle?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        {data.length > 0 ? (
          <Button
            disabled={data.length === 0}
            variant='link'
            size='sm'
            className='z-0 h-auto whitespace-nowrap p-0'
          >
            {title ?? '-'}
          </Button>
        ) : (
          <span className='pointer-events-none whitespace-nowrap'>
            {emptyTitle ?? '-'}
          </span>
        )}
      </PopoverTrigger>
      <PopoverContent align='center' className='p-0'>
        <div className='relative max-h-36 overflow-auto rounded-md pb-4'>
          <table className='w-full caption-bottom text-sm'>
            <thead className='sticky top-0 bg-background pt-4'>
              <tr>
                {fields.map((field) => (
                  <th
                    key={field}
                    className='h-6 whitespace-nowrap px-4 pt-4 text-left align-middle text-xs font-normal text-foreground/60'
                  >
                    {field}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='w-full'>
              {data?.map((item, i) => (
                <tr key={item + i.toString()}>
                  {fields.map((field, i) => {
                    return (
                      <td
                        key={field.toString() + i}
                        className='whitespace-nowrap px-4 text-left align-middle'
                      >
                        {item ?? '-'}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </PopoverContent>
    </Popover>
  );
}
