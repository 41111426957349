 import type { IndexationSettingUsage } from '@pigello/pigello-matrix';

import { IndexationSettingUsageConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetIndexationSettingUsage = (
  options: useGetGenericInstanceProps<IndexationSettingUsage>
) => {
  return useGetInstance<IndexationSettingUsage>({
    ...options,
    config: IndexationSettingUsageConfig,
    modelName: 'indexationsettingusage'
  });
};

export const useGetIndexationSettingUsageList = (
  options: useGetGenericListProps<IndexationSettingUsage>
) => {
  return useGetList<IndexationSettingUsage>({
    ...options,
    config: IndexationSettingUsageConfig,
    modelName: 'indexationsettingusage'
  });
};
