'use client';

import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header';
import { TableActions } from '@/components/data-table/table-actions';
import { TableTags } from '@/components/data-table/table-tags';
import { Checkbox } from '@/components/ui/checkbox';
import { toReadableDateTime } from '@/lib/dates';
import { Urls } from '@/lib/urls';
import {
  RealEstateConfig,
  SegmentConfig,
  type Segment,
} from '@pigello/pigello-matrix';
import type { ColumnDef } from '@tanstack/react-table';
import { DropdownList } from '../../components/dropdown-list';
import { SegmentPopover } from './segment-popover';

export const columns: ColumnDef<Segment>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'customId',
    meta: {
      columnVisibilityName: SegmentConfig.fields.customId.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.customId ?? '-'}</div>
    ),
  },
  {
    accessorKey: 'name',
    meta: {
      columnVisibilityName: SegmentConfig.fields.name.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <div className='whitespace-nowrap'>{row.original.name}</div>
    ),
  },
  {
    accessorKey: 'realestates',
    enableSorting: false,
    meta: {
      columnVisibilityName: SegmentConfig.fields.realestates.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <DropdownList
        ids={row.original.realestates?.map((re) => re.id) ?? []}
        detailModalLinkUrl={(id) => Urls.detail.realestate.overview(id)}
        config={RealEstateConfig}
        fields={['name']}
        initialData={row.original.realestates?.[0]}
      />
    ),
  },
  {
    accessorKey: 'createdAt',
    enableSorting: false,
    meta: {
      columnVisibilityName: SegmentConfig.fields.createdAt.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <span className='whitespace-nowrap'>
        {toReadableDateTime(row.original.createdAt)}
      </span>
    ),
  },
  {
    accessorKey: 'apartmentCategories',
    enableSorting: false,
    meta: {
      columnVisibilityName:
        SegmentConfig.fields.apartmentCategories.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <SegmentPopover
        fields={['Kategori']}
        title={
          row.original.apartmentCategories?.length
            ? `${
                SegmentConfig.fields.apartmentCategories.choices?.find(
                  (c) => c.v == row.original.apartmentCategories?.[0]
                )?.d
              }${
                row.original.apartmentCategories &&
                row.original.apartmentCategories?.length > 1
                  ? ` + ${row.original.apartmentCategories?.length - 1}`
                  : ''
              }`
            : ''
        }
        emptyTitle='Inga specifika kategorier angivna'
        data={
          row.original.apartmentCategories?.map(
            (r) =>
              SegmentConfig.fields.apartmentCategories.choices?.find(
                (c) => c.v == r
              )?.d as string
          ) ?? []
        }
      />
    ),
  },
  {
    accessorKey: 'industrialPremisesCategories',
    enableSorting: false,
    meta: {
      columnVisibilityName:
        SegmentConfig.fields.industrialPremisesCategories.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <SegmentPopover
        fields={['Kategori']}
        title={
          row.original.industrialPremisesCategories?.length
            ? `${
                SegmentConfig.fields.industrialPremisesCategories.choices?.find(
                  (c) => c.v == row.original.industrialPremisesCategories?.[0]
                )?.d
              }${
                row.original.industrialPremisesCategories &&
                row.original.industrialPremisesCategories?.length > 1
                  ? ` + ${
                      row.original.industrialPremisesCategories?.length - 1
                    }`
                  : ''
              }`
            : ''
        }
        emptyTitle='Inga specifika kategorier angivna'
        data={
          row.original.industrialPremisesCategories?.map(
            (r) =>
              SegmentConfig.fields.industrialPremisesCategories.choices?.find(
                (c) => c.v == r
              )?.d as string
          ) ?? []
        }
      />
    ),
  },
  {
    accessorKey: 'outdoorSectionCategories',
    enableSorting: false,
    meta: {
      columnVisibilityName:
        SegmentConfig.fields.outdoorSectionCategories.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <SegmentPopover
        fields={['Kategori']}
        title={
          row.original.outdoorSectionCategories?.length
            ? `${
                SegmentConfig.fields.outdoorSectionCategories.choices?.find(
                  (c) => c.v == row.original.outdoorSectionCategories?.[0]
                )?.d
              }${
                row.original.outdoorSectionCategories &&
                row.original.outdoorSectionCategories?.length > 1
                  ? ` + ${row.original.outdoorSectionCategories?.length - 1}`
                  : ''
              }`
            : ''
        }
        emptyTitle='Inga specifika kategorier angivna'
        data={
          row.original.outdoorSectionCategories?.map(
            (r) =>
              SegmentConfig.fields.outdoorSectionCategories.choices?.find(
                (c) => c.v == r
              )?.d as string
          ) ?? []
        }
      />
    ),
  },
  {
    accessorKey: 'parkingSpotCategories',
    enableSorting: false,
    meta: {
      columnVisibilityName:
        SegmentConfig.fields.parkingSpotCategories.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <SegmentPopover
        fields={['Kategori']}
        title={
          row.original.parkingSpotCategories?.length
            ? `${
                SegmentConfig.fields.parkingSpotCategories.choices?.find(
                  (c) => c.v == row.original.parkingSpotCategories?.[0]
                )?.d
              }${
                row.original.parkingSpotCategories &&
                row.original.parkingSpotCategories?.length > 1
                  ? ` + ${row.original.parkingSpotCategories?.length - 1}`
                  : ''
              }`
            : ''
        }
        emptyTitle='Inga specifika kategorier angivna'
        data={
          row.original.parkingSpotCategories?.map(
            (r) =>
              SegmentConfig.fields.parkingSpotCategories.choices?.find(
                (c) => c.v == r
              )?.d as string
          ) ?? []
        }
      />
    ),
  },
  {
    accessorKey: 'commonAreaCategories',
    enableSorting: false,
    meta: {
      columnVisibilityName:
        SegmentConfig.fields.commonAreaCategories.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <SegmentPopover
        fields={['Kategori']}
        title={
          row.original.commonAreaCategories?.length
            ? `${
                SegmentConfig.fields.commonAreaCategories.choices?.find(
                  (c) => c.v == row.original.commonAreaCategories?.[0]
                )?.d
              }${
                row.original.commonAreaCategories &&
                row.original.commonAreaCategories?.length > 1
                  ? ` + ${row.original.commonAreaCategories?.length - 1}`
                  : ''
              }`
            : ''
        }
        emptyTitle='Inga specifika kategorier angivna'
        data={
          row.original.commonAreaCategories?.map(
            (r) =>
              SegmentConfig.fields.commonAreaCategories.choices?.find(
                (c) => c.v == r
              )?.d as string
          ) ?? []
        }
      />
    ),
  },
  {
    accessorKey: 'brfApartmentCategories',
    enableSorting: false,
    meta: {
      columnVisibilityName:
        SegmentConfig.fields.brfApartmentCategories.verboseName,
    },
    header: ({ column }) => <DataTableColumnHeader column={column} />,
    cell: ({ row }) => (
      <SegmentPopover
        fields={['Kategori']}
        title={
          row.original.brfApartmentCategories?.length
            ? `${
                SegmentConfig.fields.brfApartmentCategories.choices?.find(
                  (c) => c.v == row.original.brfApartmentCategories?.[0]
                )?.d
              }${
                row.original.brfApartmentCategories &&
                row.original.brfApartmentCategories?.length > 1
                  ? ` + ${row.original.brfApartmentCategories?.length - 1}`
                  : ''
              }`
            : ''
        }
        emptyTitle='Inga specifika kategorier angivna'
        data={
          row.original.brfApartmentCategories?.map(
            (r) =>
              SegmentConfig.fields.brfApartmentCategories.choices?.find(
                (c) => c.v == r
              )?.d as string
          ) ?? []
        }
      />
    ),
  },
  {
    accessorKey: 'tags',
    meta: {
      columnVisibilityName: 'Taggar',
    },
    header: 'Taggar',
    cell: ({ row }) => (
      <span className='flex gap-2'>
        <TableTags
          tags={row.original.tags}
          modelName={SegmentConfig.modelName}
        />
      </span>
    ),
  },
  {
    id: 'actions',
    enableSorting: false,
    enableHiding: false,
    cell: ({ row }) => (
      <TableActions
        instance={row.original}
        config={SegmentConfig}
        title={row.original.name}
      />
    ),
  },
];
