import { cn } from '@/lib/utils';
import type { ListResponse } from '@/requests/types';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/16/solid';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { Button } from './ui/button';

interface PaginationProps {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  meta?: ListResponse<BaseInstance>['meta'];
  className?: string;
}

export function Pagination({
  page,
  setPage,
  meta,
  className,
}: PaginationProps) {
  return (
    <div className={cn('flex w-full items-center justify-end', className)}>
      <div className='flex w-[100px] items-center justify-center whitespace-nowrap text-sm font-medium'>
        Sida {page} av {meta?.page_amount}
      </div>
      <div className='flex items-center space-x-2'>
        <Button
          variant='outline'
          className='hidden size-8 p-0 lg:flex'
          onClick={() => setPage(1)}
          disabled={page === 1}
        >
          <span className='sr-only'>Gå till första sidan</span>
          <ChevronDoubleLeftIcon className='size-4' />
        </Button>
        <Button
          variant='outline'
          className='size-8 p-0'
          onClick={() => setPage((prev) => prev - 1)}
          disabled={page === 1}
        >
          <span className='sr-only'>Gå till föredetta sida</span>
          <ChevronLeftIcon className='size-4' />
        </Button>
        <Button
          variant='outline'
          className='size-8 p-0'
          onClick={() => setPage((prev) => prev + 1)}
          disabled={page === meta?.page_amount}
        >
          <span className='sr-only'>Gå till nästa sida</span>
          <ChevronRightIcon className='size-4' />
        </Button>
        <Button
          variant='outline'
          className='hidden size-8 p-0 lg:flex'
          onClick={() => setPage(meta?.page_amount ?? 1)}
          disabled={page === meta?.page_amount}
        >
          <span className='sr-only'>Gå till sista sidan</span>
          <ChevronDoubleRightIcon className='size-4' />
        </Button>
      </div>
    </div>
  );
}
