 import type { PaymentMethod } from '@pigello/pigello-matrix';

import { PaymentMethodConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetPaymentMethod = (
  options: useGetGenericInstanceProps<PaymentMethod>
) => {
  return useGetInstance<PaymentMethod>({
    ...options,
    config: PaymentMethodConfig,
    modelName: 'paymentmethod'
  });
};

export const useGetPaymentMethodList = (
  options: useGetGenericListProps<PaymentMethod>
) => {
  return useGetList<PaymentMethod>({
    ...options,
    config: PaymentMethodConfig,
    modelName: 'paymentmethod'
  });
};
