import SelectTable from '@/components/data-table/custom-tables/select-table';
import { getSelectItemFromModelName } from '@/components/data-table/custom-tables/select-table/select-items/relation-item-map';
import { Icons } from '@/components/icons';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useConfig } from '@/hooks/useConfig';
import useInfiniteScroll from '@/hooks/useInfiniteScroll';
import { usePerms } from '@/hooks/usePerms';
import { cn, typeGuard } from '@/lib/utils';
import { useGetInfiniteList, useGetInstance } from '@/requests/hooks';
import {
  ArrowsPointingOutIcon,
  CheckIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import { useMemo, useRef, useState } from 'react';
import { useFormContext, type Path } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import type { FieldProps } from '../types';
import { DescriptionTooltip } from './description-tooltip';

export function getCustomDisplayName<Instance extends BaseInstance>(
  modelName: ModelName | undefined,
  instance: Instance
): string | null {
  if (!modelName) return null;

  switch (modelName) {
    case 'tenant':
      return typeGuard(instance, 'communicationName')
        ? instance.communicationName
        : null;
    case 'brfcompany':
      return typeGuard(instance, 'company') ? instance.company?.name : null;
    default:
      return null;
  }
}

export default function RelationField<
  Instance extends BaseInstance = BaseInstance,
>({
  formField,
  name,
  placeholder,
  label,
  description,
  className,
  horizontal,
  disabled,
  overrideRelationConfigModelName,
  overrideRequired,
  filters,
  canHandleField,
}: FieldProps<Instance>) {
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [search, setSearch] = useState('');
  const btnRef = useRef<HTMLButtonElement>(null);
  const debouncedSearch = useDebounceCallback(setSearch, 300);
  const relationConfigName = formField.config?.fields[formField.name];
  const [initialData, setInitialData] = useState<Instance>();
  const { getValues } = useFormContext();

  const isNullable =
    formField.config.fields?.[name]?.nullable != null
      ? formField.config.fields?.[name]?.nullable
      : true;

  if (!('relationConfig' in relationConfigName)) {
    throw new Error('relationConfig is not in relationConfigName');
  }
  const { fields } = usePerms<Instance>(formField.config.modelName);

  const canView =
    fields[formField.name]?.canView === true ||
    fields[formField.name]?.canView === undefined;

  const relationConfigModelName =
    overrideRelationConfigModelName ?? relationConfigName.relationConfig;

  const { config: relationConfig, isLoading: isRelationConfigLoading } =
    useConfig<Instance>(relationConfigModelName);

  const id: string = getValues(name as Path<Instance>)?.id;

  const noInFilter = useMemo(() => {
    const clonedFilters = structuredClone(filters);

    const noInFilter = clonedFilters?.['id']?.['__in!'] as string;

    if (filters?.['id'] && filters?.['id']?.['__in!']) {
      filters['id']['__in!'] = undefined;
    }
    return noInFilter;
  }, [filters]);

  const idFilter = [id, noInFilter].filter(Boolean).join(',') || undefined;

  const { data, isFetching, hasNextPage, fetchNextPage, error } =
    useGetInfiniteList<Instance>({
      modelName: relationConfigModelName,
      enabled: !isRelationConfigLoading && canView && !disabled,
      queryParams: {
        filters: {
          ...filters,
          ...((id || noInFilter) && {
            id: {
              '__in!': idFilter,
            },
          }),
          archived: { __in: false },
        },
        page: 1,
        pageSize: id ? 24 : 25,
        search,
      },
      nested: getSelectItemFromModelName(relationConfigModelName)?.nested,
    });

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage,
    onLoadMore: fetchNextPage,
    disabled: !!error,
    rootMargin: '0px 0px 100px 0px',
  });

  const renderDisplayName = (instance: Instance | undefined) => {
    if (!instance) return '';

    const customDisplay = getCustomDisplayName(
      relationConfig?.modelName,
      instance
    );

    if (customDisplay) return customDisplay;

    const displayKey = relationConfig?.displayFieldName;

    if (displayKey && instance[displayKey as keyof Instance] != null) {
      return instance[displayKey as keyof Instance]?.toString();
    }

    if (instance['customId'] != null) return instance['customId'];

    return instance['id'];
  };

  const { data: singleInstance, isPending: isInitialLoadingSelectedInstance } =
    useGetInstance<Instance>({
      modelName: relationConfigModelName,
      id: id,
      enabled: !isRelationConfigLoading && !!id && canView && !disabled,
      nested: getSelectItemFromModelName(relationConfigModelName)?.nested,
      initialData,
    });

  const options = [singleInstance]
    .concat(
      data?.pages.flatMap((p) => p.list).filter((inst) => inst?.id !== id) ?? []
    )
    .filter(Boolean) as Instance[];

  if (!canView) return null;
  return (
    <FormField
      control={formField.control}
      name={name as Path<Instance>}
      rules={{
        required:
          (overrideRequired != null ? overrideRequired : formField.required) &&
          'Detta fält är obligatoriskt',
      }}
      render={({ field }) => {
        const clearField = () => {
          field.onChange(null);
        };

        return (
          <>
            <Dialog open={expanded} onOpenChange={setExpanded}>
              <DialogContent
                size='lg'
                className='p-0 shadow-2xl'
                overlayClassName='bg-gradient-to-t from-black/0 to-black/0 backdrop-blur-0'
              >
                <DialogHeader>
                  <DialogTitle>
                    Välj {formField.label.toLowerCase()}
                  </DialogTitle>
                  <Button
                    variant={'secondary'}
                    size={'icon-sm'}
                    onClick={() => setExpanded(false)}
                  >
                    <XMarkIcon className='size-4' />
                  </Button>
                </DialogHeader>
                <SelectTable
                  queryParams={{
                    filters: filters,
                  }}
                  modelName={relationConfigModelName}
                  tableId={`${relationConfigModelName}-relation-field-table`}
                  selected={field.value ? [field.value.id] : []}
                  onSelected={(id) => {
                    setInitialData(options.find((o) => o.id === id));
                    field.onChange({ id });
                    setExpanded(false);
                  }}
                />
              </DialogContent>
            </Dialog>
            <FormItem
              className={cn(
                'flex items-center justify-start',
                !horizontal && 'flex w-full flex-col items-start',
                className
              )}
            >
              <div className={cn(horizontal ? 'w-1/2 shrink-0' : 'w-full')}>
                <div className={cn('flex items-start')}>
                  {(label || formField?.label) && (
                    <FormLabel
                      className={cn(
                        !canView ||
                          disabled ||
                          (field.disabled && 'pointer-events-none')
                      )}
                    >
                      {label ?? formField.label}{' '}
                      {label ?? (formField.label && formField.required && '*')}
                    </FormLabel>
                  )}
                  {(description || formField.description) && (
                    <DescriptionTooltip
                      description={description ?? formField.description}
                    />
                  )}
                </div>
                {horizontal && <FormMessage />}
              </div>
              <div className='flex w-full items-center gap-2'>
                <Popover
                  modal={true}
                  open={open}
                  onOpenChange={(open) => {
                    setSearch('');
                    setOpen(open);
                    setExpanded(false);
                    setInitialData(undefined);
                  }}
                >
                  <FormControl>
                    <PopoverTrigger asChild>
                      <Button
                        ref={btnRef}
                        disabled={disabled ?? field.disabled ?? !canHandleField}
                        variant='outline'
                        role='listbox'
                        className={cn(
                          'h-10 w-full items-center justify-between px-3 leading-relaxed shadow-input'
                        )}
                      >
                        {field.value ? (
                          <div className='h-full truncate'>
                            {isInitialLoadingSelectedInstance
                              ? 'Laddar...'
                              : renderDisplayName(singleInstance)}
                          </div>
                        ) : (
                          <div className='line-clamp-1 opacity-60'>
                            {placeholder ?? `Välj ${formField.label}`}
                          </div>
                        )}
                        <div className='flex items-center'>
                          {isNullable && field.value && (
                            <span
                              title='Rensa fältet'
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  clearField();
                                }
                              }}
                              role='button'
                              tabIndex={0}
                              onClick={(e) => {
                                e.stopPropagation();
                                clearField();
                              }}
                            >
                              <XMarkIcon className='size-4 opacity-50 transition-opacity hover:opacity-100' />
                            </span>
                          )}
                          <ChevronUpDownIcon className='ml-2 size-4 shrink-0 opacity-50' />
                        </div>
                      </Button>
                    </PopoverTrigger>
                  </FormControl>
                  <PopoverContent
                    align='center'
                    style={{
                      width: btnRef.current?.offsetWidth,
                    }}
                    className={
                      'p-0 pb-1 shadow-[rgba(0,0,0,0.12)_0px_4px_30px,rgba(0,0,0,0.04)_0px_3px_17px,rgba(0,0,0,0.04)_0px_2px_8px,rgba(0,0,0,0.04)_0px_1px_1px] transition-all duration-300 ease-in-out'
                    }
                  >
                    <Command shouldFilter={false} loop={true}>
                      <CommandInput
                        placeholder={
                          placeholder ??
                          `Sök efter ${formField.label.toLowerCase()}`
                        }
                        onValueChange={(value) => debouncedSearch(value.trim())}
                        isLoading={isFetching}
                        expand={
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setOpen(false);
                                    setExpanded((expanded) => !expanded);
                                  }
                                }}
                                variant='ghost'
                                size={'icon-sm'}
                                className='group'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpen(false);
                                  setExpanded((expanded) => !expanded);
                                }}
                              >
                                <ArrowsPointingOutIcon className='size-4 shrink-0 opacity-50 transition-opacity group-hover:opacity-100' />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              Visa avancerade sökalternativ
                            </TooltipContent>
                          </Tooltip>
                        }
                      />
                      <CommandList>
                        {!isFetching && (
                          <CommandEmpty className='flex items-center justify-center gap-2 font-medium text-muted-foreground'>
                            <MagnifyingGlassIcon className='size-6 opacity-50' />
                            Inga resultat hittades
                          </CommandEmpty>
                        )}
                        <CommandGroup
                          className={cn('max-h-60 w-auto overflow-y-scroll')}
                        >
                          {options.map((option) => (
                            <CommandItem
                              keywords={[option.id]}
                              key={option.id}
                              value={option.id}
                              onSelect={() => {
                                setInitialData(option);
                                field.onChange({ id: option.id });
                                setOpen(false);
                              }}
                            >
                              <div className='flex w-full'>
                                <CheckIcon
                                  className={cn(
                                    'mr-2 size-4',
                                    option.id === field.value?.['id']
                                      ? 'opacity-100'
                                      : 'opacity-0'
                                  )}
                                />
                                <div
                                  className={cn(
                                    'mr-4',
                                    !getSelectItemFromModelName(
                                      relationConfigModelName
                                    ).renderItem(option).avatar && 'hidden'
                                  )}
                                >
                                  {
                                    getSelectItemFromModelName(
                                      relationConfigModelName
                                    ).renderItem(option).avatar
                                  }
                                </div>
                                <div className='w-full'>
                                  <div className='flex w-full items-center justify-between'>
                                    {renderDisplayName(option)}{' '}
                                    <span>
                                      {
                                        getSelectItemFromModelName(
                                          relationConfigModelName
                                        )?.renderItem(option).badge
                                      }
                                    </span>
                                  </div>
                                  <div className='whitespace-nowrap text-xs text-muted-foreground'>
                                    {
                                      getSelectItemFromModelName(
                                        relationConfigModelName
                                      )?.renderItem(option, true)
                                        .descriptionItems
                                    }
                                  </div>
                                </div>
                              </div>
                            </CommandItem>
                          ))}

                          {(isFetching || hasNextPage) && (
                            <div
                              ref={sentryRef}
                              className='flex size-full items-center justify-center'
                            >
                              <Icons.loader className='my-4 size-6 animate-spin' />
                            </div>
                          )}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              </div>
              {!horizontal && <FormMessage />}
            </FormItem>
          </>
        );
      }}
    />
  );
}
