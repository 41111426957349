 import type { Folder } from '@pigello/pigello-matrix';

import { FolderConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useCreateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, createGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { createGenericInstance } from '@/requests/creating';

export const createFolder = async (options: createGenericInstanceProps<Folder>) => {
  return createGenericInstance<Folder>({
    ...options,
    config: FolderConfig,
  });
};

export const useCreateFolder = (
  options: UseMutationOptions<Folder, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useCreateGenericInstance<Folder>(createFolder, options, 'folder');
