import { type IOperator } from '@/components/table-filters/constants';
import { camelToSnake, ObjectKeys, raise } from '@/lib/utils';
import type {
  PaginationState,
  SortingState,
  Updater,
} from '@tanstack/react-table';
import { cloneDeep, merge } from 'lodash';
import {
  parseAsArrayOf,
  parseAsInteger,
  parseAsJson,
  parseAsString,
  useQueryState,
} from 'nuqs';
import { useMemo } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import type { JsonFilter } from './types';
import { orderByToParams, parseJsonFilter, parseOrderToClient } from './utils';

type TableFilterProps = {
  isClient?: boolean;
  defaultPageSize?: number;
  tableId: string;
};

const cleanFilters = (filters: JsonFilter) => {
  const filterClone = cloneDeep(filters);

  ObjectKeys(filterClone).forEach((key) => {
    Object.keys(filterClone[key]).forEach((operatorKey) => {
      if (filterClone[key][operatorKey as IOperator] === undefined) {
        delete filterClone[key][operatorKey as IOperator];
      }
    });
  });

  return filterClone;
};

export function useTableFilter({
  isClient = false,
  defaultPageSize = 25,
  tableId,
}: TableFilterProps) {
  if (isClient && !tableId) raise('Needs tableId if client filter');

  const [filter, setFilter] = useQueryState(
    'filter',
    parseAsJson<JsonFilter>()
  );

  const [sessionFilter, setSessionFilter] =
    useSessionStorage<JsonFilter | null>(tableId, {});

  const [lastUsedFilter, setLastUsedFilter] =
    useSessionStorage<JsonFilter | null>(tableId, {});

  const currentFilter = useMemo(() => {
    if (isClient) return cleanFilters(sessionFilter ?? {});
    return cleanFilters(filter ?? {});
  }, [filter, sessionFilter, isClient]);

  const [search, setSearch] = useQueryState('_search', {
    ...parseAsString,
    clearOnDefault: true,
    defaultValue: '',
  });

  const [sessionSearch, setSessionSearch] = useSessionStorage(
    `${tableId}_search`,
    ''
  );

  const [page, setPage] = useQueryState('_page', {
    ...parseAsInteger,
    defaultValue: 0,
  });

  const [pageSize, setPageSize] = useQueryState('_page_size', {
    ...parseAsInteger,
    defaultValue: defaultPageSize ?? 25,
  });
  const [sessionPage, setSessionPage] = useSessionStorage(`${tableId}_page`, 0);
  const [sessionPageSize, setSessionPageSize] = useSessionStorage(
    `${tableId}_page_size`,
    defaultPageSize ?? 25
  );

  const [orderBy, setOrderBy] = useQueryState('_order_by', {
    ...parseAsArrayOf(parseAsString),
    defaultValue: [],
    clearOnDefault: true,
  });

  const parsedSorting = parseOrderToClient(orderBy ?? []);

  const backendReadyParsedSorting = parsedSorting.map(
    (p) => `${p.desc ? '-' : ''}${camelToSnake(p.id)}`
  );

  /**
 * @description
 * This combines input filters with the url/session filters 
 * @param name - combineWith: the filters to be merged with state filters.

 */
  const combineFilters = (combineWith: JsonFilter) => {
    const merged = merge(currentFilter, combineWith);

    return merged;
  };

  /**
 * @description
 * This parses filters for sending to backend
 * @param name - filters: JsonFilter object.

 */
  const parseFilters = (filters: JsonFilter) => {
    return parseJsonFilter(filters);
  };

  const toExcelDownloadFilterFormat = (
    filters: JsonFilter
  ): Record<string, string> => {
    const filterStr = parseFilters(filters);
    const filterParts = filterStr.split('&');

    const excelFormat: Record<string, string> = {};

    for (let i = 0; i < filterParts.length; i++) {
      const current = filterParts[i];
      const [filterKey, filterVal] = current.split('=');

      excelFormat[filterKey] = filterVal;
    }

    return excelFormat;
  };

  const setSearchAndClearPagination = (searchValue: string) => {
    if (isClient) {
      setSessionSearch(searchValue);
      setSessionPage(0);
    } else {
      setSearch(searchValue);
      setPage(0);
    }
  };

  const setPagination = (paginationData: Updater<PaginationState>) => {
    const oldPaginationData = {
      pageIndex: isClient ? sessionPage : page,
      pageSize: isClient ? sessionPageSize : pageSize,
    };
    if (typeof paginationData === 'function') {
      const newPaginationData = paginationData(oldPaginationData);
      const { pageSize: newPageSize, pageIndex: newPageIndex } =
        newPaginationData;

      if (isClient) {
        setSessionPage(newPageIndex);
        setSessionPageSize(newPageSize);
      } else {
        setPage(newPageIndex);
        setPageSize(newPageSize);
      }
    }
  };

  const setSorting = (sorting: Updater<SortingState>) => {
    if (typeof sorting === 'function') {
      const res = sorting(parsedSorting);
      const querySort = orderByToParams(res);
      setOrderBy(querySort);
    }
  };

  const setTableFilter = (filter: JsonFilter | null) => {
    if (isClient) {
      setSessionFilter(filter);
      setSessionPage(0);
    } else {
      setFilter(filter);
      setPage(0);
      setLastUsedFilter(filter);
    }
  };

  return {
    page: isClient ? sessionPage : page,
    pageSize: isClient ? sessionPageSize : pageSize,
    filter: isClient ? sessionFilter : filter,
    currentFilter,
    sorting: parsedSorting,
    sortingArray: orderBy,
    search: isClient ? sessionSearch : search,
    setSearch: setSearchAndClearPagination,
    setPage: isClient ? setSessionPage : setPage,
    setSorting,
    setPagination,
    setTableFilter,
    combineFilters,
    parseFilters,
    lastUsedFilter,
    backendReadyParsedSorting,
    toExcelDownloadFilterFormat,
  };
}
