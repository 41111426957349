'use client';

import { useGetArticle } from '@/config/accounting/articles/article/client';
import { useGetArticleAccountOverrideList } from '@/config/accounting/articles/articleaccountoverride/client';
import { Urls } from '@/lib/urls';
import { cn } from '@/lib/utils';
import { ArticleConfig } from '@pigello/pigello-matrix';
import Link from 'next/link';
import { CardSection } from '../detail/components/cardSection';
import { Badge } from '../ui/badge';
import { buttonVariants } from '../ui/button';
import { Card, CardHeader, CardTitle } from '../ui/card';
import { Switch } from '../ui/switch';

export default function ArticleRowInfo({ id }: { id: string | undefined }) {
  const { data, isLoading } = useGetArticle({
    id,
    enabled: !!id,
  });

  const { data: overrides } = useGetArticleAccountOverrideList({
    queryParams: {
      filters: {
        article: { noop: id! },
      },
    },
    enabled: !!id,
  });
  const hasOverrides = !!overrides?.list?.length;

  return (
    <Card className='min-w-[400px] border-none p-0 shadow-none'>
      <CardHeader className='flex flex-row items-center justify-between p-2'>
        <CardTitle>Artikel</CardTitle>
      </CardHeader>
      <div className='max-h-[350px] overflow-auto p-2'>
        {hasOverrides && (
          <>
            <Badge className='w-full' variant='orange'>
              OBS: Denna artikel har kontoöverskrivningar för vissa bolag
            </Badge>
            <hr className='my-2' />
          </>
        )}
        <CardSection
          isLoading={isLoading}
          items={[
            {
              title: ArticleConfig.fields.name.verboseName,
              content: data?.name ? (
                <Link
                  prefetch={false}
                  className={cn(
                    buttonVariants({
                      variant: 'link',
                      className: 'h-auto p-0 whitespace-nowrap',
                    })
                  )}
                  href={Urls.settings.modules.invoicing.articles.default}
                >
                  {data?.name}
                </Link>
              ) : (
                'Information saknas'
              ),
            },
            {
              title: ArticleConfig.fields.invoiceDisplayName.verboseName,
              content: data?.invoiceDisplayName ?? 'Samma som namn',
            },
            {
              title:
                ArticleConfig.fields.includeSpaceCategoryInDisplayName
                  .verboseName,
              content: (
                <Switch
                  disabled
                  checked={data?.includeSpaceCategoryInDisplayName}
                />
              ),
            },
            {
              title: ArticleConfig.fields.vat.verboseName,
              content: ArticleConfig.fields.vat.choices?.find(
                (c) => c.v === data?.vat
              )?.d,
            },
            {
              title:
                ArticleConfig.fields.ignoreDynamiclyCalculatedVat.verboseName,
              content: (
                <Switch disabled checked={data?.ignoreDynamiclyCalculatedVat} />
              ),
            },
            {
              title: ArticleConfig.fields.revenueVat0Account.verboseName,
              content: data?.revenueVat0Account,
            },
            {
              title: ArticleConfig.fields.revenueVat6Account.verboseName,
              content: data?.revenueVat6Account,
            },
            {
              title: ArticleConfig.fields.revenueVat12Account.verboseName,
              content: data?.revenueVat12Account,
            },
            {
              title: ArticleConfig.fields.revenueVat25Account.verboseName,
              content: data?.revenueVat25Account,
            },
            {
              title: ArticleConfig.fields.performRevenueLossBooking.verboseName,
              content: (
                <Switch disabled checked={data?.performRevenueLossBooking} />
              ),
            },
            {
              title: ArticleConfig.fields.revenueLossAccount.verboseName,
              content: data?.revenueLossAccount,
            },
          ]}
        />
      </div>
    </Card>
  );
}
