import {
  defaultRelationFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import {
  ApartmentConfig,
  ApartmentContractConfig,
} from '@pigello/pigello-matrix';
import baseFilters, { baseTagFilter } from '../../filters';
import baseContractFilters from '../components/base-contract-filters';

const apartmentContractFilters: IFilter[] = [
  {
    title: ApartmentContractConfig.fields.space.verboseName,
    relationModelName: ApartmentConfig.modelName,
    externalFieldName: ApartmentContractConfig.fields.space.externalFieldName,
    ...defaultRelationFilter,
  },
  ...baseContractFilters,
  ...baseTagFilter,
  ...baseFilters,
];

export default apartmentContractFilters;
