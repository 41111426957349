 import type { CustomerColumnValue } from '@pigello/pigello-matrix';

import { CustomerColumnValueConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useDeleteGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, deleteGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { deleteGenericInstance } from '@/requests/deleting';

export const deleteCustomerColumnValue = async (
  options: deleteGenericInstanceProps<CustomerColumnValue>
) => {
  return deleteGenericInstance<CustomerColumnValue>({
    ...options,
    config: CustomerColumnValueConfig,
  });
};

export const useDeleteCustomerColumnValue = (
  options: UseMutationOptions<CustomerColumnValue, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useDeleteGenericInstance<CustomerColumnValue>(deleteCustomerColumnValue, options, 'customercolumnvalue');