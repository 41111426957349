import {
  defaultDateFilter,
  defaultNumberFilter,
  defaultRelationFilter,
  defaultSelectFilter,
  defaultStringFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import { BuildingConfig, RealEstateConfig } from '@pigello/pigello-matrix';
import baseFilters, { baseTagFilter } from '../../filters';

const buildingFilters: IFilter[] = [
  {
    title: BuildingConfig.fields.name.verboseName,
    externalFieldName: BuildingConfig.fields.name.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: BuildingConfig.fields.customId.verboseName,
    externalFieldName: BuildingConfig.fields.customId.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: BuildingConfig.fields.structureOperationType.verboseName,
    externalFieldName:
      BuildingConfig.fields.structureOperationType.externalFieldName,
    choices: BuildingConfig.fields.structureOperationType.choices,
    ...defaultSelectFilter,
  },
  {
    title: BuildingConfig.fields.structureType.verboseName,
    externalFieldName: BuildingConfig.fields.structureType.externalFieldName,
    choices: BuildingConfig.fields.structureType.choices,
    ...defaultSelectFilter,
  },
  {
    title: BuildingConfig.fields.realestate.verboseName,
    externalFieldName: BuildingConfig.fields.realestate.externalFieldName,
    relationModelName: RealEstateConfig.modelName,
    ...defaultRelationFilter,
  },
  {
    title: BuildingConfig.fields.registeredUsableArea.verboseName,
    externalFieldName:
      BuildingConfig.fields.registeredUsableArea.externalFieldName,

    ...defaultNumberFilter,
  },
  {
    title: BuildingConfig.fields.registeredGrossArea.verboseName,
    externalFieldName:
      BuildingConfig.fields.registeredGrossArea.externalFieldName,

    ...defaultNumberFilter,
  },
  {
    title: BuildingConfig.fields.registeredBuildingArea.verboseName,
    externalFieldName:
      BuildingConfig.fields.registeredBuildingArea.externalFieldName,

    ...defaultNumberFilter,
  },
  {
    title: BuildingConfig.fields.registeredBiArea.verboseName,
    externalFieldName: BuildingConfig.fields.registeredBiArea.externalFieldName,

    ...defaultNumberFilter,
  },
  {
    title: BuildingConfig.fields.calculatedUsableArea.verboseName,
    externalFieldName:
      BuildingConfig.fields.calculatedUsableArea.externalFieldName,

    ...defaultNumberFilter,
  },
  {
    title: BuildingConfig.fields.calculatedBiArea.verboseName,
    externalFieldName: BuildingConfig.fields.calculatedBiArea.externalFieldName,

    ...defaultNumberFilter,
  },

  {
    title: BuildingConfig.fields.availabilityStartDate.verboseName,
    externalFieldName:
      BuildingConfig.fields.availabilityStartDate.externalFieldName,
    ...defaultDateFilter,
  },
  {
    title: BuildingConfig.fields.availabilityEndDate.verboseName,
    externalFieldName:
      BuildingConfig.fields.availabilityEndDate.externalFieldName,
    ...defaultDateFilter,
  },
  {
    title: BuildingConfig.fields.builtDate.verboseName,
    externalFieldName: BuildingConfig.fields.builtDate.externalFieldName,
    ...defaultDateFilter,
  },
  {
    title: BuildingConfig.fields.demolitionDate.verboseName,
    externalFieldName: BuildingConfig.fields.demolitionDate.externalFieldName,
    ...defaultDateFilter,
  },

  ...baseTagFilter,
  ...baseFilters,
];

export default buildingFilters;
