'use client';

import { Input } from '@/components/ui/input';
import { useTableFilter } from '@/hooks/use-table-filter';
import { useDebounceCallback } from 'usehooks-ts';

export default function TableSearch({
  isClient,
  tableId,
}: {
  isClient?: boolean;
  tableId: string;
}) {
  const { search, setSearch } = useTableFilter({ isClient, tableId });
  const debounced = useDebounceCallback(setSearch, 500);

  return (
    <Input
      className='w-80'
      placeholder='Sök...'
      defaultValue={search ?? ''}
      onChange={(event) => {
        debounced(event.target.value);
      }}
    />
  );
}
