'use client';

import { cn } from '@/lib/utils';
import { getRelationFieldNames } from '@/requests/instanceMapper';
import { QuotaFeeConfig, type QuotaFee } from '@pigello/pigello-matrix';
import Dot from './dot';
import type { RelationFieldProps } from './relation-item-map';

export const QuotaFeeItem: RelationFieldProps<QuotaFee> = {
  nested: getRelationFieldNames(QuotaFeeConfig),
  filters: [],
  columns: [],
  renderItem: (item, isRelationField) => ({
    title: item.customId,
    descriptionItems: (
      <div
        className={cn(
          isRelationField ? 'grid w-full' : 'flex flex-row items-center'
        )}
      >
        <div className='font-medium'>{item.article?.name}</div>
        {!isRelationField && <Dot />}
        <div
          className={cn(
            'flex w-full items-center',
            isRelationField && 'justify-between'
          )}
        >
          <span>{item.quotaCollection?.name}</span>
          {!isRelationField && <Dot />}
          <span> {item.customId ?? 'Inget objekts-id angivet'}</span>
        </div>
      </div>
    ),
  }),
};
