import {
  AutomaticDistributionTypeConfig,
  type ChoiceField,
} from '@pigello/pigello-matrix';
import type { BadgeVariant } from '../ui/badge';

export function convertStructureOperationTypes(choices: ChoiceField<number>) {
  return (
    choices.choices?.map((choice) => ({
      operator: 'in',
      description: choice.d,
      value: choice.v,
    })) ?? []
  );
}

export type FieldType =
  | 'text'
  | 'number'
  | 'date'
  | 'boolean'
  | 'booleanNull'
  | 'status'
  | 'mtActionKindStatus'
  | 'revenueRowStatus'
  | 'objectStatus'
  | 'tenantStatus'
  | 'distributionKind';

export type ConditionalOperation =
  | {
      type: 'boolean';
      operations: {
        operator: string;
        id?: string;
        description: string;
        value: string | number;
      }[];
    }
  | {
      type: FieldType;
      operations: {
        id?: string;
        operator: string;
        description: string;
        custom?: string;
        value?: string | number;
      }[];
    };

export type MetaFilterData = {
  id: string;
  name: string;
} & ConditionalOperation;

export const FilterForType: Record<
  FieldType,
  Omit<MetaFilterData, 'id' | 'name'>
> = {
  text: {
    type: 'text',
    operations: [
      {
        operator: 'icontains',
        description: 'Innehåller',
      },
      {
        operator: 'iexact',
        description: 'Exakt',
      },
    ],
  },
  number: {
    type: 'number',
    operations: [
      {
        operator: 'gte',
        description: 'Min',
      },
      {
        operator: 'lte',
        description: 'Max',
      },
    ],
  },
  date: {
    type: 'date',
    operations: [
      {
        operator: 'gte',
        description: 'Från',
      },
      {
        operator: 'lte',
        description: 'Till',
      },
    ],
  },
  boolean: {
    type: 'boolean',
    operations: [
      {
        operator: '',
        description: 'Ja',
        value: 'true',
      },
      {
        operator: '',
        description: 'Nej',
        value: 'false',
      },
    ],
  },
  booleanNull: {
    type: 'booleanNull',
    operations: [
      {
        operator: '',
        description: 'Ja',
        value: 'true',
      },
      {
        operator: '',
        description: 'Inget värde',
        value: 'null',
      },
      {
        operator: '',
        description: 'Nej',
        value: 'false',
      },
    ],
  },
  mtActionKindStatus: {
    type: 'status',
    operations: [
      {
        description: 'Skapades',
        operator: 'in',
        value: 0,
      },
      {
        description: 'Uppdaterades',
        operator: 'in',
        value: 1,
      },
      {
        description: 'Raderades',
        operator: 'in',
        value: 2,
      },
    ],
  },
  revenueRowStatus: {
    type: 'status',
    operations: [
      {
        operator: 'in',
        description: 'Aktiv',
        value: '0',
      },
      {
        operator: 'in',
        description: 'Pausad',
        value: '1',
      },
      {
        operator: 'in',
        description: 'Tidigare',
        value: '2',
      },
      {
        operator: 'in',
        description: 'Kommande',
        value: '3',
      },
    ],
  },
  tenantStatus: {
    type: 'status',
    operations: [
      {
        operator: 'lte',
        description: 'Aktiv',
        value: '2',
      },
      {
        operator: `custom__tenant__status`,
        description: 'Potentiell',
        value: 'potential',
      },
      {
        operator: `custom__tenant__status`,
        description: 'Tidigare',
        value: 'earliest',
      },
      {
        operator: `custom__tenant__status`,
        description: 'Kommande',
        value: 'upcoming',
      },
    ],
  },
  objectStatus: {
    type: 'status',
    operations: [
      {
        operator: 'in',
        description: 'Uthyrd',
        value: '0',
      },
      {
        operator: 'in',
        description: 'Uthyrd (Tillsvidare)',
        value: '1',
      },
      {
        operator: 'in',
        description: 'Uthyrd (Tidsbestämt)',
        value: '2',
      },
      {
        operator: 'in',
        description: 'Uthyrd (Pågående uppsägning för villkorsändring)',
        value: '3',
      },
      {
        operator: 'in',
        description: 'Uthyrd (Pågående uppsägning för avflytt)',
        value: '4',
      },
      {
        operator: 'in',
        description: 'Uthyrd (Uppsagt för villkorsändring)',
        value: '5',
      },
      {
        operator: 'in',
        description: 'Uthyrd (Uppsagt för avflytt)',
        value: '6',
      },
      {
        operator: 'in',
        description: 'Uthyrd (Utgående)',
        value: '7,8,9',
      },
      {
        operator: 'gte',
        description: 'Vakant',
        value: '10',
      },
      {
        operator: 'lte',
        description: 'Uthyrd',
        value: '9',
      },
    ],
  },
  status: {
    type: 'status',
    operations: [
      {
        operator: 'in',
        description: 'Aktiv',
        value: '0',
      },
      {
        operator: 'in',
        description: 'Aktiv tillsvidare',
        value: '1',
      },
      {
        operator: 'in',
        description: 'Aktiv till slutdag',
        value: '2',
      },
      {
        operator: 'in',
        description: 'Uppsagt för avtalsändring',
        value: '3',
      },
      {
        operator: 'in',
        description: 'Uppsägning pågår',
        value: '4',
      },
      {
        operator: 'in',
        description: 'Uppsagt för förhandling',
        value: '5',
      },
      {
        operator: 'in',
        description: 'Uppsagt',
        value: '6',
      },
      {
        operator: 'in',
        description: 'Varning för ansvarig',
        value: '7',
      },
      {
        operator: 'in',
        description: 'Varning för motpart',
        value: '8',
      },
      {
        operator: 'in',
        description: 'Kommande',
        value: '9',
      },
      {
        operator: 'in',
        description: 'Ej signerat',
        value: '10',
      },
      {
        operator: 'in',
        description: 'Inaktiv, uppsägning ej signerad',
        value: '11',
      },
      {
        operator: 'in',
        description: 'Inaktiv',
        value: '12',
      },
    ],
  },
  distributionKind: {
    type: 'status',
    operations: [
      {
        operator: 'in',
        description:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 0
          )?.d as string,
        value:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 0
          )?.v,
      },
      {
        operator: 'in',
        description:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 1
          )?.d as string,
        value:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 1
          )?.v,
      },
      {
        operator: 'in',
        description:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 2
          )?.d as string,
        value:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 2
          )?.v,
      },
      {
        operator: 'in',
        description:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 3
          )?.d as string,
        value:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 3
          )?.v,
      },
      {
        operator: 'in',
        description:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 4
          )?.d as string,
        value:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 4
          )?.v,
      },
      {
        operator: 'in',
        description:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 5
          )?.d as string,
        value:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 5
          )?.v,
      },
      {
        operator: 'in',
        description:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 6
          )?.d as string,
        value:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 6
          )?.v,
      },
      {
        operator: 'in',
        description:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 7
          )?.d as string,
        value:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 7
          )?.v,
      },
      {
        operator: 'in',
        description:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 8
          )?.d as string,
        value:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 8
          )?.v,
      },
      {
        operator: 'in',
        description:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 9
          )?.d as string,
        value:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 9
          )?.v,
      },
      {
        operator: 'in',
        description:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 10
          )?.d as string,
        value:
          AutomaticDistributionTypeConfig.fields.distributionMethod.choices?.find(
            (c) => c.v == 10
          )?.v,
      },
    ],
  },
};

export type StatusType = 'tenant' | 'contract' | 'object';

type Status = {
  text: string;
  color: BadgeVariant['variant'];
};

export const StatusMap: Record<StatusType, Record<number, Status>> = {
  tenant: {
    0: { text: 'Aktiv', color: 'green' },
    1: { text: 'Aktiv', color: 'green' },
    2: { text: 'Aktiv', color: 'green' },
    3: { text: 'Uppsagt för avtalsändring', color: 'destructive' },
    4: { text: 'Uppsägning pågår', color: 'destructive' },
    5: { text: 'Uppsagt för förhandling', color: 'destructive' },
    6: { text: 'Uppsagt', color: 'destructive' },
    7: { text: 'Varning för ansvarig', color: 'yellow' },
    8: { text: 'Varning för motpart', color: 'yellow' },
    9: { text: 'Kommande', color: 'blue' },
    10: { text: 'Ej signerat', color: 'cyan' },
    11: { text: 'Inaktiv, uppsägning ej signerad', color: 'orange' },
    12: { text: 'Inaktiv', color: 'orange' },
  },
  contract: {
    0: { text: 'Aktivt', color: 'green' },
    1: { text: 'Aktivt (Tillsvidare)', color: 'green' },
    2: { text: 'Aktivt (Tidsbestämt)', color: 'green' },
    3: {
      text: 'Uppsagt för villkorsändring (Ej bekräftad)',
      color: 'destructive',
    },
    4: { text: 'Uppsagt för avflytt (Ej bekräftad)', color: 'destructive' },
    5: { text: 'Uppsagt för villkorsändring', color: 'destructive' },
    6: { text: 'Uppsagt för avflytt', color: 'destructive' },
    7: { text: 'Utgående', color: 'yellow' },
    8: { text: 'Utgående', color: 'yellow' },
    9: { text: 'Kommande', color: 'blue' },
    10: { text: 'Ej signerat', color: 'purple' },
    11: { text: 'Uppsägning ej signerad', color: 'light-gray' },
    12: { text: 'Tidigare', color: 'gray' },
  },
  object: {
    0: { text: 'Uthyrd', color: 'green' },
    1: { text: 'Uthyrd (Tillsvidare)', color: 'green' },
    2: { text: 'Uthyrd (Tidsbestämt)', color: 'green' },
    3: { text: 'Uthyrd (Uppsagt för villkorsändring)', color: 'destructive' },
    4: { text: 'Uthyrd (Uppsagt för avflytt)', color: 'destructive' },
    5: { text: 'Uthyrd (Uppsagt för villkorsändring)', color: 'destructive' },
    6: { text: 'Uthyrd (Uppsagt för avflytt)', color: 'destructive' },
    7: { text: 'Uthyrd (Utgående)', color: 'yellow' },
    8: { text: 'Uthyrd (Utgående)', color: 'yellow' },
    9: { text: 'Vakant (Kommande)', color: 'destructive' },
    10: { text: 'Vakant', color: 'destructive' },
    11: { text: 'Vakant', color: 'destructive' },
    12: { text: 'Vakant', color: 'destructive' },
  },
};

// ACTIVE = 0
// ACTIVE_FURTHER = 1
// ACTIVE_DEFINITE_END = 2
// CANCELLED_PENDING_NEGOTIATION = 3
// CANCELLED_PENDING = 4
// CANCELLED_NEGOTIATION = 5
// CANCELLED = 6
// OWNER_WARNING = 7
// COUNTERPART_WARNING = 8
// UPCOMING = 9
// NOT_SIGNED = 10
// INACTIVE_CANCELLATION_NOT_SIGNED = 11
// INACTIVE = 12

type BadgeProp = {
  text: string;
  variant: BadgeVariant['variant'];
};

export const readableStatusMonitoring: Record<number, BadgeProp> = {
  666: {
    text: 'Okänd',
    variant: 'destructive',
  },
  0: {
    text: 'Skapades',
    variant: 'green',
  },
  1: {
    text: 'Uppdaterades',
    variant: 'yellow',
  },
  2: {
    text: 'Raderades',
    variant: 'destructive',
  },
};

// export const QueryOperations = {
//   isnull: 'Är tom',
//   startswith: 'Börjar med',
//   icontains: 'Innehåller',
//   iexact: 'Exakt',
//   gte: 'Större än',
//   lte: 'Mindre än',
//   in: 'I',
//   '': '',
//   'in!': 'Inte i',
// } as const;

// export const QueryFieldTypes = {
//   DATE: 'date',
//   NUMBER: 'number',
//   BOOLEAN: 'boolean',
//   TEXT: 'text',
// } as const;
