'use client';

import { DynamicField } from '@/components/form/dynamic-field';
import { DescriptionTooltip } from '@/components/form/dynamic-field/components/description-tooltip';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
} from '@/components/ui/accordion';
import { Button, buttonVariants } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Fieldset } from '@/components/ui/fieldset';
import { Form } from '@/components/ui/form';
import { Label } from '@/components/ui/label';
import { Legend } from '@/components/ui/legend';
import OverlayLoader from '@/components/ui/overlay-loader';
import { Switch } from '@/components/ui/switch';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useGetArticle } from '@/config/accounting/articles/article/client';
import { useCreateArticle } from '@/config/accounting/articles/article/create';
import { useMutateArticle } from '@/config/accounting/articles/article/mutate';
import { useIsFormDirty } from '@/hooks/use-is-form-dirty';
import { useForm } from '@/hooks/useForm';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useToast } from '@/hooks/useToast';
import setFormValue from '@/lib/set-form-value';
import { cn } from '@/lib/utils';
import type { ErrorResponse } from '@/requests/types';
import { MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { ArticleConfig, getCleanArticle } from '@pigello/pigello-matrix';
import { AccordionTrigger } from '@radix-ui/react-accordion';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AdvancedCategories } from '../../components/advanced-categories';
import { ArticleCategoryForm } from '../categories/category-create-form';
import { revenueAccount } from '../utils';

export function ArticleRentForm({
  closeFunction,
  articleId,
}: {
  closeFunction: VoidFunction;
  articleId?: string;
}) {
  const { addToast } = useToast();
  const [openCategory, setOpenCategory] = useState(false);
  const [showRevenueLoss, setShowRevenueLoss] = useState(false);
  const { data, isLoading } = useGetArticle({
    id: articleId,
    enabled: !!articleId,
  });

  const form = useForm({
    config: ArticleConfig,
    isUpdate: !!articleId,
    values: data,
    disabled: isLoading,
    defaultValues: { ...getCleanArticle(), ignoreDynamiclyCalculatedVat: true },
    orderedFields: [
      {
        key: 'general',
        fields: ['name', 'invoiceDisplayName'],
      },
      {
        key: 'settings',
        fields: [
          'includeSpaceCategoryInDisplayName',
          'allowSpaceBoundRevenueRows',
        ],
      },
      {
        key: 'vatSettings',
        fields: ['vat'],
      },
      {
        key: 'accounts',
        fields: [
          'revenueVat0Account',
          'revenueVat6Account',
          'revenueVat12Account',
          'revenueVat25Account',
        ],
      },
      {
        key: 'spaceCategories',
        fields: [
          'apartmentCategories',
          'industrialPremisesCategories',
          'parkingSpotCategories',
          'outdoorSectionCategories',
          'brfApartmentCategories',
        ],
      },
    ],
    ...(!articleId && {
      preMutatedValues: {
        allowSpaceBoundRevenueRows: true,
      },
    }),
  });
  const formValues = useMemo(() => form.getValues(), [form]);
  const { isDirty } = form.formState;

  const mutation = useCreateArticle({});

  const closeAndClear = () => {
    closeFunction();
    form.reset();
  };

  const mutationPatch = useMutateArticle({});

  const handleSubmit = () => {
    const data = form.getDirtyData();

    if (articleId) {
      return addToast({
        type: 'promise',
        promise: mutationPatch.mutateAsync({
          id: articleId,
          body: {
            ...data,
            ignoreDynamiclyCalculatedVat:
              formValues.ignoreDynamiclyCalculatedVat,
            ...(!formValues.ignoreDynamiclyCalculatedVat &&
              formValues.vat != null && {
                revenueVat0Account: formValues.revenueVat0Account,
                revenueVat6Account: formValues.revenueVat6Account,
                revenueVat12Account: formValues.revenueVat12Account,
                revenueVat25Account: formValues.revenueVat25Account,
              }),
            ...(formValues.ignoreDynamiclyCalculatedVat &&
              formValues.vat != null && {
                revenueVat0Account: formValues[revenueAccount[formValues.vat]],
                revenueVat6Account: formValues[revenueAccount[formValues.vat]],
                revenueVat12Account: formValues[revenueAccount[formValues.vat]],
                revenueVat25Account: formValues[revenueAccount[formValues.vat]],
              }),
          },
        }),
        loading: 'Uppdaterar hyresartikel...',
        success: () => {
          closeAndClear();
          return 'Hyresartikel uppdaterades';
        },
        error: (error: ErrorResponse) => {
          const field = handleFormErrors(error, form);
          return field;
        },
      });
    }
    addToast({
      type: 'promise',
      promise: mutation.mutateAsync({
        body: {
          ...data,
          invoiceTypes: 0,
          ignoreDynamiclyCalculatedVat: formValues.ignoreDynamiclyCalculatedVat,
          ...(!formValues.ignoreDynamiclyCalculatedVat &&
            formValues.vat != null && {
              revenueVat0Account: formValues.revenueVat0Account,
              revenueVat6Account: formValues.revenueVat6Account,
              revenueVat12Account: formValues.revenueVat12Account,
              revenueVat25Account: formValues.revenueVat25Account,
            }),
          ...(formValues.ignoreDynamiclyCalculatedVat &&
            formValues.vat != null && {
              revenueVat0Account: formValues[revenueAccount[formValues.vat]],
              revenueVat6Account: formValues[revenueAccount[formValues.vat]],
              revenueVat12Account: formValues[revenueAccount[formValues.vat]],
              revenueVat25Account: formValues[revenueAccount[formValues.vat]],
            }),
        },
      }),
      loading: 'Skapar hyresartikel...',
      success: () => {
        closeAndClear();
        return 'Hyresartikel skapades';
      },
      error: (error: ErrorResponse) => {
        const field = handleFormErrors(error, form);
        return field;
      },
    });
  };

  const { setValue } = form;
  const clearVatAccounts = useCallback(() => {
    for (const key in revenueAccount) {
      setValue(revenueAccount[key], 0);
    }
  }, [setValue]);

  useEffect(() => {
    if (data?.considerRevenueLoss) {
      setShowRevenueLoss(true);
    }
  }, [data?.considerRevenueLoss]);

  const { confirmClose } = useIsFormDirty({
    isDirty,
    closeModal: closeAndClear,
  });

  return (
    <>
      {openCategory && (
        <ArticleCategoryForm closeFunction={() => setOpenCategory(false)} />
      )}
      <Dialog defaultOpen onOpenChange={confirmClose}>
        <DialogContent
          onInteractOutside={(e) => {
            e.preventDefault();
            if (openCategory) return;
            confirmClose();
          }}
          size='lg'
          className='p-0'
        >
          <DialogHeader>
            <DialogTitle>
              {articleId ? 'Redigera hyresartikel' : 'Skapa hyresartikel'}
            </DialogTitle>
            <Button
              onClick={confirmClose}
              variant='secondary'
              size='icon-sm'
              type='button'
            >
              <XMarkIcon className='size-4' />
            </Button>
          </DialogHeader>
          <Form {...form}>
            <form
              onSubmit={(e) => {
                form.clearErrors();
                form.handleSubmit(handleSubmit)(e);
              }}
            >
              <div className='relative grid h-[60vh] gap-6 overflow-auto p-4'>
                {(mutation.isPending || isLoading) && <OverlayLoader />}
                <Fieldset className='grid-cols-2'>
                  <Legend>Generellt</Legend>
                  {form.orderedFields.general.map((field) => (
                    <DynamicField key={field.name} formField={field} />
                  ))}
                  {form.orderedFields.settings.map((field) => (
                    <DynamicField
                      className='col-span-full'
                      horizontal
                      key={field.name}
                      formField={field}
                    />
                  ))}
                  <div className='relative col-span-full'>
                    <DynamicField
                      className='w-full'
                      formField={form.fields.category}
                    />
                    {!formValues.category && (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            onClick={() => setOpenCategory(true)}
                            type='button'
                            variant={'secondary'}
                            size={'icon'}
                            className='absolute -right-3 top-3 z-10 rounded-full'
                          >
                            <PlusIcon className='size-4' />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          Skapa en ny artikelkategori
                        </TooltipContent>
                      </Tooltip>
                    )}
                  </div>
                </Fieldset>
                <Fieldset className='grid-cols-2'>
                  <Legend>Moms</Legend>

                  <DynamicField
                    className='col-span-full'
                    horizontal={true}
                    formField={form.fields.vat}
                  />
                  {formValues.vat != null && (
                    <div className='col-span-full flex items-center justify-between'>
                      <div className='flex items-center'>
                        <Label htmlFor='allow-dynamic'>
                          Tillåt dynamisk moms
                        </Label>
                        <DescriptionTooltip description='Dynamisk moms innebär att systemet väljer vilken moms som skall användas utifrån momsstatusen på avtalet vid debitering av artikeln. Om artikeln är av sådan karaktär att momssatsen inte ska förändras skall inställningen inte aktiveras och en fast momssats anges.' />
                      </div>
                      <Switch
                        id='allow-dynamic'
                        defaultChecked={
                          !formValues.ignoreDynamiclyCalculatedVat
                        }
                        onCheckedChange={(checked) => {
                          clearVatAccounts();
                          setFormValue(
                            form,
                            'ignoreDynamiclyCalculatedVat',
                            !checked
                          );
                        }}
                      />
                    </div>
                  )}
                  {formValues.vat != null &&
                    !formValues.ignoreDynamiclyCalculatedVat &&
                    form.orderedFields.accounts.map((field) => (
                      <DynamicField key={field.name} formField={field} />
                    ))}
                  {formValues.vat != null &&
                    formValues.ignoreDynamiclyCalculatedVat && (
                      <DynamicField
                        horizontal
                        className='col-span-full'
                        formField={form.fields[revenueAccount[formValues.vat]]}
                      />
                    )}
                </Fieldset>
                <Accordion
                  defaultValue={showRevenueLoss ? 'revenueLoss' : undefined}
                  value={showRevenueLoss ? 'revenueLoss' : undefined}
                  onValueChange={() => {
                    setShowRevenueLoss((prev) => !prev);
                  }}
                  type='single'
                  collapsible
                >
                  <AccordionItem value='revenueLoss' className='border-b-0'>
                    <AccordionTrigger
                      className={cn(
                        buttonVariants({ variant: 'link' }),
                        'justify-between px-0'
                      )}
                    >
                      Vakansbokningar{' '}
                      {showRevenueLoss ? (
                        <MinusIcon className='ml-1 size-4' />
                      ) : (
                        <PlusIcon className='ml-1 size-4' />
                      )}
                    </AccordionTrigger>
                    <AccordionContent>
                      <Fieldset className='grid grid-cols-1 gap-4'>
                        <DynamicField
                          horizontal={true}
                          formField={form.fields.considerRevenueLoss}
                        />
                        {formValues.considerRevenueLoss && (
                          <DynamicField
                            horizontal={true}
                            formField={form.fields.performRevenueLossBooking}
                          />
                        )}

                        {formValues.performRevenueLossBooking && (
                          <DynamicField
                            horizontal={true}
                            formField={form.fields.revenueLossAccount}
                          />
                        )}
                        {formValues.performRevenueLossBooking && (
                          <DynamicField
                            horizontal={true}
                            formField={form.fields.revenueLossAccountVat}
                          />
                        )}
                      </Fieldset>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>

                <AdvancedCategories
                  form={form}
                  fields={form.orderedFields.spaceCategories}
                />
              </div>

              <DialogFooter>
                <Button
                  variant={'outline'}
                  onClick={confirmClose}
                  type='button'
                >
                  Avbryt
                </Button>
                <Button type='submit'>Spara</Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
}
