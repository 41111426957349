import type { Chapter } from '@/components/form/types';
import type { JsonFilter } from '@/hooks/use-table-filter/types';
import type {
  Address,
  Apartment,
  ApartmentContract,
  ApartmentContractRevenueRow,
  ApartmentRevenueRow,
  ApartmentTargetAudience,
  ApartmentTerms,
  ApartmentVacancyNotation,
  BlockContract,
  BlockContractRevenueRow,
  BrfApartment,
  BrfApartmentInvoiceConfigurationRevenueRow,
  BrfApartmentRevenueRow,
  BrfCompany,
  BrfCompanyAnnualReport,
  Building,
  CommonArea,
  Community,
  Company,
  ExternalClient,
  IndustrialPremises,
  IndustrialPremisesContract,
  IndustrialPremisesContractRevenueRow,
  IndustrialPremisesRevenueRow,
  IndustrialPremisesTargetAudience,
  IndustrialPremisesTerms,
  IndustrialPremisesVacancyNotation,
  Organization,
  OrganizationUser,
  OtherContract,
  OtherContractRevenueRow,
  OutdoorArea,
  OutdoorSection,
  OutdoorSectionContract,
  OutdoorSectionContractRevenueRow,
  OutdoorSectionRevenueRow,
  OutdoorSectionTargetAudience,
  OutdoorSectionTerms,
  OutdoorSectionVacancyNotation,
  ParkingLot,
  ParkingSpot,
  ParkingSpotContract,
  ParkingSpotContractRevenueRow,
  ParkingSpotRevenueRow,
  ParkingSpotTargetAudience,
  ParkingSpotTerms,
  ParkingSpotVacancyNotation,
  PriceGroup,
  RealEstate,
  Region,
  Segment,
  Sensor,
  SensorSpecification,
  SwedishPriceBaseValue,
  Tenant,
} from '@pigello/pigello-matrix';

export type ChapterData = {
  organizationuser: Chapter<OrganizationUser>;
  company: Chapter<Company>;
  region: Chapter<Region>;
  community: Chapter<Community>;
  segment: Chapter<Segment>;
  apartment: Chapter<Apartment>;
  tenant_switch: Chapter<Tenant>;
  brf_member_switch: Chapter<Tenant>;
  tenant_company: Chapter<Tenant>;
  tenant_private: Chapter<Tenant>;
  member_company: Chapter<Tenant>;
  member_private: Chapter<Tenant>;
  building: Chapter<Building>;
  commonarea: Chapter<CommonArea>;
  industrialpremises: Chapter<IndustrialPremises>;
  outdoorarea: Chapter<OutdoorArea>;
  outdoorsection: Chapter<OutdoorSection>;
  parkinglot: Chapter<ParkingLot>;
  parkingspot: Chapter<ParkingSpot>;
  realestate: Chapter<RealEstate>;
  apartmentcontract: Chapter<ApartmentContract>;
  othercontract: Chapter<OtherContract>;
  blockcontract: Chapter<BlockContract>;
  industrialpremisescontract: Chapter<IndustrialPremisesContract>;
  outdoorsectioncontract: Chapter<OutdoorSectionContract>;
  organization: Chapter<Organization>;
  parkingspotcontract: Chapter<ParkingSpotContract>;
  apartmentTargetAudience: Chapter<ApartmentTargetAudience>;
  industrialPremisesTargetAudience: Chapter<IndustrialPremisesTargetAudience>;
  outdoorSectionTargetAudience: Chapter<OutdoorSectionTargetAudience>;
  parkingSpotTargetAudience: Chapter<ParkingSpotTargetAudience>;
  apartmentTerms: Chapter<ApartmentTerms>;
  industrialPremisesTerms: Chapter<IndustrialPremisesTerms>;
  outdoorSectionTerms: Chapter<OutdoorSectionTerms>;
  parkingSpotTerms: Chapter<ParkingSpotTerms>;
  apartmentrevenuerow: Chapter<ApartmentRevenueRow>;
  apartmentcontractrevenuerow: Chapter<ApartmentContractRevenueRow>;
  industrialpremisesrevenuerow: Chapter<IndustrialPremisesRevenueRow>;
  industrialpremisescontractrevenuerow: Chapter<IndustrialPremisesContractRevenueRow>;
  outdoorsectionrevenuerow: Chapter<OutdoorSectionRevenueRow>;
  outdoorsectioncontractrevenuerow: Chapter<OutdoorSectionContractRevenueRow>;
  parkingspotrevenuerow: Chapter<ParkingSpotRevenueRow>;
  parkingspotcontractrevenuerow: Chapter<ParkingSpotContractRevenueRow>;
  othercontractrevenuerow: Chapter<OtherContractRevenueRow>;
  blockcontractrevenuerow: Chapter<BlockContractRevenueRow>;
  brfapartmentrevenuerow: Chapter<BrfApartmentRevenueRow>;
  brfapartmentinvoiceconfigurationrevenuerow: Chapter<BrfApartmentInvoiceConfigurationRevenueRow>;
  sensor: Chapter<Sensor>;
  pricegroup: Chapter<PriceGroup>;
  sensorspecification: Chapter<SensorSpecification>;
  address: Chapter<Address>;
  externalclient: Chapter<ExternalClient>;
  brfapartment: Chapter<BrfApartment>;
  company_brfVersion: Chapter<Company>;
  brfcompany_financial_plan: Chapter<BrfCompany>;
  brfcompany_statutes_plan: Chapter<BrfCompany>;
  brfcompany_pledges: Chapter<BrfCompany>;
  brfcompany_transaction_fees: Chapter<BrfCompany>;
  brfcompany_realtor_image: Chapter<BrfCompany>;
  brfcompany_reparation_fund: Chapter<BrfCompany>;
  brfcompanyannualreport: Chapter<BrfCompanyAnnualReport>;
  swedishpricebasevalue: Chapter<SwedishPriceBaseValue>;
  apartmentvacancynotation: Chapter<ApartmentVacancyNotation>;
  industrialpremisesvacancynotation: Chapter<IndustrialPremisesVacancyNotation>;
  outdoorsectionvacancynotation: Chapter<OutdoorSectionVacancyNotation>;
  parkingspotvacancynotation: Chapter<ParkingSpotVacancyNotation>;
};

const revenueRowFields = <
  T extends
    | ApartmentRevenueRow
    | ApartmentContractRevenueRow
    | IndustrialPremisesRevenueRow
    | IndustrialPremisesContractRevenueRow
    | OutdoorSectionRevenueRow
    | OutdoorSectionContractRevenueRow
    | ParkingSpotRevenueRow
    | ParkingSpotContractRevenueRow
    | BrfApartmentRevenueRow
    | OtherContractRevenueRow
    | BrfApartmentInvoiceConfigurationRevenueRow,
>(): Chapter<T> => [
  {
    title: 'Debiteringsrad',
    fields: ['title', 'article', 'customDimensionValue'],
    filter: {
      article: {
        invoice_types: { __in: '0,2' },
      },
    } as Partial<Record<keyof T, JsonFilter>>,
  },
  {
    title: 'Värde',
    fields: ['unitAmount', 'unitPrice'],
  },
  {
    title: 'Period',
    fields: ['startDate', 'endDate', 'excemptFromMonths'],
  },
  {
    title: 'Övrigt',
    fields: ['periodsAdjustsTotalAmount', 'customId'],
  },
];

const vacancyNotationFields = <
  T extends
    | ApartmentVacancyNotation
    | IndustrialPremisesVacancyNotation
    | OutdoorSectionVacancyNotation
    | ParkingSpotVacancyNotation,
>(): Chapter<T> => [
  {
    title: 'Avtal',
    fields: ['customId'],
  },
  {
    title: 'Period',
    fields: ['startDate', 'endDate'],
  },
];

export const Chapters: ChapterData = {
  apartmentvacancynotation: vacancyNotationFields(),
  industrialpremisesvacancynotation: vacancyNotationFields(),
  outdoorsectionvacancynotation: vacancyNotationFields(),
  parkingspotvacancynotation: vacancyNotationFields(),
  apartmentrevenuerow: revenueRowFields(),
  apartmentcontractrevenuerow: revenueRowFields(),
  industrialpremisesrevenuerow: revenueRowFields(),
  industrialpremisescontractrevenuerow: revenueRowFields(),
  parkingspotrevenuerow: revenueRowFields(),
  parkingspotcontractrevenuerow: revenueRowFields(),
  outdoorsectionrevenuerow: revenueRowFields(),
  outdoorsectioncontractrevenuerow: revenueRowFields(),
  othercontractrevenuerow: revenueRowFields(),
  blockcontractrevenuerow: revenueRowFields(),
  brfapartmentrevenuerow: revenueRowFields(),
  brfapartmentinvoiceconfigurationrevenuerow: revenueRowFields(),
  swedishpricebasevalue: [
    {
      title: 'Prisbasbelopp',
      fields: ['forYear', 'value'],
    },
  ],
  brfcompanyannualreport: [
    {
      title: 'Årsredovisning',
      fields: ['forYear', 'fileData'],
    },
  ],
  brfcompany_financial_plan: [
    {
      title: 'Datum',
      fields: ['financialPlanCreationDate', 'financialPlanRegistrationDate'],
    },
    {
      title: 'Dokument',
      fields: ['financialPlanDocument'],
    },
  ],
  brfcompany_statutes_plan: [
    {
      title: 'Datum',
      fields: ['statutesRegistrationDate'],
    },
    {
      title: 'Dokument',
      fields: ['statutesDocument'],
    },
  ],
  brfcompany_pledges: [
    {
      title: 'Artikel',
      fields: ['pledgeFeeArticle'],
    },
    {
      title: 'Pantsättningsavgift',
      description:
        'Välj mellan en fast avgift eller en procentsats av Svenskt Prisbasbelopp',
      fields: ['pledgeFeeFirmValue', 'pledgeFeeQuota'],
    },
  ],
  brfcompany_transaction_fees: [
    {
      title: 'Inställning',
      fields: ['transactionFeeArticle', 'transactionFeeDebitsSeller'],
    },
    {
      title: 'Avgift',
      description:
        'Välj mellan en fast avgift eller en procentsats av Svenskt Prisbasbelopp',
      fields: ['transactionFeeFirmValue', 'transactionFeeQuota'],
    },
  ],
  brfcompany_realtor_image: [
    {
      title: 'Information',
      fields: ['realtorImageCommonInfoText'],
    },
  ],
  brfcompany_reparation_fund: [
    {
      title: 'Inställningar',
      fields: ['reparationFundQuota'],
    },
  ],
  externalclient: [
    {
      title: 'Kundinformation',
      fields: ['name', 'customId', 'orgNo', 'visitingAddress'],
    },
    {
      title: 'Faktureringsinformation',
      fields: ['invoiceEmail', 'invoiceAddress', 'fmi'],
    },
  ],
  address: [
    {
      title: 'Adress',
      fields: ['street', 'streetNumber', 'postalCode', 'city', 'country'],
    },
    {
      title: 'Övrigt',
      fields: ['customId'],
    },
  ],
  parkingSpotTerms: [
    {
      title: 'Villkor',
      fields: [
        'name',
        'depositionValue',
        'depositionPercentageValue',
        'allowedDisobedienceAmount',
        'disobedienceFee',
      ],
    },

    {
      title: 'Övrigt',
      fields: ['additionalNotations', 'customId'],
    },
  ],
  outdoorSectionTerms: [
    {
      title: 'Villkor',
      fields: [
        'name',
        'requireInsurance',
        'allowedDisobedienceAmount',
        'disobedienceFee',
      ],
    },
    {
      title: 'Bekvämligheter',
      fields: ['snowPlowingService', 'wasteManagementService'],
    },
    {
      title: 'Övrigt',
      fields: ['additionalNotations', 'customId'],
    },
  ],
  industrialPremisesTerms: [
    {
      title: 'Villkor',
      fields: [
        'name',
        'allowedDisobedienceAmount',
        'requireInsurance',
        'allowPainting',
        'allowSmoking',
        'allowPets',
        'disobedienceFee',
      ],
    },
    {
      title: 'Bekvämligheter',
      fields: [
        'electricitySupply',
        'warmWaterSupply',
        'coldWaterSupply',
        'heatSupply',
        'coldSupply',
        'cleaningService',
        'snowPlowingService',
        'wasteManagementService',
      ],
    },
    {
      title: 'Övrigt',
      fields: ['additionalNotations', 'customId'],
    },
  ],
  apartmentTerms: [
    {
      title: 'Villkor',
      fields: [
        'name',
        'depositionValue',
        'depositionPercentageValue',
        'allowedDisobedienceAmount',
        'allowSmoking',
        'allowPets',
        'disobedienceFee',
      ],
    },
    {
      title: 'Bekvämligheter',
      fields: [
        'electricitySupply',
        'warmWaterSupply',
        'coldWaterSupply',
        'heatSupply',
        'coldSupply',
      ],
    },
    {
      title: 'Övrigt',
      fields: ['additionalNotations', 'customId'],
    },
  ],
  parkingSpotTargetAudience: [
    {
      title: 'Målgrupp',
      fields: [
        'name',
        'minimumAge',
        'maximumAge',
        'students',
        'retired',
        'requireNonParkingTenant',
        'sniCodes',
      ],
    },
    {
      title: 'Inkomst',
      fields: [
        'allowAnyBankruptcy',
        'allowAnyDeptSanitation',
        'allowBenefitIncome',
        'allowPartTimeWork',
        'allowTemporaryWork',
        'maximumPaymentRemarkAmount',
        'minimumPreviousYearRevenue',
        'preRentNetIncomeFactor',
        'postRentNetIncomeFactor',
      ],
    },
    {
      title: 'Övrigt',
      fields: ['customId'],
    },
  ],
  outdoorSectionTargetAudience: [
    {
      title: 'Målgrupp',
      fields: [
        'name',
        'minimumNumberOfEmployees',
        'maximumNumberOfEmployees',
        'sniCodes',
      ],
    },
    {
      title: 'Inkomst',
      fields: [
        'maximumPaymentRemarkAmount',
        'allowAnyBankruptcy',
        'minimumPreviousYearRevenue',
      ],
    },
    {
      title: 'Övrigt',
      fields: ['customId'],
    },
  ],
  organization: [{ title: 'Organisation', fields: ['name', 'organization'] }],
  industrialPremisesTargetAudience: [
    {
      title: 'Målgrupp',
      fields: [
        'name',
        'minimumNumberOfEmployees',
        'maximumNumberOfEmployees',
        'sniCodes',
      ],
    },
    {
      title: 'Inkomst',
      fields: [
        'maximumPaymentRemarkAmount',
        'allowAnyBankruptcy',
        'minimumPreviousYearRevenue',
      ],
    },
    {
      title: 'Övrigt',
      fields: ['customId'],
    },
  ],
  apartmentTargetAudience: [
    {
      title: 'Målgrupp',
      fields: [
        'name',
        'maximumNumberOfCoResidents',
        'minimumAge',
        'maximumAge',
        'disabilities',
        'retired',
        'students',
      ],
    },
    {
      title: 'Inkomst',
      fields: [
        'includeCoResidentsIncome',
        'allowTrustee',
        'allowCreditor',
        'allowAnyBankruptcy',
        'allowAnyDeptSanitation',
        'allowBenefitIncome',
        'allowPartTimeWork',
        'allowTemporaryWork',
        'preRentNetIncomeFactor',
        'postRentNetIncomeFactor',
        'maximumPaymentRemarkAmount',
      ],
    },
    {
      title: 'Övrigt',
      fields: ['customId'],
    },
  ],
  organizationuser: [
    {
      title: 'Användaruppgifter',
      description: 'Grundläggande användaruppgifter',
      fields: [
        'detailAccess',
        'role',
        'firstName',
        'lastName',
        'email',
        'phoneNumber',
        'ssn',
        'birthDate',
      ],
    },
    {
      title: 'Företagsuppifter',
      description: 'Eventuell information om användarens företag',
      fields: ['corporateName', 'orgNo'],
    },
    {
      title: 'Uppdragsgivare',
      fields: ['externalClients'],
    },
    {
      title: 'Övrigt',
      fields: ['activeTo', 'customId', 'communicationName'],
    },
  ],
  segment: [
    {
      title: 'Segment',
      fields: ['name', 'customId', 'realestates'],
    },
    {
      title: 'Kategorier',
      fields: [
        'apartmentCategories',
        'industrialPremisesCategories',
        'parkingSpotCategories',
        'outdoorSectionCategories',
        'brfApartmentCategories',
        'commonAreaCategories',
      ],
    },
  ],
  community: [
    {
      title: 'Område',
      fields: [
        'name',
        'customId',
        'mainAddress',
        'primaryPurposeType',
        'region',
      ],
    },
    {
      title: 'Beskrivningar',
      fields: ['publicDescription', 'salesDescription'],
    },
  ],
  region: [
    {
      title: 'Region',
      fields: ['name', 'customId', 'mainAddress'],
    },
    {
      title: 'Beskrivningar',
      fields: ['publicDescription', 'salesDescription'],
    },
  ],
  company: [
    {
      title: 'Bolag',
      fields: [
        'name',
        'customId',
        'orgNo',
        'fui',
        'sniNumber',
        'postalAddress',
        'visitingAddress',
        'registeredAddress',
      ],
    },
    {
      title: 'Bolagsdetaljer',
      fields: ['taxRegistered', 'vatApproved', 'vatCode', 'gln13Number'],
    },
    {
      title: 'Uppdragsgivare',
      fields: ['managedBy'],
    },
    {
      title: 'Logotyp',
      fields: ['logo'],
    },
  ],
  company_brfVersion: [
    {
      title: 'Förening',
      fields: [
        'name',
        'customId',
        'orgNo',

        'sniNumber',
        'postalAddress',
        'visitingAddress',
        'registeredAddress',
      ],
    },
    {
      title: 'Detaljer',
      fields: ['taxRegistered', 'vatApproved', 'vatCode', 'gln13Number'],
    },
    {
      title: 'Uppdragsgivare',
      fields: ['managedBy'],
    },
    {
      title: 'Logotyp',
      fields: ['logo'],
    },
  ],

  apartment: [
    {
      title: 'Lägenhet',
      fields: [
        'customId',
        'apartmentId',
        'address',
        'building',
        'category',
        'floors',
      ],
    },
    {
      title: 'Yta',
      fields: ['usableArea', 'biArea', 'temperedArea'],
    },
    // {
    //   title: 'Uthyrning',
    //   fields: ['allowedTargetAudiences', 'terms'],
    // },
    {
      title: 'Övrigt',
      fields: ['financialHandler', 'landlordCustomId', 'popularName'],
    },
  ],
  brfapartment: [
    {
      title: 'Bostadsrätt',
      fields: [
        'customId',
        'apartmentId',
        'address',
        'building',
        'category',
        'floors',
      ],
    },
    {
      title: 'Yta',
      fields: ['usableArea', 'biArea', 'temperedArea'],
    },
    {
      title: 'Ekonomi',
      fields: ['quota', 'placementFee', 'placementDate', 'investmentCapital'],
    },
    {
      title: 'Inre fond',
      fields: ['reparationFundQuota'],
    },

    {
      title: 'Övrigt',
      fields: ['financialHandler', 'landlordCustomId', 'popularName'],
    },
  ],
  tenant_switch: [
    {
      title: 'Privathyresgästinformation',
      description: 'Ange kontaktinformationsuppgifter till hyresgästen',
      fields: ['firstName', 'lastName'],
    },
    {
      title: 'Bolagshyresgästinformation',
      description: 'Ange information om bolagshyresgäst',
      fields: ['corporateName', 'orgNo', 'sniNumber'],
    },
  ],
  brf_member_switch: [
    {
      title: 'Privatmedlemsinformation',
      description: 'Ange kontaktinformationsuppgifter till medlemmen',
      fields: ['firstName', 'lastName'],
    },
    {
      title: 'Bolagsmedlemsinformation',
      description: 'Ange information om bolagsmedlem',
      fields: ['corporateName', 'orgNo', 'sniNumber'],
    },
  ],
  tenant_company: [
    {
      title: 'Bolagsuppgifter',
      description: 'Ange information om bolagshyresgäst',
      fields: ['corporateName', 'orgNo', 'sniNumber'],
    },
    {
      title: 'Kontaktuppgifter',
      description: 'Ange kontaktinformationsuppgifter till hyresgästen',
      fields: [
        'firstName',
        'lastName',
        'anonymizationName',
        'email',
        'phoneNumber',
      ],
    },
    {
      title: 'Avisering',
      description: 'Ange information om avisering',
      fields: [
        'fmi',
        'invoiceEmail',
        'invoiceAddress',
        'preferInvoiceViewOnly',
        'invoiceDistributionInstruction',
        'reminderDistributionInstruction',
        'debtDistributionInstruction',
        'customDimensionValue',
        'customDimensionCategoryValue',
      ],
    },
    {
      title: 'Övrigt',
      description: 'Övrig information om hyresgäst',
      fields: ['customId', 'futureAddress', 'previousAddress', 'realestates'],
    },
  ],
  tenant_private: [
    {
      title: 'Hyresgästinformation',
      description: 'Ange kontaktinformationsuppgifter till hyresgästen',
      fields: [
        'firstName',
        'lastName',
        'ssn',
        'birthDate',
        'ssnIsCoordinationNumber',
        'anonymizationName',
        'email',
        'phoneNumber',
        'currentRegisteredAddress',
      ],
    },
    {
      title: 'Avisering',
      description: 'Ange information om avisering',
      fields: [
        'fmi',
        'invoiceEmail',
        'invoiceAddress',
        'preferInvoiceViewOnly',
        'invoiceDistributionInstruction',
        'reminderDistributionInstruction',
        'debtDistributionInstruction',
        'customDimensionValue',
        'customDimensionCategoryValue',
      ],
    },
    {
      title: 'Övrigt',
      description: 'Övrig information om hyresgäst',
      fields: ['customId', 'futureAddress', 'previousAddress', 'realestates'],
    },
  ],
  member_company: [
    {
      title: 'Bolagsuppgifter',
      description: 'Ange information om bolagsmedlem',
      fields: ['corporateName', 'orgNo', 'sniNumber'],
    },
    {
      title: 'Kontaktuppgifter',
      description: 'Ange kontaktinformationsuppgifter till medlemmen',
      fields: [
        'firstName',
        'lastName',
        'anonymizationName',
        'email',
        'phoneNumber',
      ],
    },
    {
      title: 'Avisering',
      description: 'Ange information om avisering',
      fields: [
        'invoiceEmail',
        'invoiceAddress',
        'preferInvoiceViewOnly',
        'invoiceDistributionInstruction',
        'reminderDistributionInstruction',
        'debtDistributionInstruction',
        'customDimensionValue',
        'customDimensionCategoryValue',
      ],
    },
    {
      title: 'Övrigt',
      description: 'Övrig information om medlem',
      fields: ['customId', 'futureAddress', 'previousAddress', 'realestates'],
    },
  ],
  member_private: [
    {
      title: 'Medlemsinformation',
      description: 'Ange kontaktinformationsuppgifter till medlemmen',
      fields: [
        'firstName',
        'lastName',
        'ssn',
        'birthDate',
        'ssnIsCoordinationNumber',
        'anonymizationName',
        'email',
        'phoneNumber',
      ],
    },
    {
      title: 'Avisering',
      description: 'Ange information om avisering',
      fields: [
        'invoiceEmail',
        'invoiceAddress',
        'preferInvoiceViewOnly',
        'invoiceDistributionInstruction',
        'reminderDistributionInstruction',
        'debtDistributionInstruction',
        'customDimensionValue',
        'customDimensionCategoryValue',
      ],
    },
    {
      title: 'Övrigt',
      description: 'Övrig information om medlem',
      fields: ['customId', 'futureAddress', 'previousAddress', 'realestates'],
    },
  ],
  building: [
    {
      title: 'Byggnad',
      fields: [
        'name',
        'customId',
        'address',
        'realestate',
        'structureType',
        'structureOperationType',
      ],
    },
    {
      title: 'Yta',
      fields: [
        'registeredGrossArea',
        'registeredBuildingArea',
        'registeredUsableArea',
        'registeredBiArea',
        'registeredTemperedArea',
      ],
    },
    {
      title: 'Detaljer',
      fields: [
        'availabilityStartDate',
        'availabilityEndDate',
        'builtDate',
        'demolitionDate',
      ],
    },
    {
      title: 'Beskrivningar',
      fields: ['publicDescription', 'salesDescription'],
    },
  ],
  commonarea: [
    {
      title: 'Gemensam yta',
      fields: [
        'customId',
        'popularName',
        'address',
        'building',
        'category',
        'floors',
      ],
    },
    {
      title: 'Yta',
      fields: ['usableArea', 'biArea', 'temperedArea'],
    },
    {
      title: 'Övrigt',
      fields: ['landlordCustomId'],
    },
  ],
  industrialpremises: [
    {
      title: 'Lokal',
      fields: [
        'customId',
        'popularName',
        'address',
        'building',
        'category',
        'floors',
        'isFurnished',
      ],
    },
    {
      title: 'Yta',
      fields: ['usableArea', 'biArea', 'temperedArea'],
    },
    // {
    //   title: 'Uthyrning',
    //   fields: ['allowedTargetAudiences', 'terms'],
    // },
    {
      title: 'Övrigt',
      fields: ['financialHandler', 'landlordCustomId'],
    },
  ],
  outdoorarea: [
    {
      title: 'Område',
      fields: [
        'name',
        'customId',
        'address',
        'realestate',
        'structureType',
        'structureOperationType',
      ],
    },
    {
      title: 'Yta',
      fields: [
        'registeredGrossArea',
        'registeredBuildingArea',
        'registeredUsableArea',
        'registeredBiArea',
      ],
    },
    {
      title: 'Detaljer',
      fields: [
        'availabilityStartDate',
        'availabilityEndDate',
        'builtDate',
        'demolitionDate',
      ],
    },
    {
      title: 'Beskrivningar',
      fields: ['publicDescription', 'salesDescription'],
    },
  ],
  outdoorsection: [
    {
      title: 'Utomhussektion',
      fields: ['customId', 'popularName', 'address', 'outdoorArea', 'category'],
    },
    {
      title: 'Yta',
      fields: ['usableArea', 'biArea'],
    },
    // {
    //   title: 'Uthyrning',
    //   fields: ['allowedTargetAudiences', 'terms'],
    // },
    {
      title: 'Övrigt',
      fields: ['financialHandler', 'landlordCustomId'],
    },
  ],
  parkinglot: [
    {
      title: 'Parkeringsområde',
      fields: [
        'name',
        'customId',
        'address',
        'realestate',
        'structureType',
        'structureOperationType',
        'isGarage',
      ],
    },
    {
      title: 'Specifikationer',
      fields: ['maxHeight', 'maxWeight', 'maxWidth'],
    },
    {
      title: 'Yta',
      fields: [
        'registeredGrossArea',
        'registeredBuildingArea',
        'registeredUsableArea',
        'registeredBiArea',
      ],
    },
    {
      title: 'Detaljer',
      fields: [
        'availabilityStartDate',
        'availabilityEndDate',
        'builtDate',
        'demolitionDate',
      ],
    },
    {
      title: 'Beskrivningar',
      fields: ['publicDescription', 'salesDescription'],
    },
  ],
  parkingspot: [
    {
      title: 'Fordonsplats',
      fields: [
        'customId',
        'popularName',
        'address',
        'parkingLot',
        'building',
        'category',
        'floors',
      ],
    },
    {
      title: 'Specifikationer',
      fields: ['maxHeight', 'maxWeight', 'maxWidth'],
    },
    {
      title: 'Yta',
      fields: ['usableArea', 'biArea'],
    },
    // {
    //   title: 'Uthyrning',
    //   fields: ['allowedTargetAudiences', 'terms'],
    // },
    {
      title: 'Övrigt',
      fields: ['financialHandler', 'landlordCustomId'],
    },
  ],
  realestate: [
    {
      title: 'Fastighet',
      fields: ['name', 'customId', 'popularName', 'mainAddress', 'community'],
    },
    {
      title: 'Användning',
      fields: ['publicUsage', 'typeCode'],
    },
    {
      title: 'Yta',
      fields: ['registeredLandArea', 'registeredWaterArea'],
    },
    {
      title: 'Detaljer',
      fields: [
        'landlord',
        'primaryFinancialHandler',
        'ownedBy',
        'ownershipType',
        'purchasedDate',
        'soldDate',
        'financialResponsibilityFrom',
        'financialResponsibilityTo',
        'nonFinancialResponsibilityFrom',
        'nonFinancialResponsibilityTo',
      ],
    },
  ],
  apartmentcontract: [
    {
      title: 'Avtal',
      fields: ['idNumber'],
    },
    {
      title: 'Objekt',
      fields: ['space'],
    },
    {
      title: 'Parter',
      fields: ['tenant', 'biTenants'],
    },
    {
      title: 'Period',
      fields: [
        'startDate',
        'initialEndDate',
        'renewIndefinetely',
        'renewIntervalWeeks',
        'ownerNotificationIntervalWeeks',
        'counterpartNotificationIntervalWeeks',
        'ownerWarningIntervalWeeks',
        'counterpartWarningIntervalWeeks',
      ],
    },
    {
      title: 'Debiteringsrader vid avierinsg',
      fields: ['invoiceMergeIndexInView', 'invoiceMergeNonBaseRentInView'],
    },
  ],
  blockcontract: [
    {
      title: 'Avtal',
      fields: ['idNumber'],
    },
    {
      title: 'Objekt',
      fields: [
        'apartments',
        'parkingSpots',
        'industrialPremises',
        'outdoorSections',
      ],
    },
    {
      title: 'Avisering',
      fields: [
        'invoicingCompany',
        'mainRealestate',
        'invoiceMergeIndexInView',
        'invoiceMergeNonBaseRentInView',
      ],
    },
    {
      title: 'Parter',
      fields: ['tenant', 'biTenants'],
    },
    {
      title: 'Period',
      fields: [
        'startDate',
        'initialEndDate',
        'renewIndefinetely',
        'renewIntervalWeeks',
        'ownerNotificationIntervalWeeks',
        'counterpartNotificationIntervalWeeks',
        'ownerWarningIntervalWeeks',
        'counterpartWarningIntervalWeeks',
      ],
    },
  ],
  othercontract: [
    {
      title: 'Avtal',
      fields: ['idNumber'],
    },
    {
      title: 'Aviseringsinställningar',
      fields: [
        'invoicingCompany',
        'invoicingContractSetting',
        'invoicingPaymentTerms',
      ],
    },
    {
      title: 'Parter',
      fields: ['tenantCounterpart', 'subTenantCounterpart'],
    },
    {
      title: 'Period',
      fields: [
        'startDate',
        'initialEndDate',
        'renewIndefinetely',
        'renewIntervalWeeks',
        'ownerNotificationIntervalWeeks',
        'counterpartNotificationIntervalWeeks',
        'ownerWarningIntervalWeeks',
        'counterpartWarningIntervalWeeks',
      ],
    },
  ],
  industrialpremisescontract: [
    {
      title: 'Avtal',
      fields: ['idNumber'],
    },
    {
      title: 'Objekt',
      fields: ['space'],
    },
    {
      title: 'Parter',
      fields: ['tenant', 'biTenants'],
    },
    {
      title: 'Period',
      fields: [
        'startDate',
        'initialEndDate',
        'renewIndefinetely',
        'renewIntervalWeeks',
        'ownerNotificationIntervalWeeks',
        'counterpartNotificationIntervalWeeks',
        'ownerWarningIntervalWeeks',
        'counterpartWarningIntervalWeeks',
      ],
    },
    {
      title: 'Debiteringsrader vid avierinsg',
      fields: ['invoiceMergeIndexInView', 'invoiceMergeNonBaseRentInView'],
    },
  ],
  outdoorsectioncontract: [
    {
      title: 'Avtal',
      fields: ['idNumber'],
    },
    {
      title: 'Objekt',
      fields: ['space'],
    },
    {
      title: 'Parter',
      fields: ['tenant', 'biTenants'],
    },
    {
      title: 'Period',
      fields: [
        'startDate',
        'initialEndDate',
        'renewIndefinetely',
        'renewIntervalWeeks',
        'ownerNotificationIntervalWeeks',
        'counterpartNotificationIntervalWeeks',
        'ownerWarningIntervalWeeks',
        'counterpartWarningIntervalWeeks',
      ],
    },
    {
      title: 'Debiteringsrader vid avierinsg',
      fields: ['invoiceMergeIndexInView', 'invoiceMergeNonBaseRentInView'],
    },
  ],
  parkingspotcontract: [
    {
      title: 'Avtal',
      fields: ['idNumber'],
    },
    {
      title: 'Objekt',
      fields: ['space'],
    },
    {
      title: 'Parter',
      fields: ['tenant', 'biTenants'],
    },
    {
      title: 'Period',
      fields: [
        'startDate',
        'initialEndDate',
        'renewIndefinetely',
        'renewIntervalWeeks',
        'ownerNotificationIntervalWeeks',
        'counterpartNotificationIntervalWeeks',
        'ownerWarningIntervalWeeks',
        'counterpartWarningIntervalWeeks',
      ],
    },
    {
      title: 'Debiteringsrader vid avierinsg',
      fields: ['invoiceMergeIndexInView', 'invoiceMergeNonBaseRentInView'],
    },
  ],
  sensor: [
    {
      title: 'Sensor',
      fields: [
        'name',
        'specification',
        'customId',
        'billingEnabled',
        'granularity',
        'outputMode',
        'description',
      ],
    },
    {
      title: 'Specifikationer',
      fields: [
        'apartment',
        'commonArea',
        'industrialPremises',
        'parkingSpot',
        'brfApartment',
      ],
    },
  ],
  sensorspecification: [
    {
      title: 'Mätartyp',
      fields: ['name', 'unitName', 'billingArticle', 'category'],
      filter: {
        billingArticle: {
          category_consider_base_rent: { __isnull: true },
          invoice_types: { noop: 2 },
        },
      },
    },
  ],
  pricegroup: [
    {
      title: 'Prisgrupp',
      fields: ['name', 'customId', 'sensorSpecification', 'description'],
    },
    // {
    //   title: 'Intervaller',
    //   fields: [
    //     'autoAcceptDelayDays',
    //     'targetPriceChangeIntervalDays',
    //     'targetPriceChangeDayOfMonth',
    //   ],
    // },
  ],
};
