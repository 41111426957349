 import type { Folder } from '@pigello/pigello-matrix';

import { FolderConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, mutateGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { mutateGenericInstance } from '@/requests/mutating';

export const mutateFolder = async (
  options: mutateGenericInstanceProps<Folder>
) => {
  return mutateGenericInstance<Folder>({
    ...options,
    config: FolderConfig,
  });
};

export const useMutateFolder = (
  options: UseMutationOptions<Folder, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useMutateGenericInstance<Folder>(mutateFolder, options, 'folder');