import {
  defaultBooleanFilter,
  defaultDateFilter,
  defaultNumberFilter,
  defaultRelationFilter,
  defaultSelectFilter,
  defaultStringFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import { ParkingLotConfig, RealEstateConfig } from '@pigello/pigello-matrix';
import baseFilters, { baseAddressFilters, baseTagFilter } from '../../filters';

const parkingLotFilters: IFilter[] = [
  {
    title: ParkingLotConfig.fields.name.verboseName,
    externalFieldName: ParkingLotConfig.fields.name.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: ParkingLotConfig.fields.customId.verboseName,
    externalFieldName: ParkingLotConfig.fields.customId.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: ParkingLotConfig.fields.structureOperationType.verboseName,
    externalFieldName:
      ParkingLotConfig.fields.structureOperationType.externalFieldName,
    choices: ParkingLotConfig.fields.structureOperationType.choices,
    ...defaultSelectFilter,
  },
  {
    title: ParkingLotConfig.fields.structureType.verboseName,
    externalFieldName: ParkingLotConfig.fields.structureType.externalFieldName,
    choices: ParkingLotConfig.fields.structureType.choices,

    ...defaultSelectFilter,
  },
  {
    title: ParkingLotConfig.fields.isGarage.verboseName,
    externalFieldName: ParkingLotConfig.fields.isGarage.externalFieldName,
    ...defaultBooleanFilter,
  },
  {
    title: ParkingLotConfig.fields.realestate.verboseName,
    externalFieldName: ParkingLotConfig.fields.realestate.externalFieldName,
    relationModelName: RealEstateConfig.modelName,
    ...defaultRelationFilter,
  },
  {
    title: ParkingLotConfig.fields.maxHeight.verboseName,
    externalFieldName: ParkingLotConfig.fields.maxHeight.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: ParkingLotConfig.fields.maxWeight.verboseName,
    externalFieldName: ParkingLotConfig.fields.maxWeight.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: ParkingLotConfig.fields.maxWidth.verboseName,
    externalFieldName: ParkingLotConfig.fields.maxWidth.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: ParkingLotConfig.fields.registeredGrossArea.verboseName,
    externalFieldName:
      ParkingLotConfig.fields.registeredGrossArea.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: ParkingLotConfig.fields.registeredUsableArea.verboseName,
    externalFieldName:
      ParkingLotConfig.fields.registeredUsableArea.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: ParkingLotConfig.fields.registeredUsableArea.verboseName,
    externalFieldName:
      ParkingLotConfig.fields.registeredUsableArea.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: ParkingLotConfig.fields.registeredBiArea.verboseName,
    externalFieldName:
      ParkingLotConfig.fields.registeredBiArea.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: ParkingLotConfig.fields.calculatedUsableArea.verboseName,
    externalFieldName:
      ParkingLotConfig.fields.calculatedUsableArea.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: ParkingLotConfig.fields.calculatedBiArea.verboseName,
    externalFieldName:
      ParkingLotConfig.fields.calculatedBiArea.externalFieldName,
    ...defaultNumberFilter,
  },
  {
    title: ParkingLotConfig.fields.builtDate.verboseName,
    externalFieldName: ParkingLotConfig.fields.builtDate.externalFieldName,
    ...defaultDateFilter,
  },
  {
    title: ParkingLotConfig.fields.demolitionDate.verboseName,
    externalFieldName: ParkingLotConfig.fields.demolitionDate.externalFieldName,
    ...defaultDateFilter,
  },
  {
    title: ParkingLotConfig.fields.availabilityStartDate.verboseName,
    externalFieldName:
      ParkingLotConfig.fields.availabilityStartDate.externalFieldName,
    ...defaultDateFilter,
  },
  {
    title: ParkingLotConfig.fields.availabilityEndDate.verboseName,
    externalFieldName:
      ParkingLotConfig.fields.availabilityEndDate.externalFieldName,
    ...defaultDateFilter,
  },

  ...baseAddressFilters,
  ...baseTagFilter,
  ...baseFilters,
];

export default parkingLotFilters;
