import type { BaseInstance } from '@pigello/pigello-matrix';

export function deleteBaseFields<Instance extends BaseInstance>(
  instance: Partial<Instance> | undefined | null
) {
  const copy = { ...instance };
  delete copy.id;
  delete copy.createdAt;

  return copy;
}
