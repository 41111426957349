import { CheckIcon } from '@heroicons/react/16/solid';
import { parseDate } from '@internationalized/date';
import { DateTimePicker } from '../form/dynamic-field/components/date-field/date-time-picker';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../ui/command';
import { Input } from '../ui/input';
import {
  BOOLEAN_FILTER,
  DATE_FILTER,
  NUMBER_FILTER,
  SELECT_FILTER,
  STRING_FILTER,
  type IFilter,
  type IFilterValue,
} from './constants';

/*
    This component handles all inputs that aren't relations, 
    thats handled by the relation modal instead
*/

export default function FilterValueInput({
  filter,
  value,
  setValue,
  onDone,
}: {
  filter: IFilter;
  value: IFilterValue;
  setValue: (val: IFilterValue) => void;
  onDone: VoidFunction;
}) {
  switch (filter.kind) {
    case STRING_FILTER: {
      if (typeof value !== 'boolean') {
        return (
          <Input
            onKeyDown={(event) => event.key === 'Enter' && onDone()}
            value={value ?? undefined}
            onChange={(event) => {
              setValue(event.target.value);
            }}
          />
        );
      }
      break;
    }
    case DATE_FILTER: {
      return (
        <DateTimePicker
          label='Filtrera på datum'
          hideResetButton={true}
          value={value && typeof value === 'string' ? parseDate(value) : null}
          onChange={(val) => {
            if (val) {
              const date = val.toString();

              if (date === value) {
                onDone();
              } else {
                setValue(date);
              }
            }
          }}
        />
      );
      // return (
      //   <Calendar
      //     mode='single'
      //     selected={
      //       value && typeof value === 'string' ? new Date(value) : undefined
      //     }
      //     defaultMonth={
      //       value && typeof value === 'string' ? new Date(value) : undefined
      //     }
      //     locale={sv}
      //     onDayClick={(val) => {
      //       if (val) {
      //         const date = DateTime.fromJSDate(val).toFormat('yyyy-MM-dd');

      //         if (date === value) {
      //           onDone();
      //         } else {
      //           setValue(date);
      //         }
      //       }
      //     }}
      //     initialFocus
      //   />
      // );
    }
    case NUMBER_FILTER: {
      if (typeof value !== 'boolean') {
        return (
          <Input
            onKeyDown={(event) => event.key === 'Enter' && onDone()}
            value={value ?? undefined}
            onChange={(event) => {
              setValue(event.target.value);
            }}
          />
        );
      }
      break;
    }

    case BOOLEAN_FILTER: {
      return (
        <Command>
          <CommandList>
            <CommandGroup className='max-h-[400px] overflow-y-scroll'>
              <CommandItem
                onSelect={() => {
                  setValue(true);
                }}
              >
                {value ? <CheckIcon className='size-4' /> : ''} Sant
              </CommandItem>
              <CommandItem
                onSelect={() => {
                  setValue(false);
                }}
              >
                {value === false ? <CheckIcon className='size-4' /> : ''} Falskt
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      );
    }

    case SELECT_FILTER: {
      if (!Array.isArray(value) && value != null) break;
      return (
        <Command>
          <CommandInput placeholder='Sök bland val...' />
          <CommandList>
            <CommandEmpty>Hittade inga alternativ.</CommandEmpty>
            <CommandGroup className='max-h-[400px] overflow-y-scroll'>
              {filter.choices?.map((c) => {
                const selected = (value ?? []).includes(c.v.toString());

                return (
                  <CommandItem
                    onSelect={() => {
                      if (selected) {
                        setValue(
                          (value || []).filter((v) => v != c.v.toString())
                        );
                      } else {
                        setValue([...(value || []), c.v.toString()]);
                      }
                    }}
                    key={c.v?.toString()}
                  >
                    {selected ? <CheckIcon className='size-4' /> : ''} {c.d}
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      );
    }
  }
}
