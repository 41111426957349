 import type { InvoiceRecipient } from '@pigello/pigello-matrix';

import { InvoiceRecipientConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetInvoiceRecipient = (
  options: useGetGenericInstanceProps<InvoiceRecipient>
) => {
  return useGetInstance<InvoiceRecipient>({
    ...options,
    config: InvoiceRecipientConfig,
    modelName: 'invoicerecipient'
  });
};

export const useGetInvoiceRecipientList = (
  options: useGetGenericListProps<InvoiceRecipient>
) => {
  return useGetList<InvoiceRecipient>({
    ...options,
    config: InvoiceRecipientConfig,
    modelName: 'invoicerecipient'
  });
};
