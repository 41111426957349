'use client';

import { Button } from '@/components/ui/button';
import { Fieldset } from '@/components/ui/fieldset';
import { FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Legend } from '@/components/ui/legend';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { ObjectEntries, cn } from '@/lib/utils';
import { XMarkIcon } from '@heroicons/react/20/solid';
import type { BaseInstance } from '@pigello/pigello-matrix';
import { useState } from 'react';
import { type ControllerRenderProps, type Path } from 'react-hook-form';
import type { FieldProps } from '../types';
type Row = [string | number, string | number];

export function AbstractObjectField<
  Instance extends BaseInstance = BaseInstance,
>({
  formField,
  name,
  className,
  horizontal,
  label,
  btnLabel,
}: FieldProps<Instance>) {
  const [value, setValue] = useState<Row[]>(() => {
    if (formField.control._fields[formField.name]?._f.value != null) {
      if (
        Object.keys(formField.control._fields[formField.name]?._f.value)
          .length !== 0
      ) {
        const val: [string, string][] = [];
        ObjectEntries(
          formField.control._fields[formField.name]?._f.value
        ).forEach(([key, value]) => {
          val.push([key.toString(), value]);
        });

        return val;
      }
    }

    return [['', '']];
  });

  const applyValueToField = (
    field: ControllerRenderProps<any, Path<Instance>>
  ) => {
    const filtered = [];

    const seenKeys: Row[0][] = [];
    for (const row of value) {
      if (seenKeys.includes(row[0])) {
        continue;
      }
      seenKeys.push(row[0]);
      filtered.push(row);
    }

    const val: Record<Row[0], Row[0]> = {};

    for (const row of filtered) {
      if (typeof row[0] === 'string' && row[0].trim().length === 0) continue;
      val[row[0]] = row[1];
    }

    field.onChange(val);
    setValue(filtered);
  };

  const deleteRow = (index: number) => {
    setValue((prev) => {
      const clone = prev.slice();

      clone.splice(index, 1);

      return clone;
    });
  };

  const changeRowValue = (rowIndex: number, index: 0 | 1, val: Row[0]) => {
    setValue((prev) => {
      const clone = prev.slice();

      clone[rowIndex][index] = val;

      return clone;
    });
  };

  return (
    <Fieldset>
      <Legend>{label ?? formField.label}</Legend>
      <FormField
        control={formField.control}
        name={name as Path<Instance>}
        render={({ field }) => {
          return (
            <FormItem className={cn(horizontal && 'flex-row', className)}>
              {value.map(([key, val], index) => {
                return (
                  <div className='flex items-start gap-6' key={`row${index}`}>
                    <div className='grid w-full gap-2'>
                      <Label htmlFor={`row-${key}-${index}-from`}>Från</Label>
                      <Input
                        {...field}
                        value={key}
                        placeholder='Från'
                        type='number'
                        onBlur={() => applyValueToField(field)}
                        onChange={(evt) => {
                          const str = evt.target.value;

                          try {
                            const num = parseInt(str);

                            if (Number.isNaN(num)) return;

                            changeRowValue(index, 0, num);
                          } catch (err) {
                            return;
                          }
                        }}
                      />
                    </div>
                    <div className='grid w-full gap-2'>
                      <Label htmlFor={`row-${key}-${index}-to`}>Till</Label>
                      <Input
                        {...field}
                        value={val}
                        placeholder='Från'
                        type='number'
                        onBlur={() => applyValueToField(field)}
                        onChange={(evt) => {
                          const str = evt.target.value;

                          try {
                            const num = parseInt(str);

                            if (Number.isNaN(num)) return;

                            changeRowValue(index, 1, num);
                          } catch (err) {
                            return;
                          }
                        }}
                      />
                    </div>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          className='self-center'
                          variant='ghost'
                          size={'icon-sm'}
                          type='button'
                          onClick={() => {
                            if (value.length === 1) {
                              setValue([['', '']]);
                              return;
                            }
                            deleteRow(index);
                          }}
                        >
                          <XMarkIcon className='size-4' />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        {value.length === 1 ? 'Rensa rad' : 'Ta bort rad'}
                      </TooltipContent>
                    </Tooltip>
                  </div>
                );
              })}
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <Button
        className='justify-self-end'
        type='button'
        onClick={() => {
          setValue((prev) => [...prev, ['', '']]);
        }}
      >
        {btnLabel ?? 'Lägg till'}
      </Button>
    </Fieldset>
  );
}
