import { DynamicField } from '@/components/form/dynamic-field';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { useGetFolderList } from '@/config/documents/filehandling/folder/client';
import { useCreateFolder } from '@/config/documents/filehandling/folder/create';
import { useForm } from '@/hooks/useForm';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useModal } from '@/hooks/useModal';
import { usePerms } from '@/hooks/usePerms';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import {
  FolderConfig,
  getCleanFolder,
  type ContentType,
} from '@pigello/pigello-matrix';
import { toast } from 'sonner';

export type CreateFolderModalContentData = {
  title?: string;
  name?: string;
  folderId?: string;
  objectId?: string;
  contentType?: ContentType;
};

interface Props extends GetModalContentProps {
  closeModal: () => void;
  contentData: CreateFolderModalContentData;
}

function CreateFolderModal({
  closeModal,
  contentData: { folderId, contentType, objectId, name },
}: Props) {
  const { openConfirmModal, openFolderPermissionsModal } = useModal();
  const { canUpdate } = usePerms('folder');
  const { data: parentFolderList } = useGetFolderList({
    overrideUrl: contentType,
    queryParams: {
      filters: {
        id: {
          __in: folderId,
        },
      },
    },
    enabled: !!folderId,
  });

  const parentFolder = parentFolderList?.list?.[0];
  const form = useForm({
    config: FolderConfig,
    defaultValues: getCleanFolder(),
    preMutatedValues: {
      name: name,
    },
  });
  const { mutate } = useCreateFolder({
    onSuccess: async (data) => {
      form.reset();
      toast.success('Mappen skapades');
      if (data?.accessShare?.id || !canUpdate) {
        closeModal();
        return;
      }

      const addAccessSettings = await openConfirmModal({
        title: 'Vill du lägga till behörigheter?',
        message:
          'När du lägger till undermappar i denna mapp kommer du inte att längre kunna lägga till behörighetsinställningar. Vill du lägga till behörigheter nu?',
        confirmButtonText: 'Lägg till behörigheter',
        cancelButtonText: 'Hoppa över',
      });
      if (addAccessSettings) {
        openFolderPermissionsModal({
          folder: data,
        });
      }
    },
    onError: (error) => {
      const field = handleFormErrors(error, form);
      toast.error(field ?? 'Något gick fel');
    },
  });
  const onSubmit = () => {
    const data = form.getDirtyData();
    mutate({
      body: {
        ...(name && { name }),
        ...(parentFolder?.accessShare?.id && {
          accessShare: { id: parentFolder.accessShare.id },
        }),
        ...(folderId && { folder: { id: folderId } }),
        ...(contentType && { notationContentType: { id: contentType } }),
        ...(objectId && { notationObjectId: objectId }),
        ...data,
      },
    });
  };
  return (
    <Form {...form}>
      <form
        className='grid gap-4'
        onSubmit={(e) => {
          form.clearErrors();
          form.handleSubmit(onSubmit)(e);
        }}
      >
        <DynamicField formField={form.fields.name} />
        <div className='flex justify-end'>
          <Button type='submit'>Spara</Button>
        </div>
      </form>
    </Form>
  );
}

export const getCreateFolderModalContent = (props: Props): ModalContent => {
  return {
    title: props.contentData.title ?? 'Skapa mapp',
    description: 'Välj ett namn för din nya mapp',
    body: <CreateFolderModal {...props} />,
    closeButton: true,
  };
};
