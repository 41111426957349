import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import { getGenericCleanInstance } from '@pigello/pigello-matrix';
import { useEffect, useState } from 'react';
import type { DefaultValues } from 'react-hook-form';

export function useGetGenerics<TData extends BaseInstance>(
  modelName: ModelName
) {
  const [cleanInstance, setCleanInstance] = useState<DefaultValues<TData>>();
  useEffect(() => {
    getGenericCleanInstance<TData>(modelName)
      .then((cleanInstance) =>
        setCleanInstance(cleanInstance as DefaultValues<TData>)
      )
      .catch((err) => console.error('Error fetching clean instance: ', err));
  }, [modelName]);
  return { cleanInstance };
}
