'use client';

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Switch } from '@/components/ui/switch';
import { toMoneyString } from '@/lib/utils';
import { BellAlertIcon } from '@heroicons/react/16/solid';
import { CardSection } from '../../components/cardSection';
import type { IInvoiceCreateResult } from './types';

export default function PreviewReminderSettings({
  invoiceData,
}: {
  invoiceData: IInvoiceCreateResult;
}) {
  const remindersActive =
    invoiceData.reminder_amount != null
      ? invoiceData.reminder_amount > 0
      : false;
  return (
    <Card>
      <CardHeader>
        <CardTitle className='flex items-center'>
          <BellAlertIcon className='mr-2 size-4' />
          Inställningar för påminnelser
        </CardTitle>
      </CardHeader>
      <CardContent>
        <CardSection
          className='py-1'
          items={[
            {
              title: 'Skicka påminnelser',
              content: <Switch disabled checked={remindersActive} />,
            },
            {
              title: 'Antal påminnelser',
              content: invoiceData.reminder_amount,
              hidden: !remindersActive,
            },
            {
              title: 'Påminnelse skickas efter',
              content: `${invoiceData.reminder_days_after_due_date ?? '-'} dagar`,
              hidden: !remindersActive,
            },
            {
              title: 'Betalningsvillkor för påminnelser',
              content: `${invoiceData.reminder_expires_in_days ?? '-'} dagar`,
              hidden: !remindersActive,
            },
            {
              title: 'Påminnelseavgift',
              content: toMoneyString(invoiceData.reminder_fee),
              hidden: !remindersActive,
            },
            {
              title: 'Nådedagar',
              content: `${invoiceData.grace_days ?? '-'} dagar`,
              hidden: !remindersActive,
            },
            {
              title: 'Nådevärde',
              content: toMoneyString(invoiceData.grace_value),
              hidden: !remindersActive,
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}
