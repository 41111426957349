import { DynamicField } from '@/components/form/dynamic-field';
import { ColorField } from '@/components/form/dynamic-field/components/color-field';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { useMutateFolder } from '@/config/documents/filehandling/folder/mutate';
import { useForm } from '@/hooks/useForm';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useToast } from '@/hooks/useToast';
import type {
  GetModalContentProps,
  ModalContent,
} from '@/providers/modal-provider';
import type { ErrorResponse } from '@/requests/types';
import {
  FolderConfig,
  getCleanFolder,
  type Folder,
} from '@pigello/pigello-matrix';

export type EditFolderBasicSettingsModalContentData = {
  folder: Folder;
};

interface Props extends GetModalContentProps {
  closeModal: () => void;
  contentData: EditFolderBasicSettingsModalContentData;
}

function EditFolderBasicSettingsModal({ closeModal, contentData }: Props) {
  const form = useForm({
    config: FolderConfig,
    defaultValues: getCleanFolder(),
    values: contentData.folder,
    isUpdate: true,
  });
  const { mutateAsync: mutateFolder } = useMutateFolder({});
  const { addToast } = useToast();
  const { isDirty } = form.formState;
  const onSubmit = () => {
    const data = form.getDirtyData();

    addToast({
      type: 'promise',
      promise: mutateFolder({ id: contentData.folder.id, body: data }),
      loading: 'Sparar...',
      success: () => {
        closeModal();
        form.reset();
        return 'Sparat!';
      },
      error: (error: ErrorResponse) => {
        const field = handleFormErrors(error, form);
        if (field) {
          return field;
        }
        return 'Något gick fel';
      },
    });
  };
  return (
    <div>
      <Form {...form}>
        <form className='grid gap-4' onSubmit={form.handleSubmit(onSubmit)}>
          <DynamicField formField={form.fields.name} />
          <ColorField
            formField={form.fields.color}
            name={form.fields.color.name}
          />
          <DynamicField formField={form.fields.private} />
          <DynamicField formField={form.fields.containsFilesWithPersonalData} />
          <div className='flex items-center justify-end gap-4'>
            <Button
              onClick={() => closeModal()}
              type='button'
              variant={'outline'}
            >
              Avbryt
            </Button>
            <Button disabled={!isDirty}>Spara</Button>
          </div>
        </form>
      </Form>
    </div>
  );
}

export const getEditFolderBasicSettingsModalContent = ({
  closeModal,
  contentData,
}: Props): ModalContent => {
  return {
    title: 'Ändra mappnamn',
    description: 'Ändra mappnamn',
    body: (
      <EditFolderBasicSettingsModal
        closeModal={closeModal}
        contentData={contentData}
      />
    ),
  };
};
