 import type { Folder } from '@pigello/pigello-matrix';

import { FolderConfig } from "@pigello/pigello-matrix"



import { useGetInstance, useGetList } from '@/requests/hooks';
import type { useGetGenericInstanceProps, useGetGenericListProps, } from '@/requests/types';


export const useGetFolder = (
  options: useGetGenericInstanceProps<Folder>
) => {
  return useGetInstance<Folder>({
    ...options,
    config: FolderConfig,
    modelName: 'folder'
  });
};

export const useGetFolderList = (
  options: useGetGenericListProps<Folder>
) => {
  return useGetList<Folder>({
    ...options,
    config: FolderConfig,
    modelName: 'folder'
  });
};
