'use client';

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import * as React from 'react';

import { cn } from '@/lib/utils';
import { labelVariants } from './label';

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('grid gap-2', className)}
      {...props}
      ref={ref}
    />
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        'aspect-square size-4 rounded-full border border-primary  text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className='flex items-center justify-center'>
        <div className='size-2.5 rounded-full bg-current text-current' />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

const RadioGroupCard = React.forwardRef<
  React.ElementRef<'label'>,
  React.ComponentPropsWithoutRef<'label'>
>(({ className, ...props }, ref) => {
  const { htmlFor } = props;
  return (
    <label
      htmlFor={htmlFor}
      ref={ref}
      className={cn(
        'flex h-full items-center justify-between gap-2 rounded-md border bg-background p-2 transition-all hover:bg-muted disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent has-[:checked]:border-x has-[:checked]:border-primary has-[:checked]:bg-secondary',
        className
      )}
      {...props}
    />
  );
});

RadioGroupCard.displayName = 'RadioGroupCard';

const RadioGroupCardHeader = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn('flex h-full flex-col gap-2', className)}
      {...props}
    />
  );
});

RadioGroupCardHeader.displayName = 'RadioGroupCardHeader';

const RadioGroupCardTitle = React.forwardRef<
  React.ElementRef<'span'>,
  React.ComponentPropsWithoutRef<'span'>
>(({ className, ...props }, ref) => {
  return (
    <span
      ref={ref}
      className={cn(labelVariants(), 'text-foreground', className)}
      {...props}
    />
  );
});

RadioGroupCardTitle.displayName = 'RadioGroupCardTitle';

const RadioGroupCardDescription = React.forwardRef<
  React.ElementRef<'span'>,
  React.ComponentPropsWithoutRef<'span'>
>(({ className, ...props }, ref) => {
  return (
    <span ref={ref} className={cn('max-w-60 text-xs', className)} {...props} />
  );
});

RadioGroupCardDescription.displayName = 'RadioGroupCardDescription';

export {
  RadioGroup,
  RadioGroupCard,
  RadioGroupCardDescription,
  RadioGroupCardHeader,
  RadioGroupCardTitle,
  RadioGroupItem,
};
