 import type { DebtInvoice } from '@pigello/pigello-matrix';

import { DebtInvoiceConfig } from "@pigello/pigello-matrix"

import type { UseMutationOptions } from '@tanstack/react-query';
import { useCreateGenericInstance } from '@/requests/hooks';
import type { ErrorResponse, createGenericInstanceProps, UseGenericMutateOptions } from '@/requests/types';
import { createGenericInstance } from '@/requests/creating';

export const createDebtInvoice = async (options: createGenericInstanceProps<DebtInvoice>) => {
  return createGenericInstance<DebtInvoice>({
    ...options,
    config: DebtInvoiceConfig,
  });
};

export const useCreateDebtInvoice = (
  options: UseMutationOptions<DebtInvoice, ErrorResponse, unknown, unknown> & UseGenericMutateOptions
) => useCreateGenericInstance<DebtInvoice>(createDebtInvoice, options, 'debtinvoice');
