import { DynamicField } from '@/components/form/dynamic-field';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { useMutateInvoicePayment } from '@/config/accounting/payments/invoicepayment/mutate';
import { useForm } from '@/hooks/useForm';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { useModal } from '@/hooks/useModal';
import { useToast } from '@/hooks/useToast';
import type { ErrorResponse } from '@/requests/types';
import {
  InvoicePaymentConfig,
  getCleanInvoicePayment,
  type InvoicePayment,
} from '@pigello/pigello-matrix';

export function AnnulledModal({
  invoicePaymentId,
  closeFunction,
}: {
  invoicePaymentId: string;
  closeFunction: VoidFunction;
}) {
  const { mutateAsync } = useMutateInvoicePayment({});
  const { addToast } = useToast();
  const { openConfirmModal } = useModal();
  const form = useForm({
    defaultValues: getCleanInvoicePayment(),
    config: InvoicePaymentConfig,
    isUpdate: true,
  });
  const handleAnnulment = (data: Partial<InvoicePayment>) => {
    addToast({
      type: 'promise',
      promise: mutateAsync({
        id: invoicePaymentId,
        body: { annulled: true, ...data },
      }),
      loading: 'Annullerar betalning...',
      error: (error: ErrorResponse) => {
        const err = handleFormErrors(error, form);
        return `Betalning kunde ej annulleras, ${err}`;
      },
      success: () => {
        form.reset();
        closeFunction();
        return 'Betalning annullerad';
      },
    });
  };
  const onSubmit = () => {
    const data = form.getDirtyData();
    openConfirmModal({
      title: 'Är du säker?',
      message: `Betalningen tas bort från fakturan, alla rader i
      betalverifikatet överstryks och fakturans status återställs till
      obetald eller delvis betald.`,
      cancelButtonText: 'Avbryt',
      confirmButtonText: 'Annullera betalning',
      onConfirm: () => {
        handleAnnulment(data);
      },
    });
  };
  return (
    <AlertDialog defaultOpen onOpenChange={closeFunction}>
      <AlertDialogContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <AlertDialogHeader>
              <AlertDialogTitle>Annullera betalning</AlertDialogTitle>
            </AlertDialogHeader>
            <AlertDialogDescription>
              När du annullerar en betalning måste alltid hela betalningen
              annulleras. Till exempel, en betalning har registrerats på två
              fakturor (en korrekt och en felaktig), kommer båda betalningarna
              att annulleras.
            </AlertDialogDescription>

            <div className='my-4 grid gap-4'>
              <DynamicField
                horizontal={true}
                formField={form.fields.forcedAnnullmentBookkeepingAccount}
              />

              <DynamicField
                horizontal={true}
                formField={form.fields.skipAnnullmentBookkeeping}
              />
            </div>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={closeFunction}>
                Avbryt
              </AlertDialogCancel>
              <Button type='submit'>Annullera betalning</Button>
            </AlertDialogFooter>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  );
}
