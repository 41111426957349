import {
  defaultBooleanFilter,
  defaultRelationFilter,
  defaultSelectFilter,
  defaultStringFilter,
  type IFilter,
} from '@/components/table-filters/constants';
import { OrganizationUserConfig, RoleConfig } from '@pigello/pigello-matrix';

const organizationUserFilters: IFilter[] = [
  {
    title: OrganizationUserConfig.fields.communicationName.verboseName,
    externalFieldName:
      OrganizationUserConfig.fields.communicationName.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: OrganizationUserConfig.fields.email.verboseName,
    externalFieldName: OrganizationUserConfig.fields.email.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: OrganizationUserConfig.fields.emailVerified.verboseName,
    externalFieldName:
      OrganizationUserConfig.fields.emailVerified.externalFieldName,
    ...defaultBooleanFilter,
  },
  {
    title: OrganizationUserConfig.fields.phoneNumber.verboseName,
    externalFieldName:
      OrganizationUserConfig.fields.phoneNumber.externalFieldName,
    ...defaultStringFilter,
  },
  {
    title: OrganizationUserConfig.fields.phoneNumberVerified.verboseName,
    externalFieldName:
      OrganizationUserConfig.fields.phoneNumberVerified.externalFieldName,
    ...defaultBooleanFilter,
  },
  {
    title: OrganizationUserConfig.fields.detailAccess.verboseName,
    externalFieldName:
      OrganizationUserConfig.fields.detailAccess.externalFieldName,
    choices: OrganizationUserConfig.fields.detailAccess.choices,
    ...defaultSelectFilter,
  },
  {
    title: OrganizationUserConfig.fields.role.verboseName,
    externalFieldName: OrganizationUserConfig.fields.role.externalFieldName,
    relationModelName: RoleConfig.modelName,
    ...defaultRelationFilter,
  },
  // {
  //   title: OrganizationUserConfig.fields.workEngagements.verboseName,
  //   externalFieldName:
  //     OrganizationUserConfig.fields.workEngagements.externalFieldName,
  //   relationModelName: WorkEngagementConfig.modelName,
  //   ...defaultRelationFilter,
  // },
  {
    title: OrganizationUserConfig.fields.username.verboseName,
    externalFieldName: OrganizationUserConfig.fields.username.externalFieldName,
    ...defaultStringFilter,
  },
];

export default organizationUserFilters;
